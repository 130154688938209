import React, { Component, Fragment } from "react";
import "./DashboardProspection.css";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
	APPLICATION_NAME,
	ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT,
	ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS,
	ID_PERMISSIONS_GROUP_PROSPECTION_MANAGERS,
	ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR,
	IDS_PERMISSIONS_GROUP_MANAGERS,
	months,
	STATUS_BUSINESS_GAIN,
	STATUS_BUSINESS_LOSS
} from "../../core/constants";
import { 
	formatedDateTime,
	formatedDateTimeToBRA,
	formatedDateTimeToUSA,
	formatedMoneyBRAFromFloat,
	formatedPercentageNumberFromFloat,
	formatedTime,
	getCurrentTimeBrowser,
	getDateTimeCurrentBrowserAtMidnight,
	getGreetingMessage,
	getOptionsSelectCheckedFormated,
	getWindowDimensions,
	sortInAlphabeticalOrder,
	validTime,
	getRandomNumber
} from "../../auxiliary/generalFunctions";
import { Cell, Pie, PieChart } from "recharts";

import personDefault from "../../assets/images/person-default.png";

import { setModalMessage } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import DatePickerCustom from "../../components/general/DatePickerCustom";
import Box from "../../components/general/Box";
import PerformanceIndex from "../../components/general/PerformanceIndex";
import SubtitleProgressLine from "../../components/CRM/SubtitleProgressLine";
import CardChartPie from "../../components/CRM/CardChartPie";
import CardInfoLeftChart from "../../components/CRM/CardInfoLeftChart";
import CardResumeWithHover from "../../components/CRM/CardResumeWithHover";
import Loading from "../../components/general/Loading";
import MessageContent from "../../components/general/MessageContent";
import ButtonIcon from "../../components/general/ButtonIcon";

import UserController from "../../controllers/UserController";
import DashboardProspectionController from "../../controllers/DashboardProspectionController";
import SelectMulti from "../../components/general/SelectMulti";
import Button from "../../components/general/Button";
import StylizedMoneyOrNumber from "../../components/general/StylizedMoneyOrNumber";
import InputGeneral from "../../components/general/InputGeneral";
import LimitedDynamicTable from "../../components/general/LimitedDynamicTable";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";

const COLORS = ['transparent', 'success', 'warning', 'error', 'focus', 'information'];
const MIN_WIDTH_TO_FIXED_TABLE_TITLE_RULER = 1024;

var DEFAULT_VALUES_CARDS_STATE = {
	positionHeaderTitleRulerAttandants: "",
	positionHeaderTitleRulerCoordinatorsAndSupervisors: "",

	//start general charts data
	typeCommissionRuleCurrent: 2,
	minimuGoalDayComissionRule: 0,
	minimumGoalMonthComissionRule: 0,
	amountBusiness: 0,
	amountClients: 0,
	amountClientsAnotherPeriod: 0,
	amountClientsAnotherPeriodValueTotal: 0,
	amountBusinessValueTotal: 0,
	amountBusinessToday: 0,
	amountBusinessTodayValueTotal: 0,
	amountBusinessOwnerProspection: 0,
	amountBusinessOwnerProspectionValueTotal: 0,
	amountScheduled: 0,
	amountScheduledDay: 0,
	amountScheduledDayValueTotal: 0,
	amountScheduledMonth: 0,
	amountScheduledMonthValueTotal: 0,
	amountScheduledValueTotal: 0,
	amountScheduledSamePeriod: 0,
	amountScheduledSamePeriodValueTotal: 0,
	amountClientsScheduledSamePeriod: 0,
	amountScheduledAnotherPeriod: 0,
	amountScheduledAnotherPeriodValueTotal: 0,
	amountClientsScheduledAnotherPeriod: 0,
	amountScheduledTotalSameAndAnotherPeriod: 0,
	amountScheduledTotalSameAndAnotherPeriodValueTotal: 0,
	amountScheduledTo: 0,
	amountScheduledToValueTotal: 0,
	amountScheduledToCurrentTime: 0,
	amountScheduledToCurrentTimeValueTotal: 0,
	amountScheduledToSamePeriod: 0,
	amountScheduledToSamePeriodValueTotal: 0,
	amountScheduledToAnotherPeriod: 0,
	amountScheduledToAnotherPeriodValueTotal: 0,
	amountShowedUp: 0,
	amountShowedUpValueTotal: 0,
	amountShowedUpWithoutStatusBusiness: 0,
	amountShowedUpWithoutStatusBusinessValueTotal: 0,
	amountNoShowedUp: 0,
	amountNoShowedUpValueTotal: 0,
	amountGain: 0,
	amountGainValueTotal: 0,
	amountGainWithShowedup: 0,
	amountGainWithShowedupValueTotal: 0,
	amountGainWithUnits: 0,
	amountGainWithUnitsValueTotal: 0,
	amountGainERP: 0,
	amountGainValueTotalERP: 0,
	amountLoss: 0,
	amountLossValueTotal: 0,
	//ends general charts data

	// start general results (cards)
	amountBusinessAllGeneralResults: 0,
	amountBusinessInProgressAttendanceGeneralResults: 0,
	amountBusinessLossGeneralResults: 0,
	detailsBusinessLossGeneralResults: [],
	amountBusinessPausedGeneralResults: 0,
	amountBusinessNotApplicableGeneralResults: 0,
	amountBusinessDiscardedMonthResults: 0,
	detailsBusinessNotApplicableGeneralResults: [],
	amountBusinessDiscardedGeneralResults: 0,
	detailsBusinessDiscardedGeneralResults: [],
	amountBusinessWithoutAttendanceGeneralResults: 0,
	amountScheduledGeneralResults: 0,
	percentUtilizationGeneralResults: 0,
	// ends general results (cards)

	// start month results (cards)
	amountBusinessAllMonthResults: 0,
	amountBusinessBeforeMonthMonthResults: 0,
	amountBusinessInProgressAttendanceMonthResults: 0,
	amountBusinessLossMonthResults: 0,
	detailsBusinessLossMonthResults: [],
	amountBusinessPausedMonthResults: 0, 
	amountBusinessMonthMonthResults: 0,
	amountBusinessNotApplicableMonthResults: 0,
	detailsBusinessNotApplicableMonthResults: [],
	detailsBusinessDiscardedMonthResults: [],
	amountBusinessTodayMonthResults: 0,
	amountBusinessWeekMonthResults: 0,
	amountBusinessWithoutAttendanceMonthResults: 0,
	amountScheduledMonthResults: 0,
	percentMonthOverBeforeMonthMonthResults: 0,
	percentUtilizationMonthResults: 0,
	paramsDateCalculatedMonthResults: {},
	paramsDateStartWeek: "",
	paramsDateEndsWeek: "",
	paramsFirstDayBeforeMonth: "",
	paramsLastDayBeforeMonthProportionally: "",
	paramsFirstDayCurrentMonth: "",
	// ends month results (cards)

	// start content about utilization owner prospection by user
	percentUtilizationOwnerProspectionBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentUtilizationOwnerProspectionByUser: "0",
	colorProgressChartPieUtilizationOwnerProspectionBusinessByUser: "success",
	// ends content about utilization owner prospection by user

	// start content about utilization by user
	percentUtilizationBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentUtilizationByUser: "0",
	colorProgressChartPieUtilizationBusinessByUser: "success",
	// ends content about utilization by user

	// start content about utilization scheduled month by user
	percentUtilizationScheduledMonthByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentUtilizationScheduledMontByUser: "0",
	colorProgressChartPieUtilizationScheduledMonthByUser: "purple",
	// ends content about utilization scheduled month by user

	// start content about utilization scheduled day by user
	percentUtilizationScheduleDayByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentUtilizationScheduledDayByUser: "0",
	colorProgressChartPieUtilizationScheduleDayByUser: "information",
	// ends content about utilization scheduled day by user

	// start content about utilization by user
	percentUtilizationEffectiveBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentUtilizationEffectiveByUser: "0",
	colorProgressChartPieUtilizationEffectiveBusinessByUser: "success",
	// ends content about utilization by user

	// start content about utilization by user
	percentGainBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	labelPercentGainByUser: "0",
	colorProgressChartPieGainBusinessByUser: "success",
	// ends content about utilization by user	

	// start message content modal
	message_type_content: "information",
	message_show_content: false,
	message_content_content: null, //DOM element
	message_title_content: "",
	// ends message content modal

	loadingCards: true,
	requestedTables: false,
}

var DEFAULT_VALUES_TABLES_STATE = {

	// start content about tables
	attendanceByUsersAttendants: [],
	attendanceByUsersCoordinatorsAndSupervisors: {},
	attendanceByUsersCompilation: {},
	loadingTables: true,
	// ends content about tables
}

const initialState = {

	name: "", 
	mail: "", //looks like: "name@domain.com"
	photo: personDefault, //looks like: "https://i.pinimg.com/564x/d3/1a/e3/d31ae3d4130a644b4f53eaefdaa481f0.jpg"
	lastAccess: "", //looks like: "01/01/2022 às 14hs"
	currentMonthYear: "", //looks like: "2022-04"
	currentMonthName: "", //looks like: "Abril"
	dateRunning: "", //looks like: "2022/12/31"
	chosenStartDateReport: "", //looks like: "2022-09-01"
	chosenEndsDateReport: "", //looks like: "2022-09-31"
	isWithinTheSameMonth: true, //default: true
	timeStartDataReport: "00:00",
	timeEndsDataReport: "00:00",
	optionsUnitCompany: [],
	optionsUnitBrand: [],
	hasAvatar: false,
	version: 1, //default is 1 (one)

	idsAttendantsByUserOnAccess: "", //comma separation: looks like: "1,2,3,4,",
	permissions: {}, // permissions object by user on acess

	...DEFAULT_VALUES_CARDS_STATE,
	...DEFAULT_VALUES_TABLES_STATE
}

const MESSAGE_WAITING_REPORT_IN_PROGRESS = "Por favor, aguarde a geração do relatório em andamento, para poder escolher outro período!";

const dashboardProspectionController = new DashboardProspectionController();
const userController = new UserController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();

class DashboardProspection extends Component
{
	_mounted = false;
	state = {...initialState}

	constructor(props) 
	{
        super(props);        
        this.containerScroll = React.createRef();
        this.boxTableAttandantsDashboardProspection = React.createRef();
        this.boxTableLeadersDashboardProspection = React.createRef();
    }

	componentDidMount = async () =>
	{
		this._mounted = true;
		document.title = `${APPLICATION_NAME} - Vendas Operação`;

		await this.getAvatar();
		await this.getInitialParameters();
		await this.getCompanyUnit();
		await this.getCardsDataDashboardProspection();
		await this.getAttendantsBySupervisorOnAccess();
	}

	componentDidUpdate = async (prevProps) =>
    {
        // console.log("DASHBOARD PROSPECTION prevState ", prevState);
        // console.log("DASHBOARD PROSPECTION this.state ", this.state.allSpecialists);
		
        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.dashboardProspection.toView) 
			{ 
				this.props.history.push(`/activities`);
			}
			else
			{ 
				let permissions = this.props.dashboardProspection;
				this.setState({permissions}); 
			}
		}
    }

	componentWillUnmount = async () =>
	{
		this._mounted = false;
		console.info("DASHBOARD PROSPECTION demoting.");
	}

	getAvatar = async () => 
	{
		let result = await userController.hasAvatar(this.props.user.PER_ID);
		if (result.status)
		{
			let {data} = result.data;
			this.setState({hasAvatar: data});
		}
	}

	getInitialParameters = async () =>
	{
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		let version = (window.location.href.toString().includes("version=2")) ? 2 : 1;

		if (this._mounted)
		{
			await this.setState({
				user: this.props.user,
				dateRunning: currentDate,
				timeEndsDataReport: "23:59",
				version,
				...paramsDate
			});
		}
	}
	
	getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
		let optionsUnitBrandChecked = await this.state.optionsUnitBrand.filter(b => b.checked).map(b => b.value);
        let optionsUnitCompany = [];
		let optionsUnitBrand = [];
		
        for (const unit of result.data.unit_companies) 
        {
            optionsUnitCompany.push(
				{
					name: `${unit.UNT_NAME}`,
					value: unit.UNT_ID,
					checked: (optionsUnitBrandChecked.includes(unit.BNT_ID)) ? true : false,
					brand: unit.BNT_ID
            	}
			);

			let inserted = false;

			if (optionsUnitBrand.length > 0)
			{
				inserted = optionsUnitBrand.filter(b => b.value === unit.BNT_ID);
				inserted = inserted.length > 0 ? true : false;
			}

			if (!inserted) 
			{
				optionsUnitBrand.push(
					{
						name: `${unit.BNT_NAME}`,
						value: unit.BNT_ID,
						checked: (unit.BNT_ID !== 8 && unit.BNT_ID !== 9) ? true : false
					}
				);
			}
        }

		if (this._mounted)
		{
			optionsUnitCompany = sortInAlphabeticalOrder(optionsUnitCompany);
			optionsUnitBrand = sortInAlphabeticalOrder(optionsUnitBrand);
			await this.setState({ optionsUnitCompany, optionsUnitBrand });
			this.onChangeUnitBrand(optionsUnitBrand);
		}
    }

	onChangeUnitBrand = async (optionsUnitBrand) =>
	{
		let optionsUnitCompany = await this.state.optionsUnitCompany;
		let optionsUnitBrandChecked = optionsUnitBrand.filter(b => b.checked).map(b => b.value);

		optionsUnitCompany = optionsUnitCompany.map(u => {
			u.checked = optionsUnitBrandChecked.includes(u.brand);
			return u;
		});

		if (this._mounted)
		{
			optionsUnitCompany = sortInAlphabeticalOrder(optionsUnitCompany);
			await this.setState({ optionsUnitCompany, optionsUnitBrand });
		}
	}

	setDefaultValuesCardsState = async () =>
	{
		if (this._mounted)
		{
			await this.setState({...DEFAULT_VALUES_CARDS_STATE});
		}
	}

	setDefaultValuesTablesState = async () =>
	{
		if (this._mounted)
		{
			await this.setState({...DEFAULT_VALUES_TABLES_STATE});
		}
	}

	getCardsDataDashboardProspection = async () =>
	{
		let user = await this.state.user;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let timeStartDataReport = await this.state.timeStartDataReport;
		let timeEndsDataReport = await this.state.timeEndsDataReport;
		let dateRunning = await this.state.dateRunning;
		let optionsUnitCompany = await this.state.optionsUnitCompany.filter(u => u.checked).map(u => u.value);
		let version = await this.state.version;
		let filters = {
			units: [...optionsUnitCompany]
		};

		if (timeStartDataReport.length <= 4 || timeEndsDataReport.length <= 4)
		{
			await this.message("information", "Confira os campos de horas de início e fim, e tente novamente!");
			return;
		}

		await this.setDefaultValuesCardsState();

		let target = "CARDS";
		let resultDashboardProspectionCards = await dashboardProspectionController.get(
				user.PER_ID,
				chosenStartDateReport,
				chosenEndsDateReport,
				timeStartDataReport,
				timeEndsDataReport,
				dateRunning,
				target,
				filters,
				version
			);

		if (resultDashboardProspectionCards.status)
		{
			let {
				name, 
				mail,
				photo, 
				lastAccess, 
				charts, 
				generalResults,
				monthResults
			} = resultDashboardProspectionCards.data.data;

			let {
				typeCommissionRuleCurrent,
				minimuGoalDayComissionRule,
				minimumGoalMonthComissionRule,
				amountBusiness,
				amountClients,
				amountClientsAnotherPeriod,
				amountClientsAnotherPeriodValueTotal,
				amountBusinessValueTotal,
				amountBusinessToday, 
				amountBusinessTodayValueTotal,
				amountBusinessOwnerProspection, 
				amountBusinessOwnerProspectionValueTotal,
				amountScheduled,
				amountScheduledDay,
				amountScheduledDayValueTotal,
				amountScheduledMonth,
				amountScheduledMonthValueTotal,
				amountScheduledValueTotal,
				amountScheduledSamePeriod,
				amountScheduledSamePeriodValueTotal,
				amountClientsScheduledSamePeriod,
				amountScheduledAnotherPeriod,
				amountScheduledAnotherPeriodValueTotal,
				amountClientsScheduledAnotherPeriod,
				amountScheduledTotalSameAndAnotherPeriod,
				amountScheduledTotalSameAndAnotherPeriodValueTotal,
				amountScheduledTo,
				amountScheduledToValueTotal,
				amountScheduledToCurrentTime,
				amountScheduledToCurrentTimeValueTotal,
				amountScheduledToSamePeriod,
				amountScheduledToSamePeriodValueTotal,
				amountScheduledToAnotherPeriod,
				amountScheduledToAnotherPeriodValueTotal,
				amountShowedUp,
				amountShowedUpValueTotal,
				amountShowedUpWithoutStatusBusiness,
				amountShowedUpWithoutStatusBusinessValueTotal,
				amountNoShowedUp,
				amountNoShowedUpValueTotal,
				amountGain,
				amountGainValueTotal,
				amountGainWithShowedup,
				amountGainWithShowedupValueTotal,
				amountGainWithUnits,
				amountGainWithUnitsValueTotal,
				amountGainERP,
				amountGainValueTotalERP,
				amountLoss,
				amountLossValueTotal,
				percentScheduledSamePeriodOverBusiness,
				percentClientsSamePeriodOverClients,
				percentScheduledAnotherPeriodOverBusiness,
				percentClientsScheduledAnotherPeriodOverClients,
				percentGeneralUtilization,
				percentScheduledOverBusiness,
				percentScheduledOverBusinessOwnerProspection,
				percentShowedUpOverScheduledToCurrentTime,
				percentGainOverShowedUp,
				percentScheduledMonthOverGoal,
				percentScheduledDayOverGoal
			} = charts;

			if (charts?.debug) { console.log(charts.debug); }

			let amountBusinessAllMonthResults = 0;
			let amountBusinessBeforeMonthMonthResults = 0;
			let amountBusinessInProgressAttendanceMonthResults = 0;
			let amountBusinessLossMonthResults = 0;
			let amountBusinessPausedMonthResults = 0;
			let amountBusinessMonthMonthResults = 0;
			let amountBusinessNotApplicableMonthResults = 0;
			let amountBusinessDiscardedMonthResults = 0;
			let amountBusinessTodayMonthResults = 0;
			let amountBusinessWeekMonthResults = 0;
			let amountBusinessWithoutAttendanceMonthResults = 0;
			let amountScheduledMonthResults = 0;
			let percentMonthOverBeforeMonthMonthResults = 0;
			let percentUtilizationMonthResults = 0;
			let detailsBusinessNotApplicableMonthResults = [];
			let detailsBusinessLossMonthResults = [];
			let detailsBusinessDiscardedMonthResults = [];
			let paramsDateCalculatedMonthResults = {};

			let amountBusinessAllGeneralResults = 0;
			let amountBusinessInProgressAttendanceGeneralResults = 0;
			let amountBusinessLossGeneralResults = 0;
			let detailsBusinessLossGeneralResults = [];
			let amountBusinessPausedGeneralResults = 0;
			let amountBusinessNotApplicableGeneralResults = 0;
			let amountBusinessDiscardedGeneralResults = 0;
			let detailsBusinessNotApplicableGeneralResults = [];
			let detailsBusinessDiscardedGeneralResults = [];
			let amountBusinessWithoutAttendanceGeneralResults = 0;
			let amountScheduledGeneralResults = 0;
			let percentUtilizationGeneralResults = 0;

			if (generalResults && monthResults)
			{
				amountBusinessAllMonthResults = monthResults.amountBusinessAll;
				amountBusinessBeforeMonthMonthResults = monthResults.amountBusinessBeforeMonth;
				amountBusinessInProgressAttendanceMonthResults = monthResults.amountBusinessInProgressAttendance;
				amountBusinessLossMonthResults = monthResults.amountBusinessLoss;
				amountBusinessPausedMonthResults = monthResults.amountBusinessPaused;
				amountBusinessMonthMonthResults = monthResults.amountBusinessMonth;
				amountBusinessNotApplicableMonthResults = monthResults.amountBusinessNotApplicable;
				amountBusinessDiscardedMonthResults = monthResults.amountBusinessDiscarded;
				amountBusinessTodayMonthResults = monthResults.amountBusinessToday;
				amountBusinessWeekMonthResults = monthResults.amountBusinessWeek;
				amountBusinessWithoutAttendanceMonthResults = monthResults.amountBusinessWithoutAttendance;
				amountScheduledMonthResults = monthResults.amountScheduled;
				percentMonthOverBeforeMonthMonthResults = monthResults.percentMonthOverBeforeMonth;
				percentUtilizationMonthResults = monthResults.percentUtilization;
				detailsBusinessNotApplicableMonthResults = monthResults.detailsBusinessNotApplicable;
				detailsBusinessLossMonthResults = monthResults.detailsBusinessLoss;
				detailsBusinessDiscardedMonthResults = monthResults.detailsBusinessDiscarded;
				paramsDateCalculatedMonthResults = monthResults.paramsDateCalculated;

				amountBusinessAllGeneralResults = generalResults.amountBusinessAll;
				amountBusinessInProgressAttendanceGeneralResults = generalResults.amountBusinessInProgressAttendance;
				amountBusinessLossGeneralResults = generalResults.amountBusinessLoss;
				amountBusinessPausedGeneralResults = generalResults.amountBusinessPaused;
				amountBusinessNotApplicableGeneralResults = generalResults.amountBusinessNotApplicable;
				amountBusinessDiscardedGeneralResults = generalResults.amountBusinessDiscarded;
				amountBusinessWithoutAttendanceGeneralResults = generalResults.amountBusinessWithoutAttendance;
				amountScheduledGeneralResults = generalResults.amountScheduled;
				percentUtilizationGeneralResults = generalResults.percentUtilization;
				detailsBusinessNotApplicableGeneralResults = generalResults.detailsBusinessNotApplicable;
				detailsBusinessDiscardedGeneralResults = generalResults.detailsBusinessDiscarded;
				detailsBusinessLossGeneralResults = generalResults.detailsBusinessLoss;

			}

			if (this._mounted)
			{
				await this.setState({
					name,
					mail,
					photo: photo === "" ? personDefault : photo,
					lastAccess: formatedDateTime(lastAccess),
					typeCommissionRuleCurrent,
					minimuGoalDayComissionRule,
					minimumGoalMonthComissionRule,
					amountBusiness,
					amountClients,
					amountClientsAnotherPeriod,
					amountClientsAnotherPeriodValueTotal,
					amountBusinessValueTotal,
					amountBusinessToday, 
					amountBusinessTodayValueTotal,
					amountBusinessOwnerProspection, 
					amountBusinessOwnerProspectionValueTotal,
					amountScheduled,
					amountScheduledDay,
					amountScheduledDayValueTotal,
					amountScheduledMonth,
					amountScheduledMonthValueTotal,
					amountScheduledValueTotal,
					amountScheduledSamePeriod, //REMOVE
					amountScheduledSamePeriodValueTotal,
					amountClientsScheduledSamePeriod,
					amountScheduledAnotherPeriod, //REMOVE
					amountScheduledAnotherPeriodValueTotal,
					amountClientsScheduledAnotherPeriod,
					amountScheduledTotalSameAndAnotherPeriod,
					amountScheduledTotalSameAndAnotherPeriodValueTotal,
					amountScheduledTo,
					amountScheduledToValueTotal,
					amountScheduledToCurrentTime,
					amountScheduledToCurrentTimeValueTotal,
					amountScheduledToSamePeriod,
					amountScheduledToSamePeriodValueTotal,
					amountScheduledToAnotherPeriod,
					amountScheduledToAnotherPeriodValueTotal,
					amountShowedUp,
					amountShowedUpValueTotal,
					amountShowedUpWithoutStatusBusiness,
					amountShowedUpWithoutStatusBusinessValueTotal,
					amountNoShowedUp,
					amountNoShowedUpValueTotal,
					amountGain,
					amountGainValueTotal,
					amountGainWithShowedup,
					amountGainWithShowedupValueTotal,
					amountGainWithUnits,
					amountGainWithUnitsValueTotal,
					amountGainERP: amountGainERP || 0,
					amountGainValueTotalERP: amountGainValueTotalERP || 0,
					amountLoss,
					amountLossValueTotal,
					percentScheduledSamePeriodOverBusiness, //REMOVE
					percentClientsSamePeriodOverClients,
					percentScheduledAnotherPeriodOverBusiness, //REMOVE
					percentClientsScheduledAnotherPeriodOverClients,
					percentGeneralUtilization,
					percentScheduledOverBusiness,
					loadingCards: false,
					// start general results
					amountBusinessAllGeneralResults,
					amountBusinessInProgressAttendanceGeneralResults,
					amountBusinessLossGeneralResults,
					detailsBusinessLossGeneralResults,
					amountBusinessPausedGeneralResults,
					amountBusinessNotApplicableGeneralResults,
					amountBusinessDiscardedGeneralResults,
					detailsBusinessNotApplicableGeneralResults,
					detailsBusinessDiscardedGeneralResults,
					amountBusinessWithoutAttendanceGeneralResults,
					amountScheduledGeneralResults,
					percentUtilizationGeneralResults,
					// ends general results
					// start month results
					amountBusinessAllMonthResults,
					amountBusinessBeforeMonthMonthResults,
					amountBusinessInProgressAttendanceMonthResults,
					amountBusinessLossMonthResults,
					detailsBusinessLossMonthResults,
					detailsBusinessDiscardedMonthResults,
					amountBusinessPausedMonthResults,
					amountBusinessMonthMonthResults,
					amountBusinessNotApplicableMonthResults,
					amountBusinessDiscardedMonthResults,
					detailsBusinessNotApplicableMonthResults,
					amountBusinessTodayMonthResults,
					amountBusinessWeekMonthResults,
					amountBusinessWithoutAttendanceMonthResults,
					amountScheduledMonthResults,
					percentMonthOverBeforeMonthMonthResults,
					percentUtilizationMonthResults,
					paramsDateCalculatedMonthResults,
					paramsDateStartWeek: paramsDateCalculatedMonthResults.dateStartWeek,
					paramsDateEndsWeek: paramsDateCalculatedMonthResults.dateEndsWeek,
					paramsFirstDayBeforeMonth: paramsDateCalculatedMonthResults.firstDayBeforeMonth,
					paramsLastDayBeforeMonthProportionally: paramsDateCalculatedMonthResults.lastDayBeforeMonthProportionally,
					paramsFirstDayCurrentMonth: paramsDateCalculatedMonthResults.firstDayCurrentMonth,
					// ends month results
				});

				await this.setDataChartScheduledOverBusinessOwnerProspection(percentScheduledOverBusinessOwnerProspection, "success");
				await this.setDataChartScheduledOverBusiness(percentGeneralUtilization, "success");
				await this.setDataChartShowedUpOverScheduled(percentShowedUpOverScheduledToCurrentTime, "success");
				await this.setDataChartGainOverShowedUp(percentGainOverShowedUp, "success");
				await this.setDataChartScheduledMonthnOverGoal(percentScheduledMonthOverGoal, "purple");
				await this.setDataChartScheduledDayOverGoal(percentScheduledDayOverGoal, "information");
			}
		}

	}

	getTablesDataDashboardProspection = async () =>
	{
		let user = await this.state.user;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let timeStartDataReport = await this.state.timeStartDataReport;
		let timeEndsDataReport = await this.state.timeEndsDataReport;
		let dateRunning = await this.state.dateRunning;
		let optionsUnitCompany = await this.state.optionsUnitCompany.filter(u => u.checked).map(u => u.value);
		let version = await this.state.version;
		let filters = {
			units: [...optionsUnitCompany]
		};

		if (timeStartDataReport.length <= 4 || timeEndsDataReport.length <= 4)
		{
			await this.message("information", "Confira os campos de horas de início e fim, e tente novamente!");
			return;
		}

		await this.setDefaultValuesTablesState();
		await this.setState({requestedTables: true});

		let target = "TABLES";
		let resultDashboardProspectionCards = await dashboardProspectionController.get(
			user.PER_ID,
			chosenStartDateReport,
			chosenEndsDateReport,
			timeStartDataReport,
			timeEndsDataReport,
			dateRunning,
			target,
			filters,
			version
		);

		let {
			attendanceByUsersAttendants,
			attendanceByUsersCoordinatorsAndSupervisors,
			attendanceByUsersCompilation
		} = resultDashboardProspectionCards.data.data;

		if (resultDashboardProspectionCards.data.data?.debug) { console.log(resultDashboardProspectionCards.data.data.debug); }

		if (this._mounted)
		{
			await this.setState({
				attendanceByUsersAttendants,
				attendanceByUsersCoordinatorsAndSupervisors,
				attendanceByUsersCompilation,
				loadingTables: false,
				requestedTables: true,
			});
		}

	}

	setDatesAndTimesToday = async () =>
	{
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let loadingCards = await this.state.loadingCards;
		let timeEndsDataReport = getCurrentTimeBrowser();

		if (loadingCards)
		{
			this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
			return;
		}

		if (this._mounted)
		{
			await this.setState({
				chosenStartDateReport: currentDate,
				chosenEndsDateReport: currentDate,
				timeStartDataReport: "00:00",
				timeEndsDataReport,
				dateRunning: currentDate,
				attendanceByUsersAttendants: []
			});

			await this.getCardsDataDashboardProspection();
		}

	}

	setDataChartScheduledOverBusinessOwnerProspection = async (percentScheduledOverBusinessOwnerProspection, color) =>
	{
		let percentUtilizationOwnerProspectionBusinessByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentScheduledOverBusinessOwnerProspection) },
			{ id: 2, name: 'Progresso', value: percentScheduledOverBusinessOwnerProspection },
		];
		let labelPercentUtilizationOwnerProspectionByUser = percentScheduledOverBusinessOwnerProspection.toString().replace(".", ",");

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationOwnerProspectionBusinessByUser,
				labelPercentUtilizationOwnerProspectionByUser,
				colorProgressChartPieUtilizationOwnerProspectionBusinessByUser: color
			})
		}
	}

	setDataChartScheduledOverBusiness = async (percentScheduledSamePeriodOverBusiness, color) =>
	{
		let percentUtilizationBusinessByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentScheduledSamePeriodOverBusiness) },
			{ id: 2, name: 'Progresso', value: percentScheduledSamePeriodOverBusiness },
		];
		let labelPercentUtilizationByUser = percentScheduledSamePeriodOverBusiness?.toString().replace(".", ",") || "0";

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationBusinessByUser,
				labelPercentUtilizationByUser,
				colorProgressChartPieUtilizationBusinessByUser: color
			})
		}
	}

	setDataChartShowedUpOverScheduled = async (percentShowedUpOverScheduledTo, color) =>
	{
		let	percentUtilizationEffectiveBusinessByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentShowedUpOverScheduledTo) },
			{ id: 2, name: 'Progresso', value: percentShowedUpOverScheduledTo },
		];
		let labelPercentUtilizationEffectiveByUser = percentShowedUpOverScheduledTo.toString().replace(".", ",");

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationEffectiveBusinessByUser,
				labelPercentUtilizationEffectiveByUser,
				colorProgressChartPieUtilizationEffectiveBusinessByUser: color
			})
		}
	}

	setDataChartGainOverShowedUp = async (percentGainOverShowedUp, color) =>
	{
		let percentGainBusinessByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentGainOverShowedUp) },
			{ id: 2, name: 'Progresso', value: percentGainOverShowedUp },
		];
		let labelPercentGainByUser = percentGainOverShowedUp.toString().replace(".", ",");

		if (this._mounted)
		{
			await this.setState({
				percentGainBusinessByUser,
				labelPercentGainByUser,
				colorProgressChartPieGainBusinessByUser: color
			})
		}
	}

	setDataChartScheduledMonthnOverGoal = async (percentScheduledOverGoal, color) =>
	{
		let percentUtilizationScheduledMonthByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentScheduledOverGoal) },
			{ id: 2, name: 'Progresso', value: percentScheduledOverGoal },
		];
		let labelPercentUtilizationScheduledMontByUser = percentScheduledOverGoal.toString().replace(".", ",");

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationScheduledMonthByUser,
				labelPercentUtilizationScheduledMontByUser,
				colorProgressChartPieUtilizationScheduledMonthByUser: color
			})
		}
	}

	setDataChartScheduledDayOverGoal = async (percentScheduledOverGoal, color) =>
	{
		let percentUtilizationScheduleDayByUser = [
			{ id: 1, name: '[NO_FILL]', value: (100 - percentScheduledOverGoal) },
			{ id: 2, name: 'Progresso', value: percentScheduledOverGoal },
		];
		let labelPercentUtilizationScheduledDayByUser = percentScheduledOverGoal.toString().replace(".", ",");

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationScheduleDayByUser,
				labelPercentUtilizationScheduledDayByUser,
				colorProgressChartPieUtilizationScheduleDayByUser: color
			})
		}
	}

	getCurrentFirstAndLastDayOnMonth = async (chosenMonthYear) =>
	{
		let parts = chosenMonthYear.toString().split("-");
		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');
		let currentMonthYear = `${y}-${m}`;
		let chosenStartDateReport = formatedDateTimeToUSA((new Date(y, (m - 1), 1)).toDateString());
		let chosenEndsDateReport = formatedDateTimeToUSA((new Date(y, m, 0)).toDateString());
		let currentMonthName = months.find(o => o.order === parseInt(m - 1)).month.toString().toLocaleUpperCase();

		return {
			currentMonthYear,
			chosenStartDateReport,
			chosenEndsDateReport,
			currentMonthName,
		}
	}

	getChosenDatesReport = () =>
	{
		return `${formatedDateTimeToBRA(this.state.chosenStartDateReport)} a ${formatedDateTimeToBRA(this.state.chosenEndsDateReport)}`;
	}

	getColorAndSignalAndValue = (number) =>
	{
		let color = "";
		let value = parseFloat(number);

		if (number > 0)
		{
			color = "success";
		}
		else if (number < 0)
		{
			color = "error";
			value =  Math.abs(value);
		}
		else
		{
			color = "focus";
		}

		return {color, value}
	}

	getAttendantsBySupervisorOnAccess = async () =>
	{
		let permissions = await this.state.permissions;
		let user = this.props.user;
		let idPermissionsGroup = user.PER_ID_PERMISSIONS_GROUP;
		let userId = user.PER_ID;
		let idsAttendantsByUserOnAccess = "";
		
		if (!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup))
		{
			let resultTeamProspection = await prospectionTeamHierarchyController.get();

			if (resultTeamProspection.status)
			{
				let {data} = resultTeamProspection.data;
				let coordinators = data.coordinators;

				let team = await prospectionTeamHierarchyController.getProspectionOwners(coordinators, userId, permissions);
				idsAttendantsByUserOnAccess = team.filter(a => a.value !== 0).map(a => a.value).join(",");

				await this.setState({idsAttendantsByUserOnAccess});
			}
		}
	}

	setChosenMonthReport = async (chosenMonth) =>
	{
		let paramsDate = null;

		if (typeof chosenMonth === "string" && chosenMonth.trim() !== "")
		{
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(chosenMonth);
		}
		else
		{
			// chosenMonth is not empty!
			let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		}

		if (this._mounted)
		{
			await this.setState({...paramsDate});
		}
	}

	getAnchorForDetailsCard = (title, amount, url) =>
	{
		return (
			<div 
				key={`${getRandomNumber()}`}
				className="row no-gutters w-100 lineDetailsCardDashboardProspection"
				onClick={() => {}}
			>
				<div className="col-3">
					{amount}
				</div>
				<div className="col-7">
					{title}
				</div>
				<div className="col-2 d-flex justify-content-end" onClick={() => window.open(url)}>
					<i className="fas fa-eye iconDetailsCardDashboardProspection"></i>
				</div>
			</div>
		);
	}

	getLimitedDynamicTableForAttendants = (content) =>
	{
		let header = [
			{
				name: "Atendente",
				justify: "left"
			},
			{
				name: "ATG",
				nameHelp: "Atendimentos Geral",
			},
			{
				name: "ATP",
				nameHelp: "Atendimentos no Período",
			},
			// {
			// 	name: "NEG",
			// 	nameHelp: "Em Negociação Geral",
			// },
			// {
			// 	name: "NEP",
			// 	nameHelp: "Em Negociação no Período",
			// },
			{
				name: "NAP",
				nameHelp: "Não se Aplica no Período",
			},
			// {
			// 	name: "PAP",
			// 	nameHelp: "Pausados no Período",
			// },
			{
				name: "PEP",
				nameHelp: "Perdidos no Período",
			},
			{
				name: "AGN",
				nameHelp: "Agendados no Período",
			},
			{
				name: "AGD",
				nameHelp: "Média de Agendamentos por dia",
			},
			{
				name: "AGP",
				nameHelp: "Agendados para o Período",
			},
			{
				name: "APP",
				nameHelp: "Aproveitamento no Período",
			},
			{
				name: "COP",
				nameHelp: "Compareceu no Período",
			},
			{
				name: "DEP",
				nameHelp: "Desempenho no Período",
			},
		];
		
		let body = [];
		// separating the body of the table
		content.attendants.forEach((bl, i) => {
			body.push([
				{
					name: bl.name,
					justify: "left"
				},
				{
					name: bl.amountBusinessAllGlobal,
					onClick: () => this.setAdvancedBusinessURL("all", [], [{with_activities: 1, type_activity: "files,historic,phone,task,visit", activity_created_by: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessAll,
					onClick: () => this.setAdvancedBusinessURL("all", [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{with_activities: 1, type_activity: "files,historic,phone,task,visit", activity_created_by: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessInProgressAttendanceGlobal,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				// {
				// 	name: bl.amountBusinessInProgressAttendance,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				{
					name: bl.amountBusinessNotApplicable,
					onClick: () => this.setAdvancedBusinessURL("not_applicable", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessPaused,
				// 	onClick: () => this.setAdvancedBusinessURL("paused", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"					
				// },
				{
					name: bl.amountBusinessLoss,
					onClick: () => this.setAdvancedBusinessURL("loss", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessScheduledSameAndAnotherPeriod,
					onClick: () => this.setAdvancedBusinessURL("all", [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{with_activities: 1, type_activity: "visit", activity_created_by: bl.id, group_by: 2}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessAveragePerDay,
					onClick: () => {},
					justify: "center"
				},
				{
					name: `${bl.amountBusinessScheduledToWithCurrentTime}/${bl.amountBusinessScheduledTo}`,
					onClick: () => this.setAdvancedBusinessURL("all", [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{with_activities: 1, type_activity: "visit", activity_created_by: bl.id}]),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentScheduledSameAndAnotherPeriodOverBusinessAll} />
				},
				{
					name: bl.amountBusinessShowedUp,
					onClick: () => this.setAdvancedBusinessURL("all", [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{with_activities: 1, type_activity: "visit", activity_created_by: bl.id, showedup: 1}]),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentShowedUpOverScheduledTo} />
				}
			])
		});
		
		let footer = [
			{
				name: "Total",
				justify: "left"
			},
			{
				name: content.amountFinalBusinessAllGlobal
			},
			{
				name: content.amountFinalBusinessAll,
			},
			// {
			// 	name: content.amountFinalBusinessInProgressAttendanceGlobal,
			// },
			// {
			// 	name: content.amountFinalBusinessInProgressAttendance,
			// },
			{
				name: content.amountFinalBusinessNotApplicable,
			},
			// {
			// 	name: content.amountFinalBusinessPaused,
			// },
			{
				name: content.amountFinalBusinessLoss,
			},
			{
				name: content.amountFinalBusinessScheduledSameAndAnotherPeriod,
			},
			{
				name: content.amountFinalBusinessAveragePerDay,
			},
			{
				name: `${content.amountFinalBusinessScheduledToWithCurrentTime}/${content.amountFinalBusinessScheduledTo}`,
			},
			{
				name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.percentFinalScheduledSameAndAnotherPeriodOverBusinessAll} />,
				justify: "center"
			},
			{
				name: content.amountFinalBusinessShowedUp
			},
			{
				name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.percentFinalShowedUpOverScheduledTo} />,
				justify: "center"
			}
		];

		return (
			<LimitedDynamicTable
				classaditional="attendantsLimitedDynamicTableDashboardProspection"
				header={header}
				body={body}
				footer={footer}
				config={{
					columns: [
						{
							index: 0,
							width: 16
						}
					],
					positionHeaderTitleRuler: this.state.positionHeaderTitleRulerAttandants
				}}
				withHeaderTitleRuler
			/>
		)
	}

	getLimitedDynamicTableForCoordinatorsAndSupervisors = (content) =>
	{
		let header = [
			{
				name: "Líder",
				justify: "left"
			},
			{
				name: "ATG",
				nameHelp: "Atendimentos Geral",
			},
			{
				name: "ATP",
				nameHelp: "Atendimentos no Período",
			},
			// {
			// 	name: "NEG",
			// 	nameHelp: "Em Negociação Geral",
			// },
			// {
			// 	name: "NEP",
			// 	nameHelp: "Em Negociação no Período",
			// },
			{
				name: "NAP",
				nameHelp: "Não se Aplica no Período",
			},
			// {
			// 	name: "PAP",
			// 	nameHelp: "Pausados no Período",
			// },
			{
				name: "PEP",
				nameHelp: "Perdidos no Período",
			},
			{
				name: "AGN",
				nameHelp: "Agendados no Período",
			},
			{
				name: "AGP",
				nameHelp: "Agendados para o Período",
			},
			{
				name: "APP",
				nameHelp: "Aproveitamento no Período",
			},
			{
				name: "COP",
				nameHelp: "Compareceu no Período",
			},
			{
				name: "DEP",
				nameHelp: "Desempenho no Período",
			},
		];
		
		let body = [];
		// separating the body of the table
		content.body.forEach((bl, i) => {
			body.push([
				{
					name: bl.name,
					justify: "left"
				},
				{
					name: bl.amountBusinessAllGlobal,
					onClick: () => this.setAdvancedBusinessURL("", [], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessAll,
					onClick: () => this.setAdvancedBusinessURL("", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessInProgressAttendanceGlobal,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				// {
				// 	name: bl.amountBusinessInProgressAttendance,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				{
					name: bl.amountBusinessNotApplicable,
					onClick: () => this.setAdvancedBusinessURL("not_applicable", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessPaused,
				// 	onClick: () => this.setAdvancedBusinessURL("paused", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"					
				// },
				{
					name: bl.amountBusinessLoss,
					onClick: () => this.setAdvancedBusinessURL("loss", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessScheduledSameAndAnotherPeriod,
					onClick: () => this.setAdvancedBusinessURL("scheduled", [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: `${bl.amountBusinessScheduledToWithCurrentTime}/${bl.amountBusinessScheduledTo}`,
					onClick: () => this.setAdvancedBusinessURL("scheduled", [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentScheduledSameAndAnotherPeriodOverBusinessAll} />
				},
				{
					name: bl.amountBusinessShowedUp,
					onClick: () => this.setAdvancedBusinessURL("showed_up", [{status_date_start: "chosenStartDateReport"}, {status_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentShowedUpOverScheduledTo} />
				}
			])
		});
		
		let footer = [
			{
				name: "Total",
				justify: "left"
			},
			{
				name: content.footer.amountFinalBusinessAllGlobal
			},
			{
				name: content.footer.amountFinalBusinessAll,
			},
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendanceGlobal,
			// },
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendance,
			// },
			{
				name: content.footer.amountFinalBusinessNotApplicable,
			},
			// {
			// 	name: content.footer.amountFinalBusinessPaused,
			// },
			{
				name: content.footer.amountFinalBusinessLoss,
			},
			{
				name: content.footer.amountFinalBusinessScheduledSameAndAnotherPeriod,
			},
			{
				name: `${content.amountFinalBusinessScheduledToWithCurrentTime}/${content.amountFinalBusinessScheduledTo}`,
			},
			{
				name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalScheduledSameAndAnotherPeriodOverBusinessAll} />,
				justify: "center"
			},
			{
				name: content.footer.amountFinalBusinessShowedUp
			},
			{
				name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalShowedUpOverScheduledTo} />,
				justify: "center"
			}
		];

		return (
			<LimitedDynamicTable
				classaditional="attendantsLimitedDynamicTableDashboardProspection"
				header={header}
				body={body}
				footer={footer}
				config={{
					columns: [
						{
							index: 0,
							width: 16
						},
					],
				}}
			/>
		)
	}

	getLimitedDynamicTableForLeaders = (content) =>
	{
		let header = [
			{
				name: "Líder",
				justify: "left"
			},
			{
				name: "ATG",
				nameHelp: "Atendimentos Geral",
			},
			{
				name: "ATP",
				nameHelp: "Atendimentos no Período",
			},
			// {
			// 	name: "NEG",
			// 	nameHelp: "Em Negociação Geral",
			// },
			// {
			// 	name: "NEP",
			// 	nameHelp: "Em Negociação no Período",
			// },
			{
				name: "NAP",
				nameHelp: "Não se Aplica no Período",
			},
			// {
			// 	name: "PAP",
			// 	nameHelp: "Pausados no Período",
			// },
			{
				name: "PEP",
				nameHelp: "Perdidos no Período",
			},
			{
				name: "AGN",
				nameHelp: "Agendados no Período",
			},
			{
				name: "AGD",
				nameHelp: "Média de Agendamentos por dia",
			},
			{
				name: "AGP",
				nameHelp: "Agendados para o Período",
			},
			{
				name: "APP",
				nameHelp: "Aproveitamento no Período",
			},
			{
				name: "COP",
				nameHelp: "Compareceu no Período",
			},
			{
				name: "DEP",
				nameHelp: "Desempenho no Período",
			},
		];
		
		let body = [];
		// separating the body of the table
		content.body.forEach((bl, i) => {
			const group = bl.group;
			const user = {
				PER_ID: bl.id, 
				PER_ID_PERMISSIONS_GROUP: group
			};
			body.push([
				{
					name: bl.name,
					justify: "left"
				},
				{
					name: bl.amountBusinessAllGlobal,
					onClick: () => group && this.getAdvancedBusinessURL("000", user),
					justify: "center"
				},
				{
					name: bl.amountBusinessAll,
					onClick: () => group && this.getAdvancedBusinessURL("001", user),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessInProgressAttendanceGlobal,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				// {
				// 	name: bl.amountBusinessInProgressAttendance,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				{
					name: bl.amountBusinessNotApplicable,
					onClick: () => group && this.setAdvancedBusinessURL("not_applicable", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessPaused,
				// 	onClick: () => this.setAdvancedBusinessURL("paused", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"					
				// },
				{
					name: bl.amountBusinessLoss,
					onClick: () => group && this.setAdvancedBusinessURL("loss", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessScheduledSameAndAnotherPeriod,
					onClick: () => group && this.getAdvancedBusinessURL("016", user),
					justify: "center"
				},
				{
					// name: bl.amountBusinessAveragePerDay,
					name: "-",
					onClick: () => {},
					justify: "center"
				},
				{
					name: `${bl.amountBusinessScheduledToWithCurrentTime}/${bl.amountBusinessScheduledTo}`,
					onClick: () => group && this.getAdvancedBusinessURL("010", user),
					justify: "center"
				},
				{
					// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentScheduledSameAndAnotherPeriodOverBusinessAll} />
					name: "-"
				},
				{
					name: bl.amountBusinessShowedUp,
					onClick: () => group && this.getAdvancedBusinessURL("012", user),
					justify: "center"
				},
				{
					// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentShowedUpOverScheduledTo} />
					name: "-"
				}
			])
		});
		
		let footer = [
			{
				name: "Total",
				justify: "left"
			},
			{
				name: content.footer.amountFinalBusinessAllGlobal
			},
			{
				name: content.footer.amountFinalBusinessAll,
			},
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendanceGlobal,
			// },
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendance,
			// },
			{
				name: content.footer.amountFinalBusinessNotApplicable,
			},
			// {
			// 	name: content.footer.amountFinalBusinessPaused,
			// },
			{
				name: content.footer.amountFinalBusinessLoss,
			},
			{
				name: content.footer.amountFinalBusinessScheduledSameAndAnotherPeriod,
			},
			{
				// name: content.footer.amountFinalBusinessAveragePerDay,
				name: "-"
			},
			{
				name: `${content.footer.amountFinalBusinessScheduledToWithCurrentTime}/${content.footer.amountFinalBusinessScheduledTo}`,
			},
			{
				// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalScheduledSameAndAnotherPeriodOverBusinessAll} />,
				name: "-",
				justify: "center"
			},
			{
				name: content.footer.amountFinalBusinessShowedUp
			},
			{
				// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalShowedUpOverScheduledTo} />,
				name: "-",
				justify: "center"
			}
		];

		return (
			<LimitedDynamicTable
				classaditional="attendantsLimitedDynamicTableDashboardProspection"
				header={header}
				body={body}
				footer={footer}
				config={{
					columns: [
						{
							index: 0,
							width: 16
						},
					],
				}}
			/>
		)
	}

	getLimitedDynamicTableForCompilation = (content) =>
	{
		let header = [
			{
				name: "Líder",
				justify: "left"
			},
			{
				name: "ATG",
				nameHelp: "Atendimentos Geral",
			},
			{
				name: "ATP",
				nameHelp: "Atendimentos no Período",
			},
			// {
			// 	name: "NEG",
			// 	nameHelp: "Em Negociação Geral",
			// },
			// {
			// 	name: "NEP",
			// 	nameHelp: "Em Negociação no Período",
			// },
			{
				name: "NAP",
				nameHelp: "Não se Aplica no Período",
			},
			// {
			// 	name: "PAP",
			// 	nameHelp: "Pausados no Período",
			// },
			{
				name: "PEP",
				nameHelp: "Perdidos no Período",
			},
			{
				name: "AGN",
				nameHelp: "Agendados no Período",
			},
			{
				name: "AGD",
				nameHelp: "Média de Agendamentos por dia",
			},
			{
				name: "AGP",
				nameHelp: "Agendados para o Período",
			},
			{
				name: "APP",
				nameHelp: "Aproveitamento no Período",
			},
			{
				name: "COP",
				nameHelp: "Compareceu no Período",
			},
			{
				name: "DEP",
				nameHelp: "Desempenho no Período",
			},
		];
		
		let body = [];
		// separating the body of the table
		content.body.forEach((bl, i) => {
			const group = bl.group;
			const user = {
				PER_ID: bl.id, 
				PER_ID_PERMISSIONS_GROUP: group
			};
			body.push([
				{
					name: bl.name,
					justify: "left"
				},
				{
					name: bl.amountBusinessAllGlobal,
					onClick: () => group && this.getAdvancedBusinessURL("000", user),
					justify: "center"
				},
				{
					name: bl.amountBusinessAll,
					onClick: () => group && this.getAdvancedBusinessURL("001", user),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessInProgressAttendanceGlobal,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				// {
				// 	name: bl.amountBusinessInProgressAttendance,
				// 	onClick: () => this.setAdvancedBusinessURL("in_progress_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"
				// },
				{
					name: bl.amountBusinessNotApplicable,
					onClick: () => group && this.setAdvancedBusinessURL("not_applicable", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				// {
				// 	name: bl.amountBusinessPaused,
				// 	onClick: () => this.setAdvancedBusinessURL("paused", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
				// 	justify: "center"					
				// },
				{
					name: bl.amountBusinessLoss,
					onClick: () => group && this.setAdvancedBusinessURL("loss", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}], [{prospection_owner: bl.id}]),
					justify: "center"
				},
				{
					name: bl.amountBusinessScheduledSameAndAnotherPeriod,
					onClick: () => group && this.getAdvancedBusinessURL("016", user),
					justify: "center"
				},
				{
					name: bl.amountBusinessAveragePerDay,
					onClick: () => {},
					justify: "center"
				},
				{
					name: `${bl.amountBusinessScheduledToWithCurrentTime}/${bl.amountBusinessScheduledTo}`,
					onClick: () => group && this.getAdvancedBusinessURL("010", user),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentScheduledSameAndAnotherPeriodOverBusinessAll} />
				},
				{
					name: bl.amountBusinessShowedUp,
					onClick: () => group && this.getAdvancedBusinessURL("012", user),
					justify: "center"
				},
				{
					name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={bl.percentShowedUpOverScheduledTo} />
				}
			])
		});
		
		let footer = [
			{
				name: "Total",
				justify: "left"
			},
			{
				name: content.footer.amountFinalBusinessAllGlobal
			},
			{
				name: content.footer.amountFinalBusinessAll,
			},
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendanceGlobal,
			// },
			// {
			// 	name: content.footer.amountFinalBusinessInProgressAttendance,
			// },
			{
				name: content.footer.amountFinalBusinessNotApplicable,
			},
			// {
			// 	name: content.footer.amountFinalBusinessPaused,
			// },
			{
				name: content.footer.amountFinalBusinessLoss,
			},
			{
				name: content.footer.amountFinalBusinessScheduledSameAndAnotherPeriod,
			},
			{
				name: content.footer.amountFinalBusinessAveragePerDay,
			},
			{
				name: `${content.footer.amountFinalBusinessScheduledToWithCurrentTime}/${content.footer.amountFinalBusinessScheduledTo}`,
			},
			{
				// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalScheduledSameAndAnotherPeriodOverBusinessAll} />,
				name: "-",
				justify: "center"
			},
			{
				name: content.footer.amountFinalBusinessShowedUp
			},
			{
				// name: <PerformanceIndex classaditional="performaceIndexDashboardProspection" valueBefore={0} valueAfter={content.footer.percentFinalShowedUpOverScheduledTo} />,
				name: "-",
				justify: "center"
			}
		];

		return (
			<LimitedDynamicTable
				classaditional="attendantsLimitedDynamicTableDashboardProspection"
				header={header}
				body={body}
				footer={footer}
				config={{
					columns: [
						{
							index: 0,
							width: 16
						},
					],
				}}
			/>
		)
	}

	showDetaisCard = async (type) =>
	{
		let loadingCards = await this.state.loadingCards;

		if (loadingCards) { return;  }

		let optionsUnitCompanyChecked = await this.state.optionsUnitCompany.filter(u => (u.checked));
		let stringCompanyUnitChecked = optionsUnitCompanyChecked.map(u => u.value).join(",");
		let queryCompanyUnit = (optionsUnitCompanyChecked.length > 0) ? `&company_unit=${stringCompanyUnitChecked}` : "";

		switch (type) {
			case "monthNotApplicable":
				let detailsBusinessNotApplicableMonthResults = await this.state.detailsBusinessNotApplicableMonthResults;
				if (detailsBusinessNotApplicableMonthResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"NÃO SE APLICA" (detalhamento)',
					detailsBusinessNotApplicableMonthResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=not_applicable&create_date_start=${this.state.chosenStartDateReport}&create_date_ends=${this.state.chosenEndsDateReport}&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
			case "monthLoss":
				let detailsBusinessLossMonthResults = await this.state.detailsBusinessLossMonthResults;
				if (detailsBusinessLossMonthResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"PERDIDOS" (detalhamento)',
					detailsBusinessLossMonthResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=loss&create_date_start=${this.state.chosenStartDateReport}&create_date_ends=${this.state.chosenEndsDateReport}&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
			case "monthDiscarded":
				let detailsBusinessDiscardedMonthResults = await this.state.detailsBusinessDiscardedMonthResults;
				if (detailsBusinessDiscardedMonthResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"DESCARTADOS" (detalhamento)',
					detailsBusinessDiscardedMonthResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=discarded&create_date_start=${this.state.chosenStartDateReport}&create_date_ends=${this.state.chosenEndsDateReport}&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
			case "generalNotApplicable":
				let detailsBusinessNotApplicableGeneralResults = await this.state.detailsBusinessNotApplicableGeneralResults;
				if (detailsBusinessNotApplicableGeneralResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"NÃO SE APLICA" (detalhamento)',
					detailsBusinessNotApplicableGeneralResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=not_applicable&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
			case "generalLoss":
				let detailsBusinessLossGeneralResults = await this.state.detailsBusinessLossGeneralResults;
				if (detailsBusinessLossGeneralResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"PERDIDOS" (detalhamento)',
					detailsBusinessLossGeneralResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=loss&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
			case "generalDiscarded":
				let detailsBusinessDiscardedGeneralResults = await this.state.detailsBusinessDiscardedGeneralResults;
				if (detailsBusinessDiscardedGeneralResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"DESCARTADOS" (detalhamento)',
					detailsBusinessDiscardedGeneralResults.map(n => this.getAnchorForDetailsCard(n.title, n.amount, `/business/advanced?type=discarded&reason_for_loss=${n.code}${queryCompanyUnit}`))
				);
				break;
		
			default:
				break;
		}
	}

	setAdvancedBusinessURL = (type, filters, excludes = null) =>
	{
		let url = "/business/advanced?";
		let optionsUnitCompany = this.state.optionsUnitCompany;
		optionsUnitCompany = optionsUnitCompany.filter(u => u.checked).map(u => u.value);

		url = url + "type=" + type;

		// INFORMATION: for parameters that is on state.
		filters.forEach(f => {
			Object.keys(f).forEach(k =>{
				let state = this.state[f[k]];
				url = url + "&" + k + "=" + state;
			});
		});
		
		// INFORMATION: for parameters that isn't on state.
		if (excludes !== null)
		{
			excludes.forEach(f => {
				Object.keys(f).forEach(prorpety => {
					url = url + "&" + prorpety + "=" + f[prorpety];
				});
			});
		}

		if (optionsUnitCompany.length > 0) 
		{ 
			optionsUnitCompany = optionsUnitCompany.join(",");
			url = url + "&company_unit=" + optionsUnitCompany;
		}

		return  window.open(url);
	}

	getAdvancedBusinessURL = (index, user = null) =>
	{
		user = user ? user : this.props.user;
		let idPermissionsGroup = user.PER_ID_PERMISSIONS_GROUP;
		let paramsOne = "";
		let paramsTwo = [];
		let paramsThree = null;

		let yesterday = this.state.chosenStartDateReport;
		yesterday = new Date(`${yesterday} 00:00`);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday = formatedDateTimeToUSA(yesterday);

		if (ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS.includes(idPermissionsGroup))
		{
			switch (index) 
			{
				case "000":
					// all
					paramsOne = "all";
					paramsTwo = [];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "files,phone,historic,phone,task,visit"}];
					break;
				case "001":
					// business
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "files,phone,historic,phone,task,visit"}];
					break;

				case "002":
					// scheduled same period
					paramsOne = "all";
					paramsTwo = [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;

				case "003":
					// scheduled another period
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{create_date_ends: yesterday, coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;

				case "004":
					// scheduled units
					paramsOne = "";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, prospection_owner: 0, type_activity: "visit"}];
					break;

				case "005":
					// scheduled to same period
					paramsOne = "all";
					paramsTwo = [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "006":
					// scheduled to another period
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{create_date_ends: yesterday, coordinator: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "007":
					// showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "008":
					// no showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 2}];
					break;

				case "009":
					// showedup or no showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: "1,2"}];
					break;

				case "010":
					// scheduled to
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "011":
					// showedup and open
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: "open", coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "012":
					// showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "013":
					// showedup and gain
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_GAIN, coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 3}];
					break;

				case "014":
					paramsOne = "gain_units";
					paramsTwo = [{status_date_start: "chosenStartDateReport"}, {status_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}, {prospection_owner: "idsAttendantsByUserOnAccess"}];
					break;

				case "015":
					// showedup and loss
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_LOSS, coordinator: user.PER_ID, only_business_with_owner: 1, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "016":
					// scheduled period
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{coordinator: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;
			
				default:
					break;
			}

			this.setAdvancedBusinessURL(paramsOne, paramsTwo, paramsThree);
		}

		else if (ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(idPermissionsGroup))
		{
			switch (index) 
			{
				case "000":
					// all
					paramsOne = "all";
					paramsTwo = [];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "files,phone,historic,phone,task,visit"}];
					break;
				case "001":
					// business
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "files,phone,historic,phone,task,visit"}];
					break;

				case "002":
					// scheduled same period
					paramsOne = "all";
					paramsTwo = [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;

				case "003":
					// scheduled another period
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{create_date_ends: yesterday, supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;

				case "004":
					// scheduled units
					paramsOne = "";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, prospection_owner: 0, type_activity: "visit"}];
					break;

				case "005":
					// scheduled to same period
					paramsOne = "all";
					paramsTwo = [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "006":
					// scheduled to another period
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{create_date_ends: yesterday, supervisor: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "007":
					// showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "008":
					// no showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 2}];
					break;

				case "009":
					// showedup or no showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: "1,2"}];
					break;

				case "010":
					// scheduled to
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit"}];
					break;

				case "011":
					// showedup and open
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: "open", supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "012":
					// showedup
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "013":
					// showedup and gain
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_GAIN, supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", showedup: 3}];
					break;

				case "014":
					paramsOne = "gain_units";
					paramsTwo = [{status_date_start: "chosenStartDateReport"}, {status_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}, {prospection_owner: "idsAttendantsByUserOnAccess"}];
					break;

				case "015":
					// showedup and loss
					paramsOne = "all";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_LOSS, supervisor: user.PER_ID, only_business_with_owner: 1, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;

				case "016":
					// scheduled period
					paramsOne = "all";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{supervisor: user.PER_ID, with_activities: 1, type_activity: "visit", group_by: 2}];
					break;
			
				default:
					break;
			}

			this.setAdvancedBusinessURL(paramsOne, paramsTwo, paramsThree);
		}

		else if (ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup))
		{

		}

		else
		{
			switch (index) 
			{
				case "000":
					// business
					paramsOne = "all";
					paramsTwo = [];
					break;

				case "001":
					// business
					paramsOne = "all";
					paramsTwo = [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					break;

				case "002":
					// scheduled same period
					paramsOne = "";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", only_business_with_attendant: 1, type_visit_period: "same_period"}];
					break;

				case "003":
					// scheduled another period
					paramsOne = "";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", only_business_with_attendant: 1, type_visit_period: "another_period", group_by: 2}];
					break;

				case "004":
					// scheduled units
					paramsOne = "";
					paramsTwo = [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, prospection_owner: 0, type_activity: "visit"}];
					break;

				case "005":
					// scheduled to same period
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", type_visit_period: "same_period", group_by: 2}];
					break;

				case "006":
					// scheduled to another period
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", type_visit_period: "another_period", group_by: 2}];
					break;

				case "007":
					// showedup
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", showedup: 1, group_by: 2}];
					break;

				case "008":
					// no showedup
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", showedup: 2, group_by: 2}];
					break;

				case "009":
					// showedup or no showedup
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", showedup: "1,2", group_by: 2}];
					break;

				case "010":
					// scheduled to
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", group_by: 2}];
					break;

				case "011":
					// showedup and open
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: "open", with_activities: 1, type_activity: "visit", showedup: 1, group_by: 2}];
					break;

				case "012":
					// showedup
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{with_activities: 1, type_activity: "visit", showedup: 1, group_by: 2}];
					break;

				case "013":
					// showedup and gain
					paramsOne = "";
					paramsTwo = [{status_date_start: "chosenStartDateReport"}, {status_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_GAIN, with_activities: 1, type_activity: "visit", showedup: 3}];
					break;

				case "014":
					paramsOne = "gain_units";
					paramsTwo = [{status_date_start: "chosenStartDateReport"}, {status_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					break;

				case "015":
					// showedup and loss
					paramsOne = "";
					paramsTwo = [{activity_date_start: "chosenStartDateReport"}, {activity_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}];
					paramsThree = [{status: STATUS_BUSINESS_LOSS, with_activities: 1, type_activity: "visit", showedup: 1}];
					break;
			
				default:
					break;
			}

			this.setAdvancedBusinessURL(paramsOne, paramsTwo, paramsThree);
		}
	}

	setDetailsPieCharts = async (type) =>
	{
		let loadingCards = await this.state.loadingCards;

		if (loadingCards) { return; }

		switch (type) {
			case "generalUtilization":
				let percentGeneralUtilization = await this.state.percentGeneralUtilization;
				this.setDataChartScheduledOverBusiness(percentGeneralUtilization, "success");
				break;
				
			case "defaultUtilization":
					let percentScheduledSamePeriodOverBusiness = await this.state.percentScheduledSamePeriodOverBusiness;
					this.setDataChartScheduledOverBusiness(percentScheduledSamePeriodOverBusiness, "information");
					break;

			case "clientsGeneralUtilization":
				let percentClientsSamePeriodOverClients = await this.state.percentClientsSamePeriodOverClients;
				this.setDataChartScheduledOverBusiness(percentClientsSamePeriodOverClients, "information");
				break;

			case "anotherUtilization":
				let percentScheduledAnotherPeriodOverBusiness = await this.state.percentScheduledAnotherPeriodOverBusiness;
				this.setDataChartScheduledOverBusiness(percentScheduledAnotherPeriodOverBusiness, "purple");
				break;

			case "clientAnotherUtilization":
				let percentClientsScheduledAnotherPeriodOverClients = await this.state.percentClientsScheduledAnotherPeriodOverClients;
				this.setDataChartScheduledOverBusiness(percentClientsScheduledAnotherPeriodOverClients, "purple");
				break;

			case "unitsUtilization":
				let percentScheduledOverBusiness = await this.state.percentScheduledOverBusiness;
				this.setDataChartScheduledOverBusiness(percentScheduledOverBusiness, "error");
				break;
		
			default:
				break;
		}
	}

	setTableTitleRuler = (e) =>
	{
		const element = this.containerScroll;
		let device = getWindowDimensions();
		let y = element.current.scrollTop;
		let positionHeaderTitleRulerAttandants = "none";
		let boxTableAttandantsDashboardProspection = this.boxTableAttandantsDashboardProspection.current;
		let boxTableLeadersDashboardProspection = this.boxTableLeadersDashboardProspection.current;

		// INFORMATION: for devices smaller than MIN_WIDTH_TO_FIXED_TABLE_TITLE_RULER the style of table titles is the default
		
		if (boxTableAttandantsDashboardProspection !== null && boxTableLeadersDashboardProspection !== null)
		{
			// setting the fixed (or not) of the tableTitleRuler
			if (device.width >= MIN_WIDTH_TO_FIXED_TABLE_TITLE_RULER)
			{
				let minToFixedAttandents = boxTableAttandantsDashboardProspection.offsetTop;
				let minToFixedLeaders = boxTableLeadersDashboardProspection.offsetTop;
				positionHeaderTitleRulerAttandants = ((y > (minToFixedAttandents + 215)) && y < minToFixedLeaders) ? "fixed" : "none";
			}
		}
		
		if (this._mounted) { this.setState({positionHeaderTitleRulerAttandants}); }
	}

	messageContent = async (type, title, content) =>
	{
		await this.setState({
			message_show_content: true,
			message_content_content: content,
			message_type_content: type,
			message_title_content: title
		});
	}

	message = (type, message) =>
	{
        this.props.setModalMessage({show: true, type, message});
	}

	getCardInfoLeftChart = (number) =>
	{
		switch (number) 
		{
			case 1:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection exclusive"
						title={`${getGreetingMessage()}, ${this.state.name}`}
						typeColorTitle="success"
						amountColumnLeft={4}
						amountColumnRight={8}
						information={(
							<div className="areaDashboardProspection">
								{
									this.state.hasAvatar
									? <img src={`${process.env.REACT_APP_BACKEND_URL}/user/avatar/${this.props.user.PER_ID}`} alt="Foto de Ana" className="img-fluid avatarDashboardProspection" />
									: <img src={`${this.state.photo}`} alt="Foto de Ana" className="img-fluid avatarDashboardProspection" />
								}
							</div>
						)}
					>
						<div className="col-12">
							<div className="itemInfoLeftChartDashboardProspection"><span className="featured">E-mail:</span>&nbsp;{this.state.mail}</div>
							<div className="itemInfoLeftChartDashboardProspection"><span className="featured">Último acesso:</span>&nbsp;{this.state.lastAccess}hs</div>
						</div>
					</CardInfoLeftChart>
				);
		
			case 2:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection exclusive"
						title={`Aproveitamento do mês (atendidos)`}
						typeColorTitle="success"
						amountColumnLeft={6}
						amountColumnRight={6}
						information={(
							<div>
								<SubtitleProgressLine
									label="Clientes/Negócios Atendidos:"
									// label="Negócios Atendidos:"
									color="warning"
									progressOne={`${this.state.amountClients}/${this.state.amountBusiness}`}
									// progressOne={`${this.state.amountBusiness}`}
									unitSymbolOne=""
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("001")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountBusinessValueTotal)}`} color="warning" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								{
									!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<Fragment>
										<SubtitleProgressLine
											label="Negócios de Outro Período:"
											color="success"
											progressOne={`${this.state.amountClientsAnotherPeriod}`}
											unitSymbolOne=""
											onOverOne={() => {}}
											onClickOne={() => this.getAdvancedBusinessURL("001")}
										/>
										<div className="areSubtitleChartPieDashboardProspection">
											<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountClientsAnotherPeriodValueTotal)}`} color="success" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
										</div>
									</Fragment>
								}
								<SubtitleProgressLine
									label={"Clientes/Agendou: (do período):"}
									// label={"Agendou: (do período):"}
									color="information"
									unitSymbolOne=""
									// progressOne={`${this.state.amountScheduledSamePeriod}`}
									// onOverOne={() => this.setDetailsPieCharts("defaultUtilization")}
									// onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
									// onClickOne={() => this.getAdvancedBusinessURL("002")}
									progressOne={`${this.state.amountClientsScheduledSamePeriod}`}
									onOverOne={() => this.setDetailsPieCharts("clientsGeneralUtilization")}
									onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
									onClickOne={() => {}}
									progressTwo={`${this.state.amountScheduledSamePeriod}`}
									onOverTwo={() => this.setDetailsPieCharts("defaultUtilization")}
									onLeaveTwo={() => this.setDetailsPieCharts("generalUtilization")}
									onClickTwo={() => this.getAdvancedBusinessURL("002")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledSamePeriodValueTotal)}`} color="information" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>								
								<SubtitleProgressLine
									label={"Clientes/Agendou: (outras datas):"}
									// label={"Agendou: (outras datas):"}
									color="purple"
									unitSymbolOne=""
									// progressOne={`${this.state.amountScheduledAnotherPeriod}`}
									// onOverOne={() => this.setDetailsPieCharts("anotherUtilization")}
									// onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
									// onClickOne={() => this.getAdvancedBusinessURL("003")}
									progressOne={`${this.state.amountClientsScheduledAnotherPeriod}`}
									onOverOne={() => this.setDetailsPieCharts("clientAnotherUtilization")}
									onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
									onClickOne={() => {}}
									progressTwo={`${this.state.amountScheduledAnotherPeriod}`}
									onOverTwo={() => this.setDetailsPieCharts("anotherUtilization")}
									onLeaveTwo={() => this.setDetailsPieCharts("generalUtilization")}
									onClickTwo={() => this.getAdvancedBusinessURL("003")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledAnotherPeriodValueTotal)}`} color="purple" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								{
									!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<Fragment>
										<SubtitleProgressLine 
											label="Agendou Negócios Unidades:"
											color="error"
											progressOne={this.state.amountScheduled}
											unitSymbolOne=""
											onOverOne={() => this.setDetailsPieCharts("unitsUtilization")}
											onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
											onClickOne={() => this.getAdvancedBusinessURL("004")}
										/>
										<div className="areSubtitleChartPieDashboardProspection">
											<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledValueTotal)}`} color="error" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
										</div>
									</Fragment>
								}					
							</div>
						)}
						multipleChildren
					>
						<CardChartPie
							title={<div className="labelsCardChartPieDashboardProspection">Seu desempenho:</div>}
							labelOver={`${this.state.labelPercentUtilizationByUser}%`}
							colorLabelOver={`${this.state.colorProgressChartPieUtilizationBusinessByUser}`}
						>								
							<PieChart 
								width={170} 
								height={170} 
								onMouseEnter={this.onPieEnter}
								style={{transform: "rotate(-90deg)"}}
							>
								<Pie
									data={this.state.percentUtilizationBusinessByUser}
									cx="50%"
									cy="50%"
									innerRadius={70}
									outerRadius={80}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
									label={false}
								>
									{this.state.percentUtilizationBusinessByUser.map((entry, index) => (
										<Cell 
											key={`cell-${index}`} 
											fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationBusinessByUser})` : "transparent"} 
											strokeWidth={2}
										/>
									))}
								</Pie>
							</PieChart>
						</CardChartPie>
						<div className="subtitle">
							<span>agendou: {this.state.amountScheduledTotalSameAndAnotherPeriod}</span>
							<span>R$ {formatedMoneyBRAFromFloat(this.state.amountScheduledTotalSameAndAnotherPeriodValueTotal)}</span>
						</div>
					</CardInfoLeftChart>
				);			

			case 3:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection"
						title={`Compareceu`}
						typeColorTitle="success"
						amountColumnLeft={6}
						amountColumnRight={6}
						information={(
							<div>
								<SubtitleProgressLine
									label="Agendou p/: (leads do período):"
									color="warning"
									progressOne={this.state.amountScheduledToSamePeriod}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("005")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledToSamePeriodValueTotal)}`} color="warning" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>	
								<SubtitleProgressLine 
									label="Agendou p/: (leads de outras datas):"
									color="error"
									progressOne={this.state.amountScheduledToAnotherPeriod}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("006")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledToAnotherPeriodValueTotal)}`} color="error" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>	
								<SubtitleProgressLine 
									label="Compareceu:"
									color="information"
									progressOne={this.state.amountShowedUp}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("007")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountShowedUpValueTotal)}`} color="information" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								<SubtitleProgressLine 
									label="Não Compareceu:"
									color="purple"
									progressOne={this.state.amountNoShowedUp}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("008")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountNoShowedUpValueTotal)}`} color="purple" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
							</div>
						)}
						multipleChildren
					>
						<CardChartPie
							title={<div className="labelsCardChartPieDashboardProspection">Seu desempenho:</div>}
							labelOver={`${this.state.labelPercentUtilizationEffectiveByUser}%`}
							colorLabelOver={`${this.state.colorProgressChartPieUtilizationEffectiveBusinessByUser}`}
						>
							<PieChart
								width={170} 
								height={170} 
								onMouseEnter={this.onPieEnter}
								style={{transform: "rotate(-90deg)"}}
							>
								<Pie
									data={this.state.percentUtilizationEffectiveBusinessByUser}
									cx="50%"
									cy="50%"
									innerRadius={70}
									outerRadius={80}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
									label={false}
								>
									{this.state.percentUtilizationEffectiveBusinessByUser.map((entry, index) => (
										<Cell 
											key={`cell-${index}`} 
											fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationEffectiveBusinessByUser})` : "transparent"} 
											strokeWidth={2}
										/>
									))}
								</Pie>
							</PieChart>
						</CardChartPie>
						<div className="subtitle">
							<div>
								<span>agendou para: </span>
								<span
									className="click" 
									onClick={() => this.getAdvancedBusinessURL("009")}
								>
									{this.state.amountScheduledToCurrentTime}
								</span>
								<span 
									className="click" 
									onClick={() => this.getAdvancedBusinessURL("010")}
								>
									/{this.state.amountScheduledTo}
								</span>
							</div>
							<span>R$ {formatedMoneyBRAFromFloat(this.state.amountScheduledToCurrentTimeValueTotal)} / {formatedMoneyBRAFromFloat(this.state.amountScheduledToValueTotal)}</span>
						</div>
					</CardInfoLeftChart>
				);
				
			case 4:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection"
						title={`Ganhou`}
						typeColorTitle="success"
						amountColumnLeft={6}
						amountColumnRight={6}
						information={(
							<div>
								<SubtitleProgressLine
									label="Compareceu:"
									color="warning"
									progressOne={(
										<div>
											<span
												className="click"
												onClick={() => this.getAdvancedBusinessURL("011")}
											>
												{this.state.amountShowedUpWithoutStatusBusiness}
											</span>
											<span 
												className="click" 
												onClick={() => this.getAdvancedBusinessURL("012")}
											>
												/{this.state.amountShowedUp}
											</span>
										</div>
									)} 
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$&nbsp;" value={`${formatedMoneyBRAFromFloat(this.state.amountShowedUpWithoutStatusBusinessValueTotal)} / ${formatedMoneyBRAFromFloat(this.state.amountShowedUpValueTotal)}`} color="warning" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								<SubtitleProgressLine 
									label="Ganhou (Compareceu):"
									color="information"
									progressOne={this.state.amountGainWithShowedup}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("013")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$&nbsp;" value={`${formatedMoneyBRAFromFloat(this.state.amountGainWithShowedupValueTotal)}`} color="information" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								<SubtitleProgressLine 
									label="Ganhou Unidades:"
									color="error"
									progressOne={this.state.amountGainWithUnits}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("014")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$&nbsp;" value={`${formatedMoneyBRAFromFloat(this.state.amountGainWithUnitsValueTotal)}`} color="error" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								<SubtitleProgressLine 
									label="Perdeu:"
									color="purple"
									progressOne={this.state.amountLoss}
									unitSymbolOne="&nbsp;" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("015")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountLossValueTotal)}`} color="purple" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>											
							</div>
						)}
						multipleChildren
					>
						<CardChartPie
							title={<div className="labelsCardChartPieDashboardProspection">&nbsp;</div>}
							labelOver={`${this.state.labelPercentGainByUser}%`}
							colorLabelOver={`${this.state.colorProgressChartPieGainBusinessByUser}`}
						>								
							<PieChart
								width={170} 
								height={170} 
								onMouseEnter={this.onPieEnter}
								style={{transform: "rotate(-90deg)"}}
							>
								<Pie
									data={this.state.percentGainBusinessByUser}
									cx="50%"
									cy="50%"
									innerRadius={70}
									outerRadius={80}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
									label={false}
								>
									{this.state.percentGainBusinessByUser.map((entry, index) => (
										<Cell 
											key={`cell-${index}`} 
											fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieGainBusinessByUser})` : "transparent"} 
											strokeWidth={2}
										/>
									))}
								</Pie>
							</PieChart>
						</CardChartPie>
						<div className="subtitle">
							<span>ganhou: {this.state.amountGain}/{this.state.amountGainERP}</span>
							<span>R$ {formatedMoneyBRAFromFloat(this.state.amountGainValueTotal)} / {formatedMoneyBRAFromFloat(this.state.amountGainValueTotalERP)}</span>
						</div>
					</CardInfoLeftChart>					
				);

			case 5:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection"
						title={`Aproveitamento do dia`}
						typeColorTitle="success"
						amountColumnLeft={6}
						amountColumnRight={6}
						information={(
							<div>
								<SubtitleProgressLine
									label="Negócios Atendidos:"
									color="warning"
									progressOne={this.state.amountBusinessToday}
									unitSymbolOne=""
									onOverOne={() => {}}
									onClickOne={() => {}}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountBusinessTodayValueTotal)}`} color="warning" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								<SubtitleProgressLine
									label={"Agendou Negócios/Meta:"}
									color="information"
									unitSymbolOne=""
									onOverOne={() => {}}
									onClickOne={() => {}}
									progressOne={
										<div>
											<span className="click">
												{this.state.amountScheduledDay}
											</span>
											<span className="click">
												/{this.state.minimuGoalDayComissionRule}
											</span>
										</div>
									}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledDayValueTotal)}`} color="information" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								{
									!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									!ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<Fragment>
										<SubtitleProgressLine 
											label="Agendou Negócios Unidades:"
											color="error"
											progressOne={this.state.amountScheduled}
											unitSymbolOne=""
											onOverOne={() => this.setDetailsPieCharts("unitsUtilization")}
											onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
											onClickOne={() => this.getAdvancedBusinessURL("004")}
										/>
										<div className="areSubtitleChartPieDashboardProspection">
											<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledValueTotal)}`} color="error" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
										</div>
									</Fragment>
								}					
							</div>
						)}
						multipleChildren
					>
						<CardChartPie
							title={<div className="labelsCardChartPieDashboardProspection">Seu desempenho:</div>}
							labelOver={`${this.state.labelPercentUtilizationScheduledDayByUser}%`}
							colorLabelOver={`${this.state.colorProgressChartPieUtilizationScheduleDayByUser}`}
						>								
							<PieChart 
								width={170} 
								height={170} 
								onMouseEnter={this.onPieEnter}
								style={{transform: "rotate(-90deg)"}}
							>
								<Pie
									data={this.state.percentUtilizationScheduleDayByUser}
									cx="50%"
									cy="50%"
									innerRadius={70}
									outerRadius={80}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
									label={false}
								>
									{this.state.percentUtilizationScheduleDayByUser.map((entry, index) => (
										<Cell 
											key={`cell-${index}`} 
											fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationScheduleDayByUser})` : "transparent"} 
											strokeWidth={2}
										/>
									))}
								</Pie>
							</PieChart>
						</CardChartPie>
						<div className="subtitle">
							<span>agendou: {this.state.amountScheduledDay}</span>
							<span>R$ {formatedMoneyBRAFromFloat(this.state.amountScheduledDayValueTotal)}</span>
						</div>
					</CardInfoLeftChart>
				);
				
			case 6:
				return (
					<CardInfoLeftChart
						classaditional="cardInfoLeftChartDashboardProspection"
						title={`Aproveitamento do mês`}
						typeColorTitle="success"
						amountColumnLeft={6}
						amountColumnRight={6}
						information={(
							<div>
								<SubtitleProgressLine
									label="Negócios Atendidos:"
									color="warning"
									progressOne={this.state.amountBusiness}
									unitSymbolOne="" 
									onOverOne={() => {}}
									onClickOne={() => this.getAdvancedBusinessURL("001")}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountBusinessValueTotal)}`} color="warning" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>									
								<SubtitleProgressLine
									label={"Agendou Negócios/Meta:"}
									color="purple"
									unitSymbolOne=""
									onOverOne={() => {}}
									onLeaveOne={() => {}}
									onClickOne={() => {}}
									progressOne={
										<div>
											<span className="click">
												{this.state.amountScheduledMonth}
											</span>
											<span className="click">
												/{this.state.minimumGoalMonthComissionRule}
											</span>
										</div>
									}
								/>
								<div className="areSubtitleChartPieDashboardProspection">
									<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledMonthValueTotal)}`} color="purple" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
								</div>
								{
									!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									!ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<Fragment>
										<SubtitleProgressLine 
											label="Agendou Negócios Unidades:"
											color="error"
											progressOne={this.state.amountScheduled}
											unitSymbolOne="&nbsp;"
											onOverOne={() => this.setDetailsPieCharts("unitsUtilization")}
											onLeaveOne={() => this.setDetailsPieCharts("generalUtilization")}
											onClickOne={() => this.getAdvancedBusinessURL("004")}
										/>
										<div className="areSubtitleChartPieDashboardProspection">
											<StylizedMoneyOrNumber symbol="R$" value={`${formatedMoneyBRAFromFloat(this.state.amountScheduledValueTotal)}`} color="error" style={{"marginLeft": "0px", "position": "absolute", "top": "-10px"}} />
										</div>
									</Fragment>
								}					
							</div>
						)}
						multipleChildren
					>
						<CardChartPie
							title={<div className="labelsCardChartPieDashboardProspection">Seu desempenho:</div>}
							labelOver={`${this.state.labelPercentUtilizationScheduledMontByUser}%`}
							colorLabelOver={`${this.state.colorProgressChartPieUtilizationScheduledMonthByUser}`}
						>
							<PieChart 
								width={170} 
								height={170} 
								onMouseEnter={this.onPieEnter}
								style={{transform: "rotate(-90deg)"}}
							>
								<Pie
									data={this.state.percentUtilizationScheduledMonthByUser}
									cx="50%"
									cy="50%"
									innerRadius={70}
									outerRadius={80}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
									label={false}
								>
									{this.state.percentUtilizationScheduledMonthByUser.map((entry, index) => (
										<Cell 
											key={`cell-${index}`} 
											fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationScheduledMonthByUser})` : "transparent"} 
											strokeWidth={2}
										/>
									))}
								</Pie>
							</PieChart>
						</CardChartPie>
						<div className="subtitle">
							<span>agendou: {this.state.amountScheduledMonth}</span>
							<span>R$ {formatedMoneyBRAFromFloat(this.state.amountScheduledMonthValueTotal)}</span>
						</div>
					</CardInfoLeftChart>
				);
			
			default:
				break;
		}
	}

	setMapCardsNumberByPosition = (position) =>
	{
		let idPermissionsGroup = this.props.user.PER_ID_PERMISSIONS_GROUP;
		let typeCommissionRule = this.state.typeCommissionRuleCurrent;

		switch (position)
		{
			case 1:
				return 1;

			case 2:
				return 2;

			case 3:
				if 
				(
					ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup) ||
					ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(idPermissionsGroup)
				)
				{ return (typeCommissionRule === 1) ? 0 : 5; }
				else
				{ return (typeCommissionRule === 1) ? 0 : 5; }
			
			case 4:
				if 
				(
					ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup) ||
					ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(idPermissionsGroup)
				)
				{ return (typeCommissionRule === 1) ? 0 : 6; }
				else
				{ return (typeCommissionRule === 1) ? 0 : 6; }

			case 5:
				if 
				(
					ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup) ||
					ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(idPermissionsGroup)
				)
				{ return (typeCommissionRule === 1) ? 3 : 2; }
				else
				{ return (typeCommissionRule === 1) ? 3 : 3; }

			case 6:
				if 
				(
					ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(idPermissionsGroup) ||
					ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(idPermissionsGroup)
				)
				{ return (typeCommissionRule === 1) ? 4 : 3; }
				else
				{ return (typeCommissionRule === 1) ? 4 : 4; }
		
			default:
				break;
		}
	}

	render()
	{
		return (
			<div className="dashboardProspection">
				<Header title="Dashboard Vendas Operação" classaditional="headerDashboardProspection" />
                <MessageContent
                    show={this.state.message_show_content}
                    message_type={this.state.message_type_content}
					message_title={this.state.message_title_content}
                    onClose={() => this.setState({message_show_content: false, message_content_content: null})}
                >
                    {this.state.message_content_content}
                </MessageContent>
				<div className="containerBodyDashboardProspection" ref={this.containerScroll} onScroll={this.setTableTitleRuler}>			
					{
						this.props.permissionMonths.toView && !ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
						<Box classaditional="boxHeaderFiltersDashboardProspection filtersDashboardProspection">
							<div className="filterCompanyUnitDashboardProspection m-1 justify-content-between">
								<i className="fas fa-building iconFilterCompanyUnitDashboardProspection"></i>
								<SelectMulti
									classaditional="selectCompanyUnitDashboardProspection"
									default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitBrand, "[Todas Brands]", "brands")}}
									options={this.state.optionsUnitBrand} 
									onChange={async (optionsUnitBrand) => {												
										if (this.state.loadingCards)
										{
											this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
										}
										else
										{
											this.onChangeUnitBrand(optionsUnitBrand);
										}	
									}}
									withFieldSearch
								/>
							</div>
							<div className="filterCompanyUnitDashboardProspection m-1 justify-content-between">
								<i className="fas fa-building iconFilterCompanyUnitDashboardProspection"></i>
								<SelectMulti
									classaditional="selectCompanyUnitDashboardProspection"
									default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
									options={this.state.optionsUnitCompany} 
									onChange={async (optionsUnitCompany) => {												
										if (this.state.loadingCards)
										{
											this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
										}
										else
										{
											await this.setState({optionsUnitCompany});
										}	
									}}
									withFieldSearch
								/>
							</div>									
							<DatePickerCustom
								classaditional="inputFilterDashboardProspection m-1"
								value={this.state.chosenStartDateReport}
								onChange={async (chosenStartDateReport) => {
									if (chosenStartDateReport.toString().trim() === "")
									{
										let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
										let month = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
										chosenStartDateReport = formatedDateTimeToUSA(month.chosenStartDateReport);
									}
									await this.setState({attendanceByUsersAttendants: [], chosenStartDateReport});
								}}
							/>							
							<DatePickerCustom
								classaditional="inputFilterDashboardProspection m-1"
								value={this.state.chosenEndsDateReport}
								onChange={async (chosenEndsDateReport) => {												
									if (chosenEndsDateReport.toString().trim() === "")
									{
										let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
										let month = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
										chosenEndsDateReport = formatedDateTimeToUSA(month.chosenEndsDateReport);
									}
									await this.setState({attendanceByUsersAttendants: [], chosenEndsDateReport});
								}}
							/>
							<InputGeneral
								classaditional="inputTimeFilterDashboardProspection m-1"
								value={`${this.state.timeStartDataReport}`}
								onChange={async (e) => {
									let time = e.target.value;
									time = (time.length > 4) ? validTime(time) : time;
									await this.setState({timeStartDataReport: formatedTime(time)});
								}}
								onClear={async () => await this.setState({timeStartDataReport: "00:00"})}
								maxLength={5}
							/>
							<InputGeneral
								classaditional="inputTimeFilterDashboardProspection m-1"
								value={`${this.state.timeEndsDataReport}`}
								onChange={async (e) => {
									let time = e.target.value;
									time = (time.length > 4) ? validTime(time) : time;
									await this.setState({timeEndsDataReport: formatedTime(time)});

								}}
								onClear={async () => await this.setState({timeEndsDataReport: "23:59"})}
								maxLength={5}
							/>
							<Button
								classaditional="buttonTodayDashboardProspection"
								name="HOJE"
								onClick={() => this.setDatesAndTimesToday()}
							/>											
							<ButtonIcon
								classaditional="buttonSearchReportDashboardProspection m-1"
								icon="far fa-search"
								onClick={async () => {
									// let loadingCards = await this.state.loadingCards;
									await this.getCardsDataDashboardProspection();
								}}
							/>
							{/* {
								ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
								<DatePickerCustom
									classaditional="inputFilterDashboardProspection ml-auto"
									value={this.state.currentMonthYear}
									onChange={async (currentMonthYear) => {
										await this.setChosenMonthReport(currentMonthYear);
										await this.getCardsDataDashboardProspection();
									}}
									type="month"
								/>
							} */}
						</Box>						
					}
					{
						this.props.permissionMonths.toView &&
						<div className="row no-gutters lineHeaderDashboardProspection d-none d-md-flex">
							<Box classaditional="boxHeaderDashboardProspection">
								<h4>Resultados de: {this.getChosenDatesReport()}</h4>
							</Box>
						</div>
					}
					{
						(
							IDS_PERMISSIONS_GROUP_MANAGERS.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) || 
							this.props.user.PER_ID_PERMISSIONS_GROUP === 1 ||
							ID_PERMISSIONS_GROUP_PROSPECTION_MANAGERS.includes(this.props.user.PER_ID_PERMISSIONS_GROUP)
						) &&
						<div className="row no-gutters lineBodyDashboardProspection">
							<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection one pl-md-2">
								{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(1))}
							</div>
							<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection two pr-md-2">
								{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(2))}
							</div>
						</div>
					}
					<div className="row no-gutters lineBodyDashboardProspection">
						<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection three pl-md-2">
							{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(3))}
						</div>
						<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection four pr-md-2">
							{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(4))}
						</div>
					</div>
					<div className="row no-gutters lineBodyDashboardProspection">
						<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection five pl-md-2">
							{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(5))}
						</div>
						<div className="col-12 col-md-12 col-lg-6 columnBodyDashboardProspection spaceCardDashboardProspection six pr-md-2">
							{this.getCardInfoLeftChart(this.setMapCardsNumberByPosition(6))}
						</div>
					</div>
					{
						!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && !ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
						<div className="row no-gutters lineBodyDashboardProspection">
							<div className="col-12 columnBodyDashboardProspection pl-md-2 pr-md-2">
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Resultados de Hoje ({ formatedDateTimeToBRA(this.state.dateRunning)})</h4>
									<div className="row no-gutters lineBoxDashboardProspection">
										<CardResumeWithHover
											icon="fas fa-calendar-day"
											title="Leads de Hoje"
											labelClick={`${this.state.amountBusinessTodayMonthResults}`}
											onClick={() => this.setAdvancedBusinessURL("all", [{create_date_start: "dateRunning"}, {create_date_ends: "dateRunning"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar-week"
											title="Leads da Semana"
											labelClick={`${this.state.amountBusinessWeekMonthResults}`}
											onClick={() => this.setAdvancedBusinessURL("all", [{create_date_start: "paramsDateStartWeek"}, {create_date_ends: "paramsDateEndsWeek"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar"
											title="Leads do Mês"
											labelClick={`${this.state.amountBusinessMonthMonthResults}`}
											onClick={() => this.setAdvancedBusinessURL("all", [{create_date_start: "paramsFirstDayCurrentMonth"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar"
											title="Leads do Mês Anterior"
											labelClick={`${this.state.amountBusinessBeforeMonthMonthResults}`}
											onClick={() => this.setAdvancedBusinessURL("all", [{create_date_start: "paramsFirstDayBeforeMonth"}, {create_date_ends: "paramsLastDayBeforeMonthProportionally"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-greater-than-equal"
											title="Relação ao Mês Anterior"
											backgroundClick={`${this.getColorAndSignalAndValue(this.state.percentMonthOverBeforeMonthMonthResults).color}`}
											labelClick={`${formatedPercentageNumberFromFloat(this.getColorAndSignalAndValue(this.state.percentMonthOverBeforeMonthMonthResults).value)}%`}
										/>
									</div>										
								</Box>
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Resultados de: {this.getChosenDatesReport()}</h4>
									<div className="row no-gutters lineBoxDashboardProspection">
										<CardResumeWithHover
											icon="fas fa-clipboard-list"
											title="Todos os Leads"
											width={155}
											labelClick={`${this.state.amountBusinessAllMonthResults}`}
											onClick={() => this.setAdvancedBusinessURL("", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-exclamation-triangle"
											title="Sem Atendimento"
											width={155}
											labelClick={`${this.state.amountBusinessWithoutAttendanceMonthResults}`}
											colorBackground="var(--color-warning)"
											colorTitle="var(--white-light)" 
											onClick={() => this.setAdvancedBusinessURL("without_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-handshake-alt"
											title="Em Negociação"
											width={155}
											labelClick={`${this.state.amountBusinessInProgressAttendanceMonthResults}`}
											colorBackground="var(--color-focus)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("in_progress_attendance", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-pause"
											title="Pausados"
											width={155}
											labelClick={`${this.state.amountBusinessPausedMonthResults}`}
											colorBackground="var(--color-warning)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("paused", [{create_date_start: "chosenStartDateReport"}, {create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-times"
											title="Não se Aplica"
											width={155}
											labelClick={`${this.state.amountBusinessNotApplicableMonthResults}`}
											colorBackground="var(--color-error)"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("monthNotApplicable")}
										/>
										<CardResumeWithHover
											icon="fas fa-trash"
											title="Descartados"
											width={155}
											labelClick={`${this.state.amountBusinessDiscardedMonthResults}`}
											colorBackground="#f00"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("monthDiscarded")}
										/>
										<CardResumeWithHover
											icon="fas fa-thumbs-down"
											title="Perdidos"
											width={155}
											labelClick={`${this.state.amountBusinessLossMonthResults}`}
											colorBackground="var(--color-purple)"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("monthLoss")}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar"
											title="Agendados"
											width={155}
											labelClick={`${this.state.amountScheduledMonthResults}`}
											colorBackground="var(--dark-dark-three)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("scheduled", [{activity_create_date_start: "chosenStartDateReport"}, {activity_create_date_ends: "chosenEndsDateReport"}, {time_start: "timeStartDataReport"}, {time_ends: "timeEndsDataReport"}])}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar-check"
											title="Aproveitamento"
											width={155}											
											backgroundClick={`${this.getColorAndSignalAndValue(this.state.percentUtilizationMonthResults).color}`}
											labelClick={`
												${formatedPercentageNumberFromFloat(this.getColorAndSignalAndValue(this.state.percentUtilizationMonthResults).value)}%
											`}
										/>
									</div>
								</Box>							
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Resultados Gerais</h4>
									<div className="row no-gutters lineBoxDashboardProspection">
										<CardResumeWithHover
											icon="fas fa-clipboard-list"
											title="Todos os Leads"
											width={155}
											labelClick={`${this.state.amountBusinessAllGeneralResults}`}
											onClick={() => this.setAdvancedBusinessURL("", [])}
										/>
										<CardResumeWithHover
											icon="fas fa-exclamation-triangle"
											title="Sem Atendimento"
											width={155}
											labelClick={`${this.state.amountBusinessWithoutAttendanceGeneralResults}`}
											colorBackground="var(--color-warning)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("without_attendance", [])}
										/>
										<CardResumeWithHover
											icon="fas fa-handshake-alt"
											title="Em Negociação"
											width={155}
											labelClick={`${this.state.amountBusinessInProgressAttendanceGeneralResults}`}
											colorBackground="var(--color-focus)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("in_progress_attendance", [])}
										/>
										<CardResumeWithHover
											icon="fas fa-pause"
											title="Pausados"
											width={155}
											labelClick={`${this.state.amountBusinessPausedGeneralResults}`}
											colorBackground="var(--color-warning)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("paused", [])}
										/>
										<CardResumeWithHover
											icon="fas fa-times"
											title="Não se Aplica"
											width={155}
											labelClick={`${this.state.amountBusinessNotApplicableGeneralResults}`}
											colorBackground="var(--color-error)"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("generalNotApplicable")}
										/>
										<CardResumeWithHover
											icon="fas fa-trash"
											title="Descartados"
											width={155}
											labelClick={`${this.state.amountBusinessDiscardedGeneralResults}`}
											colorBackground="#f00"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("generalDiscarded")}
										/>
										<CardResumeWithHover
											icon="fas fa-thumbs-down"
											title="Perdidos"
											width={155}
											labelClick={`${this.state.amountBusinessLossGeneralResults}`}
											colorBackground="var(--color-purple)"
											colorTitle="var(--white-light)"
											onClick={() => this.showDetaisCard("generalLoss")}
										/>
										<CardResumeWithHover
											icon="fas fa-calendar"
											title="Agendados"
											width={155}
											labelClick={`${this.state.amountScheduledGeneralResults}`}
											colorBackground="var(--dark-dark-three)"
											colorTitle="var(--white-light)"
											onClick={() => this.setAdvancedBusinessURL("scheduled", [])}												
										/>
										<CardResumeWithHover
											icon="fas fa-calendar-check"
											title="Aproveitamento"
											width={155}
											backgroundClick={`${this.getColorAndSignalAndValue(this.state.percentUtilizationGeneralResults).color}`}
											labelClick={`
												${formatedPercentageNumberFromFloat(this.getColorAndSignalAndValue(this.state.percentUtilizationGeneralResults).value)}%
											`}
										/>
									</div>
								</Box>
							</div>
						</div>
					}
					{
						!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && 
						!this.state.requestedTables && !this.state.loadingCards &&
						<div className="row no-gutters lineBodyDashboardProspection">
							<div className="col-12 columnBodyDashboardProspection pl-md-2 pr-md-2">
								<Box classaditional="boxDashboardProspection">
									<Button
										classaditional="buttonToLoaderTablesDashboardProspection"
										name="carregar tabelas"
										onClick={() => this.getTablesDataDashboardProspection()}
									/>
								</Box>
							</div>
						</div>
					}			
					{
						!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && this.state.requestedTables &&
						<div className="row no-gutters lineBodyDashboardProspection" ref={this.boxTableAttandantsDashboardProspection}>
							<div className="col-12 columnBodyDashboardProspection pl-md-2 pr-md-2">
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Atendimentos por Usuários de: {this.getChosenDatesReport()}</h4>
									<br /><br />
									{
										this.state.loadingTables && this.state.requestedTables &&
										<Loading loading={this.state.loadingTables} />
									}
									{
										!this.state.loadingTables && this.state.requestedTables &&
										<div className="boxScroolManager">
												{
													this.state.attendanceByUsersAttendants.map(c => {
														return (
															<div key={`${getRandomNumber()}`} className="boxManagerDashboardProspection">
																<span className="flagManager">Equipe Coordenador(a) {c.name}</span>
																{
																	c.supervisors.map(s => {
																		if (s.attendants.length === 0)
																		{ return (<div key={`${getRandomNumber()}`} />) }
																		
																		return (
																			<div key={`${getRandomNumber()}`} className="boxScroolTeam">
																				<div className="row no-gutters lineBoxTableDashboardProspection">
																					<span className="flagSupervisor">Equipe Supervisor {s.name}</span>
																					{this.getLimitedDynamicTableForAttendants(s)}
																				</div>
																			</div>
																		)
																	})
																}
															</div>
														)
													})
												}
										</div>
									}
								</Box>
							</div>
						</div>
					}
					{
						!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && 
						// !ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && 
						this.state.requestedTables &&
						<div className="row no-gutters lineBodyDashboardProspection" ref={this.boxTableLeadersDashboardProspection}>
							<div className="col-12 columnBodyDashboardProspection pl-md-2 pr-md-2">
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Atendimentos da Liderança de: {this.getChosenDatesReport()}</h4>
									<br /><br />
									{
										this.state.loadingTables && this.state.requestedTables &&
										<Loading loading={this.state.loadingTables} />
									}
									{
										!this.state.loadingTables && this.state.requestedTables &&
										this.getLimitedDynamicTableForLeaders(this.state.attendanceByUsersCoordinatorsAndSupervisors)
									}
								</Box>
							</div>
						</div>
					}
					{
						!ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && 
						!ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) && 
						this.state.requestedTables &&
						<div className="row no-gutters lineBodyDashboardProspection">
							<div className="col-12 columnBodyDashboardProspection pl-md-2 pr-md-2">
								<Box classaditional="boxDashboardProspection">
									<h4 className="titleBoxDashboardPropsection information">Compilado de: {this.getChosenDatesReport()}</h4>
									<br /><br />
									{
										this.state.loadingTables && this.state.requestedTables &&
										<Loading loading={this.state.loadingTables} />
									}
									{
										!this.state.loadingTables && this.state.requestedTables &&
										this.getLimitedDynamicTableForCompilation(this.state.attendanceByUsersCompilation)
									}
								</Box>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) 
{
	const permissionMonths = state.permissions.dashboardMonths;
	const {hasPermissionsLoaded, dashboardProspection} = state.permissions;
	const {user} = state.auth;

	return {
		user,
		hasPermissionsLoaded,
		dashboardProspection,
		permissionMonths,
	}
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardProspection));