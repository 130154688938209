import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AudioPlayer.css";
import { identifiesBrowser } from "../../auxiliary/generalFunctions";

export default function AudioPlayer (props)
{
    const [currentDuration, setCurrentDuration] = useState(0);
    const [maximumDuration, setMaximumDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [source, setSource] = useState(null);
    const [isSafari, setIsSafari] = useState(false);
    const [listened, setListened] = useState(false);
    const audioRef = useRef(null);

    const start = useCallback(() => {
        let audio = audioRef;

        if (audio)
        {
            let play = props.play;

            if (play) 
            { 
                audioRef.current.play(); 
                audioRef.current.playbackRate = props.speed;
            }
            else 
            { 
                if (source !== null) { audioRef.current.pause(); }
            }
        }
  
    }, [audioRef, props, source])

    useEffect(() => {
        start();
        let source = props.source;
        if (source.toString().trim() !== "") { setSource(source); }
        let browser = identifiesBrowser();
        if (browser.isSafari) { setIsSafari(true); }
        setListened(props.listened);
    }, [start, setListened, setIsSafari, props.source, props.listened])

    function handleChangeDuration (e)
    {
        let audio = audioRef;
        let value = e.target.value;
        audio.current.currentTime = value;
        setCurrentDuration(value);
        setExecutedTrack(value);
    }

    function setExecutedTrack (currentDuration)
    {
        setProgress(currentDuration / maximumDuration * 100);
    }

    function getTrackOneColorHex ()
    {
        if (listened)
        {
            return "#30b0e8";
        }
        else if (!listened)
        {
            return "#6f8171";
        }
        else if (props.emphasis)
        {
            return "#FF0000";
        }
    }

    function getTrackTwoColorHex ()
    {
        if (props.emphasis && props.sentForMe)
        {
            return "#b7d5b2d6";
        }
        else if (props.emphasis && !props.sentForMe)
        {
            return "#cfcdcdd0";
        }
        else
        {
            return "#e7e8e9";
        }
    }
    
    function getDuration (source)
    {
        let player = new Audio(source);
        player.addEventListener("durationchange", function (e) 
        {
            if (this.duration !== Infinity) 
            {
                let duration = this.duration
                player.remove();
                setMaximumDuration(duration);
                props.maximumDuration(duration);
                props.finishedDownload();
            };
        }, false);      
        player.load();
        player.currentTime = 24*60*60; //fake big time
        player.volume = 0;
        player.play();

    }

    return (
        <div className={`audioPlayer ${props?.classaditional && props.classaditional}`}>
            <input
                className={`rangeAudioPlayer ${listened ? "" : "noListened"} ${isSafari && "safari"}`}
                type="range" 
                value={currentDuration}
                min="0" 
                max={maximumDuration}
                onChange={handleChangeDuration}
                style={
                    {
                        background: `linear-gradient(to right, ${getTrackOneColorHex()} ${progress}%, ${getTrackTwoColorHex()} ${progress}%)`
                    }
                }
            />
            {
                isSafari && <div className="progressAudioPlayer" style={{width: `calc(${progress}%)`, backgroundColor: `${getTrackOneColorHex()}`}}/>
            }
            {
                source !== null &&
                <audio
                    className="audioAudioPlayer"
                    ref={audioRef}
                    loop={false}
                    onTimeUpdate={(e) => {
                        let value = e.target.currentTime;
                        setCurrentDuration(value);
                        setExecutedTrack(value);
                        props.onChangeDuration(value);
                    }}
                    onLoadedMetadata={(e) => {
                        let value = e.target.duration;

                        //bug from Chrome (https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity)
                        if (value === Infinity)
                        {
                            getDuration(source);
                            return;
                        }
                        
                        setMaximumDuration(value);
                        props.maximumDuration(value);
                        props.finishedDownload();
                    }}
                    src={source}
                >
                </audio>
            }
        </div>
    )
}