import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class WhatsAppController
{
    async getAllChats(amount, screenshot) 
    {
        const response = await api.whatsapp().getAllChats(amount, screenshot);
        return apiCallbackTreatment(response);
    }
    
    async getOldMessages(idContact, idFirstMessageAlreadyInserted) 
    {
        const response = await api.whatsapp().getOldMessages(idContact, idFirstMessageAlreadyInserted);
        return apiCallbackTreatment(response);
    }
    
    async send({idContact, message, phone, idUserCRM, idMessageReference = null, file = null}) 
    {
        const response = await api.whatsapp().send(idContact, message, phone, idUserCRM, idMessageReference, file);
        return apiCallbackTreatment(response);
    }

    async sendImage(formData) 
    {
        const response = await api.whatsapp().sendImage(formData);
        return apiCallbackTreatment(response);
    }

    async sendDocument(formData) 
    {
        const response = await api.whatsapp().sendDocument(formData);
        return apiCallbackTreatment(response);
    }

    async sendAudio(formData) 
    {
        const response = await api.whatsapp().sendAudio(formData);
        return apiCallbackTreatment(response);
    }
    
    async deleteMessage(id) 
    {
        const response = await api.whatsapp().deleteMessage(id);
        return apiCallbackTreatment(response);
    }

    async getAmountScreenshotToAnswerMessage(id)
    {
        const response = await api.whatsapp().getAmountScreenshotToAnswerMessage(id);
        return apiCallbackTreatment(response);
    }

    async getTokenFirebase()
    {
        const response = await api.whatsapp().getTokenFirebase();
        return apiCallbackTreatment(response);
    }

    async reTryingDownloadFile (id)
    {
        const response = await api.whatsapp().reTryingDownloadFile(id);
        return apiCallbackTreatment(response);
    }

    async setAudioDuration (idMessageDigiSac, duration)
    {
        const response = await api.whatsapp().setAudioDuration(idMessageDigiSac, duration);
        return apiCallbackTreatment(response);
    }
}