import React, { Component, Fragment } from "react";
import InputGeneral from "../general/InputGeneral";
import DropDown from "../../components/general/DropDown";
import Menus from "../general/Menus";
import "./ActivityForm.css";
import {
    PHONE_TYPE_ACTIVITY,
    VISIT_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY,
    HISTORIC_TYPE_ACTIVITY,
    FILES_TYPE_ACTIVITY,
    STATUS_BUSINESS_LOSS,
    STATUS_BUSINESS_GAIN,
    STATUS_BUSINESS_PAUSED,
    ID_BRAND_NOT_VERIFY_DATE_START_VISIT
} from "../../core/constants";
import { formatedTime, validTime, formatedMoneyBRA, formatedOnlyNumber, formatedDateTimeToUSA, getDateTimeCurrentBrowserAtMidnight, formatedDateTimeToBrowserAtMidnight, dateDiff, getNextDateTimeCurrentBrowserAtMidnight } from "../../auxiliary/generalFunctions";
import ButtonIcon from "../general/ButtonIcon";
import InputArea from "../general/InputArea";
import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import ActivityController from "../../controllers/ActivityController";
import { connect } from "react-redux";
import MessageConfirmation from "../general/MessageConfirmation";

const initialState = {
    // start data task
    task: "INFORMAÇÃO",
    type: HISTORIC_TYPE_ACTIVITY, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
    dateStart: "",
    timeStart: "07:00",
    dateEnds: "",
    timeEnds: "00:00",
    annotations: "",
    file: null,
    filePath: null,
    finished: false,
    isHistoric: true,
    // ends data task

    // start info business
    title: "",
    name: "",
    // ends info business

    menus: [
        {
            option: 0,
            name: "Informação",
            value: HISTORIC_TYPE_ACTIVITY,
            icon: "far fa-clock"
        },
        {
            option: 1,
            name: "Visita",
            value: VISIT_TYPE_ACTIVITY,
            icon: "fas fa-user-friends"
        },
        {
            option: 2,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            icon: "fas fa-thumbtack"
        },
        // {
        //     option: 3,
        //     name: "E-mail",
        //     value: MAIL_TYPE_ACTIVITY,
        //     icon: "far fa-paper-plane"
        // },
        {
            option: 3,
            name: "Ligar",
            value: PHONE_TYPE_ACTIVITY,
            icon: "fas fa-phone-alt"
        },
    ],
    menusActive: 0,
    contentOption: 0,
    intervalsTime: [],
    showIntervalsStart: false,
    showIntervalsEnds: false,
    optionsOwnersLead: [
        {
            name: "Maria G. Jesus",
            value: "Maria G. Jesus",
            checked: true
        },
        {
            name: "Pedro Gustavo",
            value: "Pedro Gustavo",
            checked: false
        },
        {
            name: "Lucas Santos",
            value: "Lucas Santos",
            checked: false
        },
    ],
    optionsPhonesClient: [
        {
            name: "(11) 9 1111-2222",
            value: "11911112222",
            checked: true
        },
    ],
    optionsTypeServices: [
        {
            name: "Cartão de Crédito",
            value: "Cartão de Crédito",
            checked: false
        },
        {
            name: "Cheque Especial",
            value: "Cheque Especial",
            checked: false
        },
        {
            name: "Consignado",
            value: "Consignado",
            checked: false
        },
        {
            name: "Empréstimo (OMNI)",
            value: "Empréstimo (OMNI)",
            checked: false
        },
        {
            name: "Empréstimo Bancário",
            value: "Empréstimo Bancário",
            checked: false
        },
        {
            name: "Financiamento Veículo",
            value: "Financiamento Veículo",
            checked: false
        },
    ],

    // start fiels values "Análise"
    choosenService: {
        name: "",
        value: "",
    },
    bankService: "",
    valueTotalService: "",
    
    amountInstallments: "", //qtd de parcelas
    amountPayedInstallments: "", //qtd de parcelas pagas
    valueInstallments: "", //valor da parcela
    
    custeFinancing: "", //custo do financimaneto
    feesFinancing: "", //juros do financiamento
    feesSpecialFinancing: "", //taxas especiais

    valuePayed: "", //valor já pago
    openInstallments: "", //parcelas em aberto
    valueDebit: "", //valor da dívida
    valueToSette: "", //valor para quitar
    valueService: "", //valor do serviço

    inCashMoney: "", //à vista (dinheiro),
    inCashCredCard: "", //à vista (cartão de crédito)
    inCashDebitCard: "", //à vista (cartão de débito)

    portionInput: "", //entrade de?
    portion1X: "", //valor das parcela por 1X
    portion2X: "", //valor das parcela por 2X
    portion3X: "", //valor das parcela por 3X
    portion4X: "", //valor das parcela por 4X
    portion5X: "", //valor das parcela por 5X
    portion6X: "", //valor das parcela por 6X
    portion7X: "", //valor das parcela por 7X
    portion8X: "", //valor das parcela por 8X
    portion9X: "", //valor das parcela por 9X
    portion10X: "", //valor das parcela por 10X
    portion11X: "", //valor das parcela por 11X
    portion12X: "", //valor das parcela por 12X
    portion13X: "", //valor das parcela por 13X
    portion14X: "", //valor das parcela por 14X
    portion15X: "", //valor das parcela por 15X
    // ends fiels values "Análise"

    message_type: "information",
    message_show: false,
    message: "",

    isDisabledButton: false,

    // start message confirmantion modal
    message_show_confirmation: false,
    show_children_confirmation: false,
    show_modal_on_action_form: false,
    // ends message confirmantion modal

    dateMin: null,
    dateMax: null
}

const activityController = new ActivityController();

class ActivityForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};
        let contentOption = this.props.contentOption;
        this.setState({name, title, contentOption})
        this.generateIntervalTime();
    }

    componentDidUpdate = async (prevProps) =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};

        if (this.props.contentOption !== this.state.contentOption)
        {
            await this.setState({contentOption: this.props.contentOption});
        }

        if (name !== this.state.name  || title !== this.state.title)
        {
            await this.setState({name, title});
        }

    }

    generateIntervalTime = async () =>
    {        
        let intervalsTime = [];

        for (let i = 7; i < 20; i++) //looping in hours
        {
            let hour = i.toString().padStart(2, "0");
            let minutes = 0;

            for (let j = 0; j <= 3; j++) 
            {
                minutes = (j*15).toString().padStart(2, "0");
                intervalsTime.push([hour, minutes].join(":"));
            }
        }
        intervalsTime.push(["20", "00"].join(":"));
        this.setState({intervalsTime});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    file = () => {
        document.getElementById('fileActivityForm').click();
        document.getElementById('fileActivityForm').onchange = () => {
            let fileInput = document.getElementById('fileActivityForm');
            let file = fileInput.files[0].name;
            this.setState({file, filePath: fileInput.files[0] });
        }
    } 
    
    zeroPad = (num, places) => String(num).padStart(places, '0')

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    getNameStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "EM ANDAMENTO";
            case STATUS_BUSINESS_GAIN:
                return "GANHO";
            case STATUS_BUSINESS_LOSS:
                return "PERDIDO";
            case STATUS_BUSINESS_PAUSED:
                return "PAUSADO";
            default:
                return "";
        }
    }

    onActionForm = async () =>
    {
        await this.props.onSaveActivity();
        if ([STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS].includes(this.props.business.status))
        {
            return this.message("error", `Não é permitido criar atividade em um negócio ${this.getNameStatusBusiness(this.props.business.status)}!`);
        }

        const hasFile = this.state.filePath !== null ? true : false;
        let businessId = null;
        let clientId = null;
        let file = null;
        let title = this.state.task;

        let finished = 2;

        if (this.props.business.id)
        {
            businessId = this.props.business.id;
            clientId = this.props.business.clientId;
        }

        if (title === "" && !hasFile)
        {
            return this.message("error", "Informe o título!");
        }
        
        if (this.state.type === HISTORIC_TYPE_ACTIVITY)
        {
            finished = 1;
            await this.setState({dateStart: null, timeStart: null});
        }
        else
        {
            if (!this.state.dateStart)
            {
                return this.message("error", "Informe a data da atividade!");
            }

            if (this.state.type === VISIT_TYPE_ACTIVITY) 
            {
                const clientCompanyUnit = this.props.business.clientCompanyUnit;
                const companyUnit = this.props.user.unit_companies.find(u => u.UNT_ID === clientCompanyUnit);
                let verify = true;
        
                if (companyUnit?.BNT_ID === ID_BRAND_NOT_VERIFY_DATE_START_VISIT) 
                {
                    verify = false;
                }

                if (verify && (this.state.dateStart !== this.state.dateMin && this.state.dateStart !== this.state.dateMax))
                {
                    return this.message("error", "Data inválida!");
                }
            }
        }

        if (hasFile)
        {
            title = "Arquivos";

            file = {
                name: this.state.filePath.name,
                size: this.state.filePath.size,
                src: await this.toBase64(this.state.filePath)
            }

            await this.setState({
                type: FILES_TYPE_ACTIVITY
            });

            finished = 1;
        }

        if ((this.state.timeStart === "" || this.state.timeStart === null) && (this.state.type !== HISTORIC_TYPE_ACTIVITY && this.state.type !== FILES_TYPE_ACTIVITY))
        {
            return this.message("error", "Informe o horário!");
        }

        if (this.state.annotations === "" && this.state.type !== FILES_TYPE_ACTIVITY)
        {
            return this.message("error", "Descreva a atividade!");
        }

        if (this.state.type === VISIT_TYPE_ACTIVITY)
        {
            const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, this.props.business.id);
            const activities = resultActivities.data.data;

            let dateInstanceToday = getDateTimeCurrentBrowserAtMidnight();
            let hasActivityVisitInPrgress = false;
            let hasOpenedActivityVisitInProgress = false;

            activities.forEach((a) => {
                if (a.ACT_TYPE === "VISIT")
                { 
                    let datetimeCreated = a.ACT_CREATE_DATE;
                    let partsDateTimeCreated = datetimeCreated.split(" ");
                    let dateCreated = partsDateTimeCreated[0];
                    let dateInstanceCreated = formatedDateTimeToBrowserAtMidnight(dateCreated);
                    let diffDateCreateToToday = dateDiff.inDays(dateInstanceCreated, dateInstanceToday);
                    let daysToInvalidate = 4;

                    if (diffDateCreateToToday <= daysToInvalidate)
                    {
                        let dateStartActivity = a.ACT_DATE_START;
                        let dateToday = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());
                        let dateTomorrow = formatedDateTimeToUSA(getNextDateTimeCurrentBrowserAtMidnight(1));

                        if (dateStartActivity === dateToday || dateStartActivity === dateTomorrow)
                        {
                            hasActivityVisitInPrgress = true;
                            hasOpenedActivityVisitInProgress = (a.ACT_FINISHED === 2);
                        }
                    }
                }
            });

            if (hasActivityVisitInPrgress)
            {
                let extension = (!hasOpenedActivityVisitInProgress) ? " reabri-la e " : "";
                let message = `Opsssss, já existe uma visita agendada para o presente, você deve ${extension} editar a data!`;
                return this.message("error", message);
            }

            if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_DATE_START === this.state.dateStart && a.ACT_TIME_START === this.state.timeStart)) 
            {
                return this.message("error", "Opsssss, já existe uma visita agendada para este horário!");
            }

            if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_DATE_START === this.state.dateStart)) 
            {
                return this.message("error", "Opsssss, já existe uma visita agendada para este dia, caso esteja fechada, você deve reabri-la!");
            }
        }

        const result = await activityController.create({
            ACT_TITLE: title,
            ACT_TYPE: this.state.type,
            ACT_DATE_START: this.state.dateStart,
            ACT_TIME_START: this.state.timeStart,
            ACT_ANNOTATIONS: this.state.annotations,
            ACT_ID_BUSINESS: businessId,
            ACT_ID_PERSON_CLIENT: clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: finished,
            hasFile,
            file
        });

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        this.setState({
            task: "",
            dateStart: "",
            timeStart: "00:00",
            dateEnds: "",
            timeEnds: "00:00",
            annotations: "",
            file: null,
            filePath: null
        });

        if (this.props.business.id)
        {
            if (hasFile)
            {
                window.location.reload();
            } 
            else
            {
                this.message("success", "Atividade criada com sucesso!");
                await this.props.onSaveActivity();
            }
        }

        if (this.state.show_modal_on_action_form)
        {
            await this.setState({
                message_show: false, 
                message_show_confirmation: true
            });
        }
    }

    changeDateMinAndDateMax = async () =>
    {
        let dateMin = null;
        let dateMax = null;

        const type = this.state.type;

        if (type === VISIT_TYPE_ACTIVITY)
        {
            const clientCompanyUnit = this.props.business.clientCompanyUnit;
            const companyUnit = this.props.user.unit_companies.find(u => u.UNT_ID === clientCompanyUnit);
            let verify = true;

            if (companyUnit?.BNT_ID === ID_BRAND_NOT_VERIFY_DATE_START_VISIT) {
                verify = false;
            }

            if (verify)
            {
                await this.setState({ dateStart: "" });
                dateMin = getDateTimeCurrentBrowserAtMidnight();
                dateMin = formatedDateTimeToUSA(dateMin);

                dateMax = getDateTimeCurrentBrowserAtMidnight();

                const indexFromDay = dateMax.getDay();

                if (indexFromDay === 6)
                {
                    dateMax.setDate(dateMax.getDate() + 2);
                }
                else
                {
                    dateMax.setDate(dateMax.getDate() + 1);
                }

                dateMax = formatedDateTimeToUSA(dateMax);
            }
        }

        await this.setState({
            dateMin,
            dateMax
        });

    }

    render ()
    {
        return (
            <div className="activityForm">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <MessageConfirmation
                    message_type="information"
                    message={`Parabéns, ${this.props.user.PER_NAME}!`}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    onClickYes={() => {
                        this.setState({
                            message_show_confirmation: false,
                        });
                    }}
                    show={this.state.message_show_confirmation}
                    showButtonYes={false}
                >
                    <p className="text-left">
                        Você cumpriu mais uma etapa do processo. Agora confira se já providenciou os itens abaixo:<br /><br />1 - Solicitar documentação do cliente para confirmar se ele é titular.<br />2 - Se não for titular tem que ter Procuração.<br />3 - Confirmar os dados da dívida (carnê, extrato atualizado ou se ele tem acesso ao aplicativo).<br />4 - Marque o especialista da vez.<br />5 - Encaminhe o cliente para fazer análise junto ao Especialista.
                    </p>
                </MessageConfirmation>
                <div 
                    className="bodyActivityForm" 
                    style={{padding: `${this.props.inModal ? "0px" : ""}`}}
                >
                    {
                        (this.state.contentOption === 1) &&
                        <Fragment>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        classaditional="nameActivityForm"
                                        placeholder={this.state.menus[this.state.menusActive].name}
                                        value={this.state.task}
                                        onChange={(e) => this.setState({task: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-6 columnActivityForm">
                                    <Menus
                                        classaditional="menusActivityForm"
                                        menus={this.state.menus}
                                        active={this.state.menusActive}
                                        onChange={async (option) => {
                                            if (option === 0)
                                            {
                                                await this.setState({
                                                    task: "INFORMAÇÃO",
                                                    isHistoric: true
                                                });
                                            }
                                            else
                                            {
                                                await this.setState({
                                                    task: "",
                                                    isHistoric: false
                                                });
                                            }
                                            const type = this.state.menus[option].value;
                                            await this.setState({menusActive: option, type});
                                            await this.changeDateMinAndDateMax();
                                        }}
                                    />
                                </div>
                                {
                                    this.props.permissions.toInsert &&
                                    <Fragment>
                                        <div className="col-6 text-right">
                                            <Button
                                                classaditional="buttonShortcutActivityForm"
                                                name="Cliente na Unidade"
                                                icon="far fa-street-view"
                                                onClick={async() => {
                                                    this.setState({
                                                        isDisabledButton: true,
                                                        show_modal_on_action_form: true
                                                    });

                                                    const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, this.props.business.id);
                                                    const activities = resultActivities.data.data;
                                                    const activityToUpdate = activities.find(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY);
                                                    if (activityToUpdate)
                                                    {
                                                        await activityController.update(activityToUpdate.ACT_ID, {
                                                            ACT_IS_SHOWEDUP: 1
                                                        });
                                                    }

                                                    await this.setState({
                                                        task: "INFORMAÇÃO",
                                                        type: "HISTORIC",
                                                        annotations: "● CLIENTE CHEGOU NA UNIDADE PARA ATENDIMENTO."
                                                    });

                                                    await this.onActionForm();

                                                    this.setState({
                                                        isDisabledButton: false,
                                                        show_modal_on_action_form: false
                                                    });
                                                }}
                                                type="button"
                                            />
                                            <Button
                                                classaditional="buttonShortcutActivityForm"
                                                name="Cliente em Atendimento"
                                                icon="far fa-user-chart"
                                                onClick={async() => {
                                                    let owner = this.props.business.owner;
                                                    if (!owner) 
                                                    {
                                                        return this.message("error", "O Cliente não pode ser atendido sem um proprietário, por favor marque o especialista da vez!");
                                                    }
                                                    this.setState({ isDisabledButton: true });
                                                    await this.setState({
                                                        task: "INFORMAÇÃO",
                                                        type: "HISTORIC",
                                                        annotations: "● CLIENTE ENTROU PARA ATENDIMENTO."
                                                    });
                                                    await this.onActionForm();
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                type="button"
                                            />
                                        </div>
                                    </Fragment>
                                    }
                            </div>
                            { !this.state.isHistoric &&
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 pr-2 columnActivityForm">
                                    <InputGeneral
                                        type="date" 
                                        onChange={(e) => this.setState({dateStart: e.target.value})}
                                        value={this.state.dateStart} 
                                        min={this.state.dateMin} 
                                        max={this.state.dateMax}
                                    />
                                </div>
                                <div className="col-2 pl-2 pr-2 columnActivityForm">
                                    <InputGeneral
                                        placeholder=""
                                        value={this.state.timeStart}
                                        onClick={() => this.setState({showIntervalsStart: !this.state.showIntervalsStart})}
                                        onChange={(e) => this.setState({timeStart: validTime(formatedTime(e.target.value))})}
                                        maxLength={5}
                                    />
                                    {
                                        this.state.showIntervalsStart &&
                                        <DropDown classaditional="dropdownTimeActivityForm">
                                            {
                                                this.state.intervalsTime.map((t, i) => {
                                                    return (
                                                        <div
                                                            key={`${i}`} 
                                                            className={`timeActivityForm ${(t === this.state.timeStart) ? "active" : ""}`}
                                                            onClick={() => this.setState({timeStart: t, showIntervalsStart: false})}
                                                        >{formatedTime(t)}</div>
                                                    )
                                                })
                                            }
                                        </DropDown>
                                    }
                                    {
                                        this.state.timeStart !== "" &&
                                        <ButtonIcon
                                            icon="fal fa-times"
                                            classaditional="buttonClearTimeActivityForm"
                                            onClick={() => this.setState({timeStart: ""})}
                                        />
                                    }
                                </div>
                                {/* <div className="col-2 pl-2 pr-2 columnActivityForm">
                                    <InputGeneral
                                        placeholder="12:15"
                                        value={this.state.timeEnds}
                                        onClick={() => this.setState({showIntervalsEnds: !this.state.showIntervalsEnds})}
                                        onChange={(e) => this.setState({timeEnds: validTime(formatedTime(e.target.value))})}
                                        maxLength={5}
                                    />
                                    {
                                        this.state.showIntervalsEnds &&
                                        <DropDown classaditional="dropdownTimeActivityForm">
                                            {
                                                this.state.intervalsTime.map((t, i) => {
                                                    return (
                                                        <div
                                                            key={`${i}`} 
                                                            className={`timeActivityForm ${(t === this.state.timeEnds) ? "active" : ""}`}
                                                            onClick={() => this.setState({timeEnds: t, showIntervalsEnds: false})}
                                                        >{formatedTime(t)}</div>
                                                    )
                                                })
                                            }
                                        </DropDown>
                                    }
                                    {
                                        this.state.timeEnds !== "" &&
                                        <ButtonIcon
                                            icon="fal fa-times"
                                            classaditional="buttonClearTimeActivityForm"
                                            onClick={() => this.setState({timeEnds: ""})}
                                        />
                                    }
                                </div>
                                <div className="col-4 pl-2 columnActivityForm">
                                    <InputGeneral
                                        type="date" 
                                        onChange={(e) => this.setState({dateEnds: e.target.value})}
                                        value={this.state.dateEnds}
                                    />
                                </div> */}
                            </div>
                            }
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm">
                                <InputArea
                                    classaditional="annotationsActivityForm"
                                    placeholder="...suas anotações"
                                    value={this.state.annotations}
                                    onChange={(e) => this.setState({annotations: e.target.value})}
                                    rows="25" 
                                    cols="50"
                                />
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                        <Fragment>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm file">
                                    <ButtonIcon
                                        classaditional="buttonFileActivityForm"
                                        icon="fas fa-paperclip"
                                        onClick={this.file}
                                    />
                                    {
                                        this.state.file !== "" &&
                                        <span className="nameFileActivityForm">{this.state.file}</span>
                                    }
                                    <InputGeneral
                                        classaditional="d-none"
                                        id="fileActivityForm"
                                        value=""
                                        onChange={(e) => {}}
                                        type="file"
                                        hidden 
                                        accept=".jpg,.png,.jpeg,.pdf"
                                    />
                                </div>
                            </div>
                            {/* <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <SelectMulti
                                        label="Proprietários"
                                        default={this.state.optionsOwnersLead}
                                        options={this.state.optionsOwnersLead} 
                                        onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        label="Negócio"
                                        placeholder={this.state.title}
                                        value={this.state.title}
                                        onChange={(e) => this.setState({title: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        label="Cliente"
                                        placeholder={this.state.name}
                                        value={this.state.name}
                                        onChange={(e) => this.setState({name: e.target.value})}
                                    />
                                </div>
                            </div> */}
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 2 || this.state.contentOption === 3) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm d-flex justify-content-center" >
                                {/* <SelectMulti
                                    label="Ligar para?"
                                    default={this.state.optionsPhonesClient}
                                    options={this.state.optionsPhonesClient} 
                                    onChange={(optionsPhonesClient) => this.setState({optionsPhonesClient})}
                                /> */}
                                <span>nothing...</span>
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 4) &&
                        <Fragment>
                            <span className="labelSuccessServiceActivityForm">Informações Iniciais:</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <Select
                                        label="Tipo de Serviço"
                                        default={this.state.choosenService}
                                        options={this.state.optionsTypeServices} 
                                        onChange={(choosenService) => this.setState({choosenService})}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Banco"
                                        placeholder="Itaú, Banco do Brasil, Santander..."
                                        value={this.state.bankService}
                                        onChange={(e) => this.setState({bankService: e.target.value})}
                                        maxLength={25}
                                        mandatory
                                    />                                    
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor Financiado (R$)"
                                        placeholder="4.000,00"
                                        value={this.state.valueTotalService}
                                        onChange={(e) => this.setState({valueTotalService: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        mandatory
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Quantidade de Parcelas"
                                        placeholder="12"
                                        value={this.state.amountInstallments}
                                        onChange={(e) => this.setState({amountInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Quantidade de Parcelas Pagas"
                                        placeholder="3"
                                        value={this.state.amountPayedInstallments}
                                        onChange={(e) => this.setState({amountPayedInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4}
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor da Parcela (R$)"
                                        placeholder="300,00"
                                        value={this.state.valueInstallments}
                                        onChange={(e) => this.setState({valueInstallments: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Custo do Financiamento (R$)"
                                        placeholder="1.000,00"
                                        disabled
                                        value={this.state.custeFinancing}
                                        onChange={(e) => this.setState({custeFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Juros do Financiamento (R$)"
                                        placeholder="250,99"
                                        disabled
                                        value={this.state.feesFinancing}
                                        onChange={(e) => this.setState({feesFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor já Pago (R$)"
                                        placeholder="400,00"
                                        disabled
                                        value={this.state.valuePayed}
                                        onChange={(e) => this.setState({valuePayed: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Parcelas em Aberto"
                                        placeholder="8"
                                        value={this.state.openInstallments}
                                        disabled
                                        onChange={(e) => this.setState({openInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={14}                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor da Dívida"
                                        placeholder="6.000,00"
                                        disabled
                                        value={this.state.valueDebit}
                                        onChange={(e) => this.setState({valueDebit: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor para Quitar (R$)"
                                        placeholder="5.100,00"
                                        disabled
                                        value={this.state.valueToSette}
                                        onChange={(e) => this.setState({valueToSette: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Taxas Especiais"
                                        placeholder="1.250,00"
                                        value={this.state.feesSpecialFinancing}
                                        onChange={(e) => this.setState({feesSpecialFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor do Serviço"
                                        placeholder="5.350,00"
                                        disabled
                                        value={this.state.valueTotalService}
                                        onChange={(e) => this.setState({valueTotalService: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">                                                                
                                </div>
                            </div>
                            <br />
                            <span className="labelSuccessServiceActivityForm">Resultado da Análise:</span>
                            <span className="labeDetailsServiceActivityForm">À Vista:</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Dinheiro (R$)"
                                        placeholder="3.600,00"
                                        value={this.state.inCashMoney}
                                        disabled
                                        onChange={(e) => this.setState({inCashMoney: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Cartão de Débito (R$)"
                                        placeholder="3.720,00"
                                        value={this.state.inCashDebitCard}
                                        disabled
                                        onChange={(e) => this.setState({inCashDebitCard: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Cartão de Crédito (R$)"
                                        placeholder="4.000,00"
                                        value={this.state.inCashCredCard}
                                        disabled
                                        onChange={(e) => this.setState({inCashCredCard: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <span className="labeDetailsServiceActivityForm">Parcelado (com entrada):</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Entrada de (R$):"
                                        placeholder="0,00"
                                        value={this.state.portionInput}
                                        onChange={(e) => this.setState({portionInput: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +1X de (R$):"
                                        disabled
                                        placeholder="4.000,00"
                                        value={this.state.portion1X}
                                        onChange={(e) => this.setState({portion1X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +2X de (R$):"
                                        disabled
                                        placeholder="2.000,00"
                                        value={this.state.portion2X}
                                        onChange={(e) => this.setState({portion2X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +3X de (R$):"
                                        disabled
                                        placeholder="1.333,33"
                                        value={this.state.portion3X}
                                        onChange={(e) => this.setState({portion3X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +4X de (R$):"
                                        disabled
                                        placeholder="1.000,00"
                                        value={this.state.portion4X}
                                        onChange={(e) => this.setState({portion4X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +5X de (R$):"
                                        disabled
                                        placeholder="800,00"
                                        value={this.state.portion5X}
                                        onChange={(e) => this.setState({portion5X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +6X de (R$):"
                                        disabled
                                        placeholder="666,67"
                                        value={this.state.portion6X}
                                        onChange={(e) => this.setState({portion6X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +7X de (R$):"
                                        disabled
                                        placeholder="571,43"
                                        value={this.state.portion7X}
                                        onChange={(e) => this.setState({portion7X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +8X de (R$):"
                                        disabled
                                        placeholder="500,00"
                                        value={this.state.portion8X}
                                        onChange={(e) => this.setState({portion8X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationWarningAcitivityForm"
                                        label="Entrada +9X de (R$):"
                                        disabled
                                        placeholder="444,44"
                                        value={this.state.portion9X}
                                        onChange={(e) => this.setState({portion9X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationWarningAcitivityForm"
                                        label="Entrada +10X de (R$):"
                                        disabled
                                        placeholder="400,00"
                                        value={this.state.portion10X}
                                        onChange={(e) => this.setState({portion10X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +11X de (R$):"
                                        disabled
                                        placeholder="363,64"
                                        value={this.state.portion11X}
                                        onChange={(e) => this.setState({portion11X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +12X de (R$):"
                                        disabled
                                        placeholder="333,33"
                                        value={this.state.portion12X}
                                        onChange={(e) => this.setState({portion12X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +13X de (R$):"
                                        disabled
                                        placeholder="307,69"
                                        value={this.state.portion13X}
                                        onChange={(e) => this.setState({portion13X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +14X de (R$):"
                                        disabled
                                        placeholder="285,71"
                                        value={this.state.portion14X}
                                        onChange={(e) => this.setState({portion14X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +15X de (R$):"
                                        disabled
                                        placeholder="266,67"
                                        value={this.state.portion15X}
                                        onChange={(e) => this.setState({portion15X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <br />
                            <span className="labelSuccessServiceActivityForm">Legendas:</span>
                            <span className="subtitleGeneralActivityForm">
                                (*) indica campos cujo preenchimento é obrigatório.
                            </span>
                            <span className="subtitleSuccessActivityForm">
                                indica campos cujo valor informado poderá ser negociado sem prévia autorização de superior.
                            </span>
                            <span className="subtitleWarningActivityForm">
                                indica campos cujo valor informado deve ser negociado com atenção (prefira o valor dos campos em verde).
                            </span>
                            <span className="subtitleErrorActivityForm">
                                indica campos cujo valor informado deve ser negociados com prévia autorização de superior.
                            </span>

                        </Fragment>
                    }

                </div>
                <div className="footerActivityForm">
                    <div
                        className="row no-gutters lineActivityForm"
                        style={{
                            padding: `${this.props.inModal ? "0px" : ""}`,
                            border: `${this.props.inModal ? "none" : ""}`,
                            backgroundColor: `${this.props.inModal ? "transparent" : ""}`
                        }}
                    >
                        {/* {
                            (this.state.contentOption === 1) &&
                            <Check
                                classaditional={`checkTaskActivityForm ${(this.state.finished) ? "active" : ""}`}
                                checked={this.state.finished}
                                name="Marcar como feito"
                                onClick={() => this.setState({finished: !this.state.finished})}
                            />
                        } */}
                        {
                            (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                            <Fragment>
                                <Button
                                    classaditional="buttonActivityForm"
                                    name="Cancelar"
                                    onClick={() => this.message("information", "nothing...")}
                                />
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        classaditional="buttonActivityForm positive"
                                        name="Salvar"
                                        onClick={async () => {
                                            this.setState({ isDisabledButton: true });
                                            await this.onActionForm();
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    />
                                }
                            </Fragment>
                        }
                        {
                            (this.state.contentOption === 4 && this.props.analysisPermissions.toUpdate) &&
                            <Fragment>
                                <Button
                                    classaditional="buttonActivityForm positive"
                                    name="Analisar"
                                    onClick={() => this.message("information", "nothing...")}
                                />
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const analysisPermissions = state.permissions.analysis;
    const permissions = state.permissions.activities;
    const { user } = state.auth;

    return {
        analysisPermissions,
        permissions,
        user
    }
}

export default connect(mapStateToProps)(ActivityForm);