import React, { Component, Fragment } from "react";
import "./DetailsBusiness.css";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import { withRouter } from "react-router-dom";
import Header from "../../components/CRM/Header";
import ProgressLine from "../../components/CRM/ProgressLine";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { changeDealBusiness } from "../../store/actions/business";
import ModalEdit from "../../components/general/ModalEdit";
import {
    dateDiff,
    formatedDateTime,
    formatedDateTimeToBRA,
    formatedDateTimeToBrowserAtAnyTime,
    formatedDateTimeToUSA,
    formatedMoneyBRA,
    formatedMoneyBRAFromFloat,
    formatedOnlyNumber,
    formatedPercentageNumber,
    getDateTimeCurrentBrowserAtMidnight,
    getDateTimeCurrentBrowserUSA,
    getFullNameFisrtCharsUppercase,
    getOptionsSelectCheckedFormated,
    isEmail,
    overshadowsPhone
} from "../../auxiliary/generalFunctions";
import ModalEditStamp from "../../components/CRM/ModalEditStamp";
import gravatar from "../../assets/images/default_avatar.svg";
import Anchor from "../../components/general/Anchor";
import Box from "../../components/general/Box";
import Menus from "../../components/general/Menus";
import ActivityForm from "../../components/CRM/ActivityForm";
import ItemHistoricActivity from "../../components/CRM/ItemHistoricActivity";
import InputGeneral from "../../components/general/InputGeneral";
import Select from "../../components/general/Select";
import Message from "../../components/general/Message";
import StampController from "../../controllers/StampController";
import BusinessController from "../../controllers/BusinessController";
import UserController from "../../controllers/UserController";
import ActivityController from "../../controllers/ActivityController";
import MessageConfirmation from "../../components/general/MessageConfirmation";
import ClientController from "../../controllers/ClientController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";
import { 
    APPLICATION_NAME,
    businessByClient,
    defaultPermissions,
    HISTORIC_TYPE_ACTIVITY,
    ID_BRAND_USE_GROUP_1_ON_REASONS_FOR_LOSS,
    ID_BRAND_USE_GROUP_2_ON_REASONS_FOR_LOSS,
    ID_BRAND_USE_GROUP_3_ON_REASONS_FOR_LOSS,
    ID_STAMP_IS_ALREADY_CLIENT,
    MAIL_TYPE_ACTIVITY,
    PHONE_TYPE_ACTIVITY,
    STATUS_BUSINESS_GAIN,
    STATUS_BUSINESS_LOSS,
    STATUS_BUSINESS_PAUSED,
    TASK_TYPE_ACTIVITY,
    TRANSFER_TYPE_ACTIVITY,
    VISIT_TYPE_ACTIVITY } from "../../core/constants";
import BusinessReasonsForLossController from "../../controllers/BusinessReasonsForLossController";
import { changeStatusAllSpecialistsUpdatedServiceQueue, setLastPositionSpecialistServiceQueue } from "../../store/actions/queue";
import SelectMulti from "../../components/general/SelectMulti";
import BusinessServices from "../../controllers/BusinessServicesController";

const initialState = {
    //start all business in database
    allDraggables: [],
    allActivities: [],
    allActivitiesWithFilesArchived: [],
    allActivitiesWithFilesNotArchived: [],
    allActivitiesBusinessRecovered: [],
    draggableUpdated: false,
    //ends all business in database

    // start draggable business details
    id: 0, //come as props
    title: "", //come as props
    name: "", //come as props
    money: "0,00", //come as props
    service: "", //come as props
    column: 0, //come as props
    checked: false,  // come as props
    createdAt: null,
    creatorName: null,
    status: "",
    statusDate: null,
    numberOfDays: 0,
    inactiveInDays: 0,
    idBusinessRecovered: null,
    activitiesBusinessRecoveredIsLoaded: false,
    wasRecovered: false,
    canReopen: false, //default is false
    owners: [],
    groupActivities: [],

    stamp: {},
    // ends draggable business details

    // start all visibles modals
    showModalEditTitle: false,
    showModalEditMoney: false,
    showModalEditBusinessService: false,
    showModalEditStamp: false,
    showModalMoreActions: false,
    showModalEditOwner: false,
    showEditPersonOthersDetailsBusiness: false,
    showEditProspectionOwner: false,
    // ends all visibles modals

    // start about stamps business
    stampName: "",
    stampColor: "error",
    allStampsBusiness: [],
    // ends about stamps business

    // start others defualts
    optionsOwnersLead: [],
    menusActivities: [
        // {
        //     option: 0,
        //     name: "Observações",
        //     icon: "far fa-sticky-note"
        // },
        {
            option: 1,
            name: "Atividades",
            icon: "far fa-calendar"
        },
        // {
        //     option: 2,
        //     name: "Ligar",
        //     icon: "fas fa-phone-alt"
        // },
        // {
        //     option: 3,
        //     name: "E-mail",
        //     icon: "fas fa-envelope"
        // },
        // {
        //     option: 4,
        //     name: "Análise",
        //     icon: "far fa-chart-line"
        // },
    ],
    optionsStepFunnelMoreFilters: [
        {
            name: "Lead",
            value: 1,
            checked: false
        },
        {
            name: "Primeiro Contato",
            value: 2,
            checked: false
        },
        {
            name: "Atendimento Presencial",
            value: 3,
            checked: false
        },
        {
            name: "Fechamento",
            value: 4,
            checked: false
        },
        {
            name: "Reagendar",
            value: 5,
            checked: false
        },
    ],
    optionsStampsLead: [],
    optionChosenStampsLead: null,
    menusActivitiesActive: 1,
    // ends others defualts

    // start business client
    nameClientEditOthersDetails: "",
    surnameClientEditOthersDetails: "",
    mailClientEditOthersDetails: "",
    phoneClientEditOthersDetails: "",
    phoneTwoClientEditOthersDetails: "",
    idCompanyUnitClientEditOthersDetails: 0,
    idBrandFromCompanyUnitClientEditOthersDetails: 0,
    nameCompanyUnitClientEditOthersDetails: "",
    nameCampaignClientEditOthersDetails: "",
    clientCreatorName: null,
    midiaOriginName: null,
    clientId: 0,
    // ends business client

    // start message modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends message modal

    // start message confirmantion modal
    message_confirmation: "",
    message_show_confirmation: false,
    message_type_confirmation: "",
    statusToUpdate: "",
    statusConfirmation: false,
    show_children_confirmation: false,

    message_show_confirmation_more_business: false,
    message_show_confirmation_showedup: false,
    message_show_confirmation_undo_showedup: false,
    data_to_update_showedup: null,
    data_to_undo_showedup: null,
    type_undo_showedup: "REOPEN", // REOPEN or CHANGE
    // ends message confirmantion modal

    // start services ERP
    optionsServicesERP: [],
    choosenServiceERP: {
        name: "",
        value: 0,
    },
    idServiceERP: null,
    // ends services ERP

    // start content businessServices
    optionsBusinessServices: [],
    optionChosenBusinessServices: null,
    // ends content businessServices

    // start reasons for loss
    optionsReasonsForLoss: [],
    choosenReasonForLoss: {
        name: "",
        value: 0,
    },
    // ends reasons for loss

    // start midia origin
    optionsMidiaOrigin: [],
    optionChosenMidiaOrigin: {
        name: "",
        value: 0,
    },
    // ends midia origin

    // start modal transfer owner
    choosenOwnerWithoutQueue: null,
    optionsCompanyUnitsWithoutQueue: [],
    optionsOwnersWithoutQueue: [],
    optionsOwnersWithoutQueueFiltered: [],
    showModalEditOwnerWithoutQueue: false,
    // ends modal transfer owner

    optionsCompanyUnit: [],
    optionChosenCompanyUnit: null,
    optionChosenOwner: null,
    owner: null,

    optionsAttendants: [],
    prospectionOwner: null,
    optionChosenProspectionOwner: null,

    // start about service queue
    idCurrentCompanyUnit: 2,
    allSpecialistsNotFiltered: [],
    optionOwnerSpecialistOrManagers: [], //WARNING: just one specialist in the array
    objManagerByCompanyUnit: {},
    // ends about service queue

    moreClientBusiness: [],

    ownerPermissions: defaultPermissions
}

const activityController = new ActivityController();
const businessController = new BusinessController();
const businessReasonsForLossController = new BusinessReasonsForLossController();
const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();
const stampController = new StampController();
const userController = new UserController();
const businessServices = new BusinessServices();
class DetailsBusiness extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let allDraggables = await this.props.allDraggables;
        await this.setState({allDraggables});
        await this.getDetailsBusiness();
        await this.setCurrentCompanyUnit();
        await this.getCompanyUnit();
        await this.getOwners();
        await this.getFirstOwnerAtQueueByCompanyUnit();
        await this.getManagerByCompanyUnit();

        await Promise.all([
            this.getStamps(),
            this.getActivities(),
            this.getMidiaOrigin(),
            this.getReasonsForLoss(),
            this.getMoreClientBusiness(),
            this.getProspectionOwnersToTransfer()
        ]);

        let owner = await this.state.owner;
        let ownerPermissions = this.props.permissions.leadOwners;

        if ((owner === null && ownerPermissions.toInsert === true) || (owner !== null && ownerPermissions.toUpdate === true)) 
        {  ownerPermissions.toUpdate = true; }
        else
        { ownerPermissions.toUpdate = false; }

        await this.setState({ownerPermissions});

        if (this.props.permissions.analysis.toView)
        {
            await this.setState({
                menusActivities: [
                    {
                        option: 1,
                        name: "Atividades",
                        icon: "far fa-calendar"
                    },
                    {
                        option: 4,
                        name: "Análise",
                        icon: "far fa-chart-line"
                    },
            ]});
        }

    }
    
    setDinamicTitlePage = () =>
    {
        let defaultTitle = `${APPLICATION_NAME} - Detalhes Negócios`;
        let nameClientTitle = this.state.name;
        document.title = (nameClientTitle.toString().trim() === "") ? defaultTitle : nameClientTitle;
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("DETAILS BUSINESS prevProps ", prevProps);
        // console.log("DETAILS BUSINESS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.business.toView && !this.props.permissions.activities.toView) 
            {
                this.props.history.push(`/preferences-user`);
            }

            if (!this.props.permissions.business.toViewAll && this.props.user.PER_ID !== this.state.owner?.id)
            {
                this.props.history.push(`/activities`);
            }

		}

        if (prevState.id !== this.state.id)
        {
            await this.getCompanyUnit();
            const idCompanyUnit = this.state.idCompanyUnitClientEditOthersDetails;
            const userUnits = this.state.optionsCompanyUnit;

            if (!userUnits.map(u => u.value).includes(idCompanyUnit))
            {
                return this.props.history.push(`/preferences-user`);
            }

            this.setDinamicTitlePage();
        }

        if (prevProps.permissions.analysis.toView !== this.props.permissions.analysis.toView)
        {
            if (this.props.permissions.analysis.toView)
            {
                await this.setState({
                    menusActivities: [
                        {
                            option: 1,
                            name: "Atividades",
                            icon: "far fa-calendar"
                        },
                        {
                            option: 4,
                            name: "Análise",
                            icon: "far fa-chart-line"
                        },
                ]});
            }
        }

        if (this.state.draggableUpdated)
        {
            let allDraggables = await this.props.allDraggables;
            await this.setState({allDraggables, draggableUpdated: false});
        }

        // getting allSpecialists of the service queue, for each change in the queue.
        if (this.props.queue.allSpecialistsNotFilteredHasUpdated && this.props.queue.allSpecialistsNotFiltered.length > 0)
        {
            await this.props.changeStatusAllSpecialistsUpdatedServiceQueue(false);
            await this.getFirstOwnerAtQueueByCompanyUnit();
            await this.getManagerByCompanyUnit();
        }

        this.setDinamicTitlePage();
    }

    getCompanyUnitsAndOwnersWithoutQueue = async () =>
    {
        let optionsCompanyUnitsWithoutQueue = [];
        let optionsOwnersWithoutQueue = [];

        const result = await userController.getOwnersForUserOnAccess(this.props.user.PER_ID);
        const allUnits = result.data.data.filter(u => u.UNT_STATUS === 1);
            
        for (const unit of allUnits) 
        {
            optionsCompanyUnitsWithoutQueue.push({
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: allUnits.length === 1 ? true : false
            });

            for (const owner of unit.owners)
            {
                if (owner.PER_STATUS === 1)
                {
                    optionsOwnersWithoutQueue.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_ID,
                        idCompanyUnit: unit.UNT_ID,
                        checked: false
                    });
                }
            }
        }

        optionsOwnersWithoutQueue.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });

        optionsOwnersWithoutQueue.unshift({
            name: "[REMOVER PROPRIETÁRIO]",
            value: 0,
            checked: false
        });

        this.setState({
            optionsCompanyUnitsWithoutQueue,
            optionsOwnersWithoutQueue,
            optionsOwnersWithoutQueueFiltered: optionsOwnersWithoutQueue
        });
    }

    getOptionsOwnersWithoutQueueFiltered = async () => 
    {
        const checkedCompanyUnits = this.state.optionsCompanyUnitsWithoutQueue.filter(u => u.checked).map(u => u.value);
        if (checkedCompanyUnits.length === 0) 
        {
            await this.setState({
                optionsOwnersWithoutQueueFiltered: this.state.optionsOwnersWithoutQueue
            });
            return;
        }

        const owners = this.state.optionsOwnersWithoutQueue.filter(o => checkedCompanyUnits.includes(o.idCompanyUnit) || o.value === 0);

        await this.setState({
            optionsOwnersWithoutQueueFiltered: owners
        });

    }

    saveOwnerWithoutQueue = async () =>
    {
        let yesterday = getDateTimeCurrentBrowserAtMidnight();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday = formatedDateTimeToUSA(yesterday);

        const owner = this.state.choosenOwnerWithoutQueue;
        if (!owner)
        {
            return this.message("error", "Selecione um proprietário!");
        }

        const result = await businessController.update(this.state.id, {
            BUS_ID_PERSON_OWNER: owner.value !== 0 ? owner.value : null
        });

        if (!result.status)
        {
            return this.message("error", "Erro ao alterar proprietário!");
        }

        let annotations = `● Adicionou ${owner.name} como proprietário.`;
        if (owner.value === 0)
        {
            annotations = `● Removeu ${this.state.owner?.name} como proprietário.`;
        }

        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.getDetailsBusiness();
        await this.getActivities();

        const activityToUpdate = this.state.allActivities.find(a => a.type === VISIT_TYPE_ACTIVITY);
        if (activityToUpdate)
        {
            if (activityToUpdate.isShowedup !== 1)
            {
                await activityController.update(activityToUpdate.id, {
                    ACT_IS_SHOWEDUP: 1
                });

                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: "● CLIENTE COMPARECEU.",
                    ACT_ID_BUSINESS: this.state.id,
                    ACT_ID_PERSON_CLIENT: this.state.clientId,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        await this.setState({showModalEditOwnerWithoutQueue: false});
        this.message("success", "Proprietário atualizado com sucesso!");

        await this.getDetailsBusiness();
        await this.getActivities();
    }

    getServicesERP = async () =>
    {

        const businessServicesERP = await businessController.getBusinessServicesERP(this.props.user.PER_ID);

        const user = await userController.getById(this.props.user.PER_ID);
        let idUserERP = null;

        if (user.status)
        {
            idUserERP = user.data.PER_ID_USER_ERP;
        }

        const response = await businessController.getServicesERPTemporaryWebhook(idUserERP);
        
        if (response.status)
        {
            if (response.data?.data && response.data?.data !== null)
            {
                let users = response.data.data;
                let optionsServicesERP = [];
                for (const s of users)
                {
                    if (businessServicesERP.data.data.some(bs => bs.BUS_ID_SERVICE_ERP === parseInt(s.idServico)) === false)
                    {
                        optionsServicesERP.push(
                            {
                                name: `(${s.idServico}) ${getFullNameFisrtCharsUppercase(s.PS_NOME)}`,
                                value: parseInt(s.idServico),
                                checked: false,
                                createdAt: s.DtCadastro,
                                title: s.Titulo,
                                money: parseFloat(s.Serv_Valor)
                            }
                        );
                    }
                }

                await this.setState({optionsServicesERP});
            }
        }
        else
        {
            this.message("error", "Erro ao obter os serviços do ERP!");
        }
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            this.props.changeDealBusiness({[propertyName]: newValue, id, propertyName});
            this.setState({draggableUpdated: true});
        }
    }   

    changeStampBusiness = async (stamp) =>
    {
        if (this.props.permissions.business.toUpdate)
        {
            this.changeValue(stamp, this.state.id, "stamp")
            await this.setState({stamp, showModalEditStamp: false});

            await businessController.update(this.state.id, {
                BUS_ID_STAMP: stamp.value
            });
        }
        else
        {
            this.message("error", "Você não tem permissão para editar esta informação!");
        }
    }

    getDetailsBusiness = async () =>
    {
        //INFORMATION: database query
        const { path, params } = this.props.match;
        
        if (path.includes("business") && path.includes("details")) 
        {
            let id = params.id;
            let canReopen = false;

            const result = await businessController.getById(id);

            if (!result.status) { return this.message("error", "Opsssss, esse negócio não foi encontrado!"); }

            const business = result.data;
            let dateCreatedAt = business.BUS_DATE_CREATED.split(" ")[0].split("-");
            dateCreatedAt = dateCreatedAt.join("-"); //adjustment for safari browser
            const numberOfDays = dateDiff.inDays(new Date(dateCreatedAt), getDateTimeCurrentBrowserAtMidnight());
            const createdAt = formatedDateTime(business.BUS_DATE_CREATED);

            let inactiveInDays = 0;

            if (business.DATE_LAST_ACTIVITY !== null)
            {
                inactiveInDays = dateDiff.inDays(new Date(`${business.DATE_LAST_ACTIVITY} 00:00:00`), getDateTimeCurrentBrowserAtMidnight());

                if (inactiveInDays < 0)
                {
                    inactiveInDays = 0;
                }
            }
            else
            {
                inactiveInDays = numberOfDays;
            }

            let groupActivities = [];

            if (business.ACTIVITIES_BY_GROUP.length > 0)
            {
                const totalActivities = business.ACTIVITIES_BY_GROUP.reduce((total, b) => {
                    total = parseInt(total);
                    total += parseInt(b.COUNT);
                    return total;
                }, 0);

                for (const activity of business.ACTIVITIES_BY_GROUP)
                {
                    groupActivities.push({
                        name: this.getNameActivity(activity.ACT_TYPE),
                        count: activity.COUNT,
                        percentage: formatedPercentageNumber(((activity.COUNT * 100) / totalActivities).toFixed(2))
                    });
                }
            }

            let optionChosenOwner = null;
            let choosenOwnerWithoutQueue = null;
            let owner = null;

            if (business.BUS_ID_PERSON_OWNER !== null)
            {
                optionChosenOwner = {
                    id: business.BUS_ID_PERSON_OWNER,
                    name: business.OWNER_NAME,
                    value: business.BUS_ID_PERSON_OWNER
                };
                choosenOwnerWithoutQueue = {
                    id: business.BUS_ID_PERSON_OWNER,
                    name: business.OWNER_NAME,
                    value: business.BUS_ID_PERSON_OWNER
                };
                owner = {
                    id: business.BUS_ID_PERSON_OWNER,
                    name: business.OWNER_NAME,
                    permissionGroup: business.OWNER_PERMISSIONS_GROUP_NAME
                };
            }

            let optionChosenMidiaOrigin = null;

            if (business.PER_ID_MIDIA_ORIGIN)
            {
                optionChosenMidiaOrigin = {
                    name: business.MID_NAME,
                    value: business.PER_ID_MIDIA_ORIGIN
                }
            }

            //can reopen the business?
            if (business.BUS_STATUS === STATUS_BUSINESS_LOSS)
            {
                let dateBusinessLoss = business.BUS_STATUS_DATE.split(" ")[0];
                let dateToday = getDateTimeCurrentBrowserUSA().split(" ")[0];
                canReopen = (dateBusinessLoss === dateToday);
            }

            await this.setState({
                title: business.BUS_TITLE, 
                name: business.PER_NAME, 
                money: formatedMoneyBRA(business.BUS_MONEY_VALUE), 
                column: business.BUS_STEP - 1, 
                id: business.BUS_ID, 
                createdAt,
                creatorName: business.BUS_CREATOR_NAME,
                clientCreatorName: business.CLIENT_CREATOR_NAME,
                numberOfDays,
                wasRecovered: business.BUSINESS_WAS_RECOVERED,
                canReopen,
                status: business.BUS_STATUS,
                stamp: {
                    id: business.STA_ID,
                    name: business.STA_NAME,
                    style: business.STA_STYLE
                },
                midiaOriginName: business.MID_NAME,
                inactiveInDays,
                clientId: business.PER_ID,
                nameClientEditOthersDetails: business.PER_NAME,
                mailClientEditOthersDetails: business.PER_MAIL,
                phoneClientEditOthersDetails: business.PER_PHONE_ONE,
                phoneTwoClientEditOthersDetails: business.PER_PHONE_TWO,
                groupActivities,
                idCompanyUnitClientEditOthersDetails: business.UNT_ID,
                idBrandFromCompanyUnitClientEditOthersDetails: business.UNT_ID_BRAND_COMPANY_UNIT,
                nameCompanyUnitClientEditOthersDetails: business.UNT_NAME,
                nameCampaignClientEditOthersDetails: business.PER_CAMPAIGN_NAME,
                optionChosenCompanyUnit: {
                    id: business.UNT_ID,
                    name: business.UNT_NAME,
                    value: business.UNT_ID
                },
                optionChosenMidiaOrigin,
                idServiceERP: business.BUS_ID_SERVICE_ERP,
                idBusinessRecovered: business.BUS_ID_BUSINESS_RECOVERED,
                owner,
                choosenOwnerWithoutQueue,
                optionChosenOwner
            });

            await this.getBusinessServices(business.PER_ID_COMPANY_UNIT, business.BUS_ID_BUSINESS_SERVICE);

            if (business.CLIENT_STAMP_ID !== null)
            {
                this.setState({
                    optionChosenStampsLead: {
                        id: business.CLIENT_STAMP_ID,
                        name: business.CLIENT_STAMP_NAME,
                        style: business.CLIENT_STAMP_STYLE,
                        value: business.CLIENT_STAMP_ID
                    }
                });
            }

            if (business.BUS_ID_PERSON_PROSPECTION_OWNER !== null)
            {
                const supervisor = await this.getSupervisorName(business.BUS_ID_PERSON_PROSPECTION_OWNER);

                await this.setState({
                    prospectionOwner: {
                        id: business.BUS_ID_PERSON_PROSPECTION_OWNER,
                        name: business.PROSPECTION_OWNER_NAME,
                        supervisor
                    },
                    optionChosenProspectionOwner: {
                        value: business.BUS_ID_PERSON_PROSPECTION_OWNER,
                        name: business.PROSPECTION_OWNER_NAME
                    }
                });
            }

            if (business.BUS_STATUS_DATE !== null)
            {
                let statusDate = new Date(business.BUS_STATUS_DATE);
                statusDate = `${this.zeroPad(statusDate.getDate(), 2)}/${this.zeroPad((statusDate.getMonth() + 1), 2)}/${statusDate.getFullYear()} às ${this.zeroPad(statusDate.getHours(), 2)}:${this.zeroPad(statusDate.getMinutes(), 2)}`;
                await this.setState({ statusDate });
            }
        }   
    }

    getBusinessServices = async (id, idBusinessServiceDefault) =>
    {
        const result = await businessServices.getByIdCompanyUnit(id);
        let optionsBusinessServices = [];
        let optionChosenBusinessServices = null;

        if (result.status)
        {
            for (let service of result.data)
            {
                optionsBusinessServices.push(
                    {
                        id: service.BUN_ID_BUSINESS_SERVICE,
                        name: service.BUP_NAME,
                        value: service.BUN_ID_BUSINESS_SERVICE,
                    }
                );
            }

            // ...setting a serviceBusiness as default
            if (optionsBusinessServices.length > 0)
            {
                optionChosenBusinessServices = {...optionsBusinessServices.filter(s => s.value === idBusinessServiceDefault)[0]};
            }

            await this.setState({optionsBusinessServices, optionChosenBusinessServices});
        }
    }

    setCurrentCompanyUnit = async () =>
    {
        let companies = this.props.user.unit_companies;
        await this.setState({idCurrentCompanyUnit: companies[0].UNT_ID});
    }

    getNameActivity = (type) =>
    {
        switch(type) {
            case PHONE_TYPE_ACTIVITY:
                return "Ligar";
            case VISIT_TYPE_ACTIVITY:
                return "Visita";
            case TASK_TYPE_ACTIVITY:
                return "Tarefa";
            case MAIL_TYPE_ACTIVITY:
                return "E-mail";
            case HISTORIC_TYPE_ACTIVITY:
                return "Informação";
            case TRANSFER_TYPE_ACTIVITY:
                return "Transferência";
            default:
                return type;
        }
    }

    getActivities = async () =>
    {
        const result = await activityController.getByBusiness(this.props.user.PER_ID, this.state.id);

        if (result.status) 
        {
            let activities = [];
            let allActivitiesWithFilesArchived = [];
            let allActivitiesWithFilesNotArchived = [];

            for (const activity of result.data.data)
            {
                activities.push({
                    id: activity.ACT_ID,
                    task: activity.ACT_TITLE,
                    type: activity.ACT_TYPE, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
                    dateStart: formatedDateTimeToBRA(activity.ACT_DATE_START),
                    timeStart: activity.ACT_TIME_START,
                    dateEnds: formatedDateTimeToBRA(activity.ACT_DATE_ENDS),
                    timeEnds: activity.ACT_TIME_ENDS,
                    dateFinished: null,
                    annotations: activity.ACT_ANNOTATIONS,
                    finished: parseInt(activity.ACT_FINISHED) === 2 ? false : true,
                    createdAt: formatedDateTimeToBrowserAtAnyTime(activity.ACT_CREATE_DATE),
                    isShowedup: activity.ACT_IS_SHOWEDUP,
                    business: {
                        id: this.state.id,
                        title: this.state.title,
                        name: this.state.name,
                        owner: this.state.owner
                    },
                    creator: activity.creator,
                    dateStartNoFormat: activity.ACT_DATE_START,
                    files: activity.files
                });
            }
            
            for (const activityWithFile of activities)
            {
                if (activityWithFile.files.length > 0)
                {
                    let files = activityWithFile.files;
                    let isArchived = files.filter(f => f.ACF_STATUS === 2);
                    let notArchived = files.filter(f => f.ACF_STATUS === 1);

                    if (isArchived.length > 0)
                    {
                        allActivitiesWithFilesArchived.push(
                            {
                                ...activityWithFile,
                                files: isArchived
                            }
                        );
                    }

                    if (notArchived.length > 0)
                    {
                        allActivitiesWithFilesNotArchived.push(
                            {
                                ...activityWithFile,
                                files: notArchived
                            }
                        );
                    }
                }                
            }

            await this.setState({allActivities: activities, allActivitiesWithFilesArchived, allActivitiesWithFilesNotArchived});
        }
    }

    getActivitiesBusinessRecovered = async () =>
    {
        const result = await activityController.getByBusiness(this.props.user.PER_ID, this.state.idBusinessRecovered);

        if (result.status) 
        {
            let activities = [];

            for (const activity of result.data.data)
            {
                activities.push({
                    id: activity.ACT_ID,
                    task: activity.ACT_TITLE,
                    type: activity.ACT_TYPE, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
                    dateStart: formatedDateTimeToBRA(activity.ACT_DATE_START),
                    timeStart: activity.ACT_TIME_START,
                    dateEnds: formatedDateTimeToBRA(activity.ACT_DATE_ENDS),
                    timeEnds: activity.ACT_TIME_ENDS,
                    dateFinished: null,
                    annotations: activity.ACT_ANNOTATIONS,
                    finished: parseInt(activity.ACT_FINISHED) === 2 ? false : true,
                    createdAt: formatedDateTimeToBrowserAtAnyTime(activity.ACT_CREATE_DATE),
                    business: {
                        id: this.state.idBusinessRecovered,
                        title: this.state.title,
                        name: this.state.name,
                        owner: this.state.owner
                    },
                    creator: activity.creator,
                    dateStartNoFormat: activity.ACT_DATE_START,
                    files: activity.files
                });
            }

            await this.setState({allActivitiesBusinessRecovered: activities});
            await this.canReopenTheBusiness(activities);
        }
    }

    getProspectionOwnersToTransfer = async() =>
    {
        const 
            [
                result, 
                user
            ] 
            = await Promise.all(
            [
                await prospectionTeamHierarchyController.get(),
                userController.getById(this.props.user.PER_ID)
            ]
        );

        if (!result.status) 
        {
            return;
        }

        const {coordinators} = result.data.data;
        const userId = this.props.user.PER_ID;
        const userUnits = user.data.unit_companies.map(u => u.UNT_ID);
        
        const optionsAttendants = prospectionTeamHierarchyController.getProspectionOwners(coordinators, userId, this.props.permissions.business, userUnits, false);

        await this.setState({
            optionsAttendants
        });
        
    }

    getSupervisorName = async(attendantId) => 
    {
        const result = await prospectionTeamHierarchyController.get();
        if (!result.status) {
            return;
        }

        const {coordinators} = result.data.data;
        let attendant = null;
        for (const coordinator of coordinators)
        {
            for (const supervisor of coordinator.supervisors)
            {
                if (!attendant)
                {
                    attendant = supervisor.attendants.find(a => a.id === attendantId);
                    if (attendant)
                    {
                        return supervisor.name
                    }
                }
            }
        }

        return "Ninguém";
    }

    getOwners = async () => 
    {
        let allCompanyUnitsAllowedUser = await this.state.optionsCompanyUnit;
        let owners = [];
        const result = await userController.get();

        const stateOwners = this.state.owners;

        if (result.status) 
        {
            for (const owner of result.data.data) 
            {
                let isAllowedUnit = owner.unit_companies.some(ua => allCompanyUnitsAllowedUser.map(uu => uu.id).includes(ua.UNT_ID));

                if (owner.PER_STATUS === 1 && isAllowedUnit) 
                {
                    owners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_ID,
                        checked: stateOwners.some(o => parseInt(o.PER_ID) === owner.PER_ID)
                    });
                }
            }
        }  

        this.setState({ optionsOwnersLead: owners });
    }

    getManagerByCompanyUnit = async () =>
    {
        let optionOwnerSpecialistOrManagers = await this.state.optionOwnerSpecialistOrManagers || [];
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
        let objManagerByCompanyUnit = {};

        let responseManager = await userController.getManagerOfCompanyUnit(idCurrentCompanyUnit);

        if (responseManager.status && responseManager.data !== null && responseManager.data !== undefined)
        {
            let manager = responseManager.data;
            manager = {
                id: manager.PER_ID,
                name: manager.PER_NAME,
                value: manager.PER_ID,
                checked: false
            };
            optionOwnerSpecialistOrManagers.push(manager);
            objManagerByCompanyUnit = manager;
    
            await this.setState({optionOwnerSpecialistOrManagers, objManagerByCompanyUnit});
        }

    }

    getFirstOwnerAtQueueByCompanyUnit = async () =>
    {
        let idCompanyUnit = await this.state.idCurrentCompanyUnit;
        let optionOwnerSpecialistOrManagers = [];
        let allSpecialistsNotFiltered = await this.props.queue.allSpecialistsNotFiltered;
        
        // getting just a service queue (of a company unit)
        if (this.props.user.unit_companies.length === 1 && allSpecialistsNotFiltered.length > 0)
        {
            let allSpecialists = [];
    
            allSpecialistsNotFiltered.forEach(u => {
                if (u.idCompanyUnit === idCompanyUnit)
                {
                    u.specialists.forEach(s => {
    
                        if (s.frozen === 2) //just the not-frozen specialists.
                        {
                            allSpecialists.push({
                                id: s.id,
                                name: getFullNameFisrtCharsUppercase(s.name),
                                value: s.id,
                                checked: false
                            });
                        }
                    });
                }
            });

            optionOwnerSpecialistOrManagers.push(allSpecialists[0]);

            await this.setState({
                allSpecialistsNotFiltered,
                optionOwnerSpecialistOrManagers
            });          
        }
    }

    getNameStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "Em andamento";

            case STATUS_BUSINESS_GAIN:
                return "Ganho";

            case STATUS_BUSINESS_LOSS:
                return "Perdido";

            case STATUS_BUSINESS_PAUSED:
                return "Pausado";

            default:
                return "";
        }
    }

    getIconStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "far fa-running";

            case STATUS_BUSINESS_GAIN:
                return "fas fa-trophy-alt";

            case STATUS_BUSINESS_LOSS:
                return "fas fa-do-not-enter";

            case STATUS_BUSINESS_PAUSED:
                return "far fa-pause-circle";

            default:
                return "";
        }
    }

    getMoreClientBusiness = async () =>
    {
        const result = await businessController.getByFilters({
            BUS_ID_PERSON_CLIENT: [this.state.clientId],
            USER_ID: this.props.user.PER_ID
        });

        let rows = [];

        if (result.status)
        {
            for (const row of result.data.data)
            {
                if (row.BUS_ID !== this.state.id)
                {
                    rows.push({
                        id: row.BUS_ID,
                        title: row.BUS_TITLE,
                        money: formatedMoneyBRAFromFloat(row.BUS_MONEY_VALUE),
                        status: row.BUS_STATUS,
                        statusDate: row.BUS_STATUS_DATE,
                        idServiceERP: row.BUS_ID_SERVICE_ERP
                    });
                }
            }
        }

        rows.sort((a, b) => {
            if (a.id > b.id)
            {
                return -1;
            }
            else if (a.id < b.id)
            {
                return 1;
            }
            else
            {
                return 0;
            }
        });

        await this.setState({ moreClientBusiness: rows });
    }

    getMidiaOrigin = async () =>
    {
        let optionsMidiaOrigin = [];
        const result = await midiaOriginController.get();

        if (result.status) {
            for (const midiaOrigin of result.data.data) {
                optionsMidiaOrigin.push({
                    name: midiaOrigin.MID_NAME,
                    value: midiaOrigin.MID_ID,
                    checked: false
                });
            }
        }

        await this.setState({
            optionsMidiaOrigin
        });
    }

    getReasonsForLoss = async () => 
    {
        let idBrandCompanyUnitBusiness = await this.state.idBrandFromCompanyUnitClientEditOthersDetails;
        let group = null;
        let optionsReasonsForLoss = [];

        if (ID_BRAND_USE_GROUP_1_ON_REASONS_FOR_LOSS.includes(idBrandCompanyUnitBusiness))
        { group = 1; }
        else if (idBrandCompanyUnitBusiness === ID_BRAND_USE_GROUP_2_ON_REASONS_FOR_LOSS)
        { group = 2; }
        else if (idBrandCompanyUnitBusiness === ID_BRAND_USE_GROUP_3_ON_REASONS_FOR_LOSS)
        { group = 3; }

        if (group)
        {
            let result = await businessReasonsForLossController.get(group);

            if (result.status) 
            {    
                for (let reason of result.data.data) 
                {
                    if (reason.BUL_STATUS === 1)
                    {
                        optionsReasonsForLoss.push(
                            {
                                name: reason.BUL_TITLE,
                                value: reason.BUL_ID,
                                checked: false
                            }
                        );
                    }
                }
            }
        }

        await this.setState({optionsReasonsForLoss});
    }

    getStamps = async () => 
    {
        const result = await stampController.get();
        if (result.status) {
            let stamps = [];
            for (const stamp of result.data.data) {
                stamps.push({
                    id: stamp.STA_ID,
                    name: stamp.STA_NAME,
                    value: stamp.STA_ID,
                    style: stamp.STA_STYLE
                });

            }
            this.setState({ allStampsBusiness: stamps, optionsStampsLead: stamps });
        }  
    }

    getCompanyUnit = async () => 
    {
        const unitCompanies = await userController.getById(this.props.user.PER_ID);
        let options = [];
        for (const company of unitCompanies.data.unit_companies) {
            options.push({
                id: company.UNT_ID,
                name: company.UNT_NAME,
                value: company.UNT_ID,
            });
        }

        await this.setState({ optionsCompanyUnit: options });
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    openModalConfirmation = async () =>
    {
        let message = "";
        let showChildren = true;
        let status = "";
        let typeMessage = "";
        
        switch(this.state.statusToUpdate) {
            case STATUS_BUSINESS_GAIN:
                this.getServicesERP();
                status = "GANHO";
                typeMessage = "success";
                message = `Qual é o serviço no ERP que está colocando como ${status}?`;
                break;
            case STATUS_BUSINESS_LOSS:
                status = "PERDIDO";
                typeMessage = "warning";
                message = `Qual o motivo para alterar o status para ${status}?`;
                break;
            case STATUS_BUSINESS_PAUSED:
                showChildren = false;
                status = "PAUSADO";
                typeMessage = "information";
                message = `Confirma alterar o status do negócio para ${status}?`;
                break;
            case "REOPEN":
                showChildren = false;
                typeMessage = "information";
                message = `Confirma reabrir o negócio?`;
                break;
            default:
                showChildren = false;
                status = "EM ANDAMENTO";
                typeMessage = "warning";
                message = `Confirma alterar o status do negócio para ${status}?`;
                break;
        };

        this.setState({
            message_confirmation: message,
            message_show_confirmation: true,
            message_type_confirmation: typeMessage,
            show_children_confirmation: showChildren,
            nameFunctionYesModalConfirmation: "setStatus",
            statusConfirmation: false
        });
    }

    resetModalConfirmation = async () =>
    {
        this.setState({
            message_type_confirmation: "warning",
            message_show_confirmation: false,
            message_confirmation: "",
        });
    }

    updateClient = async () =>
    {
        this.setState({showEditPersonOthersDetailsBusiness: false});

        let email = this.state.mailClientEditOthersDetails;

        if (this.state.nameClientEditOthersDetails.trim() === "")
        {
            return this.message("error", "Informe um nome!");
        }

        if (formatedOnlyNumber(this.state.phoneClientEditOthersDetails.trim()) === "")
        {
            return this.message("error", "Informe um telefone!");
        }

        if (email === "")
        {
            email = null;
        }

        if (email !== null && !isEmail(email))
        {
            return this.message("error", "Informe um e-mail válido!");
        }

        if (this.state.optionChosenStampsLead === null)
        {
            return this.message("error", "Informe uma etiqueta!");
        }

        if (this.state.optionChosenCompanyUnit === null)
        {
            return this.message("error", "Informe uma unidade!");
        }

        const phoneTwo = this.state.phoneTwoClientEditOthersDetails ? formatedOnlyNumber(this.state.phoneTwoClientEditOthersDetails) : "";

        const result = await clientController.update(this.state.clientId, {
            PER_NAME: this.state.nameClientEditOthersDetails,
            PER_MAIL: email,
            PER_PHONE_ONE: formatedOnlyNumber(this.state.phoneClientEditOthersDetails),
            PER_PHONE_TWO: phoneTwo,
            PER_STAMP: this.state.optionChosenStampsLead.value,
            PER_ID_COMPANY_UNIT: this.state.optionChosenCompanyUnit.value,
            PER_ID_MIDIA_ORIGIN: this.state.optionChosenMidiaOrigin?.value || null,
            PER_CAMPAIGN_NAME: this.state.nameCampaignClientEditOthersDetails
        });

        if (!result.status)
        {
            this.message("error", result.message);
        }
        else
        { 
            this.message("success", "Dados do cliente atualizados com sucesso!");
        }

    }

    updateMoney = async () =>
    {
        this.setState({showModalEditMoney: false})
        
        if (this.state.money.toString().trim() === "") 
        {
            return this.message("error", "Informe um valor!");
        }
        
        await businessController.update(this.state.id, {
            BUS_MONEY_VALUE: this.state.money.replace(".", "").replace(",", ".")
        });
    }

    updateBusinessService = async () =>
    {
        await this.setState({showModalEditBusinessService: false});
        let idChosenBusinessService = await this.state.optionChosenBusinessServices.value;
        let idBusiness = await this.state.id;
        
        await businessController.update(idBusiness, {BUS_ID_BUSINESS_SERVICE: idChosenBusinessService});
    }

    updateTitle = async () =>
    {
        this.setState({showModalEditTitle: false})
        
        if (this.state.title.trim() === "") 
        {
            return this.message("error", "Informe um título!");
        }
        
        await businessController.update(this.state.id, {
            BUS_TITLE: this.state.title
        });
    }

    updateStep = async (column) =>
    {
        this.setState({ column });
        await businessController.update(this.state.id, {
            BUS_STEP: column + 1
        });
        
        const annotations = `● Movido para etapa ${this.state.optionsStepFunnelMoreFilters[column].name}.`;

        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.getDetailsBusiness();
        await this.getActivities();
    }

    updateProspectionOwner = async () =>
    {
        const result = await businessController.update(this.state.id, {
            BUS_ID_PERSON_PROSPECTION_OWNER: this.state.optionChosenProspectionOwner.value
        });

        if (!result.status) 
        {
            return this.message("error", "Não foi possível atualizar o atendente!");
        }

        let annotations = `● Adicionou ${this.state.optionChosenProspectionOwner.name} como atendente.`;
        if (this.state.prospectionOwner) {
            annotations = `● Atendimento transferido de ${this.state.prospectionOwner.name} para ${this.state.optionChosenProspectionOwner.name}`;
        }

        await activityController.create({
            ACT_TITLE: "TRANSFERÊNCIA",
            ACT_TYPE: TRANSFER_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        this.getDetailsBusiness();
        this.getActivities();
        this.message("success", "Atendente atualizado com sucesso!");
    }

    saveOwner = async () =>
    {
        const resultBusiness = await businessController.getPendingBusinessFromUnits(this.props.user.PER_ID);

        if(resultBusiness.data.number_of_lines > 0)
        {
            const idsBusiness = resultBusiness.data.data.map(b => b.BUS_ID).join(",");
            return this.message("error", `Existem Negócios pendentes para dar ganho ou perdido em sua unidade! (${idsBusiness})`);
        }

        const result = await businessController.update(this.state.id, {
            BUS_ID_PERSON_OWNER: this.state.optionChosenOwner.value
        });

        let objManagerByCompanyUnit = await this.state.objManagerByCompanyUnit;

        if (result.status && objManagerByCompanyUnit.id !== this.state.optionChosenOwner.value)
        {
            this.props.setLastPositionSpecialistServiceQueue(this.state.optionChosenOwner.value);
        }
        
        const annotations = `● Adicionou ${this.state.optionChosenOwner.name} como proprietário.`;

        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.getDetailsBusiness();
        await this.getActivities();

        const activityToUpdate = this.state.allActivities.find(a => a.type === VISIT_TYPE_ACTIVITY);
        if (activityToUpdate)
        {
            if (activityToUpdate.isShowedup !== 1)
            {
                await activityController.update(activityToUpdate.id, {
                    ACT_IS_SHOWEDUP: 1
                });

                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: "● CLIENTE COMPARECEU.",
                    ACT_ID_BUSINESS: this.state.id,
                    ACT_ID_PERSON_CLIENT: this.state.clientId,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        this.message("success", "Proprietário atualizado com sucesso!");

        await this.getDetailsBusiness();
        await this.getActivities();
    }

    zeroPad = (num, places) => String(num).padStart(places, '0')

    setStatus = async () =>
    {

        if (this.state.statusToUpdate === STATUS_BUSINESS_GAIN && this.state.choosenServiceERP.value === 0)
        {
            return this.message("error", "Informe um serviço!");
        }

        if (this.state.statusToUpdate === STATUS_BUSINESS_LOSS && this.state.choosenReasonForLoss.value === 0)
        {
            return this.message("error", "Informe um motivo!");
        }

        let word = "";
        const date = new Date();
        const datetime = `${date.getFullYear()}-${this.zeroPad((date.getMonth() + 1), 2)}-${this.zeroPad(date.getDate(), 2)} ${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}:${this.zeroPad(date.getSeconds(), 2)}.000`;

        switch(this.state.statusToUpdate) {
            case STATUS_BUSINESS_GAIN:
                const service = this.state.optionsServicesERP.find(s => s.value === this.state.choosenServiceERP.value)

                word = "GANHO";
                await businessController.update(this.state.id, {
                    BUS_TITLE: service.title,
                    BUS_MONEY_VALUE: service.money,
                    BUS_STATUS: this.state.statusToUpdate,
                    BUS_ID_SERVICE_ERP: this.state.choosenServiceERP.value,
                    BUS_STATUS_DATE: service.createdAt,
                    BUS_ID_STAMP: ID_STAMP_IS_ALREADY_CLIENT
                });

                await clientController.update(this.state.clientId, {
                    PER_STAMP: ID_STAMP_IS_ALREADY_CLIENT
                })
                break;
            case STATUS_BUSINESS_LOSS:
                word = "PERDIDO";
                await businessController.update(this.state.id, {
                    BUS_STATUS: this.state.statusToUpdate,
                    BUS_ID_REASON_FOR_LOSS: this.state.choosenReasonForLoss.value,
                    BUS_STATUS_DATE: datetime
                });
                break;
            case STATUS_BUSINESS_PAUSED:
                word = "PAUSADO";
                await businessController.update(this.state.id, {
                    BUS_STATUS: this.state.statusToUpdate,
                    BUS_STATUS_DATE: datetime
                });
                break;
            case "REOPEN":
                word = "REABERTO";
                await businessController.update(this.state.id, {
                    BUS_STATUS: null,
                    BUS_ID_REASON_FOR_LOSS: null,
                    BUS_ID_SERVICE_ERP: null,
                    BUS_STATUS_DATE: null
                });
                break;
            default:
                word = "EM ANDAMENTO";
                await businessController.update(this.state.id, {
                    BUS_STATUS: null,
                    BUS_STATUS_DATE: null
                });
                break;
        };

        let annotations = `● Alterou status do negócio para ${word}.`;
        if (this.state.statusToUpdate === STATUS_BUSINESS_LOSS)
        {
            annotations += ` Motivo: ${this.state.choosenReasonForLoss.name}.`;
        }
    
        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        if ([STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS, STATUS_BUSINESS_PAUSED].includes(this.state.statusToUpdate))
        {
            await this.getDetailsBusiness();
            await this.getActivities();

            const activitiesToUpdate = this.state.allActivities.filter(a => a.finished === false);
            for (const activity of activitiesToUpdate)
            {
                await activityController.update(activity.id, {
                    ACT_FINISHED: 1
                });
            }
        }

        if ([STATUS_BUSINESS_GAIN].includes(this.state.statusToUpdate))
        {
            const activitiesToUpdateShowedup = this.state.allActivities.filter(a => a.type === VISIT_TYPE_ACTIVITY);

            for (const activity of activitiesToUpdateShowedup)
            {
                await activityController.update(activity.id, {
                    ACT_IS_SHOWEDUP: 3
                });

                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: "● CLIENTE COMPARECEU E FECHOU.",
                    ACT_ID_BUSINESS: this.state.id,
                    ACT_ID_PERSON_CLIENT: this.state.clientId,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        if ([STATUS_BUSINESS_LOSS].includes(this.state.statusToUpdate))
        {
            const activitiesToUpdateShowedup = this.state.allActivities.filter(a => a.type === VISIT_TYPE_ACTIVITY && a.isShowedup === null);

            for (const activity of activitiesToUpdateShowedup)
            {
                await activityController.update(activity.id, {
                    ACT_IS_SHOWEDUP: this.state?.owner ? 1 : 2
                });

                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: this.state?.owner ? "● CLIENTE COMPARECEU." : "● CLIENTE NÃO COMPARECEU.",
                    ACT_ID_BUSINESS: this.state.id,
                    ACT_ID_PERSON_CLIENT: this.state.clientId,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        // INFORMATION: Forcing the last VISIT activity by business for showedup equal to 3.
        // if ([STATUS_BUSINESS_GAIN].includes(this.state.statusToUpdate))
        // {
        //     let allVisits = await this.state.allActivities.filter(a => a.type === VISIT_TYPE_ACTIVITY);
        //     // INFOMATION: Making inverse sort for to get the last visit.
        //     allVisits = allVisits.sort((a, b) => {
        //         if (a.id > b.id) { return -1; }
        //         if (a.id < b.id) { return 1; }
        //         return 0;
        //     });

        //     let lastVisit = allVisits[0];
        //     await activityController.update(lastVisit.id, {ACT_IS_SHOWEDUP: 3});
        // }

        this.message("success", `Status do negócio alterado para ${word}!`);
        this.resetModalConfirmation();
        await this.getDetailsBusiness();
        await this.getActivities();
    }

    setGainWithoutModal = async () =>
    {
        const date = new Date();
        const datetime = `${date.getFullYear()}-${this.zeroPad((date.getMonth() + 1), 2)}-${this.zeroPad(date.getDate(), 2)} ${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}:${this.zeroPad(date.getSeconds(), 2)}.000`;

        await businessController.update(this.state.id, {
            BUS_STATUS: STATUS_BUSINESS_GAIN,
            BUS_STATUS_DATE: datetime,
            BUS_ID_STAMP: ID_STAMP_IS_ALREADY_CLIENT
        });

        await clientController.update(this.state.clientId, {
            PER_STAMP: ID_STAMP_IS_ALREADY_CLIENT
        })

        const annotations = `● Alterou status do negócio para GANHO.`;
        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.getDetailsBusiness();
        await this.getActivities();

        const activitiesToUpdate = this.state.allActivities.filter(a => a.finished === false);
        for (const activity of activitiesToUpdate)
        {
            await activityController.update(activity.id, {
                ACT_FINISHED: 1
            });
        }

        const activitiesToUpdateShowedup = this.state.allActivities.filter(a => a.type === VISIT_TYPE_ACTIVITY && a.isShowedup === null);
        for (const activity of activitiesToUpdateShowedup)
        {
            await activityController.update(activity.id, {
                ACT_IS_SHOWEDUP: this.state?.owner ? 1 : 2
            });

            await activityController.create({
                ACT_TITLE: "INFORMAÇÃO",
                ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                ACT_ANNOTATIONS: this.state?.owner ? "● CLIENTE COMPARECEU." : "● CLIENTE NÃO COMPARECEU.",
                ACT_ID_BUSINESS: this.state.id,
                ACT_ID_PERSON_CLIENT: this.state.clientId,
                ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                ACT_FINISHED: 1,
                hasFile: false,
                file: null
            });
        }

        this.message("success", `Status do negócio alterado para GANHO!`);
        await this.getDetailsBusiness();
        await this.getActivities();
    }

    verifyProspectionOwner = async () =>
    {
        const prospectionOwner = this.state.prospectionOwner;
        if (prospectionOwner === null)
        {
            return;
        }

        const resultProspectionOwner = await userController.getById(prospectionOwner.id);
        if (!resultProspectionOwner.status)
        {
            return;
        }

        const status = resultProspectionOwner.data.PER_STATUS;
        if (parseInt(status) === 2)
        {
            await this.setState({
                optionChosenProspectionOwner: {
                    value: this.props.user.PER_ID,
                    name: this.props.user.PER_NAME
                }
            });
            await this.updateProspectionOwner();
        }

    }

    onCheckActivity = async (id, type, finished) =>
    {
        let status = await this.state.status;

        // WARNING: Don't change showedup when business is equal o GAIN
        if (status === STATUS_BUSINESS_GAIN && type === VISIT_TYPE_ACTIVITY) { return; }

        if (!this.props.permissions.activities.toUpdate)
        { return this.message("error", "Você não tem permissão para editar a atividade!"); }

        if ([HISTORIC_TYPE_ACTIVITY, TRANSFER_TYPE_ACTIVITY].includes(type))
        { return this.message("error", "As atividades do tipo HISTÓRICO e TRANSFERÊNCIA não podem ser reabertas!"); }

        if (type === VISIT_TYPE_ACTIVITY && finished === 1)
        {
            await this.setState({
                message_show_confirmation_showedup: true,
                data_to_update_showedup: {
                    id,
                    finished
                }
            });
        }
        else
        {
            if (type === VISIT_TYPE_ACTIVITY && finished === 2)
            {
                const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, this.state.id);
                const activities = resultActivities.data.data;
                if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_ID > id)) {
                    return this.message("error", "Esta visita não pode ser reaberta, existe uma visita mais recente!");
                }

                const activity = this.state.allActivities.find(a => a.id === id);
                let typeModal = "REOPEN";
                const today = getDateTimeCurrentBrowserAtMidnight();
                const activityDate = new Date(`${activity.dateStart.split("/").reverse().join("-")} 00:00`);

                if (activityDate < today)
                {
                    if (activity.isShowedup === 2)
                    {
                        return this.message("error", "Esta visita está no passado e não pode ser reaberta!");
                    }
                    typeModal = "CHANGE";
                }

                return await this.setState({
                    message_show_confirmation_undo_showedup: true,
                    type_undo_showedup: typeModal,
                    data_to_undo_showedup: {
                        id,
                        finished
                    }
                });
            }

            await activityController.update(id, {
                ACT_FINISHED: finished,
                ACT_IS_SHOWEDUP: null
            });

            this.getActivities();
        }
    }

    onToArchivedFile = async (idFile, currentStatus) =>
    {
        let finalStatus = (currentStatus === 1) ? 2 : 1;
        let response = await activityController.setArchivedFile(idFile, finalStatus);

        if (response.status)
        {
            await this.setState({allActivitiesWithFilesArchived: [], allActivities: [], allActivitiesWithFilesNotArchived: []});
            await this.getActivities();
        }
    }

    updateActivityShowedup = async (showedup) =>
    {
        await this.getActivities();
        const activityToUpdate = this.state.allActivities.find(a => a.id === this.state.data_to_update_showedup.id);

        await activityController.update(this.state.data_to_update_showedup.id, {
            ACT_FINISHED: this.state.data_to_update_showedup.finished,
            ACT_IS_SHOWEDUP: showedup
        });

        if (activityToUpdate)
        {
            if (activityToUpdate.isShowedup !== showedup)
            {
                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: showedup === 2 ? "● CLIENTE NÃO COMPARECEU." : "● CLIENTE COMPARECEU.",
                    ACT_ID_BUSINESS: this.state.id,
                    ACT_ID_PERSON_CLIENT: this.state.clientId,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        await this.setState({
            message_show_confirmation_showedup: false,
            data_to_update_showedup: null
        });

        await this.getDetailsBusiness();
        await this.getActivities();
    }

    undoShowedup = async () =>
    {
        await activityController.update(this.state.data_to_undo_showedup.id, {
            ACT_IS_SHOWEDUP: this.state.type_undo_showedup === "REOPEN" ? null : 2,
            ACT_FINISHED: this.state.type_undo_showedup === "REOPEN" ? 2 : 1
        });

        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: this.state.type_undo_showedup === "REOPEN" ? `● VISITA REABERTA.` : `● CLIENTE NÃO COMPARECEU.`,
            ACT_ID_BUSINESS: this.state.id,
            ACT_ID_PERSON_CLIENT: this.state.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.setState({
            message_show_confirmation_undo_showedup: false,
            data_to_undo_showedup: null,
            type_undo_showedup: "REOPEN"
        });

        await this.getDetailsBusiness();
        await this.getActivities();
    }

    plusBusinessForThisClient = async () =>
    {
        let status = await this.state.status;
        let owner = await this.state.owner;
        let idClient = await this.state.clientId;

        if (status === STATUS_BUSINESS_LOSS && owner === null)
        {
            this.message("information", "Peça ao supervisor para reabrir esse negócio!");
            return;
        }

        let response = await businessController.getByClient(idClient);

        if (response.status)
        {
            let data = response.data;
            let amountBusinessGain = data.filter(b => b.BUS_STATUS === STATUS_BUSINESS_GAIN).length || 0;
            let amountBusinessLoss = data.filter(b => b.BUS_STATUS === STATUS_BUSINESS_LOSS).length || 0;
            let amountBusinessInProgress = data.filter(b => b.BUS_STATUS === null).length || 0;
            let amountBusinessTotal = (amountBusinessGain + amountBusinessLoss + amountBusinessInProgress);

            if (amountBusinessTotal >= 2)
            {
                let message = (
                    <div>
                        Esse cliente já tem:<br/>
                        {amountBusinessGain !== 0 && <span>{amountBusinessGain} negócio(s) ganho(s)<br/></span>}
                        {amountBusinessLoss !== 0 && <span>{amountBusinessLoss} negócio(s) perdido(s)<br/></span>}
                        {amountBusinessInProgress !== 0 && <span>{amountBusinessInProgress} negócio(s) em andamento<br/></span>}
                        Deseja realmente criar mais um negócio?
                    </div>
                );

                await this.setState(
                    {
                        message_show_confirmation_more_business: true,
                        message_confirmation: message,
                    }
                );
            }
            else
            {
                await this.openMoreBusinessForThisClient();
            }
        }        
    }

    openMoreBusinessForThisClient = async () =>
    {
        let owner = await this.state.owner;
        let name = await this.state.name;
        let idClient = await this.state.clientId;
        let idBusiness = await this.state.id;
        let idCompanyUnitByClient = await this.state.idCompanyUnitClientEditOthersDetails;

        await this.setState(
            {
                message_show_confirmation_more_business: false,
                message_confirmation: "",
            }
        );

        let data = {
            show: true,
            type: businessByClient,
            data: {
                id: idClient,
                idBusiness,
                idCompanyUnitByClient,
                name,
                owner,
                isBusinessRecovered: false
            }
        };

        this.props.showModalMoreActions(data);
    }

    render ()
    {
        return (
            <div className="detailsBusiness">
                <Header title="Negócios" classaditional="headerNavigationDetailsBusiness"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <MessageConfirmation
                    message_type="information"
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation_more_business: false})}
                    show={this.state.message_show_confirmation_more_business}
                    onClickYes={() => this.openMoreBusinessForThisClient()}
                    onClickNo={() => this.setState({message_show_confirmation_more_business: false, message_confirmation: ""})}
                />
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    onClickYes={() => this.setStatus()}
                    show={this.state.message_show_confirmation}
                    showButtonYes={this.state.statusToUpdate === STATUS_BUSINESS_GAIN ? this.props.permissions.gainStatus.toUpdate : true}
                >
                    {
                        this.state.show_children_confirmation &&
                        <div className="row no-gutters">
                            <div className="col-12 px-4 mb-2">
                                { 
                                    this.state.statusToUpdate === STATUS_BUSINESS_GAIN &&
                                    <Select
                                        classaditional="servicesConfirmationDetailsBusiness"
                                        default={this.state.choosenServiceERP}
                                        options={this.state.optionsServicesERP} 
                                        onChange={(choosenServiceERP) => this.setState({choosenServiceERP})}
                                    /> 
                                }
                                { 
                                    this.state.statusToUpdate === STATUS_BUSINESS_LOSS &&
                                    <Select
                                        classaditional="servicesConfirmationDetailsBusiness"
                                        default={this.state.choosenReasonForLoss}
                                        options={this.state.optionsReasonsForLoss} 
                                        onChange={(choosenReasonForLoss) => this.setState({choosenReasonForLoss})}
                                    /> 
                                }
                            </div>
                        </div>
                    }
                </MessageConfirmation>
                <MessageConfirmation
                    message_type="information"
                    message="Este cliente compareceu?"
                    onClose={() => this.setState({message_show_confirmation_showedup: false, data_to_update_showedup: null})}
                    onClickYes={() => this.updateActivityShowedup(1)}
                    onClickNo={() => this.updateActivityShowedup(2)}
                    show={this.state.message_show_confirmation_showedup}
                >
                </MessageConfirmation>
                <MessageConfirmation
                    message_type="information"
                    message=""
                    onClose={() => this.setState({message_show_confirmation_undo_showedup: false, data_to_undo_showedup: null, type_undo_showedup: "CHANGE"})}
                    onClickYes={() => this.undoShowedup()}
                    onClickNo={() => this.setState({message_show_confirmation_undo_showedup: false, data_to_undo_showedup: null, type_undo_showedup: "CHANGE"})}
                    show={this.state.message_show_confirmation_undo_showedup}
                >
                    {
                        this.state.type_undo_showedup === "REOPEN" ?
                            <p>Deseja reabrir visita?</p>
                        :
                            <Fragment>
                                <p>Esta VISITA está no passado e não pode ser reaberta!</p>
                                <p>Esta VISITA está marcada como "COMPARECEU". Deseja alterar para "NÃO COMPARECEU"?</p>
                            </Fragment>
                    }
                </MessageConfirmation>
                <MessageConfirmation
                    message="Transferir propriedade?"
                    onClose={() => this.setState({showModalEditOwnerWithoutQueue: false})}
                    onClickYes={() => {
                        this.saveOwnerWithoutQueue();
                    }}
                    onClickNo={() => this.setState({showModalEditOwnerWithoutQueue: false})}
                    show={this.state.showModalEditOwnerWithoutQueue}
                    showButtonYes={((!this.state.owner && this.props.permissions.leadOwnersModal.toInsert) || (this.state.owner && this.props.permissions.leadOwnersModal.toUpdate)) ? true : false}
                >
                    <div className="mt-3 mb-4">
                        <SelectMulti
                            classaditional="servicesConfirmationDetailsBusiness"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsCompanyUnitsWithoutQueue, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsCompanyUnitsWithoutQueue} 
                            onChange={async (optionsCompanyUnitsWithoutQueue) => {
                                await this.setState({optionsCompanyUnitsWithoutQueue, choosenOwnerWithoutQueue: null});
                                await this.getOptionsOwnersWithoutQueueFiltered();
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className="mt-3 mb-4"> 
                        <Select
                            classaditional="servicesConfirmationDetailsBusiness"
                            default={this.state.choosenOwnerWithoutQueue}
                            options={this.state.optionsOwnersWithoutQueueFiltered} 
                            onChange={(choosenOwnerWithoutQueue) => this.setState({choosenOwnerWithoutQueue})}
                            withFieldSearch
                        />
                    </div>
                </MessageConfirmation>
                <div className="scrollDetailsBusiness mb-4">
                    <div className="warningDetailsBusiness d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="headerDetailsBusiness">
                        <div className="row no-gutters lineHeaderInfoDetailsBusiness justify-content-center">
                            <div className="col-12 col-lg-7 columnHeaderInfoDetailsBusiness">
                                <div className="lineTopMoreInfoDetailsBusiness">
                                    <h1
                                        className="titleHeaderInfoDetailsBusiness"
                                        onClick={() => this.setState({showModalEditTitle: true})}
                                    >
                                        {this.state.title}
                                    </h1>
                                    {
                                        this.state.showModalEditTitle
                                        ?   <Fragment>
                                                <ModalEdit
                                                    classaditional="modalEditTitleDetailsBusiness"
                                                    title="Renomear esse negócio:"
                                                    value={this.state.title}
                                                    onChange={value => {
                                                        this.changeValue(value, this.state.id, "title")
                                                        this.setState({ title: value });
                                                    }}
                                                    onPositiveClick={() => this.updateTitle()}
                                                    onNegativeClick={() => {
                                                        this.setState({showModalEditTitle: false})
                                                    }}
                                                    permissions={this.props.permissions.business}
                                                />
                                            </Fragment>
                                        : null
                                    }
                                    <div className="columnEditStampHeaderDetailsBusiness d-none d-md-flex">
                                        {
                                            this.state.stamp.style !== "none" &&
                                            <span
                                                className={`nameStampHeaderDetailsBusiness ${this.state.stamp.style}`}
                                                style={{
                                                    backgroundColor: `var(--color-${this.state.stamp.style})`
                                                }}
                                            >
                                                {this.state.stamp.name}
                                            </span>
                                        }
                                        {
                                            (!this.state.showModalEditStamp && this.state.stamp.style === "none") &&
                                            <span className="notificationStampDetailsBusiness">sem etiqueta</span>
                                        }
                                        <ButtonIcon
                                            icon={`${(this.state.stamp.style !== "none") ? "fas fa-sort-down" : "fas fa-tag"}`}
                                            classaditional="buttonStampHeaderDetailsBusiness"
                                            onClick={() => this.setState({showModalEditStamp: !this.state.showModalEditStamp})}
                                        />
                                        {
                                            this.state.showModalEditStamp
                                            ?   <Fragment>
                                                    <ModalEditStamp
                                                        classaditional="modalEditStampDetailsBusiness"
                                                        allStampsBusiness={this.state.allStampsBusiness}
                                                        onChangeAllStampsBusiness={(allStampsBusiness) => this.setState({allStampsBusiness})}
                                                        onChange={(stamp) => this.changeStampBusiness(stamp)}
                                                        stampChecked={this.state.stamp}
                                                        permissions={this.props.permissions.business}
                                                        user={this.props.user}
                                                        onUpdateStamps={this.getStamps}
                                                    />
                                                </Fragment>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 columnHeaderInfoDetailsBusiness d-flex justify-content-center justify-content-lg-end m-2 m-lg-0">
                                {
                                this.props.permissions.leadOwners.toView &&
                                <div className="editOwnersHeaderInfoDetailsBusiness d-none d-md-flex">
                                    <div className="avatarOwnerHeaderDetailsBusiness">
                                        <img className="img-fluid" src={gravatar} alt="" />
                                    </div>
                                    <div className="nameOwnerHeaderDetailsBusiness">
                                        <Anchor
                                            classaditional="anchorNameOwnerHeaderDetailsBusiness"
                                            label={this.state.owner?.name}
                                            onClick={() => this.setState({showModalEditOwner: true})}
                                        >
                                        </Anchor>
                                        <span className="levelNameOwnerHeaderDetailsBusiness">Proprietário</span>
                                        {
                                            this.state.showModalEditOwner &&
                                            <ModalEdit
                                                classaditional="modalEditOwnerDetailsBusiness"
                                                title="Transferir propriedade"
                                                onPositiveClick={() => {
                                                    this.saveOwner();
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                onNegativeClick={() => {
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                optionsSelectMulti
                                                permissions={(this.state.status === STATUS_BUSINESS_GAIN || this.state.status === STATUS_BUSINESS_LOSS) ? defaultPermissions : this.state.ownerPermissions}
                                            >
                                                <Select
                                                    default={this.state.optionChosenOwner}
                                                    options={this.state.optionOwnerSpecialistOrManagers} 
                                                    onChange={(optionChosenOwner) => this.setState({optionChosenOwner})}
                                                    withFieldSearch
                                                />
                                            </ModalEdit>
                                        }
                                    </div>
                                    {
                                        (!this.state.showModalEditOwner) &&
                                        <span className="notificationOwnerDetailsBusiness">transferir propriedade</span>
                                    }
                                    <ButtonIcon
                                        icon="fas fa-sort-down"
                                        classaditional="buttonEditOwnerHeaderDetailsBusiness"
                                        onClick={() => {
                                            if (!this.props.permissions.leadOwnersModal.toView)
                                            {
                                                this.setState({showModalEditOwner: !this.state.showModalEditOwner});
                                            }
                                            else
                                            {
                                                this.getCompanyUnitsAndOwnersWithoutQueue();
                                                this.setState({showModalEditOwnerWithoutQueue: !this.state.showModalEditOwnerWithoutQueue});
                                            }
                                        }}
                                    />
                                </div>
                                }
                                {
                                    (this.state.status !== STATUS_BUSINESS_GAIN && this.state.status !== STATUS_BUSINESS_LOSS) &&
                                        <Fragment>
                                            {
                                                this.props.permissions.gainStatus.toView &&
                                                <Button
                                                    classaditional="buttonStatusHeaderInfoDetailsBusiness positive"
                                                    name="Ganho"
                                                    onClick={async () => {
                                                        await this.getOwners();
                                                        const owner = this.state.owner;
                                                        if (owner === null && !this.props.permissions.gainStatus.toInsert)
                                                        {
                                                            return this.message("error", "Você não tem permissão para dar ganho neste negócio!");
                                                        }

                                                        if (owner?.id !== this.props.user.PER_ID  && !this.props.permissions.gainStatus.toInsert)
                                                        {
                                                            return this.message("error", "Você não tem permissão para dar ganho neste negócio!");
                                                        }
                                                        
                                                        
                                                        if (this.props.permissions.gainStatus.toInsert)
                                                        {
                                                            this.setGainWithoutModal();
                                                        }
                                                        else
                                                        {
                                                            await this.setState({ statusToUpdate: STATUS_BUSINESS_GAIN });
                                                            this.openModalConfirmation();
                                                        }
                                                        
                                                    }}
                                                />
                                            }
                                            {
                                                this.props.permissions.lossStatus.toView &&
                                                    <Button
                                                        classaditional="buttonStatusHeaderInfoDetailsBusiness negative"
                                                        name="Perdido"
                                                        onClick={async () => {
                                                            if (this.props.permissions.lossStatus.toUpdate)
                                                            {
                                                                await this.setState({ statusToUpdate: STATUS_BUSINESS_LOSS });
                                                                this.openModalConfirmation()
                                                            }
                                                            else
                                                            {
                                                                this.message("error", "Você não tem permissão para alterar o status do negócio para PERDIDO!");
                                                            }
                                                        }}
                                                    />
                                            }
                                            {
                                                this.props.permissions.pausedStatus.toView &&
                                                    <Button
                                                        classaditional={ `buttonStatusHeaderInfoDetailsBusiness ${this.state.status !== STATUS_BUSINESS_PAUSED ? "primary" : "warning"}` }
                                                        name={ this.state.status !== STATUS_BUSINESS_PAUSED ? "Pausar" : "Pausado" }
                                                        onClick={async () => {
                                                            if (this.props.permissions.pausedStatus.toUpdate)
                                                            {
                                                                const statusToUpdate = this.state.status !== STATUS_BUSINESS_PAUSED ? STATUS_BUSINESS_PAUSED : ""
                                                                await this.setState({ statusToUpdate });
                                                                this.openModalConfirmation()
                                                            }
                                                            else
                                                            {
                                                                this.message("error", "Você não tem permissão para alterar o status do negócio para PAUSADO!");
                                                            }
                                                        }}
                                                    />
                                            }
                                        </Fragment>
                                }
                                {
                                    (this.state.status === STATUS_BUSINESS_GAIN || this.state.status === STATUS_BUSINESS_LOSS) &&
                                        <Fragment>
                                            <span className={`businessStatus ${this.state.status === STATUS_BUSINESS_GAIN ? "success" : "danger"}`}>
                                                { this.state.status === STATUS_BUSINESS_GAIN ? "GANHO" : "PERDIDO" }
                                            </span>
                                            {
                                                (
                                                    (
                                                        this.props.permissions.reopenStatus.toView && 
                                                        !this.state.wasRecovered && 
                                                        (this.state.canReopen || this.state.owner === null)
                                                    )
                                                    ||
                                                    (
                                                        this.props.user.PER_ID === 9
                                                    )
                                                )
                                                &&
                                                <Button
                                                    classaditional="buttonStatusHeaderInfoDetailsBusiness default"
                                                    name="Reabrir"
                                                    onClick={async () => {
                                                        if (this.props.permissions.reopenStatus.toUpdate)
                                                        {
                                                            const statusToUpdate = "REOPEN";
                                                            await this.setState({ statusToUpdate });
                                                            this.openModalConfirmation()
                                                        }
                                                        else
                                                        {
                                                            this.message("error", "Você não tem permissão para reabrir o negócio!");
                                                        }
                                                    }}
                                                />
                                            }
                                        </Fragment>
                                }
                                {/* <ButtonIcon
                                    classaditional="buttonMoreActionsHeaderInfoDetailsBusiness"
                                    icon="far fa-ellipsis-h"
                                    onClick={() => this.setState({showModalMoreActions: !this.state.showModalMoreActions})}
                                />
                                {
                                    this.state.showModalMoreActions &&
                                    <div className="modalMoreActionsDetailsBusiness">
                                        <Button
                                            classaditional="buttonMoreActionsDetailsBusiness"
                                            name="Duplicar"
                                            onClick={() => this.message("success", "nothing...")}
                                        />
                                        {
                                            this.props.permissions.business.toDelete &&
                                            <Button
                                                classaditional="buttonMoreActionsDetailsBusiness"
                                                name="Excluir"
                                                onClick={() => this.message("success", "nothing...")}
                                            />
                                        }
                                        <div className="dividerMoreActionsDetailsBusiness"></div>
                                    </div>
                                } */}

                            </div>
                            <div className="col-12 col-lg-12 columnHeaderInfoDetailsBusiness">
                                <div className="lineBottomMoreInfoDetailsBusiness">
                                    <span
                                        className="moneyInfoDetailsBusiness"
                                        onClick={() => this.setState({showModalEditMoney: true})}
                                    >
                                        R$&nbsp;{this.state.money}
                                    </span>
                                    {
                                        this.state.showModalEditMoney &&
                                        <ModalEdit
                                            classaditional="modalEditMoneyDetailsBusiness"
                                            title="Alterar o valor:"
                                            value={formatedMoneyBRA(this.state.money)}
                                            onChange={value => {
                                                this.changeValue(formatedMoneyBRA(value), this.state.id, "money")
                                                this.setState({ money: formatedMoneyBRA(value) });
                                            }}
                                            onPositiveClick={() => this.updateMoney()}
                                            onNegativeClick={() => {
                                                this.setState({showModalEditMoney: false})
                                            }}
                                            permissions={this.props.permissions.business}
                                        />
                                    }
                                    <span
                                        className="serviceInfoDetailsBusiness ownersInfoDetailsBusiness"
                                        onClick={() => this.setState({showModalEditBusinessService: true})}
                                    >
                                        {this.state.optionChosenBusinessServices?.name}
                                    </span>
                                    {
                                        this.state.showModalEditBusinessService &&
                                        <ModalEdit
                                            classaditional="modalEditMoneyDetailsBusiness"
                                            title="Alterar o produto:"
                                            value={""}
                                            onChange={(value) => {}}
                                            onPositiveClick={async () => await this.updateBusinessService()}
                                            onNegativeClick={async () => {
                                                await this.setState({showModalEditBusinessService: false});
                                                await this.getDetailsBusiness();
                                            }}
                                            permissions={this.props.permissions.business}
                                            optionsSelectMulti
                                        >
                                            <Select
                                                default={this.state.optionChosenBusinessServices}
                                                options={this.state.optionsBusinessServices} 
                                                onChange={(optionChosenBusinessServices) => this.setState({optionChosenBusinessServices})}
                                            />
                                        </ModalEdit>
                                    }                                    
                                    <div className="ownersInfoDetailsBusiness">
                                        <i className="fas fa-user iconUserMoreInfoDetailsBusiness"></i>
                                        <span className="nameUserMoreInfoDetailsBusiness">&nbsp;{this.state.name}</span>
                                    </div>
                                    {
                                    this.props.permissions.business.toInsert &&
                                    <div className="ownersInfoDetailsBusiness">
                                        {
                                            ((this.state.status === STATUS_BUSINESS_LOSS && this.state.owner) && !this.state.wasRecovered) &&
                                                <Button
                                                    icon="fal fa-history"
                                                    classaditional="buttonPlusBusinessMoreInformationDetailsBusiness positive"
                                                    name="&nbsp;&nbsp;Recuperar"
                                                    onClick={async () => {
                                                        let data = {
                                                            show: true,
                                                            type: businessByClient,
                                                            data: {
                                                                id: await this.state.clientId,
                                                                idBusiness: await this.state.id,
                                                                name: await this.state.name,
                                                                owner: await this.state.owner,
                                                                idCompanyUnitByClient: await this.state.idCompanyUnitClientEditOthersDetails,
                                                                isBusinessRecovered: true
                                                            }
                                                        }
                                                        this.props.showModalMoreActions(data);
                                                    }}
                                                />
                                        }
                                        {
                                            (this.state.status !== STATUS_BUSINESS_LOSS || (this.state.status === STATUS_BUSINESS_LOSS && !this.state.owner)) &&
                                                <Button
                                                    icon="fal fa-plus"
                                                    classaditional="buttonPlusBusinessMoreInformationDetailsBusiness positive"
                                                    name="&nbsp;&nbsp;negócio para esse cliente"
                                                    onClick={this.plusBusinessForThisClient}
                                                />
                                        }
                                    </div>
                                    }
                                </div>
                            </div>                            
                        </div>
                        <div className="row no-gutters lineHeaderProgressDetailsBusiness"> 
                            <div className="col-12 columnHeaderProgressDetailsBusiness d-none d-sm-block">
                                <ProgressLine 
                                    step={this.state.column} 
                                    id={this.state.id}
                                    onChangeStep={async (value) => {
                                        if (this.props.permissions.business.toUpdate)
                                        {
                                            this.changeValue(value, this.state.id, "column");
                                            this.updateStep(value);
                                        }
                                        else
                                        {
                                            this.message("error", "Você não tem permissão para editar esta informação!");
                                        }
                                    }}
                                    days={this.state.numberOfDays}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bodyDetailsBusiness">
                        <div className="row no-gutters lineBodyDetailsBusiness">
                            <div className="col-12 col-md-12 col-lg-4 p-md-2 pr-lg-4 mb-5">
                                <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Pessoa</span>
                                        {
                                            this.props.permissions.leads.toUpdate &&
                                                <ButtonIcon
                                                    classaditional="buttonEditPersonOthersDetailsBusiness"
                                                    icon="fas fa-pencil"
                                                    onClick={() => this.setState({showEditPersonOthersDetailsBusiness: true})}
                                                />
                                        }
                                    </div>
                                    {
                                        !this.state.showEditPersonOthersDetailsBusiness &&
                                        <div className="bodyBoxOthersBodyDetailsBusiness">
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <img className="avatarClientOthersDetailsBusiness" src={gravatar} alt="Imagem do Cliente do Negócio" />
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span className="nameClientBodyOthersDetailsBusiness">{ this.state.nameClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Telefone</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>
                                                        {
                                                            this.props.permissions.phone.toView ?
                                                            this.state.phoneClientEditOthersDetails :
                                                            overshadowsPhone(this.state.phoneClientEditOthersDetails)        
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Telefone 2</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>
                                                        {
                                                            this.props.permissions.phone.toView ?
                                                            this.state.phoneTwoClientEditOthersDetails :
                                                            overshadowsPhone(this.state.phoneTwoClientEditOthersDetails)
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>E-mail</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>{ this.state.mailClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Unidade</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>{ this.state.nameCompanyUnitClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Mídia</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>{ this.state.midiaOriginName }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Campanha</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>{ this.state.nameCampaignClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Criado por</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <span>{ this.state.clientCreatorName }</span>
                                                </div>
                                            </div>
                                            { 
                                                this.state.moreClientBusiness.length > 0 &&
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                    <div className="col-12 mt-4">
                                                        <span className="text-uppercase">Outros negócios desta pessoa</span>
                                                        <hr />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.moreClientBusiness.map((b, i) => {
                                                    return (
                                                        <div key={i} className="row no-gutters">
                                                            <div className="col-12 mt-1 mb-1">
                                                                <Button
                                                                    name={`${b.title} - ${b.id}`}
                                                                    classaditional="buttonLinkDetailsBusiness"
                                                                    onClick={() => window.open(`/business/details/${b.id}`)}
                                                                />
                                                                <span>
                                                                    <i className={this.getIconStatusBusiness(b.status)}></i>
                                                                    {` ${this.getNameStatusBusiness(b.status)} ${b.statusDate ? ` em ${formatedDateTime(b.statusDate)}` : ""}`} 
                                                                </span>
                                                                { b.idServiceERP &&
                                                                    <p className="m-0">{`ID Serviço ERP: ${b.idServiceERP}`}</p>
                                                                }
                                                                <p>R$ {b.money}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.showEditPersonOthersDetailsBusiness &&
                                        <div className="bodyBoxOthersBodyDetailsBusiness">
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Nome</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <InputGeneral
                                                        placeholder="Nome"
                                                        value={this.state.nameClientEditOthersDetails}
                                                        onChange={(e) => this.setState({nameClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Etiqueta</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <Select 
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={this.state.optionChosenStampsLead}
                                                        options={this.state.optionsStampsLead} 
                                                        onChange={(optionChosenStampsLead) => this.setState({optionChosenStampsLead})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Fonte</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <Select 
                                                        classaditional="w-100"
                                                        default={this.state.optionChosenMidiaOrigin}
                                                        options={this.state.optionsMidiaOrigin} 
                                                        onChange={(optionChosenMidiaOrigin) => this.setState({optionChosenMidiaOrigin})}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.props.permissions.phone.toView &&
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                    <div className="col-3 colOne">
                                                        <span>Telefone</span>
                                                    </div>
                                                    <div className="col-9 colTwo">
                                                        <InputGeneral
                                                            placeholder="Telefone"
                                                            value={this.state.phoneClientEditOthersDetails}
                                                            onChange={(e) => this.setState({phoneClientEditOthersDetails: e.target.value})}
                                                            maxLength={14}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.props.permissions.phone.toView &&
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                    <div className="col-3 colOne">
                                                        <span>Telefone 2</span>
                                                    </div>
                                                    <div className="col-9 colTwo">
                                                        <InputGeneral
                                                            placeholder="Telefone 2"
                                                            value={this.state.phoneTwoClientEditOthersDetails}
                                                            onChange={(e) => this.setState({phoneTwoClientEditOthersDetails: e.target.value})}
                                                            maxLength={14}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>E-mail</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <InputGeneral
                                                        placeholder="E-mail"
                                                        value={this.state.mailClientEditOthersDetails}
                                                        onChange={(e) => this.setState({mailClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Unidade</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <Select 
                                                        classaditional="w-100"
                                                        default={this.state.optionChosenCompanyUnit}
                                                        options={this.state.optionsCompanyUnit} 
                                                        onChange={async (optionChosenCompanyUnit) => {
                                                            let status = await this.state.status; 
                                                            
                                                            if (status === "GAIN" || status === "LOSS")
                                                            {
                                                                status = (status === "GAIN") ? "GANHO" : "PERDIDO";
                                                                this.message("warning", `Opsssss, não é mais possível a alteração da unidade pois o negócio já está com o status ${status}!`);
                                                                return;
                                                            }
                                                            this.setState({optionChosenCompanyUnit});
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-3 colOne">
                                                    <span>Campanha</span>
                                                </div>
                                                <div className="col-9 colTwo">
                                                    <InputGeneral
                                                        placeholder="Campanha"
                                                        value={this.state.nameCampaignClientEditOthersDetails}
                                                        onChange={(e) => this.setState({nameCampaignClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-12 d-flex flex-direction-row w-100 align-center justify-content-between">
                                                    <Button
                                                        classaditional="buttonBodyOthersDetailsBusiness"
                                                        name="Cancelar"
                                                        onClick={() => {
                                                            this.setState({showEditPersonOthersDetailsBusiness: false});
                                                            this.getDetailsBusiness();

                                                        }}
                                                    />
                                                    <Button
                                                        classaditional="buttonBodyOthersDetailsBusiness positive"
                                                        name="Salvar"
                                                        onClick={async() => {
                                                            await this.updateClient();
                                                            this.getDetailsBusiness();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Box>
                                {/* <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Participantes</span>
                                        <ButtonIcon
                                            classaditional="buttonEditPersonOthersDetailsBusiness"
                                            icon="fas fa-plus"
                                            onClick={() => {
                                                let data = {
                                                    show: true,
                                                    type: person
                                                }
                                                this.props.showModalMoreActions(data);
                                            }}
                                        />
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsBusiness">
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                            <div className="col-12">
                                                <span className="nameClientBodyOthersDetailsBusiness">
                                                    <i className="fas fa-user" style={{color: "#747678"}} />&nbsp;
                                                    Maria Luiza
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Box> */}
                                <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Visão Geral</span>
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsBusiness">
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Idade do negócio</span>
                                            <span>{ this.state.numberOfDays } dia(s)</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Inativo (dias)</span>
                                            <span className="minumus">{ this.state.inactiveInDays }</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Criado em</span>
                                            <span className="minumus">{ this.state.createdAt }</span>
                                        </div>
                                        {
                                            this.state.creatorName &&
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                <span className="minumus">Criado por</span>
                                                <span className="minumus">{ this.state.creatorName }</span>
                                            </div>
                                        }
                                        {
                                            this.state.status === STATUS_BUSINESS_GAIN &&
                                            <Fragment>
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                    <span className="minumus">Negócio Ganho em</span>
                                                    <span className="minumus">{ this.state.statusDate }</span>
                                                </div>
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                    <span className="minumus">Serviço ERP</span>
                                                    <span className="minumus">{ this.state.idServiceERP }</span>
                                                </div>
                                            </Fragment>
                                        }
                                        {
                                        this.props.permissions.leadOwners.toView &&
                                        <Fragment>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                                <span>Proprietário</span>
                                            </div>
                                            {
                                                this.state.owner === null ?
                                                    <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                        <span className="minumus">Nenhum proprietário</span>
                                                    </div>
                                                :
                                                    <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                        <span className="minumus">{this.state.owner.name}</span>
                                                        <span className="minumus">{this.state.owner.permissionGroup}</span>
                                                    </div>
                                            }
                                        </Fragment>
                                        }
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Atividades principais</span>
                                        </div>
                                        {
                                            this.state.groupActivities.map((a, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                            <div className="progressOthersDetailsBusiness" style={{backgroundColor: "var(--color-warning)"}} >&nbsp;</div>
                                                        </div>
                                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                            <span className="minumus">{ a.name }</span>
                                                            <span className="minumus">{ a.count }</span>
                                                            <span className="minumus">{ a.percentage } %</span>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {/* <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Usuários mais ativos</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <div className="progressOthersDetailsBusiness" style={{backgroundColor: "var(--color-focus)"}} >&nbsp;</div>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Ewerton</span>
                                            <span className="minumus">1</span>
                                            <span className="minumus">100%</span>
                                        </div> */}
                                    </div>
                                </Box>
                                <Box classaditional="boxOthersBodyDetailsBusiness mb-5">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Atendimento</span>
                                        {
                                            (this.props.permissions.business.toUpdate && (![STATUS_BUSINESS_LOSS, STATUS_BUSINESS_GAIN].includes(this.state.status))) &&
                                                <ButtonIcon
                                                    classaditional="buttonEditPersonOthersDetailsBusiness"
                                                    icon="fas fa-exchange-alt"
                                                    onClick={() => this.setState({showEditProspectionOwner: true})}
                                                />
                                        }
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsBusiness">
                                        {
                                            !this.state.showEditProspectionOwner &&
                                            <Fragment>
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                                    <span>Atendido por</span>
                                                    <span>{this.state.prospectionOwner?.name || "Ninguém"}</span>
                                                </div>
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                                    <span className="minumus">Supervisionado por</span>
                                                    <span className="minumus">{ this.state.prospectionOwner?.supervisor || "Ninguém" }</span>
                                                </div>
                                            </Fragment>
                                        }
                                        {
                                        this.state.showEditProspectionOwner &&
                                            <div className="bodyBoxOthersBodyDetailsBusiness">
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                    <div className="col-3 colOne">
                                                        <span>Atendente</span>
                                                    </div>
                                                    <div className="col-9 colTwo">
                                                        <Select 
                                                            classaditional="w-100"
                                                            default={this.state.optionChosenProspectionOwner}
                                                            options={this.state.optionsAttendants} 
                                                            onChange={(optionChosenProspectionOwner) => this.setState({optionChosenProspectionOwner})}
                                                            withFieldSearch
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                    <div className="col-12 d-flex flex-direction-row w-100 align-center justify-content-between">
                                                        <Button
                                                            classaditional="buttonBodyOthersDetailsBusiness"
                                                            name="Cancelar"
                                                            onClick={() => {
                                                                this.setState({showEditProspectionOwner: false});
                                                                this.getDetailsBusiness();
                                                            }}
                                                        />
                                                        <Button
                                                            classaditional="buttonBodyOthersDetailsBusiness positive"
                                                            name="Salvar"
                                                            onClick={async() => {
                                                                await this.updateProspectionOwner();
                                                                this.setState({showEditProspectionOwner: false});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Box>
                            </div>
                            <div className="col-12 col-md-12 col-lg-8 d-none d-md-block p-md-2 pr-lg-4">
                                <Box
                                    classaditional="boxActivitiesBodyDetailsBusiness"
                                >
                                    <Menus
                                        classaditional="menusActivitiesDetailsBusiness"
                                        menus={this.state.menusActivities}
                                        active={this.state.menusActivitiesActive}
                                        onChange={(option) => this.setState({menusActivitiesActive: option})}
                                    />
                                    <ActivityForm 
                                        business={{
                                            id: this.state.id,
                                            name: this.state.name,
                                            title: this.state.title,
                                            clientId: this.state.clientId,
                                            owners: this.state.optionsOwnersLead,
                                            owner: this.state.owner,
                                            status: this.state.status,
                                            clientCompanyUnit: this.state.idCompanyUnitClientEditOthersDetails
                                        }}
                                        onSaveActivity={async () => {
                                            this.getActivities();
                                            await this.getDetailsBusiness();
                                            this.verifyProspectionOwner();
                                        }}
                                        contentOption={this.state.menusActivitiesActive}
                                    />
                                </Box>
                                <br />
                                <Box classaditional="boxActivitiesHistoricBodyDetailsBusiness">
                                    <span className="statusActivitiesDetailsBusiness">Planejado</span>
                                    {
                                        this.state.allActivities.filter((a => !a.finished && a.files.length === 0)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity
                                                    key={a.id}
                                                    activity={a}
                                                    onUpdateActivity={this.getActivities} onCheckActivity={this.onCheckActivity}
                                                />
                                            )
                                        })
                                    }
                                    <span className="statusActivitiesDetailsBusiness">Concluído</span>
                                    {
                                        this.state.allActivitiesWithFilesNotArchived.filter((a => a.files.length > 0)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity 
                                                    key={a.id}
                                                    activity={a}
                                                    onUpdateActivity={this.getActivities}
                                                    onCheckActivity={this.onCheckActivity}
                                                    onToArchivedFile={this.onToArchivedFile}
                                                />
                                            )
                                        })
                                    }
                                    {
                                        this.state.allActivities.filter((a => a.finished && a.files.length === 0)).map((a, i, s) => {
                                            let size = s.length;
                                            return (
                                                <ItemHistoricActivity
                                                    key={a.id}
                                                    activity={a}
                                                    onUpdateActivity={this.getActivities}
                                                    onCheckActivity={this.onCheckActivity}
                                                    lastActivity={i === (size - 1)}
                                                />
                                            )
                                        })
                                    }
                                    <span className="statusActivitiesDetailsBusiness">Anexos Arquivados</span>
                                    {
                                        this.state.allActivitiesWithFilesArchived.filter((a => a.files.length > 0)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity
                                                    key={a.id}
                                                    activity={a}
                                                    onUpdateActivity={this.getActivities}
                                                    onCheckActivity={this.onCheckActivity}
                                                    onToArchivedFile={this.onToArchivedFile}
                                                />
                                            )
                                        })
                                    }                                    
                                </Box>
                                {
                                    (this.state.idBusinessRecovered && !this.state.activitiesBusinessRecoveredIsLoaded) &&
                                    <Box classaditional="boxActivitiesHistoricBodyDetailsBusiness pt-0">
                                        <Button
                                            classaditional="buttonActivitiesBusinessRecovered positive"
                                            name={`Negócio recuperado de: ${this.state.idBusinessRecovered}`}
                                            onClick={async () => {
                                                await this.getActivitiesBusinessRecovered();
                                                await this.setState({
                                                    activitiesBusinessRecoveredIsLoaded: true
                                                });
                                            }}
                                        />
                                    </Box>
                                }
                                {
                                    this.state.activitiesBusinessRecoveredIsLoaded ?
                                    <Fragment>
                                        <hr />
                                        <h5 className="text-center titleActivitiesBusinessRecovered">Histórico do negócio</h5>
                                        <hr />
                                        <Box classaditional="boxActivitiesHistoricBodyDetailsBusiness"><span className="statusActivitiesDetailsBusiness">Planejado</span>
                                            {
                                                this.state.allActivitiesBusinessRecovered.filter((a => !a.finished && a.files.length === 0)).map((a, i) => {
                                                    return (
                                                        <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivitiesBusinessRecovered} onCheckActivity={this.onCheckActivity} />
                                                    )
                                                })
                                            }
                                            <span className="statusActivitiesDetailsBusiness">Concluído</span>
                                            {
                                                this.state.allActivitiesBusinessRecovered.filter((a => a.files.length > 0)).map((a, i) => {
                                                    return (
                                                        <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivitiesBusinessRecovered} onCheckActivity={this.onCheckActivity} />
                                                    )
                                                })
                                            }
                                            {
                                                this.state.allActivitiesBusinessRecovered.filter((a => a.finished && a.files.length === 0)).map((a, i, s) => {
                                                    let size = s.length;
                                                    return (
                                                        <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivitiesBusinessRecovered} onCheckActivity={this.onCheckActivity} lastActivity={i === (size - 1)}/>
                                                    )
                                                })
                                            }
                                        </Box>
                                        <br />
                                    </Fragment>
                                    :    
                                    <br />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state) 
{
    const {allDraggables, changeDeal} = state.business;
    const permissions = state.permissions;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const { user } = state.auth;
    const queue = state.queue;
    
    return {
        allDraggables,
        changeDeal,
        permissions,
        hasPermissionsLoaded,
        user,
        queue
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeDealBusiness (data)
        {
            //action creator -> action
            const action = changeDealBusiness(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setLastPositionSpecialistServiceQueue (data) 
        {
            //action creator -> action
            const action = setLastPositionSpecialistServiceQueue(data);
            dispatch(action);
        },
        changeStatusAllSpecialistsUpdatedServiceQueue (status) 
        {
            //action creator -> action
            const action = changeStatusAllSpecialistsUpdatedServiceQueue(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsBusiness));