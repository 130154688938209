import { 
    GET_ALL_PERMISSIONS,
    defaultPermissions,
    SET_EDIT_PERMISSION_GROUP
} from "../../core/constants";

const initialState = {
    activities: defaultPermissions,
    advancedBusiness: defaultPermissions,
    analysis: defaultPermissions,
    business: defaultPermissions,
    campaign: defaultPermissions,
    commissionReport: defaultPermissions,
    commissionReportAttendant: defaultPermissions,
    commissionReportManager: defaultPermissions,
    commissionReportSupervisor: defaultPermissions,
    commissionReportIntervals: defaultPermissions,
    commissionRules: defaultPermissions,
    commissionRulesProspection: defaultPermissions,
    commissionRulesIntervals: defaultPermissions,
    companyPreferences: defaultPermissions,
    companyUnit: defaultPermissions,
    dashboard: defaultPermissions,
    dashboardMonths: defaultPermissions,
    dashboardProspection: defaultPermissions,
    dashboardMarketing: defaultPermissions,
    gainStatus: defaultPermissions,
    leads: defaultPermissions,
    leadOwners: defaultPermissions,
    leadOwnersModal: defaultPermissions,
    lossStatus: defaultPermissions,
    owners: defaultPermissions,
    ownersOnline: defaultPermissions,
    pausedStatus: defaultPermissions,
    permissionGroups: defaultPermissions,
    personalPreferences: defaultPermissions,
    reopenStatus: defaultPermissions,
    serviceQueue: defaultPermissions,
    connectionLog: defaultPermissions,
    debug: defaultPermissions,
    chat: defaultPermissions,
    training: defaultPermissions,
    preSaleServiceQueue: defaultPermissions,
    idPermissionGroupEdit: null,
    absenceAttendants: defaultPermissions,
    whatsapp: defaultPermissions,
    phone: defaultPermissions,
    hasPermissionsLoaded: false
}

export default function permissionsReducer (state = initialState, action)
{
    switch (action.type) {
        case SET_EDIT_PERMISSION_GROUP:
            return {
                ...state,
                idPermissionGroupEdit: action.payload
            }
    
        case GET_ALL_PERMISSIONS:

            const permissions = action.payload
            
            return {
                ...state,
                ...permissions
            }
    
        default:
            return state
    }
}