//design based on https://spruko.com/demo/nowa/Nowa/HTML/index.html
//design based on http://skote-v-light.svelte.themesbrand.com/
import React, { Component, Fragment } from "react";
import "./Dashboard.css";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; //https://recharts.org/en-US/guide
import CardChartPie from "../../components/CRM/CardChartPie";
import { 
	months,
	NEW_SERVICE,
	APPLICATION_NAME, 
	BANK_LOAN_SERVICE,
	OVERDRAFT_SERVICE,
	FINANCING_SERVICE,
	CREDIT_CARD_SERVICE,
	STATUS_BUSINESS_GAIN, 
	STATUS_BUSINESS_LOSS,
	ALL_EXCEPT_NEW_SERVICE,
	REMAINING_BALANCE_SERVICE,
	ID_PERMISSIONS_GROUP_TRAINING,
	IDS_PERMISSIONS_GROUP_MANAGERS, 
} from "../../core/constants";
import { 
	dateDiff,
	formatedDateTimeToBrowserAtMidnight,
	formatedDateTimeToUSA, 
	formatedMoneyBRAFromFloat, 
	getDateTimeCurrentBrowserAtMidnight,
	getPreviousMonth
} from "../../auxiliary/generalFunctions";

import Header from "../../components/CRM/Header";
import Message from "../../components/general/Message";
import CardInfoLeftChart from "../../components/CRM/CardInfoLeftChart";
import Box from "../../components/general/Box";
import Loading from "../../components/general/Loading";
import SubtitleProgressLine from "../../components/CRM/SubtitleProgressLine";
import StylizedMoneyOrNumber from "../../components/general/StylizedMoneyOrNumber";
import DatePickerCustom from "../../components/general/DatePickerCustom";

import UserController from "../../controllers/UserController";
import CommissionRulesController from "../../controllers/CommissionRulesController";
import BusinessController from "../../controllers/BusinessController";
import CommissionReportController from "../../controllers/CommissionReportController";
import CommissionReportIntervalsController from "../../controllers/CommissionReportIntervalsController";
import PerformanceIndex from "../../components/general/PerformanceIndex";

// starting dashboard for new services (2024-04-25)...
const COLORS = ['transparent', 'success', 'warning', 'error', 'focus', 'information'];

const initialState = {
	// start modal message
	message_type: "information",
	message_show: false,
	message: "",
	// ends modal message

	user: {},
	isSpecialistOrManagerUserLogged: false,
	currentMonthYear: "", //looks like: "2022-04"
	dayFirstOnMonth: "", //looks like: "2022-04-01"
	dayLastOnMonth: "", //looks like: "2022-04-30"
	currentMonthName: "", //looks like: "Abril"

	chosenStartDateReport: "", //looks like: "2022-12-31"
	chosenEndsDateReport: "", //looks like: "2022-12-31"

	isCurrentMonth: true,
	showContentByNewService: false,
	showContentByConsultancy: false,

	// start percent utilization by user specialist
	chartUtilizationByUserLoaded: true,
	labelPercentUtilizationByUser: 0,
	labelAmountUtilizationTotal: "0",
	labelAmountUtilizationGain: "0",
	labelAmountUtilizationLoss: "0",
	labelAmountUtilizationRecovered: "0",
	percentUtilizationBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumUtilization: 0,
	subtitleRulePercentMaximumUtilization: 0,
	messageProgressChartPieUtilization: ["", ""],
	colorProgressChartPieUtilization: "success",
	// ends percent utilization by user specialist

	// start percent utilization new service by user specialist
	chartUtilizationNewServiceByUserLoaded: true,
	labelPercentUtilizationNewServiceByUser: 0,
	labelAmountUtilizationNewServiceTotal: "0",
	labelAmountUtilizationNewServiceGain: "0",
	labelAmountUtilizationNewServiceLoss: "0",
	labelAmountUtilizationNewServiceRecovered: "0",
	percentUtilizationNewServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumUtilizationNewService: 0,
	subtitleRulePercentMaximumUtilizationNewService: 0,
	messageProgressChartPieUtilizationNewService: ["", ""],
	colorProgressChartPieUtilizationNewService: "success",
	// ends percent utilization new service by user specialist

	// start percent utilization other service by user specialist
	chartUtilizationOtherServiceByUserLoaded: true,
	labelPercentUtilizationOtherServiceByUser: 0,
	labelAmountUtilizationOtherServiceTotal: "0",
	labelAmountUtilizationOtherServiceGain: "0",
	labelAmountUtilizationOtherServiceLoss: "0",
	labelAmountUtilizationOtherServiceRecovered: "0",
	percentUtilizationOtherServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumUtilizationOtherService: 0,
	subtitleRulePercentMaximumUtilizationOtherService: 0,
	messageProgressChartPieUtilizationOtherService: ["", ""],
	colorProgressChartPieUtilizationOtherService: "success",
	// ends percent utilization other service by user specialist

	// start percent receiving by user specialist
	chartReceivingByUserLoaded: true,
	labelPercentReceivingByUser: 0,
	labelAmountReceivingSold: "0,00",
	labelAmountReceivingReceived: "0,00",
	percentReceivingBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumReceiving: 0,
	subtitleRulePercentMaximumReceiving: 0,
	messageProgressChartPieReceiving: ["", ""],
	colorProgressChartPieReceiving: "success",
	// ends percent receiving by user specialist

	// start percent receiving new service by user specialist
	chartReceivingNewServiceByUserLoaded: true,
	labelPercentReceivingNewServiceByUser: 0,
	labelAmountReceivingNewServiceSold: "0,00",
	labelAmountReceivingNewServiceReceived: "0,00",
	percentReceivingNewServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumReceivingNewService: 0,
	subtitleRulePercentMaximumReceivingNewService: 0,
	messageProgressChartPieReceivingNewService: ["", ""],
	colorProgressChartPieReceivingNewService: "success",
	// ends percent receiving new service by user specialist

	// start percent receiving other service by user specialist
	chartReceivingOtherServiceByUserLoaded: true,
	labelPercentReceivingOtherServiceByUser: 0,
	labelAmountReceivingOtherServiceSold: "0,00",
	labelAmountReceivingOtherServiceReceived: "0,00",
	percentReceivingOtherServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumReceivingOtherService: 0,
	subtitleRulePercentMaximumReceivingOtherService: 0,
	messageProgressChartPieReceivingOtherService: ["", ""],
	colorProgressChartPieReceivingOtherService: "success",
	// ends percent receiving other service by user specialist

	// start percent loss by user specialist
	chartLossByUserLoaded: true,
	labelPercentLossByUser: 0,
	labelAmountLossSold: "0,00",
	labelAmountLossLoss: "0,00",
	labelAverageLossGain: 0,
	labelAverageLossLoss: 0,
	percentLossBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumLoss: 0,
	subtitleRulePercentMaximumLoss: 0,
	messageProgressChartPieLoss: ["", ""],
	colorProgressChartPieLoss: "success",
	// ends percent loss by user specialist

	// start percent loss new service by user specialist
	chartLossNewServiceByUserLoaded: true,
	labelPercentLossNewServiceByUser: 0,
	labelAmountLossNewServiceSold: "0,00",
	labelAmountLossNewServiceLoss: "0,00",
	labelAverageLossNewServiceGain: 0,
	labelAverageLossNewServiceLoss: 0,
	percentLossNewServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumLossNewService: 0,
	subtitleRulePercentMaximumLossNewService: 0,
	messageProgressChartPieLossNewService: ["", ""],
	colorProgressChartPieLossNewService: "success",
	// ends percent loss new service by user specialist

	// start percent loss other service by user specialist
	chartLossOtherServiceByUserLoaded: true,
	labelPercentLossOtherServiceByUser: 0,
	labelAmountLossOtherServiceSold: "0,00",
	labelAmountLossOtherServiceLoss: "0,00",
	labelAverageLossOtherServiceGain: 0,
	labelAverageLossOtherServiceLoss: 0,
	percentLossOtherServiceBusinessByUser: [
		{ id: 1, name: '[NO_FILL]', value: 100 },
		{ id: 2, name: 'Progresso', value: 0 },
	],
	subtitleRulePercentMinimumLossOtherService: 0,
	subtitleRulePercentMaximumLossOtherService: 0,
	messageProgressChartPieLossOtherService: ["", ""],
	colorProgressChartPieLossOtherService: "success",
	// ends percent loss other service by user specialist

	// start performace compilation
	userLoggedManager: false,
	loadingPerformaceCompilation: true,
	allPerformacesSalesAndReceivedByUnitCompany: [
		{
			nameUnitCompany: "Campo Grande/MS",
			allSpecialists: [
				{
					id: 1,
					name: "José Aparecido",
					amountUtilizationTotal: 10,
					amountUtilizationGain: 6,
					percentUtilization: 60,
					amountReceivingSold: "1000.90",
					amountRecevingReceived: "800.95",
					percentReceiving: 80,
				},
				{
					id: 2,
					name: "Mara Aparecido",
					amountUtilizationTotal: 10,
					amountUtilizationGain: 6,
					percentUtilization: 60,
					amountReceivingSold: "1000.90",
					amountRecevingReceived: "800.95",
					percentReceiving: 80,
				},
			]
		},
		{
			nameUnitCompany: "Cascavel/PR",
			allSpecialists: [
				{
					id: 1,
					name: "José de Lima",
					amountUtilizationTotal: 10,
					amountUtilizationGain: 6,
					percentUtilization: 60,
					amountReceivingSold: "1000.90",
					amountRecevingReceived: "800.95",
					percentReceiving: 80,
				},
				{
					id: 2,
					name: "Mara de Lima",
					amountUtilizationTotal: 10,
					amountUtilizationGain: 6,
					percentUtilization: 60,
					amountReceivingSold: "1000.90",
					amountRecevingReceived: "800.95",
					percentReceiving: 80,
				},
			]
		},
    ],
	allPerformacesGlobalSalesAndReceivedByUnitCompany: [
		{
			nameUnitCompany: "Campo Grande/MS",
			consultanciesService: {
				allSpecialists: [
					{
						id: 1,
						name: "José Aparecido",
						amountUtilizationTotal: 0,
						amountUtilizationGain: 0,
						amountUtilizationLoss: 0,
						percentUtilization: 0,
						amountReceivingSold: "0",
						amountRecevingReceived: "0",
						amountReceivingLoss: "0",
						percentReceiving: 0,
						percentLoss: 0,
					}
				],
				final: {
					amountUtilizationTotalFooter: 0,
					amountUtilizationGainFooter: 0,
					amountUtilizationLossFooter: 0,
					percentUtilizationFooter: 0,
					amountReceivingSoldFooter: 0,
					amountRecevingReceivedFooter: 0,
					amountReceivingLossFooter: 0,
					percentReceivingFooter: 0,
					percentLossFooter: 0
				}
			},
			newService: {
				allSpecialists: [
					{
						id: 2,
						name: "Maria Aparecida",
						amountUtilizationTotal: 0,
						amountUtilizationGain: 0,
						amountUtilizationLoss: 0,
						percentUtilization: 0,
						amountReceivingSold: "0",
						amountRecevingReceived: "0",
						amountReceivingLoss: "0",
						percentReceiving: 0,
						percentLoss: 0,
					},
				],
				final: {
					amountUtilizationTotalFooter: 0,
					amountUtilizationGainFooter: 0,
					amountUtilizationLossFooter: 0,
					percentUtilizationFooter: 0,
					amountReceivingSoldFooter: 0,
					amountRecevingReceivedFooter: 0,
					amountReceivingLossFooter: 0,
					percentReceivingFooter: 0,
					percentLossFooter: 0
				}
			}
		}
	],
	// ends performace compilation

	// start about utilization bars chart
	historicTwoMonthsUtilization: [
		// RESERVED
		// {
		// 	month: 'Fevereiro',
		// 	totalUtilization: 10,
		// 	gainUtilization: 10,
		// 	lossUtilization: 10,
		// 	recoveredUtilization: 10,
		// },
		// {
		// 	month: 'Junho',
		// 	totalUtilization: 20,
		// 	gainUtilization: 20,
		// 	lossUtilization: 20,
		// 	recoveredUtilization: 20,
		// },
	],
	totalMonthOneUtilizationPerfomaceIndex: 0,
	gainMonthOneUtilizationPerfomaceIndex: 0,
	lossMonthOneUtilizationPerfomaceIndex: 0,
	recoveredMonthOneUtilizationPerfomaceIndex: 0,
	totalMonthTwoUtilizationPerfomaceIndex: 0,
	gainMonthTwoUtilizationPerfomaceIndex: 0,
	lossMonthTwoUtilizationPerfomaceIndex: 0,
	recoveredMonthTwoUtilizationPerfomaceIndex: 0,
	// ends about utilization bars chart

	// start about utilization new service bars chart
	historicTwoMonthsUtilizationNewService: [
		// RESERVED
		// {
		// 	month: 'Fevereiro',
		// 	totalUtilization: 10,
		// 	gainUtilization: 10,
		// 	lossUtilization: 10,
		// 	recoveredUtilization: 10,
		// },
		// {
		// 	month: 'Junho',
		// 	totalUtilization: 20,
		// 	gainUtilization: 20,
		// 	lossUtilization: 20,
		// 	recoveredUtilization: 20,
		// },
	],
	totalMonthOneUtilizationNewServicePerfomaceIndex: 0,
	gainMonthOneUtilizationNewServicePerfomaceIndex: 0,
	lossMonthOneUtilizationNewServicePerfomaceIndex: 0,
	recoveredMonthOneUtilizationNewServicePerfomaceIndex: 0,
	totalMonthTwoUtilizationNewServicePerfomaceIndex: 0,
	gainMonthTwoUtilizationNewServicePerfomaceIndex: 0,
	lossMonthTwoUtilizationNewServicePerfomaceIndex: 0,
	recoveredMonthTwoUtilizationNewServicePerfomaceIndex: 0,
	// ends about utilization new service bars chart

	// start about utilization other service bars chart
	historicTwoMonthsUtilizationOtherService: [
		// RESERVED
		// {
		// 	month: 'Fevereiro',
		// 	totalUtilization: 10,
		// 	gainUtilization: 10,
		// 	lossUtilization: 10,
		// 	recoveredUtilization: 10,
		// },
		// {
		// 	month: 'Junho',
		// 	totalUtilization: 20,
		// 	gainUtilization: 20,
		// 	lossUtilization: 20,
		// 	recoveredUtilization: 20,
		// },
	],
	totalMonthOneUtilizationOtherServicePerfomaceIndex: 0,
	gainMonthOneUtilizationOtherServicePerfomaceIndex: 0,
	lossMonthOneUtilizationOtherServicePerfomaceIndex: 0,
	recoveredMonthOneUtilizationOtherServicePerfomaceIndex: 0,
	totalMonthTwoUtilizationOtherServicePerfomaceIndex: 0,
	gainMonthTwoUtilizationOtherServicePerfomaceIndex: 0,
	lossMonthTwoUtilizationOtherServicePerfomaceIndex: 0,
	recoveredMonthTwoUtilizationOtherServicePerfomaceIndex: 0,
	// ends about utilization other service bars chart

	// start about receiving bars chart
	historicTwoMonthsReceiving: [
		// INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldReceiving: 3200,
		// 	receivedReceiving: 1400
		// },
		// {
		// 	month: 'Junho',
		// 	soldReceiving: 3800,
		// 	receivedReceiving: 1600
		// },
	],
	soldMonthOneReceivingPerformaceIndex: 0,
	receivedMonthOneReceivingPerfomaceIndex: 0,
	soldMonthTwoReceivingPerformaceIndex: 0,
	receivedMonthTwoReceivingPerfomaceIndex: 0,
	// ends about receiving bars chart

	// start about receiving new service bars chart
	historicTwoMonthsReceivingNewService: [
		// INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldReceiving: 3000,
		// 	receivedReceiving: 1400
		// },
		// {
		// 	month: 'Junho',
		// 	soldReceiving: 3000,
		// 	receivedReceiving: 1400
		// },
	],
	soldMonthOneReceivingNewServicePerformaceIndex: 0,
	receivedMonthOneReceivingNewServicePerfomaceIndex: 0,
	soldMonthTwoReceivingNewServicePerformaceIndex: 0,
	receivedMonthTwoReceivingNewServicePerfomaceIndex: 0,
	// ends about receiving new service bars chart

	// start about receiving new service bars chart
	historicTwoMonthsReceivingOtherService: [
		// INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldReceiving: 6000,
		// 	receivedReceiving: 1400
		// },
		// {
		// 	month: 'Junho',
		// 	soldReceiving: 6000,
		// 	receivedReceiving: 1400
		// },
	],
	soldMonthOneReceivingOtherServicePerformaceIndex: 0,
	receivedMonthOneReceivingOtherServicePerfomaceIndex: 0,
	soldMonthTwoReceivingOtherServicePerformaceIndex: 0,
	receivedMonthTwoReceivingOtherServicePerfomaceIndex: 0,
	// ends about receiving new service bars chart

	// start about loss bars chart
	historicTwoMonthsLoss:[
		// INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldLoss: 1000,
		// 	lostLoss: 2000
		// },
		// {
		// 	month: 'Junho',
		// 	soldLoss: 500,
		// 	lostLoss: 3000
		// },
	],
	soldMonthOneLossPerformaceIndex: 0,
	lossMonthOneLossPerfomaceIndex: 0,
	soldMonthTwoLossPerformaceIndex: 0,
	lossMonthTwoLossPerfomaceIndex: 0,
	// ends about loss bars chart

	// start about loss new service bars chart
	historicTwoMonthsLossNewService:[
		// // INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldLoss: 200,
		// 	lostLoss: 400
		// },
		// {
		// 	month: 'Junho',
		// 	soldLoss: 200,
		// 	lostLoss: 400
		// },
	],
	soldMonthOneLossNewServicePerformaceIndex: 0,
	lossMonthOneLossNewServicePerfomaceIndex: 0,
	soldMonthTwoLossNewServicePerformaceIndex: 0,
	lossMonthTwoLossNewServicePerfomaceIndex: 0,
	// ends about loss new service bars chart

	// start about loss other service bars chart
	historicTwoMonthsLossOtherService:[
		// INFORMATION: reserved; don't remove.
		// {
		// 	month: 'Maio',
		// 	soldLoss: 500,
		// 	lostLoss: 1000
		// },
		// {
		// 	month: 'Junho',
		// 	soldLoss: 500,
		// 	lostLoss: 1000
		// },
	],
	soldMonthOneLossOtherServicePerformaceIndex: 0,
	lossMonthOneLossOtherServicePerfomaceIndex: 0,
	soldMonthTwoLossOtherServicePerformaceIndex: 0,
	lossMonthTwoLossOtherServicePerfomaceIndex: 0,
	// ends about loss other service bars chart
}

const businessController = new BusinessController();
const commissionRulesController = new CommissionRulesController();
const commissionReportController = new CommissionReportController();
const commissionReportIntervalsController = new CommissionReportIntervalsController();
const userController = new UserController();

class Dashboard extends Component
{
	_mounted = false;
	state = {...initialState}

	componentDidMount = async () => 
	{
		this._mounted = true;
		document.title = `${APPLICATION_NAME} - Sempnet`;

		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);

		await this.setState({
			user: this.props.user,
			...paramsDate
		});

		await this.getProcessByInterval();
	}

	componentWillUnmount = async () => 
	{ 
		this._mounted = false;
		console.info("DASHBOARD demoting.");
	}

	componentDidUpdate = async (prevProps, prevState) => 
	{
		// console.log("DASHBOARD prevProps ", prevProps);
		// console.log("DASHBOARD this.props ", this.props);

		if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			// if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
	}
	
	getProcessByInterval = async () =>
	{
		let user = this.props.user;
		let isManager = false;

		// checking if user is manager...
		if 
		(
			IDS_PERMISSIONS_GROUP_MANAGERS.includes(user.PER_ID_PERMISSIONS_GROUP) || 
			ID_PERMISSIONS_GROUP_TRAINING.includes(user.PER_ID_PERMISSIONS_GROUP)
		)
		{
			if (this._mounted)
			{ 
				await this.setState({
					userLoggedManager: true,
					allPerformacesSalesAndReceivedByUnitCompany: []
				});			
			}

			isManager = true;
			let chosenStartDateReport = await this.state.chosenStartDateReport;
			let chosenEndsDateReport = await this.state.chosenEndsDateReport;
			let onMonth = this.areDatesOnSameMonth(chosenStartDateReport, chosenEndsDateReport);

			if (onMonth.areOnCurrentMonth)
			{				
				// INFORMATION: report in real time.
				await this.setProcessByManagerByInterval(user);
			}
			else
			{
				// INFORMATION: old report.
				await this.setProcessOldByManagerByInterval(user);
			}

		}
		else //is a specialist
		{
			isManager = false;
			await this.setProgressBySpecialistByInterval(user);
		}

		await this.setProcessHistoricTwoMonths(user, isManager);
	}

	getCurrentFirstAndLastDayOnMonth = async (chosenMonthYear) =>
	{
		let parts = chosenMonthYear.toString().split("-");
		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');
		let currentMonthYear = `${y}-${m}`;
		let chosenStartDateReport = formatedDateTimeToUSA((new Date(y, (m - 1), 1)).toDateString());
		let chosenEndsDateReport = formatedDateTimeToUSA((new Date(y, m, 0)).toDateString());
		let currentMonthName = months.find(o => o.order === parseInt(m - 1)).month.toString().toLocaleUpperCase();
		return {
			currentMonthYear,
			chosenStartDateReport,
			chosenEndsDateReport,
			currentMonthName,
		}
	}

	setChosenMonthReport = async (chosenMonth) =>
	{		
		let paramsDate = null;

		if (typeof chosenMonth === "string" && chosenMonth.trim() !== "")
		{
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(chosenMonth);
			// RESERVED
			// let onMonth = this.areDatesOnSameMonth(paramsDate.chosenStartDateReport, paramsDate.chosenEndsDateReport);
			// if (this._mounted && !onMonth.areOnCurrentMonth)
		}
		else
		{
			// chosenMonth is empty!
			let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		}

		if (this._mounted)
		{
			await this.setState({...paramsDate});
		}
	}

	setChosenDatesReport = async (chosenDate, type) =>
	{
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let error = false;

		if (typeof chosenDate === "string" && chosenDate.trim() !== "")
		{
			if (type === "start") 
			{ chosenStartDateReport = chosenDate; }
			else
			{ chosenEndsDateReport = chosenDate; }

			let diff = dateDiff.inDays(
				formatedDateTimeToBrowserAtMidnight(chosenStartDateReport),
				formatedDateTimeToBrowserAtMidnight(chosenEndsDateReport)
			);

			let onMonth = this.areDatesOnSameMonth(chosenStartDateReport, chosenEndsDateReport);

			if (diff < 0 || !onMonth.areOnSameMonth || !onMonth.areOnCurrentMonth) { error = true; }			
		}

		if (error) { this.message("information", "Opsssss, as datas parecem estar erradas. Confira e tente novamente!"); }

		if (this._mounted && !error)
		{
			await this.setState({
				chosenStartDateReport,
				chosenEndsDateReport
			});
		}
	}

	areDatesOnSameMonth = (dateOne, dateTwo) =>
	{
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let monthCurrent = currentDate.split("-")[1];
		let dateYear = dateOne.split("-")[0];
		let dateMonth = dateOne.split("-")[1];
		let monthDateOne = dateOne.split("-")[1];
		let monthDateTwo = dateTwo.split("-")[1];
		let areOnSameMonth = (monthDateOne === monthDateTwo) ? true: false;
		let areOnCurrentMonth = ((monthDateOne === monthDateTwo) && (monthDateOne === monthCurrent)) ? true : false;
		let areWhatMonth = `${dateYear}-${dateMonth}`;
		return { areOnSameMonth, areOnCurrentMonth, areWhatMonth }
	}

	setDataChartUtilizationByInterval = async (amountBusinessGain, amountBusinessLoss) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessRecovered = await businessController.getAmountTotalBusinessRecoveredByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport);

		let percentUtilizationBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;
		let amountBusinessRecovered = 0;

		if (amountBusinessGain !== 0)
		{
			percentGain = parseInt((amountBusinessGain / (amountBusinessGain + amountBusinessLoss)) * 100);
		}
		percentLoss = (100 - percentGain);

		percentUtilizationBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentLoss
		});

		percentUtilizationBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentGain
		});

		if (responseBusinessRecovered.status)
		{
			let {data} = responseBusinessRecovered;
			amountBusinessRecovered = data.AMOUNT_TOTAL_BUSINESS_RECOVERED;
		}

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationBusinessByUser, 
				chartUtilizationByUserLoaded: true,
				labelPercentUtilizationByUser: percentGain.toString(),
				labelAmountUtilizationTotal: (amountBusinessGain + amountBusinessLoss).toString(),
				labelAmountUtilizationGain: amountBusinessGain.toString(),
				labelAmountUtilizationLoss: amountBusinessLoss.toString(),
				labelAmountUtilizationRecovered: amountBusinessRecovered.toString(),
			});
		}
		
	}

	setDataChartUtilizationNewServiceByInterval = async (amountBusinessGain, amountBusinessLoss) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessRecovered = await businessController.getAmountTotalBusinessRecoveredByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport, NEW_SERVICE);

		let percentUtilizationNewServiceBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;
		let amountBusinessRecovered = 0;

		if (amountBusinessGain !== 0)
		{
			percentGain = parseInt((amountBusinessGain / (amountBusinessGain + amountBusinessLoss)) * 100);
		}
		percentLoss = (100 - percentGain);

		percentUtilizationNewServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentLoss
		});

		percentUtilizationNewServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentGain
		});

		if (responseBusinessRecovered.status)
		{
			let {data} = responseBusinessRecovered;
			amountBusinessRecovered = data.AMOUNT_TOTAL_BUSINESS_RECOVERED;
		}

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationNewServiceBusinessByUser, 
				chartUtilizationNewServiceByUserLoaded: true,
				labelPercentUtilizationNewServiceByUser: percentGain.toString(),
				labelAmountUtilizationNewServiceTotal: (amountBusinessGain + amountBusinessLoss).toString(),
				labelAmountUtilizationNewServiceGain: amountBusinessGain.toString(),
				labelAmountUtilizationNewServiceLoss: amountBusinessLoss.toString(),
				labelAmountUtilizationNewServiceRecovered: amountBusinessRecovered.toString(),
			});
		}
		
	}

	setDataChartUtilizationOtherServiceByInterval = async (amountBusinessGain, amountBusinessLoss) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessRecovered = await businessController.getAmountTotalBusinessRecoveredByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport, ALL_EXCEPT_NEW_SERVICE);

		let percentUtilizationOtherServiceBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;
		let amountBusinessRecovered = 0;

		if (amountBusinessGain !== 0)
		{
			percentGain = parseInt((amountBusinessGain / (amountBusinessGain + amountBusinessLoss)) * 100);
		}
		percentLoss = (100 - percentGain);

		percentUtilizationOtherServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentLoss
		});

		percentUtilizationOtherServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentGain
		});

		if (responseBusinessRecovered.status)
		{
			let {data} = responseBusinessRecovered;
			amountBusinessRecovered = data.AMOUNT_TOTAL_BUSINESS_RECOVERED;
		}

		if (this._mounted)
		{
			await this.setState({
				percentUtilizationOtherServiceBusinessByUser, 
				chartUtilizationOtherServiceByUserLoaded: true,
				labelPercentUtilizationOtherServiceByUser: percentGain.toString(),
				labelAmountUtilizationOtherServiceTotal: (amountBusinessGain + amountBusinessLoss).toString(),
				labelAmountUtilizationOtherServiceGain: amountBusinessGain.toString(),
				labelAmountUtilizationOtherServiceLoss: amountBusinessLoss.toString(),
				labelAmountUtilizationOtherServiceRecovered: amountBusinessRecovered.toString(),
			});
		}
		
	}

	setDataChartReceivingByInterval = async (amountReceivingSold, amountReceivingReceived) =>
	{
		let percentReceivingBusinessByUser = [];
		let labelPercentReceivingByUser = "0";

		if (amountReceivingSold !== 0 && amountReceivingReceived !== 0)
		{
			labelPercentReceivingByUser = parseInt((amountReceivingReceived / amountReceivingSold * 100));
		}

		percentReceivingBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: (100 - labelPercentReceivingByUser)
		});

		percentReceivingBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: labelPercentReceivingByUser
		});

		if (this._mounted)
		{
			await this.setState({
				labelAmountReceivingSold: formatedMoneyBRAFromFloat(amountReceivingSold),
				labelAmountReceivingReceived: formatedMoneyBRAFromFloat(amountReceivingReceived),
				labelPercentReceivingByUser,
				percentReceivingBusinessByUser
			});
		}
	}

	setDataChartReceivingNewServiceByInterval = async (amountReceivingNewServiceSold, amountReceivingNewServiceReceived) =>
	{
		let percentReceivingNewServiceBusinessByUser = [];
		let labelPercentReceivingNewServiceByUser = "0";

		if (amountReceivingNewServiceSold !== 0 && amountReceivingNewServiceReceived !== 0)
		{
			labelPercentReceivingNewServiceByUser = parseInt((amountReceivingNewServiceReceived / amountReceivingNewServiceSold * 100));
		}

		percentReceivingNewServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: (100 - labelPercentReceivingNewServiceByUser)
		});

		percentReceivingNewServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: labelPercentReceivingNewServiceByUser
		});

		if (this._mounted)
		{
			await this.setState({
				labelAmountReceivingNewServiceSold: formatedMoneyBRAFromFloat(amountReceivingNewServiceSold),
				labelAmountReceivingNewServiceReceived: formatedMoneyBRAFromFloat(amountReceivingNewServiceReceived),
				labelPercentReceivingNewServiceByUser,
				percentReceivingNewServiceBusinessByUser
			});
		}
	}

	setDataChartReceivingOtherServiceByInterval = async (amountReceivingOtherServiceSold, amountReceivingOtherServiceReceived) =>
	{
		let percentReceivingOtherServiceBusinessByUser = [];
		let labelPercentReceivingOtherServiceByUser = "0";

		if (amountReceivingOtherServiceSold !== 0 && amountReceivingOtherServiceReceived !== 0)
		{
			labelPercentReceivingOtherServiceByUser = parseInt((amountReceivingOtherServiceReceived / amountReceivingOtherServiceSold * 100));
		}

		percentReceivingOtherServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: (100 - labelPercentReceivingOtherServiceByUser)
		});

		percentReceivingOtherServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: labelPercentReceivingOtherServiceByUser
		});

		if (this._mounted)
		{
			await this.setState({
				labelAmountReceivingOtherServiceSold: formatedMoneyBRAFromFloat(amountReceivingOtherServiceSold),
				labelAmountReceivingOtherServiceReceived: formatedMoneyBRAFromFloat(amountReceivingOtherServiceReceived),
				labelPercentReceivingOtherServiceByUser,
				percentReceivingOtherServiceBusinessByUser
			});
		}
	}

	setDataChartLossByInterval = async (amountReceivingSold) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessLoss = await businessController.getAmountTotalBusinessLossByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport);
		let labelAmountLossLoss = "0,00";
		let amountLossLoss = 0;
		let percentLossBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;

		if (responseBusinessLoss.status)
		{
			let data = responseBusinessLoss.data;
			amountLossLoss = data.AMOUNT_TOTAL_BUSINESS_LOSS;
			labelAmountLossLoss = formatedMoneyBRAFromFloat(amountLossLoss);
		}

		if (amountLossLoss !== 0)
		{
			percentGain =  Math.round(amountReceivingSold / (amountReceivingSold + amountLossLoss) * 100);
		}

		percentLoss = (100 - percentGain);

		if (amountLossLoss === 0) { percentLoss = 0; }

		percentLossBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentGain
		});

		percentLossBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentLoss
		});

		if (this._mounted)
		{
			await this.setState({
				percentLossBusinessByUser,
				labelPercentLossByUser: percentLoss.toString(),
				labelAmountLossSold: formatedMoneyBRAFromFloat(amountReceivingSold),
				labelAmountLossLoss,
				labelAverageLossGain: percentGain.toString(),
				labelAverageLossLoss: percentLoss.toString()
			});
		} 
	}

	setDataChartLossNewServiceByInterval = async (amountReceivingSold) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessLoss = await businessController.getAmountTotalBusinessLossByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport, NEW_SERVICE);
		let labelAmountLossNewServiceLoss = "0,00";
		let amountLossLoss = 0;
		let percentLossNewServiceBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;

		if (responseBusinessLoss.status)
		{
			let data = responseBusinessLoss.data;
			amountLossLoss = data.AMOUNT_TOTAL_BUSINESS_LOSS;
			labelAmountLossNewServiceLoss = formatedMoneyBRAFromFloat(amountLossLoss);
		}

		if (amountLossLoss !== 0)
		{
			percentGain =  Math.round(amountReceivingSold / (amountReceivingSold + amountLossLoss) * 100);
		}

		percentLoss = (100 - percentGain);

		if (amountLossLoss === 0) { percentLoss = 0; }

		percentLossNewServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentGain
		});

		percentLossNewServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentLoss
		});

		if (this._mounted)
		{
			await this.setState({
				percentLossNewServiceBusinessByUser,
				labelPercentLossNewServiceByUser: percentLoss.toString(),
				labelAmountLossNewServiceSold: formatedMoneyBRAFromFloat(amountReceivingSold),
				labelAmountLossNewServiceLoss,
				labelAverageLossNewServiceGain: percentGain.toString(),
				labelAverageLossNewServiceLoss: percentLoss.toString()
			});
		} 
	}

	setDataChartLossOtherServiceByInterval = async (amountReceivingSold) =>
	{
		let idUser = this.props.user.PER_ID;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let responseBusinessLoss = await businessController.getAmountTotalBusinessLossByPeriod(idUser, chosenStartDateReport, chosenEndsDateReport, ALL_EXCEPT_NEW_SERVICE);
		let labelAmountLossOtherServiceLoss = "0,00";
		let amountLossLoss = 0;
		let percentLossOtherServiceBusinessByUser = [];
		let percentGain = 0;
		let percentLoss = 0;

		if (responseBusinessLoss.status)
		{
			let data = responseBusinessLoss.data;
			amountLossLoss = data.AMOUNT_TOTAL_BUSINESS_LOSS;
			labelAmountLossOtherServiceLoss = formatedMoneyBRAFromFloat(amountLossLoss);
		}

		if (amountLossLoss !== 0)
		{
			percentGain =  Math.round(amountReceivingSold / (amountReceivingSold + amountLossLoss) * 100);
		}

		percentLoss = (100 - percentGain);

		if (amountLossLoss === 0) { percentLoss = 0; }

		percentLossOtherServiceBusinessByUser.push({
			id: 1,
			name: '[NO_FILL]', 
			value: percentGain
		});

		percentLossOtherServiceBusinessByUser.push({
			id: 2,
			name: 'Progresso', 
			value: percentLoss
		});

		if (this._mounted)
		{
			await this.setState({
				percentLossOtherServiceBusinessByUser,
				labelPercentLossOtherServiceByUser: percentLoss.toString(),
				labelAmountLossOtherServiceSold: formatedMoneyBRAFromFloat(amountReceivingSold),
				labelAmountLossOtherServiceLoss,
				labelAverageLossOtherServiceGain: percentGain.toString(),
				labelAverageLossOtherServiceLoss: percentLoss.toString()
			});
		} 
	}

	setProcessByManagerByInterval = async (user) =>
	{
		// INFORMATION: the follow data is obtained from webhook (in real time).
		const chosenStartDateReport = await this.state.chosenStartDateReport;
		const chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let showContentByNewService = false;
		let showContentByConsultancy = false;
		let allUnitCompanyByManager = user.unit_companies;
		let responseBusinessServices = await businessController.getBusinessServices();
		let responseRelationshipTargetUnitAndService = await businessController.getRelationshipTargetUnitAndService(user.PER_ID);

		// user has some unit that can view the new service?
		if (responseRelationshipTargetUnitAndService.status)
		{
			const CONSULTANCY_SERVICES_GROUP = [FINANCING_SERVICE, CREDIT_CARD_SERVICE, BANK_LOAN_SERVICE, OVERDRAFT_SERVICE, REMAINING_BALANCE_SERVICE];
			const NEW_SERVICE_SERVICES_GROUP = [NEW_SERVICE];

			let hasNewService = responseRelationshipTargetUnitAndService.data.filter(t => NEW_SERVICE_SERVICES_GROUP.includes(t.BUP_SLUG));
			showContentByNewService = (hasNewService.length > 0);

			let hasConsultancyService = responseRelationshipTargetUnitAndService.data.filter(t => CONSULTANCY_SERVICES_GROUP.includes(t.BUP_SLUG));
			showContentByConsultancy = (hasConsultancyService.length > 0);
		}

		let newBusinessService = {};

		if (responseBusinessServices.status) { newBusinessService = responseBusinessServices.data.filter(s => s.BUP_SLUG === NEW_SERVICE)[0]; }

		let allPerformacesGlobalSalesAndReceivedByUnitCompany = [];
		let {responseSales, responseReceiving, responseBusiness} = await this.getInitiaDataOfProcessByManagerByInterval(chosenStartDateReport, chosenEndsDateReport);
		let {
			allSpecialistsFromPerformanceConsultanciesService, 
			amountTotalGlobalBusinessGainConsultanciesService, 
			amountTotalGlobalBusinessLossConsultanciesService,
			amountTotalSoldOtherServiceByUnitCompanyConsultanciesService,
			amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService
		} = await this.processDataConsultanciesService(user, newBusinessService, responseSales, responseReceiving, responseBusiness);
		let {
			allSpecialistsFromPerformanceNewService, 
			amountTotalGlobalBusinessGainNewService, 
			amountTotalGlobalBusinessLossNewService,
			amountTotalSoldNewServiceByUnitCompanyNewService,
			amountTotalReceivedNewServiceByUnitCompanyNewService
		} = await this.processDataNewService(user, newBusinessService, responseSales, responseReceiving, responseBusiness);

		//INFORMATION: separating the tables by type of service, but within the same unit.
		for (const unit of allUnitCompanyByManager)
		{
			const CONSULTANCY_SERVICES_GROUP = [FINANCING_SERVICE, CREDIT_CARD_SERVICE, BANK_LOAN_SERVICE, OVERDRAFT_SERVICE, REMAINING_BALANCE_SERVICE];
			const NEW_SERVICE_SERVICES_GROUP = [NEW_SERVICE];

			let hasContentByConsultanciesServiceForThisCompanyUnit = responseRelationshipTargetUnitAndService.data.filter(r => {
				if (CONSULTANCY_SERVICES_GROUP.includes(r.BUP_SLUG) && r.BUN_ID_COMPANY_UNIT === unit.UNT_ID) { return r; }
				return null;
			});
			let showContentByConsultanciesServiceForThisCompanyUnit = hasContentByConsultanciesServiceForThisCompanyUnit.length > 0;

			let hasContentByNewServiceForThisCompanyUnit = responseRelationshipTargetUnitAndService.data.filter(r => {
				if (NEW_SERVICE_SERVICES_GROUP.includes(r.BUP_SLUG) && r.BUN_ID_COMPANY_UNIT === unit.UNT_ID) { return r; }
				return null;
			});
			let showContentByNewServiceForThisCompanyUnit = hasContentByNewServiceForThisCompanyUnit.length > 0;

			// for Consultancies Services
			let unitByConsultanciesService = allSpecialistsFromPerformanceConsultanciesService.filter(un => un.idUnitCompany === unit.UNT_ID);
			let unitConsultanciesService = (unitByConsultanciesService.length > 0) ? unitByConsultanciesService[0] : [];
			let allSpecialistConsultanciesService = unitConsultanciesService?.allSpecialists || null;
			let finalConsultanciesService = unitConsultanciesService?.final || null;

			// for Consultancies Services
			let unitByNewService = allSpecialistsFromPerformanceNewService.filter(un => un.idUnitCompany === unit.UNT_ID);
			let unitNewService = (unitByNewService.length > 0) ? unitByNewService[0] : [];
			let allSpecialistNewService = unitNewService?.allSpecialists || null;
			let finalNewService = unitNewService?.final || null;

			allPerformacesGlobalSalesAndReceivedByUnitCompany.push(
				{
					nameUnitCompany: unit.UNT_NAME,
					consultanciesService: {
						allSpecialists: showContentByConsultanciesServiceForThisCompanyUnit ? allSpecialistConsultanciesService : null,
						final: finalConsultanciesService
					},
					newService : {
						allSpecialists: showContentByNewServiceForThisCompanyUnit ? allSpecialistNewService : null,
						final: finalNewService
					},
				}
			);
		}

		if (this._mounted)
		{
			await this.setState({
				showContentByNewService,
				showContentByConsultancy,
				loadingPerformaceCompilation: false, 
				allPerformacesGlobalSalesAndReceivedByUnitCompany,
			});
		}

		// //start utilization performace
		await this.setDataChartUtilizationOtherServiceByInterval(amountTotalGlobalBusinessGainConsultanciesService, amountTotalGlobalBusinessLossConsultanciesService);
		await this.setDataChartUtilizationNewServiceByInterval(amountTotalGlobalBusinessGainNewService, amountTotalGlobalBusinessLossNewService);
		await this.setDataChartUtilizationByInterval((amountTotalGlobalBusinessGainConsultanciesService + amountTotalGlobalBusinessGainNewService), (amountTotalGlobalBusinessLossConsultanciesService + amountTotalGlobalBusinessLossNewService));
		// //ends utilization performace

		// //start receiving perfomace
		await this.setDataChartReceivingOtherServiceByInterval(amountTotalSoldOtherServiceByUnitCompanyConsultanciesService, amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService);
		await this.setDataChartReceivingNewServiceByInterval(amountTotalSoldNewServiceByUnitCompanyNewService, amountTotalReceivedNewServiceByUnitCompanyNewService);
		await this.setDataChartReceivingByInterval((amountTotalSoldOtherServiceByUnitCompanyConsultanciesService + amountTotalSoldNewServiceByUnitCompanyNewService), (amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService + amountTotalReceivedNewServiceByUnitCompanyNewService));
		// //ends receiving perfomace

		// //start loss performace
		await this.setDataChartLossOtherServiceByInterval(amountTotalSoldOtherServiceByUnitCompanyConsultanciesService);
		await this.setDataChartLossNewServiceByInterval(amountTotalSoldNewServiceByUnitCompanyNewService);
		await this.setDataChartLossByInterval((amountTotalSoldOtherServiceByUnitCompanyConsultanciesService + amountTotalSoldNewServiceByUnitCompanyNewService));
		// //ends loss performace

		await this.setSubtitlesChartsUtilizationReceivingLossByInterval();
	}

	getInitiaDataOfProcessByManagerByInterval = async (chosenStartDateReport, chosenEndsDateReport) =>
	{
		let responseSales = await commissionRulesController.getAmountSalesGlobalByPeriodERPTemporaryWebhook(chosenStartDateReport, chosenEndsDateReport);
		let responseReceiving = await commissionRulesController.getAmountReceivedGlobalByPeriodERPTemporaryWebhook(chosenStartDateReport, chosenEndsDateReport);
		let responseBusiness = await businessController.getByFilters({"BUS_STATUS_DATE_START": chosenStartDateReport,"BUS_STATUS_DATE_ENDS": chosenEndsDateReport, "BRAND_COMPANY_UNIT": [1,2,3,4,5,6,9]});

		return {responseSales, responseReceiving, responseBusiness};
	}

	processDataConsultanciesService = async (user, newBusinessService, responseSales, responseReceiving, responseBusiness) =>
	{
		let amountTotalSoldByUnitCompanyConsultanciesService = 0;
		let amountTotalSoldNewServiceByUnitCompanyConsultanciesService = 0;
		let amountTotalSoldOtherServiceByUnitCompanyConsultanciesService = 0;
		let amountTotalReceivedByUnitCompanyConsultanciesService = 0;
		let amountTotalReceivedNewServiceByUnitCompanyConsultanciesService = 0;
		let amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService = 0;
		let amountTotalGlobalBusinessGain = 0;
		let amountTotalGlobalBusinessLoss = 0;
		// let amountTotalGlobalBusinessGainByNewService = 0;
		// let amountTotalGlobalBusinessGainByOtherService = 0;
		// let amountTotalGlobalBusinessLossByNewService = 0;
		// let amountTotalGlobalBusinessLossByOtherService = 0;
		
		let allUnitCompanyByManager = user.unit_companies;

		let allSpecialistsFromPerformanceConsultanciesService = [];
		let allSpecialistForControllERPConsultanciesService = [];
		let allSpecialistForControllCRMConsultanciesService = [];
		let allSalesBySpecialistFilteredConsultanciesService = [];
		let allReceivedBySpecialistFilteredConsultanciesService = [];
		let allBusinessBySpecialistFilteredConsultanciesService = [];

		//get all specialists by unit...
		for (let unit of allUnitCompanyByManager)
		{
			let idUnitCompany = unit.UNT_ID;
			let nameUnitCompany = unit.UNT_NAME;
			let responseSpecialist = await userController.getAllSpecialistByUnitCompany(idUnitCompany);
			let responseManager = await userController.getManagerOfCompanyUnit(idUnitCompany);
			let allSpecialists = [];

			//getting all specialists by company unit
			if (responseSpecialist.status)
			{
				for (let specialist of responseSpecialist.data.data)
				{					
					let idSpecialistCRM = specialist.PER_ID;
					let idSpecialistERP = specialist.PER_ID_USER_ERP;
					
					// INFORMATIOIN: only active users to not get null values ​​in the table.
					if (specialist.activeOnCurrentMonth)
					{
						allSpecialists.push({
							idCRM: idSpecialistCRM,
							idERP: idSpecialistERP,
							name: specialist.PER_NAME,
							status: specialist.PER_STATUS,
							amountUtilizationTotal: 0,
							amountUtilizationGain: 0,
							amountUtilizationLoss: 0,
							percentUtilization: 0,
							amountReceivingSold: 0,
							amountRecevingReceived: 0,
							amountReceivingLoss: 0,
							percentReceiving: 0,
							percentLoss: 0
						});
						allSpecialistForControllERPConsultanciesService.push(idSpecialistERP);
						allSpecialistForControllCRMConsultanciesService.push(idSpecialistCRM);
					}
				}
			}
			
			// getting the manager by company unit. WARNING: as the manager will sometimes do service, then he will be considered as a specialist too (service queue).
			if (responseManager.status)
			{
				let manager = responseManager.data;
				let idManagerCRM = manager.PER_ID;
				let idManagerERP = manager.PER_ID_USER_ERP;

				allSpecialists.push({
					idCRM: idManagerCRM,
					idERP: idManagerERP,
					name: manager.PER_NAME,
					status: manager.PER_STATUS,
					amountUtilizationTotal: 0,
					amountUtilizationGain: 0,
					amountUtilizationLoss: 0,
					percentUtilization: 0,
					amountReceivingSold: 0,
					amountRecevingReceived: 0,
					amountReceivingLoss: 0,
					percentReceiving: 0,
					percentLoss: 0
				});
				allSpecialistForControllERPConsultanciesService.push(idManagerERP);
				allSpecialistForControllCRMConsultanciesService.push(idManagerCRM);
			}

			allSpecialistsFromPerformanceConsultanciesService.push({
				idUnitCompany,
				allSpecialists,
				nameUnitCompany,
			});
		}

		//filtering sales from only the experts of interest.
		if (responseSales.status)
		{
			let dataSales = responseSales.data;
			if (dataSales !== null && dataSales.code === 200 && dataSales.data.length > 0)
			{
				for (let salesBySpecialist of dataSales.data)
				{					
					if (allSpecialistForControllERPConsultanciesService.includes(parseInt(salesBySpecialist.idUsuario)))
					{
						allSalesBySpecialistFilteredConsultanciesService.push(salesBySpecialist);
						amountTotalSoldByUnitCompanyConsultanciesService = amountTotalSoldByUnitCompanyConsultanciesService + parseFloat(salesBySpecialist.Serv_Valor);

						if (newBusinessService.BUP_ID_SERVICE_ERP === parseInt(salesBySpecialist.Tipo))
						{
							amountTotalSoldNewServiceByUnitCompanyConsultanciesService = amountTotalSoldNewServiceByUnitCompanyConsultanciesService + parseFloat(salesBySpecialist.Serv_Valor);
						}
						else
						{
							amountTotalSoldOtherServiceByUnitCompanyConsultanciesService = amountTotalSoldOtherServiceByUnitCompanyConsultanciesService + parseFloat(salesBySpecialist.Serv_Valor);
						}
					}
				}
			}
		}

		//filtering receipts only from experts of interest.
		if (responseReceiving.status)
		{
			let dataReceiving = responseReceiving.data;
			if (dataReceiving?.code === 200 && dataReceiving.data.length > 0)
			{
				for (let receivedBySpecialist of dataReceiving.data)
				{
					if (allSpecialistForControllERPConsultanciesService.includes(parseInt(receivedBySpecialist.idUsuario)))
					{
						allReceivedBySpecialistFilteredConsultanciesService.push(receivedBySpecialist);
						amountTotalReceivedByUnitCompanyConsultanciesService = amountTotalReceivedByUnitCompanyConsultanciesService + parseFloat(receivedBySpecialist.Valor);

						if (newBusinessService.BUP_ID_SERVICE_ERP === parseInt(receivedBySpecialist.Tipo))
						{
							amountTotalReceivedNewServiceByUnitCompanyConsultanciesService = amountTotalReceivedNewServiceByUnitCompanyConsultanciesService + parseFloat(receivedBySpecialist.Valor);
						}
						else
						{
							amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService = amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService + parseFloat(receivedBySpecialist.Valor);
						}
					}
				}
			}
		}

		//filtering business only from experts of interest
		if (responseBusiness.status)
		{
			let dataBusiness = responseBusiness.data;
			if (dataBusiness.data !== null && dataBusiness.data.length > 0)
			{
				for (let business of dataBusiness.data)
				{
					if (business.BUS_STATUS !== null && business.BUS_ID_PERSON_OWNER !== null) //when already have owner defined (expert), status will be different from null
					{
						// allBusinessBySpecialistFilteredConsultanciesService
						if (allSpecialistForControllCRMConsultanciesService.includes(business.BUS_ID_PERSON_OWNER))
						{
							allBusinessBySpecialistFilteredConsultanciesService.push(business);
						}
					}
				}
			}
		}

		//summing up total sold, received and pecentages per specialist.
		allSpecialistsFromPerformanceConsultanciesService.forEach(p => {

			p.allSpecialists.map(s => {
				let amountBusinessGain = 0;
				let amountBusinessLoss = 0;			

				allBusinessBySpecialistFilteredConsultanciesService.forEach(b => {

					let status = b.BUS_STATUS;

					if (b.BUS_ID_PERSON_OWNER === s.idCRM && b.BUP_SLUG !== NEW_SERVICE)
					{
						if (status === STATUS_BUSINESS_GAIN) { amountBusinessGain++; }
						if (status === STATUS_BUSINESS_LOSS) { amountBusinessLoss++; }
					}
				});
				
				s.amountUtilizationTotal = (amountBusinessGain + amountBusinessLoss);
				s.amountUtilizationGain = amountBusinessGain;
				s.amountUtilizationLoss = amountBusinessLoss;

				return s;
			});
		});

		allSpecialistsFromPerformanceConsultanciesService.map(p => {
			p.allSpecialists = p.allSpecialists.filter(s => {
				let sum = (s.amountUtilizationTotal + s.amountUtilizationGain + s.amountReceivingLoss);
				return (s.status === 2 && parseFloat(sum) === 0) ? false : true;
			});

			return p;
		});

		// //summing up total sold, received and pecentages per specialist.
		allSpecialistsFromPerformanceConsultanciesService.forEach(p => {
			let amountUtilizationTotalFooter = 0;
			let amountUtilizationGainFooter = 0;
			let amountUtilizationLossFooter = 0;
			let percentUtilizationFooter = 0;
			let amountReceivingSoldFooter = 0;
			let amountRecevingReceivedFooter = 0;
			let amountReceivingLossFooter = 0;
			let percentReceivingFooter = 0;
			let percentLossFooter = 0;

			p.allSpecialists.map(s => {
				let amountBusinessGain = 0;
				let amountBusinessLoss = 0;
				let percentUtilization = 0;
				let amountTotalSoldBySpecialist = 0;
				let amountTotalReceivedBySpecialist = 0;
				let amountTotalLossBySpecialist = 0;
				let percentReceiving = 0;
				let percentLoss = 0;
				
				allSalesBySpecialistFilteredConsultanciesService.forEach(v => {
					if (parseInt(v.idUsuario) === s.idERP && parseInt(v.Tipo) !== 30)
					{
						amountTotalSoldBySpecialist = amountTotalSoldBySpecialist + parseFloat(v.Serv_Valor);
					}
				});

				allReceivedBySpecialistFilteredConsultanciesService.forEach(r => {
					if (parseInt(r.idUsuario) === s.idERP && parseInt(r.Tipo) !== 30)
					{
						amountTotalReceivedBySpecialist = amountTotalReceivedBySpecialist + parseFloat(r.Valor);
					}
				});					

				allBusinessBySpecialistFilteredConsultanciesService.forEach(b => {
					
					let status = b.BUS_STATUS;

					if (b.BUS_ID_PERSON_OWNER === s.idCRM && b.BUP_SLUG !== NEW_SERVICE)
					{
						switch (status)
						{
							case STATUS_BUSINESS_GAIN:
								amountTotalGlobalBusinessGain++;
								amountBusinessGain++;

								break;

							case STATUS_BUSINESS_LOSS:
								amountTotalGlobalBusinessLoss++;
								amountBusinessLoss++;
								amountTotalLossBySpecialist = (b.BUS_MONEY_VALUE + amountTotalLossBySpecialist);
								amountReceivingLossFooter = (b.BUS_MONEY_VALUE + amountReceivingLossFooter);

								break;
						
							default:
								break;
						}
					}
				});

				if (amountBusinessGain !== 0)
				{
					percentUtilization = parseInt(amountBusinessGain / (amountBusinessGain + amountBusinessLoss) * 100);
				}

				if (amountTotalReceivedBySpecialist !== 0 && amountTotalSoldBySpecialist !== 0)
				{
					percentReceiving = parseInt(amountTotalReceivedBySpecialist / amountTotalSoldBySpecialist * 100);
				}

				if (amountTotalLossBySpecialist !== 0)
				{
					percentLoss = parseInt(amountTotalLossBySpecialist / (amountTotalSoldBySpecialist + amountTotalLossBySpecialist) * 100);
				}
				
				s.amountUtilizationTotal = (amountBusinessGain + amountBusinessLoss);
				s.amountUtilizationGain = amountBusinessGain;
				s.amountUtilizationLoss = amountBusinessLoss;
				s.percentUtilization = percentUtilization;
				s.amountReceivingSold = amountTotalSoldBySpecialist;
				s.amountRecevingReceived = amountTotalReceivedBySpecialist;
				s.amountReceivingLoss = amountTotalLossBySpecialist;
				s.percentReceiving = percentReceiving;
				s.percentLoss = percentLoss;
				
				amountUtilizationTotalFooter = amountUtilizationTotalFooter + (amountBusinessGain + amountBusinessLoss);
				amountUtilizationGainFooter = amountUtilizationGainFooter + amountBusinessGain;
				amountUtilizationLossFooter = amountUtilizationLossFooter + amountBusinessLoss;
				amountReceivingSoldFooter = amountReceivingSoldFooter + amountTotalSoldBySpecialist;
				amountRecevingReceivedFooter = amountRecevingReceivedFooter + amountTotalReceivedBySpecialist;

				return s;
			});

			if (amountUtilizationGainFooter !== 0)
			{
				percentUtilizationFooter = parseInt(amountUtilizationGainFooter / (amountUtilizationGainFooter + amountUtilizationLossFooter) * 100);
			}

			if (amountRecevingReceivedFooter !== 0 && amountReceivingSoldFooter !== 0)
			{
				percentReceivingFooter = parseInt(amountRecevingReceivedFooter / amountReceivingSoldFooter * 100);
			}

			if (amountReceivingLossFooter !== 0)
			{
				percentLossFooter = Math.round(amountReceivingLossFooter / (amountReceivingSoldFooter + amountReceivingLossFooter) * 100);
			}

			p["final"] = {
				amountUtilizationTotalFooter,
				amountUtilizationGainFooter,
				amountUtilizationLossFooter,
				percentUtilizationFooter,
				amountReceivingSoldFooter,
				amountRecevingReceivedFooter,
				amountReceivingLossFooter,
				percentReceivingFooter,
				percentLossFooter
			}
		});

		return {
			allSpecialistsFromPerformanceConsultanciesService,
			amountTotalGlobalBusinessGainConsultanciesService: amountTotalGlobalBusinessGain,
			amountTotalGlobalBusinessLossConsultanciesService: amountTotalGlobalBusinessLoss,
			amountTotalSoldOtherServiceByUnitCompanyConsultanciesService,
			amountTotalReceivedOtherServiceByUnitCompanyConsultanciesService
		};
	}

	processDataNewService = async (user, newBusinessService, responseSales, responseReceiving, responseBusiness) =>
	{
		let amountTotalSoldByUnitCompanyNewService = 0;
		let amountTotalSoldNewServiceByUnitCompanyNewService = 0;
		let amountTotalSoldOtherServiceByUnitCompanyNewService = 0;
		let amountTotalReceivedByUnitCompanyNewService = 0;
		let amountTotalReceivedNewServiceByUnitCompanyNewService = 0;
		let amountTotalReceivedOtherServiceByUnitCompanyNewService = 0;
		let amountTotalGlobalBusinessGain = 0;
		let amountTotalGlobalBusinessLoss = 0;
		// let amountTotalGlobalBusinessGainByNewService = 0;
		// let amountTotalGlobalBusinessGainByOtherService = 0;
		// let amountTotalGlobalBusinessLossByNewService = 0;
		// let amountTotalGlobalBusinessLossByOtherService = 0;
		
		let allUnitCompanyByManager = user.unit_companies;

		let allSpecialistsFromPerformanceNewService = [];
		let allSpecialistForControllERPNewService = [];
		let allSpecialistForControllCRMNewService = [];
		let allSalesBySpecialistFilteredNewService = [];
		let allReceivedBySpecialistFilteredNewService = [];
		let allBusinessBySpecialistFilteredNewService = [];

		//get all specialists by unit...
		for (let unit of allUnitCompanyByManager)
		{
			let idUnitCompany = unit.UNT_ID;
			let nameUnitCompany = unit.UNT_NAME;
			let responseSpecialist = await userController.getAllSpecialistByUnitCompany(idUnitCompany);
			let responseManager = await userController.getManagerOfCompanyUnit(idUnitCompany);
			let allSpecialists = [];

			//getting all specialists by company unit
			if (responseSpecialist.status)
			{
				for (let specialist of responseSpecialist.data.data)
				{					
					let idSpecialistCRM = specialist.PER_ID;
					let idSpecialistERP = specialist.PER_ID_USER_ERP;
					
					// INFORMATIOIN: only active users to not get null values ​​in the table.
					if (specialist.activeOnCurrentMonth)
					{
						allSpecialists.push({
							idCRM: idSpecialistCRM,
							idERP: idSpecialistERP,
							name: specialist.PER_NAME,
							status: specialist.PER_STATUS,
							amountUtilizationTotal: 0,
							amountUtilizationGain: 0,
							amountUtilizationLoss: 0,
							percentUtilization: 0,
							amountReceivingSold: 0,
							amountRecevingReceived: 0,
							amountReceivingLoss: 0,
							percentReceiving: 0,
							percentLoss: 0
						});
						allSpecialistForControllERPNewService.push(idSpecialistERP);
						allSpecialistForControllCRMNewService.push(idSpecialistCRM);
					}
				}
			}
			
			// getting the manager by company unit. WARNING: as the manager will sometimes do service, then he will be considered as a specialist too (service queue).
			if (responseManager.status)
			{
				let manager = responseManager.data;
				let idManagerCRM = manager.PER_ID;
				let idManagerERP = manager.PER_ID_USER_ERP;

				allSpecialists.push({
					idCRM: idManagerCRM,
					idERP: idManagerERP,
					name: manager.PER_NAME,
					status: manager.PER_STATUS,
					amountUtilizationTotal: 0,
					amountUtilizationGain: 0,
					amountUtilizationLoss: 0,
					percentUtilization: 0,
					amountReceivingSold: 0,
					amountRecevingReceived: 0,
					amountReceivingLoss: 0,
					percentReceiving: 0,
					percentLoss: 0
				});
				allSpecialistForControllERPNewService.push(idManagerERP);
				allSpecialistForControllCRMNewService.push(idManagerCRM);
			}

			allSpecialistsFromPerformanceNewService.push({
				idUnitCompany,
				allSpecialists,
				nameUnitCompany,
			});
		}

		//filtering sales from only the experts of interest.
		if (responseSales.status)
		{
			let dataSales = responseSales.data;
			if (dataSales !== null && dataSales.code === 200 && dataSales.data.length > 0)
			{
				for (let salesBySpecialist of dataSales.data)
				{					
					if (allSpecialistForControllERPNewService.includes(parseInt(salesBySpecialist.idUsuario)))
					{
						allSalesBySpecialistFilteredNewService.push(salesBySpecialist);
						amountTotalSoldByUnitCompanyNewService = amountTotalSoldByUnitCompanyNewService + parseFloat(salesBySpecialist.Serv_Valor);

						if (newBusinessService.BUP_ID_SERVICE_ERP === parseInt(salesBySpecialist.Tipo))
						{
							amountTotalSoldNewServiceByUnitCompanyNewService = amountTotalSoldNewServiceByUnitCompanyNewService + parseFloat(salesBySpecialist.Serv_Valor);
						}
						else
						{
							amountTotalSoldOtherServiceByUnitCompanyNewService = amountTotalSoldOtherServiceByUnitCompanyNewService + parseFloat(salesBySpecialist.Serv_Valor);
						}
					}
				}
			}
		}

		//filtering receipts only from experts of interest.
		if (responseReceiving.status)
		{
			let dataReceiving = responseReceiving.data;
			if (dataReceiving?.code === 200 && dataReceiving.data.length > 0)
			{
				for (let receivedBySpecialist of dataReceiving.data)
				{
					if (allSpecialistForControllERPNewService.includes(parseInt(receivedBySpecialist.idUsuario)))
					{
						allReceivedBySpecialistFilteredNewService.push(receivedBySpecialist);
						amountTotalReceivedByUnitCompanyNewService = amountTotalReceivedByUnitCompanyNewService + parseFloat(receivedBySpecialist.Valor);

						if (newBusinessService.BUP_ID_SERVICE_ERP === parseInt(receivedBySpecialist.Tipo))
						{
							amountTotalReceivedNewServiceByUnitCompanyNewService = amountTotalReceivedNewServiceByUnitCompanyNewService + parseFloat(receivedBySpecialist.Valor);
						}
						else
						{
							amountTotalReceivedOtherServiceByUnitCompanyNewService = amountTotalReceivedOtherServiceByUnitCompanyNewService + parseFloat(receivedBySpecialist.Valor);
						}
					}
				}
			}
		}

		//filtering business only from experts of interest
		if (responseBusiness.status)
		{
			let dataBusiness = responseBusiness.data;
			if (dataBusiness.data !== null && dataBusiness.data.length > 0)
			{
				for (let business of dataBusiness.data)
				{
					if (business.BUS_STATUS !== null && business.BUS_ID_PERSON_OWNER !== null) //when already have owner defined (expert), status will be different from null
					{
						// allBusinessBySpecialistFilteredNewService
						if (allSpecialistForControllCRMNewService.includes(business.BUS_ID_PERSON_OWNER))
						{
							allBusinessBySpecialistFilteredNewService.push(business);
						}
					}
				}
			}
		}

		//summing up total sold, received and pecentages per specialist.
		allSpecialistsFromPerformanceNewService.forEach(p => {

			p.allSpecialists.map(s => {
				let amountBusinessGain = 0;
				let amountBusinessLoss = 0;			

				allBusinessBySpecialistFilteredNewService.forEach(b => {

					let status = b.BUS_STATUS;

					if (b.BUS_ID_PERSON_OWNER === s.idCRM && b.BUP_SLUG === NEW_SERVICE)
					{
						if (status === STATUS_BUSINESS_GAIN) { amountBusinessGain++; }
						if (status === STATUS_BUSINESS_LOSS) { amountBusinessLoss++; }
					}
				});
				
				s.amountUtilizationTotal = (amountBusinessGain + amountBusinessLoss);
				s.amountUtilizationGain = amountBusinessGain;
				s.amountUtilizationLoss = amountBusinessLoss;

				return s;
			});
		});

		allSpecialistsFromPerformanceNewService.map(p => {
			p.allSpecialists = p.allSpecialists.filter(s => {
				let sum = (s.amountUtilizationTotal + s.amountUtilizationGain + s.amountReceivingLoss);
				return (s.status === 2 && parseFloat(sum) === 0) ? false : true;
			});

			return p;
		});

		// //summing up total sold, received and pecentages per specialist.
		allSpecialistsFromPerformanceNewService.forEach(p => {
			let amountUtilizationTotalFooter = 0;
			let amountUtilizationGainFooter = 0;
			let amountUtilizationLossFooter = 0;
			let percentUtilizationFooter = 0;
			let amountReceivingSoldFooter = 0;
			let amountRecevingReceivedFooter = 0;
			let amountReceivingLossFooter = 0;
			let percentReceivingFooter = 0;
			let percentLossFooter = 0;

			p.allSpecialists.map(s => {
				let amountBusinessGain = 0;
				let amountBusinessLoss = 0;
				let percentUtilization = 0;
				let amountTotalSoldBySpecialist = 0;
				let amountTotalReceivedBySpecialist = 0;
				let amountTotalLossBySpecialist = 0;
				let percentReceiving = 0;
				let percentLoss = 0;
				
				allSalesBySpecialistFilteredNewService.forEach(v => {
					if (parseInt(v.idUsuario) === s.idERP && parseInt(v.Tipo) === 30)
					{
						amountTotalSoldBySpecialist = amountTotalSoldBySpecialist + parseFloat(v.Serv_Valor);
					}
				});

				allReceivedBySpecialistFilteredNewService.forEach(r => {
					if (parseInt(r.idUsuario) === s.idERP && parseInt(r.Tipo) === 30)
					{
						amountTotalReceivedBySpecialist = amountTotalReceivedBySpecialist + parseFloat(r.Valor);
					}
				});					

				allBusinessBySpecialistFilteredNewService.forEach(b => {
					
					let status = b.BUS_STATUS;

					if (b.BUS_ID_PERSON_OWNER === s.idCRM && b.BUP_SLUG === NEW_SERVICE)
					{
						switch (status)
						{
							case STATUS_BUSINESS_GAIN:
								amountTotalGlobalBusinessGain++;
								amountBusinessGain++;

								break;

							case STATUS_BUSINESS_LOSS:
								amountTotalGlobalBusinessLoss++;
								amountBusinessLoss++;
								amountTotalLossBySpecialist = (b.BUS_MONEY_VALUE + amountTotalLossBySpecialist);
								amountReceivingLossFooter = (b.BUS_MONEY_VALUE + amountReceivingLossFooter);

								break;
						
							default:
								break;
						}
					}
				});

				if (amountBusinessGain !== 0)
				{
					percentUtilization = parseInt(amountBusinessGain / (amountBusinessGain + amountBusinessLoss) * 100);
				}

				if (amountTotalReceivedBySpecialist !== 0 && amountTotalSoldBySpecialist !== 0)
				{
					percentReceiving = parseInt(amountTotalReceivedBySpecialist / amountTotalSoldBySpecialist * 100);
				}

				if (amountTotalLossBySpecialist !== 0)
				{
					percentLoss = parseInt(amountTotalLossBySpecialist / (amountTotalSoldBySpecialist + amountTotalLossBySpecialist) * 100);
				}
				
				s.amountUtilizationTotal = (amountBusinessGain + amountBusinessLoss);
				s.amountUtilizationGain = amountBusinessGain;
				s.amountUtilizationLoss = amountBusinessLoss;
				s.percentUtilization = percentUtilization;
				s.amountReceivingSold = amountTotalSoldBySpecialist;
				s.amountRecevingReceived = amountTotalReceivedBySpecialist;
				s.amountReceivingLoss = amountTotalLossBySpecialist;
				s.percentReceiving = percentReceiving;
				s.percentLoss = percentLoss;
				
				amountUtilizationTotalFooter = amountUtilizationTotalFooter + (amountBusinessGain + amountBusinessLoss);
				amountUtilizationGainFooter = amountUtilizationGainFooter + amountBusinessGain;
				amountUtilizationLossFooter = amountUtilizationLossFooter + amountBusinessLoss;
				amountReceivingSoldFooter = amountReceivingSoldFooter + amountTotalSoldBySpecialist;
				amountRecevingReceivedFooter = amountRecevingReceivedFooter + amountTotalReceivedBySpecialist;

				return s;
			});

			if (amountUtilizationGainFooter !== 0)
			{
				percentUtilizationFooter = parseInt(amountUtilizationGainFooter / (amountUtilizationGainFooter + amountUtilizationLossFooter) * 100);
			}

			if (amountRecevingReceivedFooter !== 0 && amountReceivingSoldFooter !== 0)
			{
				percentReceivingFooter = parseInt(amountRecevingReceivedFooter / amountReceivingSoldFooter * 100);
			}

			if (amountReceivingLossFooter !== 0)
			{
				percentLossFooter = Math.round(amountReceivingLossFooter / (amountReceivingSoldFooter + amountReceivingLossFooter) * 100);
			}

			p["final"] = {
				amountUtilizationTotalFooter,
				amountUtilizationGainFooter,
				amountUtilizationLossFooter,
				percentUtilizationFooter,
				amountReceivingSoldFooter,
				amountRecevingReceivedFooter,
				amountReceivingLossFooter,
				percentReceivingFooter,
				percentLossFooter
			}
		});

		return {
			allSpecialistsFromPerformanceNewService,
			amountTotalGlobalBusinessGainNewService: amountTotalGlobalBusinessGain,
			amountTotalGlobalBusinessLossNewService: amountTotalGlobalBusinessLoss,
			amountTotalSoldNewServiceByUnitCompanyNewService,
			amountTotalReceivedNewServiceByUnitCompanyNewService,
		};
	}

	setProgressBySpecialistByInterval = async (user) =>
	{
		// INFORMATION: the follow data is obtained from webhook (in real time).
		const chosenStartDateReport = await this.state.chosenStartDateReport;
		const chosenEndsDateReport = await this.state.chosenEndsDateReport;

		let idSpecialistCRM = user.PER_ID;
		let idSpecialistERP = user.PER_ID_USER_ERP;
		let responseSales = await commissionRulesController.getAmountSalesGlobalByPeriodERPTemporaryWebhook(chosenStartDateReport, chosenEndsDateReport);
		let responseReceiving = await commissionRulesController.getAmountReceivedGlobalByPeriodERPTemporaryWebhook(chosenStartDateReport, chosenEndsDateReport);
		let responseBusiness = await businessController.getByFilters({"BUS_STATUS_DATE_START": chosenStartDateReport,"BUS_STATUS_DATE_ENDS": chosenEndsDateReport});
		let allSalesBySpecialistFiltered = [];
		let allReceivedBySpecialistFiltered = [];
		let allBusinessBySpecialistFiltered = [];
		let amountTotalSoldByUnitCompany = 0;
		let amountTotalReceivedByUnitCompany = 0;
		let amountTotalGlobalBusinessGain = 0;
		let amountTotalGlobalBusinessLoss = 0;

		//filtering sales from specialist
		if (responseSales.status)
		{
			let dataSales = responseSales.data;
			if (dataSales.code === 200 && dataSales.data.length > 0)
			{
				for (let salesBySpecialist of dataSales.data)
				{
					if (idSpecialistERP === parseInt(salesBySpecialist.idUsuario))
					{
						allSalesBySpecialistFiltered.push(salesBySpecialist);
						amountTotalSoldByUnitCompany = amountTotalSoldByUnitCompany + parseFloat(salesBySpecialist.Serv_Valor);
					}
				}
			}
		}
		
		//filtering receipts specialist
		if (responseReceiving.status)
		{
			let dataReceiving = responseReceiving.data;
			if (dataReceiving.code === 200 && dataReceiving.data.length > 0)
			{
				for (let receivedBySpecialist of dataReceiving.data)
				{
					if (idSpecialistERP === parseInt(receivedBySpecialist.idUsuario))
					{
						allReceivedBySpecialistFiltered.push(receivedBySpecialist);
						amountTotalReceivedByUnitCompany = amountTotalReceivedByUnitCompany + parseFloat(receivedBySpecialist.Valor);
					}
				}
			}
		}

		//filtering business specialist
		if (responseBusiness.status)
		{
			let dataBusiness = responseBusiness.data;
			if (dataBusiness.data !== null && dataBusiness.data.length > 0)
			{
				for (let business of dataBusiness.data)
				{
					if (business.BUS_STATUS !== null && business.BUS_ID_PERSON_OWNER !== null) //when already have owner defined (expert), status will be different from null
					{
						// allBusinessBySpecialistFiltered
						if (idSpecialistCRM === business.BUS_ID_PERSON_OWNER)
						{
							allBusinessBySpecialistFiltered.push(business);
						}
					}
				}
			}
		}

		//summing up total sold, received and pecentages from specialist.
		let amountTotalSoldBySpecialist = 0;
		let amountTotalReceivedBySpecialist = 0;
		
		allSalesBySpecialistFiltered.forEach(v => {
			if (parseInt(v.idUsuario) === idSpecialistERP)
			{
				amountTotalSoldBySpecialist = amountTotalSoldBySpecialist + parseFloat(v.Serv_Valor);
			}
		});

		allReceivedBySpecialistFiltered.forEach(r => {
			if (parseInt(r.idUsuario) === idSpecialistERP)
			{
				amountTotalReceivedBySpecialist = amountTotalReceivedBySpecialist + parseFloat(r.Valor);
			}
		});					

		allBusinessBySpecialistFiltered.forEach(b => {
			
			let status = b.BUS_STATUS;

			if (b.BUS_ID_PERSON_OWNER === idSpecialistCRM)
			{
				switch (status) {
					case STATUS_BUSINESS_GAIN:
						amountTotalGlobalBusinessGain++;
						break;
					case STATUS_BUSINESS_LOSS:
						amountTotalGlobalBusinessLoss++;
						break;
				
					default:
						break;
				}
			}
		});		

		await this.setDataChartReceivingByInterval(amountTotalSoldByUnitCompany, amountTotalReceivedByUnitCompany);
		await this.setDataChartUtilizationByInterval(amountTotalGlobalBusinessGain, amountTotalGlobalBusinessLoss);
		await this.setDataChartLossByInterval(amountTotalSoldByUnitCompany);
		await this.setSubtitlesChartsUtilizationReceivingLossByInterval();
	}

	setProcessOldByManagerByInterval = async (user) =>
	{
		// INFORMATION: the follow data is obtained from TB_COMMISSION_REPORT
		const allUnitCompanyByManager = user.unit_companies;

		let currentMonthYear = await this.state.currentMonthYear;
		let responseCommissionReport = await commissionReportController.getByMonth(currentMonthYear);
		let responseCommissionReportIntervals = await commissionReportIntervalsController.getByMonth(currentMonthYear);
		let responseRelationshipTargetUnitAndService = await businessController.getRelationshipTargetUnitAndService(user.PER_ID);
		let allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany = [];
		let allPerformacesNewServiceSalesAndReceivedByUnitCompany = [];
		let allPerformacesGlobalSalesAndReceivedByUnitCompany = [];
		let amountTotalGlobalBusinessGain = 0;
		let amountTotalGlobalBusinessLoss = 0;
		let amountTotalSoldByUnitCompany = 0;
		let amountTotalSoldByUnitCompanyByNewService = 0;
		let amountTotalSoldByUnitCompanyByConsultanciesService = 0;
		let amountTotalReceivedByUnitCompany = 0;
		let amountTotalReceivedByUnitCompanyByNewService = 0;
		let amountTotalReceivedByUnitCompanyByConsultanciesService = 0;
		let amountTotalGlobalBusinessGainByNewService = 0;
		let amountTotalGlobalBusinessGainByConsultanciesService = 0;
		let amountTotalGlobalBusinessLossByNewService = 0;
		let amountTotalGlobalBusinessLossByConsultanciesService = 0;

		// INFORMATION: typeService: 1 for cosultancies and 2 for new service (Limpa Nome).

		// getting the informations about the commission report for others services (consultancies).
		if (responseCommissionReport.status)
		{
			let {data} = responseCommissionReport.data;

			if (data)
			{
				//INFORMATION: the specialists will be separated by company...
				// looping in the data of all SPECIALISTS...
				for (let specialist of data)
				{
					let isUserAllowed = allUnitCompanyByManager.some(u => u.UNT_ID === specialist.COP_ID_COMPANY_UNIT);
					let idSpecialistCRM = specialist.COP_ID_PERSON;
					let idSpecialistERP = specialist.PER_ID_USER_ERP;
					
					if (isUserAllowed)
					{
						let idUnitCompany = specialist.COP_ID_COMPANY_UNIT;
						let nameUnitCompany = specialist.UNT_NAME;
						let hasUnitCompanyInserted = allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany.some(obj => obj.idUnitCompany === idUnitCompany);
						let amountUtilizationGain = specialist.COP_AMOUNT_BUSINESS_GAIN_UTILIZATION;
						let amountUtilizationLoss = specialist.COP_AMOUNT_BUSINESS_LOSS_UTILIZATION;
						let amountUtilizationTotal = (amountUtilizationGain + amountUtilizationLoss);
						let percentUtilization = (amountUtilizationGain !== 0) ? parseInt(amountUtilizationGain / amountUtilizationTotal * 100) : 0;
						let amountReceivingSold = specialist.COP_AMOUNT_SALES_ON_MONTH;
						let amountRecevingReceived = specialist.COP_AMOUNT_SALES_RECEIVED_ON_MONTH;
						let amountReceivingLoss = specialist.COP_AMOUNT_SALES_LOSS_ON_MONTH;
						let percentReceiving = (amountReceivingSold !== 0) ? parseInt(amountRecevingReceived / amountReceivingSold * 100) : 0;
						let percentLoss = (amountReceivingLoss !== 0) ? parseInt(amountReceivingLoss / (amountReceivingLoss + amountReceivingSold) * 100) : 0;
						let owner = {
							name: `${specialist.PER_NAME} (Assessorias)`,
							idCRM: idSpecialistCRM,
							idERP: idSpecialistERP,
							typeService: 1,
							amountUtilizationTotal,
							amountUtilizationGain,
							amountUtilizationLoss,
							percentUtilization,
							amountReceivingSold: amountReceivingSold.toString(),
							amountRecevingReceived: amountRecevingReceived.toString(),
							amountReceivingLoss: amountReceivingLoss.toString(),
							percentReceiving,
							percentLoss
						}

						// putting the specialist in the correctly UNIT COMPANY
						if (hasUnitCompanyInserted)
						{
							allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany = allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany.map(p => {
								if (p.idUnitCompany === idUnitCompany)
								{
									let oldAllSpecialists = p.allSpecialists;
									oldAllSpecialists.push(owner);
									p.allSpecialists = oldAllSpecialists;
								}
								return p;
							});
						}
						else
						{
							let allSpecialists = [];
							let amountUtilizationTotalFooter = 0;
							let amountUtilizationGainFooter = 0;
							let percentUtilizationFooter = 0;
							let amountReceivingSoldFooter = 0;
							let amountRecevingReceivedFooter = 0;
							let amountReceivingLossFooter = 0;
							let percentReceivingFooter = 0;
							let percentLossFooter = 0;

							allSpecialists.push(owner);
							
							allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany.push({
								idUnitCompany,
								nameUnitCompany,
								allSpecialists,
								final: {
									amountUtilizationTotalFooter,
									amountUtilizationGainFooter,
									percentUtilizationFooter,
									amountReceivingSoldFooter,
									amountRecevingReceivedFooter,
									amountReceivingLossFooter,
									percentReceivingFooter,
									percentLossFooter
								}
							});
						}						
					}
				}
			}
		}

		// getting the informations about the commission report for new service.
		if (responseCommissionReportIntervals.status)
		{
			let {data} = responseCommissionReportIntervals.data;

			if (data)
			{
				//INFORMATION: the specialists will be separated by company...
				// looping in the data of all SPECIALISTS...
				for (let specialist of data)
				{
					let isUserAllowed = allUnitCompanyByManager.some(u => u.UNT_ID === specialist.COI_ID_COMPANY_UNIT);
					let idSpecialistCRM = specialist.COI_ID_PERSON;
					let idSpecialistERP = specialist.PER_ID_USER_ERP;

					if (isUserAllowed)
					{
						let idUnitCompany = specialist.COI_ID_COMPANY_UNIT;
						let nameUnitCompany = specialist.UNT_NAME;
						let hasUnitCompanyInserted = allPerformacesNewServiceSalesAndReceivedByUnitCompany.some(obj => obj.idUnitCompany === idUnitCompany);
						let amountUtilizationGain = specialist.COI_AMOUNT_BUSINESS_GAIN;
						let amountUtilizationLoss = specialist.COI_AMOUNT_BUSINESS_LOSS;
						let amountUtilizationTotal = (amountUtilizationGain + amountUtilizationLoss);
						let percentUtilization = (amountUtilizationGain !== 0) ? parseInt(amountUtilizationGain / amountUtilizationTotal * 100) : 0;
						let amountReceivingSold = specialist.COI_AMOUNT_SALES_ON_MONTH;
						let amountRecevingReceived = specialist.COI_AMOUNT_SALES_RECEIVED_ON_MONTH;
						let amountReceivingLoss = specialist.COI_AMOUNT_SALES_LOSS_ON_MONTH;
						let percentReceiving = (amountReceivingSold !== 0) ? parseInt(amountRecevingReceived / amountReceivingSold * 100) : 0;
						let percentLoss = (amountReceivingLoss !== 0) ? parseInt(amountReceivingLoss / (amountReceivingLoss + amountReceivingSold) * 100) : 0;
						let owner = {
							name: `${specialist.PER_NAME} (Limpa Nome)`,
							idCRM: idSpecialistCRM,
							idERP: idSpecialistERP,
							typeService: 2,
							amountUtilizationTotal,
							amountUtilizationGain,
							amountUtilizationLoss,
							percentUtilization,
							amountReceivingSold: amountReceivingSold.toString(),
							amountRecevingReceived: amountRecevingReceived.toString(),
							amountReceivingLoss: amountReceivingLoss.toString(),
							percentReceiving,
							percentLoss
						}

						// putting the specialist in the correctly UNIT COMPANY
						if (hasUnitCompanyInserted)
						{
							allPerformacesNewServiceSalesAndReceivedByUnitCompany = allPerformacesNewServiceSalesAndReceivedByUnitCompany.map(p => {
								if (p.idUnitCompany === idUnitCompany)
								{
									let oldAllSpecialists = p.allSpecialists;
									oldAllSpecialists.push(owner);
									p.allSpecialists = oldAllSpecialists;
								}
								return p;
							});
						}
						else
						{
							let allSpecialists = [];
							let amountUtilizationTotalFooter = 0;
							let amountUtilizationGainFooter = 0;
							let percentUtilizationFooter = 0;
							let amountReceivingSoldFooter = 0;
							let amountRecevingReceivedFooter = 0;
							let amountReceivingLossFooter = 0;
							let percentReceivingFooter = 0;
							let percentLossFooter = 0;

							allSpecialists.push(owner);
							
							allPerformacesNewServiceSalesAndReceivedByUnitCompany.push({
								idUnitCompany,
								nameUnitCompany,
								allSpecialists,
								final: {
									amountUtilizationTotalFooter,
									amountUtilizationGainFooter,
									percentUtilizationFooter,
									amountReceivingSoldFooter,
									amountRecevingReceivedFooter,
									amountReceivingLossFooter,
									percentReceivingFooter,
									percentLossFooter
								}
							});
						}						
					}
				}
			}
		}

		// calculating the final values (footer) for each unit company (for Consultancies Service)
		allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany = allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany.map(p => {
			let amountUtilizationTotalFooter = 0;
			let amountUtilizationGainFooter = 0;
			let amountUtilizationLossFooter = 0;
			let percentUtilizationFooter = 0;
			let amountReceivingSoldFooter = 0;
			let amountRecevingReceivedFooter = 0;
			let amountReceivingLossFooter = 0;
			let percentReceivingFooter = 0;
			let percentLossFooter = 0;

			p.allSpecialists.forEach(s => {
				amountUtilizationTotalFooter += s.amountUtilizationTotal;
				amountUtilizationGainFooter += s.amountUtilizationGain;
				amountUtilizationLossFooter += s.amountUtilizationLoss;
				amountReceivingSoldFooter += parseFloat(s.amountReceivingSold);
				amountRecevingReceivedFooter += parseFloat(s.amountRecevingReceived);
				amountReceivingLossFooter += parseFloat(s.amountReceivingLoss);
			});

			if (amountUtilizationGainFooter !== 0)
			{
				percentUtilizationFooter = parseInt(amountUtilizationGainFooter / (amountUtilizationGainFooter + amountUtilizationLossFooter) * 100);
			}

			if (amountRecevingReceivedFooter !== 0 && amountReceivingSoldFooter !== 0)
			{
				percentReceivingFooter = parseInt(amountRecevingReceivedFooter / amountReceivingSoldFooter * 100);
			}

			if (amountReceivingSoldFooter !== 0 && amountReceivingLossFooter !== 0)
			{
				percentLossFooter = Math.round(amountReceivingLossFooter / (amountReceivingSoldFooter + amountReceivingLossFooter) * 100);
			}

			p.final.amountUtilizationTotalFooter = amountUtilizationTotalFooter;
			p.final.amountUtilizationGainFooter = amountUtilizationGainFooter;
			p.final.amountUtilizationLossFooter = amountUtilizationLossFooter;
			p.final.percentUtilizationFooter = percentUtilizationFooter;
			p.final.amountReceivingSoldFooter = amountReceivingSoldFooter.toString();
			p.final.amountRecevingReceivedFooter = amountRecevingReceivedFooter.toString();
			p.final.amountReceivingLossFooter = amountReceivingLossFooter.toString();
			p.final.percentReceivingFooter = percentReceivingFooter;
			p.final.percentLossFooter = percentLossFooter;
			amountTotalGlobalBusinessGainByConsultanciesService += amountUtilizationGainFooter;
			amountTotalGlobalBusinessLossByConsultanciesService += amountUtilizationLossFooter;
			amountTotalSoldByUnitCompanyByConsultanciesService += amountReceivingSoldFooter;
			amountTotalReceivedByUnitCompanyByConsultanciesService += amountRecevingReceivedFooter;

			return p;
		});	

		// calculating the final values (footer) for each unit company (for New Service)
		allPerformacesNewServiceSalesAndReceivedByUnitCompany = allPerformacesNewServiceSalesAndReceivedByUnitCompany.map(p => {
			let amountUtilizationTotalFooter = 0;
			let amountUtilizationGainFooter = 0;
			let amountUtilizationLossFooter = 0;
			let percentUtilizationFooter = 0;
			let amountReceivingSoldFooter = 0;
			let amountRecevingReceivedFooter = 0;
			let amountReceivingLossFooter = 0;
			let percentReceivingFooter = 0;
			let percentLossFooter = 0;

			p.allSpecialists.forEach(s => {
				amountUtilizationTotalFooter += s.amountUtilizationTotal;
				amountUtilizationGainFooter += s.amountUtilizationGain;
				amountUtilizationLossFooter += s.amountUtilizationLoss;
				amountReceivingSoldFooter += parseFloat(s.amountReceivingSold);
				amountRecevingReceivedFooter += parseFloat(s.amountRecevingReceived);
				amountReceivingLossFooter += parseFloat(s.amountReceivingLoss);
			});

			if (amountUtilizationGainFooter !== 0)
			{
				percentUtilizationFooter = parseInt(amountUtilizationGainFooter / (amountUtilizationGainFooter + amountUtilizationLossFooter) * 100);
			}

			if (amountRecevingReceivedFooter !== 0 && amountReceivingSoldFooter !== 0)
			{
				percentReceivingFooter = parseInt(amountRecevingReceivedFooter / amountReceivingSoldFooter * 100);
			}

			if (amountReceivingSoldFooter !== 0 && amountReceivingLossFooter !== 0)
			{
				percentLossFooter = Math.round(amountReceivingLossFooter / (amountReceivingSoldFooter + amountReceivingLossFooter) * 100);
			}

			p.final.amountUtilizationTotalFooter = amountUtilizationTotalFooter;
			p.final.amountUtilizationGainFooter = amountUtilizationGainFooter;
			p.final.amountUtilizationLossFooter = amountUtilizationLossFooter;
			p.final.percentUtilizationFooter = percentUtilizationFooter;
			p.final.amountReceivingSoldFooter = amountReceivingSoldFooter.toString();
			p.final.amountRecevingReceivedFooter = amountRecevingReceivedFooter.toString();
			p.final.amountReceivingLossFooter = amountReceivingLossFooter.toString();
			p.final.percentReceivingFooter = percentReceivingFooter;
			p.final.percentLossFooter = percentLossFooter;
			amountTotalGlobalBusinessGainByNewService += amountUtilizationGainFooter;
			amountTotalGlobalBusinessLossByNewService += amountUtilizationLossFooter;
			amountTotalSoldByUnitCompanyByNewService += amountReceivingSoldFooter;
			amountTotalReceivedByUnitCompanyByNewService += amountRecevingReceivedFooter;

			return p;
		});	

		//INFORMATION: separating the tables by type of service, but within the same unit.
		for (const unit of allUnitCompanyByManager)
		{
			const CONSULTANCY_SERVICES_GROUP = [FINANCING_SERVICE, CREDIT_CARD_SERVICE, BANK_LOAN_SERVICE, OVERDRAFT_SERVICE, REMAINING_BALANCE_SERVICE];
			const NEW_SERVICE_SERVICES_GROUP = [NEW_SERVICE];

			let hasContentByConsultanciesServiceForThisCompanyUnit = responseRelationshipTargetUnitAndService.data.filter(r => {
				if (CONSULTANCY_SERVICES_GROUP.includes(r.BUP_SLUG) && r.BUN_ID_COMPANY_UNIT === unit.UNT_ID) { return r; }
				return null;
			});
			let showContentByConsultanciesServiceForThisCompanyUnit = hasContentByConsultanciesServiceForThisCompanyUnit.length > 0;

			let hasContentByNewServiceForThisCompanyUnit = responseRelationshipTargetUnitAndService.data.filter(r => {
				if (NEW_SERVICE_SERVICES_GROUP.includes(r.BUP_SLUG) && r.BUN_ID_COMPANY_UNIT === unit.UNT_ID) { return r; }
				return null;
			});
			let showContentByNewServiceForThisCompanyUnit = hasContentByNewServiceForThisCompanyUnit.length > 0;

			// for Consultancies Services
			let unitByConsultanciesService = allPerformacesConsultanciesServiceSalesAndReceivedByUnitCompany.filter(un => un.idUnitCompany === unit.UNT_ID);
			let unitConsultanciesService = (unitByConsultanciesService.length > 0) ? unitByConsultanciesService[0] : [];
			let allSpecialistConsultanciesService = unitConsultanciesService?.allSpecialists || null;
			let finalConsultanciesService = unitConsultanciesService?.final || null;

			// for Consultancies Services
			let unitByNewService = allPerformacesNewServiceSalesAndReceivedByUnitCompany.filter(un => un.idUnitCompany === unit.UNT_ID);
			let unitNewService = (unitByNewService.length > 0) ? unitByNewService[0] : [];
			let allSpecialistNewService = unitNewService?.allSpecialists || null;
			let finalNewService = unitNewService?.final || null;

			allPerformacesGlobalSalesAndReceivedByUnitCompany.push(
				{
					nameUnitCompany: unit.UNT_NAME,
					consultanciesService: {
						allSpecialists: showContentByConsultanciesServiceForThisCompanyUnit ? allSpecialistConsultanciesService : null,
						final: finalConsultanciesService
					},
					newService : {
						allSpecialists: showContentByNewServiceForThisCompanyUnit ? allSpecialistNewService : null,
						final: finalNewService
					},
				}
			);
		}

		if (this._mounted)
		{
			await this.setState({
				loadingPerformaceCompilation: false, 
				allPerformacesGlobalSalesAndReceivedByUnitCompany
			});
		}

		amountTotalGlobalBusinessGain = (amountTotalGlobalBusinessGainByConsultanciesService + amountTotalGlobalBusinessGainByNewService);
		amountTotalGlobalBusinessLoss = (amountTotalGlobalBusinessLossByConsultanciesService + amountTotalGlobalBusinessLossByNewService);

		//start utilization performace
		await this.setDataChartUtilizationByInterval(amountTotalGlobalBusinessGain, amountTotalGlobalBusinessLoss);
		await this.setDataChartUtilizationOtherServiceByInterval(amountTotalGlobalBusinessGainByConsultanciesService, amountTotalGlobalBusinessLossByConsultanciesService);
		await this.setDataChartUtilizationNewServiceByInterval(amountTotalGlobalBusinessGainByNewService, amountTotalGlobalBusinessLossByNewService);
		//ends utilization performace

		amountTotalSoldByUnitCompany = (amountTotalSoldByUnitCompanyByConsultanciesService + amountTotalSoldByUnitCompanyByNewService);
		amountTotalReceivedByUnitCompany = (amountTotalReceivedByUnitCompanyByConsultanciesService + amountTotalReceivedByUnitCompanyByNewService);

		//start receiving perfomace
		await this.setDataChartReceivingByInterval(amountTotalSoldByUnitCompany, amountTotalReceivedByUnitCompany);
		await this.setDataChartReceivingOtherServiceByInterval(amountTotalSoldByUnitCompanyByConsultanciesService, amountTotalReceivedByUnitCompanyByConsultanciesService);
		await this.setDataChartReceivingNewServiceByInterval(amountTotalSoldByUnitCompanyByNewService, amountTotalReceivedByUnitCompanyByNewService);
		//ends receiving perfomace
		
		//start loss performace
		await this.setDataChartLossByInterval(amountTotalSoldByUnitCompany);
		await this.setDataChartLossOtherServiceByInterval(amountTotalSoldByUnitCompanyByConsultanciesService);
		await this.setDataChartLossNewServiceByInterval(amountTotalSoldByUnitCompanyByNewService);
		//ends loss performace

		await this.setSubtitlesChartsUtilizationReceivingLossByInterval();
	}

	getOldUtilizationReceivingLossRecoveredDataByInterval = async (user, chosenMonthYear, isManager) =>
	{
		// INFORMATION: this method is used for managers and specialists

		const allUnitCompanyByManager = user.unit_companies;
		let obj = {
			month: "",
			totalUtilization: 0,
			gainUtilization: 0,
			lossUtilization: 0,
			recoveredUtilization: 0,
			soldReceiving: 0,
			receivedReceiving: 0,
			soldLoss: 0,
			lostLoss: 0
		};

		let orderMonth = parseInt(chosenMonthYear.split("-")[1]);
		obj.month = months.filter(m => m.order === (orderMonth - 1))[0].month;
		let {chosenStartDateReport, chosenEndsDateReport} = await this.getCurrentFirstAndLastDayOnMonth(chosenMonthYear);
		let responseGainAndLoss = await commissionReportController.getByMonth(chosenMonthYear);
		let responseRecovered = await businessController.getAmountTotalBusinessRecoveredByPeriod(user.PER_ID, chosenStartDateReport, chosenEndsDateReport);

		if (responseGainAndLoss.status)
		{
			let {data} = responseGainAndLoss.data;

			for (let specialist of data)
			{
				let isUserAllowed = false;
				
				if (isManager)
				{
					// obtaining the compilations of the specialists of the units in which the manager has access.
					isUserAllowed = allUnitCompanyByManager.some(u => u.UNT_ID === specialist.COP_ID_COMPANY_UNIT);
				}
				else
				{
					// getting the compiled from the expert who is logged in.
					isUserAllowed = (specialist.COP_ID_PERSON === user.PER_ID) ? true : false;
				}

				if (isUserAllowed)
				{
					// utilization
					obj.gainUtilization += specialist.COP_AMOUNT_BUSINESS_GAIN_UTILIZATION;
					obj.lossUtilization += specialist.COP_AMOUNT_BUSINESS_LOSS_UTILIZATION;

					// receiving
					obj.soldReceiving += specialist.COP_AMOUNT_SALES_ON_MONTH;
					obj.receivedReceiving += specialist.COP_AMOUNT_SALES_RECEIVED_ON_MONTH;

					// loss
					obj.soldLoss += specialist.COP_AMOUNT_SALES_ON_MONTH;
					obj.lostLoss += specialist.COP_AMOUNT_SALES_LOSS_ON_MONTH;
				}
			}
		}		

		if (responseRecovered.status)
		{
			obj.recoveredUtilization = responseRecovered.data.AMOUNT_TOTAL_BUSINESS_RECOVERED;
		}

		obj.totalUtilization = (obj.gainUtilization + obj.lossUtilization);
		return obj;
	}

	setDataBarsChartAndPerformaceIndexUtilizationReceivingLoss = async (objMonthOne, objMonthTwo) =>
	{
		let historicTwoMonthsUtilization = [];
		let historicTwoMonthsReceiving = [];
		let historicTwoMonthsLoss = [];

		let totalMonthOneUtilizationPerfomaceIndex = objMonthOne.totalUtilization;
		let totalMonthTwoUtilizationPerfomaceIndex = objMonthTwo.totalUtilization;
		let gainMonthOneUtilizationPerfomaceIndex = objMonthOne.gainUtilization;
		let gainMonthTwoUtilizationPerfomaceIndex = objMonthTwo.gainUtilization;
		let lossMonthOneUtilizationPerfomaceIndex = objMonthOne.lossUtilization;
		let lossMonthTwoUtilizationPerfomaceIndex = objMonthTwo.lossUtilization;
		let recoveredMonthOneUtilizationPerfomaceIndex = objMonthOne.recoveredUtilization;
		let recoveredMonthTwoUtilizationPerfomaceIndex = objMonthTwo.recoveredUtilization;
		let soldMonthOneReceivingPerformaceIndex = objMonthOne.soldReceiving;
		let receivedMonthOneReceivingPerfomaceIndex = objMonthOne.receivedReceiving;
		let soldMonthTwoReceivingPerformaceIndex = objMonthTwo.soldReceiving;
		let receivedMonthTwoReceivingPerfomaceIndex = objMonthTwo.receivedReceiving;
		let soldMonthOneLossPerformaceIndex = objMonthOne.soldLoss;
		let lossMonthOneLossPerfomaceIndex = objMonthOne.lostLoss;
		let soldMonthTwoLossPerformaceIndex = objMonthTwo.soldLoss;
		let lossMonthTwoLossPerfomaceIndex = objMonthTwo.lostLoss;

		historicTwoMonthsUtilization.push(objMonthOne);
		historicTwoMonthsUtilization.push(objMonthTwo);

		historicTwoMonthsReceiving.push(objMonthOne);
		historicTwoMonthsReceiving.push(objMonthTwo);

		historicTwoMonthsLoss.push(objMonthOne);
		historicTwoMonthsLoss.push(objMonthTwo);

		if (this._mounted)
		{
			await this.setState({
				historicTwoMonthsUtilization,
				totalMonthOneUtilizationPerfomaceIndex,
				totalMonthTwoUtilizationPerfomaceIndex,
				gainMonthOneUtilizationPerfomaceIndex,
				gainMonthTwoUtilizationPerfomaceIndex,
				lossMonthOneUtilizationPerfomaceIndex,
				lossMonthTwoUtilizationPerfomaceIndex,
				recoveredMonthOneUtilizationPerfomaceIndex,
				recoveredMonthTwoUtilizationPerfomaceIndex,
				historicTwoMonthsReceiving,
				soldMonthOneReceivingPerformaceIndex,
				receivedMonthOneReceivingPerfomaceIndex,
				soldMonthTwoReceivingPerformaceIndex,
				receivedMonthTwoReceivingPerfomaceIndex,
				historicTwoMonthsLoss,
				soldMonthOneLossPerformaceIndex,
				lossMonthOneLossPerfomaceIndex,
				soldMonthTwoLossPerformaceIndex,
				lossMonthTwoLossPerfomaceIndex
			});
		}
	}

	setProcessHistoricTwoMonths = async (user, isManager) =>
	{
		let currentMonthYear = await this.state.currentMonthYear;
		let monthOne = getPreviousMonth(currentMonthYear, 2);
		let monthTwo = getPreviousMonth(currentMonthYear, 1);
		let objMonthOne = await this.getOldUtilizationReceivingLossRecoveredDataByInterval(user, monthOne, isManager);
		let objMonthTwo = await this.getOldUtilizationReceivingLossRecoveredDataByInterval(user, monthTwo, isManager);
		this.setDataBarsChartAndPerformaceIndexUtilizationReceivingLoss(objMonthOne, objMonthTwo);
	}

	setSubtitlesChartsUtilizationReceivingLossByInterval = async () =>
	{
		let currentMonthYear = await this.state.currentMonthYear;
		let percentUtilizationByUser = await this.state.labelPercentUtilizationByUser;
		let percentReceivingByUser = await this.state.labelPercentReceivingByUser;
		let percentLossByUser = await this.state.labelPercentLossByUser;
		let isSpecialistOrManagerUserLogged = true;
		let idUser = this.props.user.PER_ID;
		let idUnitCompany = this.props.user.unit_companies[0].UNT_ID;
		let subtitleRulePercentMinimumUtilization = 0;
		let subtitleRulePercentMaximumUtilization = 0;
		let subtitleRulePercentMinimumReceiving = 0;
		let subtitleRulePercentMaximumReceiving = 0;
		let subtitleRulePercentMinimumLoss = 0;
		let subtitleRulePercentMaximumLoss = 0;

		let responseRule = await commissionRulesController.getByUserAndUnitCompanyAndPeriod(idUser, idUnitCompany, currentMonthYear);

		if (responseRule.status)
		{
			let {data} = responseRule.data;

			if (data !== null)
			{
				subtitleRulePercentMinimumUtilization = data.COR_PERCENT_UTILIZATION_MINIMUM_GOAL;
				subtitleRulePercentMaximumUtilization = data.COR_PERCENT_UTILIZATION_MAXIMUM_GOAL;
				subtitleRulePercentMinimumReceiving = data.COR_PERCENT_RECEIVING_MINIMUM_GOAL;
				subtitleRulePercentMaximumReceiving = data.COR_PERCENT_RECEIVING_MAXIMUM_GOAL;
				subtitleRulePercentMinimumLoss = data.COR_PERCENT_LOSS_MINIMUM_GOAL;
				subtitleRulePercentMaximumLoss = data.COR_PERCENT_LOSS_MAXIMUM_GOAL;

				this.setMessageProgressChartUtilization(
						parseFloat(percentUtilizationByUser), 
						subtitleRulePercentMinimumUtilization, 
						subtitleRulePercentMaximumUtilization
				);

				this.setMessageProgressChartReceiving(
						parseFloat(percentReceivingByUser), 
						subtitleRulePercentMinimumReceiving, 
						subtitleRulePercentMaximumReceiving
				);

				this.setMessageProgressChartLoss(
						parseFloat(percentLossByUser), 
						subtitleRulePercentMinimumLoss, 
						subtitleRulePercentMaximumLoss
				);
			}
			else
			{
				isSpecialistOrManagerUserLogged = false; //INFORMATION: because there are many rules for different companies, and it is not possible to compile progress bars (minimum goal and maximum goal)
			}
		}

		if (this._mounted)
		{
			await this.setState({
				subtitleRulePercentMinimumUtilization,
				subtitleRulePercentMaximumUtilization,
				subtitleRulePercentMinimumReceiving,
				subtitleRulePercentMaximumReceiving,
				subtitleRulePercentMinimumLoss,
				subtitleRulePercentMaximumLoss,
				isSpecialistOrManagerUserLogged
			});
		}
	}

	setMessageProgressChartUtilization = async (valueCurrentByUser, valueMinimum, valueMaximum) =>
	{
		let messageProgressChartPieUtilization = ["", ""];
		let colorProgressChartPieUtilization = "success";
		
		if (valueCurrentByUser < valueMinimum)
		{
			messageProgressChartPieUtilization[0] = "Opsssss";
			messageProgressChartPieUtilization[1] = ", você ainda não atingiu a qualificação!";
			colorProgressChartPieUtilization = "error";
		}
		else if (valueCurrentByUser >= valueMinimum && valueCurrentByUser < valueMaximum)
		{
			messageProgressChartPieUtilization[0] = "Obaaaaa";
			messageProgressChartPieUtilization[1] = ", você atingiu a qualificação!";
			colorProgressChartPieUtilization = "warning";
			
		}
		else
		{
			messageProgressChartPieUtilization[0] = "Obaaaaa";
			messageProgressChartPieUtilization[1] = ", você atingiu a meta!";
			colorProgressChartPieUtilization = "success";
		}

		if (this._mounted)
		{			
			await this.setState({messageProgressChartPieUtilization, colorProgressChartPieUtilization});
		}
	}

	setMessageProgressChartReceiving = async (valueCurrentByUser, valueMinimum, valueMaximum) =>
	{
		let messageProgressChartPieReceiving = ["", ""];
		let colorProgressChartPieReceiving = "success";

		if (valueCurrentByUser < valueMinimum)
		{
			messageProgressChartPieReceiving[0] = "Opsssss";
			messageProgressChartPieReceiving[1] = ", você ainda não atingiu a qualificação!";
			colorProgressChartPieReceiving = "error";
		}
		else if (valueCurrentByUser >= valueMinimum && valueCurrentByUser < valueMaximum)
		{
			messageProgressChartPieReceiving[0] = "Obaaaaa";
			messageProgressChartPieReceiving[1] = ", você atingiu a qualificação!";
			colorProgressChartPieReceiving = "warning";
		}
		else
		{
			messageProgressChartPieReceiving[0] = "Obaaaaa";
			messageProgressChartPieReceiving[1] = ", você atingiu a meta!";
			colorProgressChartPieReceiving = "success";
		}

		if (this._mounted)
		{
			await this.setState({messageProgressChartPieReceiving, colorProgressChartPieReceiving});
		}
	}

	setMessageProgressChartLoss = async (valueCurrentByUser, valueMinimum, valueMaximum) =>
	{
		let messageProgressChartPieLoss = ["", ""];
		let colorProgressChartPieLoss = "success";
		;
		if (valueCurrentByUser <= valueMaximum)
		{
			messageProgressChartPieLoss[0] = "Obaaaaa";
			messageProgressChartPieLoss[1] = ", você está dentro da qualificação!";
			colorProgressChartPieLoss =  "success";
			
		}
		else if (valueCurrentByUser > valueMaximum && valueCurrentByUser < valueMinimum)
		{
			messageProgressChartPieLoss[0] = "Cuidado";
			messageProgressChartPieLoss[1] = ", você vai estourar a meta!";
			colorProgressChartPieLoss =  "warning";
		}
		else
		{
			messageProgressChartPieLoss[0] = "Opsssss";
			messageProgressChartPieLoss[1] = ", você estourou a meta!";
			colorProgressChartPieLoss =  "error";
		}

		if (this._mounted)
		{
			await this.setState({messageProgressChartPieLoss, colorProgressChartPieLoss});
		}
	}

	toggleIntervalReport = async () =>
	{
		let isCurrentMonth = await this.state.isCurrentMonth;

		if (!isCurrentMonth)
		{
			let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
			let paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
	
			await this.setState({...paramsDate, loadingPerformaceCompilation: true});
			await this.getProcessByInterval();
		}

		await this.setState({isCurrentMonth: !isCurrentMonth});
	}

	message = (type, message) =>
	{
		this.setState({
			message_type: type,
			message: message,
			message_show: true
		})
	}

	render ()
	{
		return (
			<div className="dashboard">
				<Header title="Dashboard Vendas Comercial" classaditional="headerDashboard" />
				<Message
					message_type={this.state.message_type}
					message={this.state.message}
					onClose={() => this.setState({message_show: false})}
					show={this.state.message_show}
				/>
				<div className="containerBodyDashboard">
					{
						this.props.permissionMonths.toView &&
						<div className="row no-gutters lineHeaderDashboard d-none d-md-flex">
							<Box classaditional="boxHeaderDashboard filterDashboard">
								<h4>Mês de Exercício:</h4>
								<Fragment>
									<DatePickerCustom
										classaditional="inputFilterDashboard ml-auto"
										value={this.state.currentMonthYear}
										onChange={async (currentMonthYear) => {
											// DANGER: wait for the current loading, because as real-time reports take longer and after fetching reports from the previous month, it will eventually overwrite the report in the view when the real-time report arrives.
											if (!this.state.loadingPerformaceCompilation)
											{
												await this.setChosenMonthReport(currentMonthYear);
												await this.setState({loadingPerformaceCompilation: true});
												await this.getProcessByInterval();
											}
											else
											{
												this.message("information", "Por favor, aguarde a geração do relatório em andamento, para poder escolher outro período!");
											}
										}}
										type="month"
									/>
								</Fragment>
								{/* RESERVED */}
								{/* <Check
									classaditional="checkToggleIntervalFilterDashboard ml-auto"
									name="Mês Anterior" 
									onClick={() => this.toggleIntervalReport()} 
									checked={!this.state.isCurrentMonth}
								/>
								<Check
									classaditional="checkToggleIntervalFilterDashboard"
									name="Mês Atual" 
									onClick={() => this.toggleIntervalReport()} 
									checked={this.state.isCurrentMonth}
								/>
								{
									this.state.isCurrentMonth &&
									<Fragment>
										<InputGeneral
											classaditional="inputFilterDashboard pr-2"
											type="date"
											value={this.state.chosenStartDateReport}
											onChange={async (e) => {
												await this.setChosenDatesReport(e.target.value, "start");
											}}
										/>
										<InputGeneral
											classaditional="inputFilterDashboard"
											type="date"
											value={this.state.chosenEndsDateReport}
											onChange={async (e) => {
												await this.setChosenDatesReport(e.target.value, "ends");
											}}
										/>
									</Fragment>
								}
								{
									!this.state.isCurrentMonth &&
									<Fragment>
										<InputGeneral
											classaditional="inputFilterDashboard"
											type="month"
											value={this.state.currentMonthYear}
											onChange={async (e) => {
												await this.setChosenMonthReport(e.target.value);
											}}
										/>
									</Fragment>
								}
								<Button
									classaditional="buttonSearchFilterDashboard"
									name=""
									icon="fas fa-search"
									onClick={async () => {
										// DANGER: wait for the current loading, because as real-time reports take longer and after fetching reports from the previous month, it will eventually overwrite the report in the view when the real-time report arrives.
										if (!this.state.loadingPerformaceCompilation)
										{
											await this.setState({loadingPerformaceCompilation: true});
											await this.getProcessByInterval();
										}
										else
										{
											this.message("information", "Por favor, aguarde a geração do relatório em andamento, para poder escolher outro período!");
										}
									}}
								/> */}
							</Box>
						</div>
					}

					{/* start content in charts */}
					<div className="row no-gutters lineBodyDashboard">
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
							<CardInfoLeftChart
								title={`Aproveitamento Global (${this.state.currentMonthName})`}
								typeColorTitle="success"
								amountColumnLeft={6}
								amountColumnRight={6}
								icon="fas fa-globe-americas"
								information={(
									<div>
										{
											this.state.isSpecialistOrManagerUserLogged &&
											<Fragment>
												<SubtitleProgressLine 
													classaditional="subtitleProgressLineDashboard"
													label="Mínimo para qualificação:"
													color="warning"
													progressOne={this.state.subtitleRulePercentMinimumUtilization}
												/>
												<SubtitleProgressLine
													classaditional="subtitleProgressLineDashboard"
													label="Meta:"
													color="success"
													progressOne={this.state.subtitleRulePercentMaximumUtilization}
												/>
											</Fragment>
										}
										<div className="areaSubtitlesChartPieDashboard">
											<div className="areSubtitleChartPieDashboard">
												Negócios atendidos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationTotal}`} color="information" style={{"marginLeft": "19px", "minWidth": "40px"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Negócios ganhos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationGain}`} color="success" style={{"marginLeft": "36px", "minWidth": "40px"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Negócios perdidos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationLoss}`} color="error" style={{"marginLeft": "27px", "minWidth": "40px"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Negócios recuperados:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationRecovered}`} color="warning" style={{"marginLeft": "0px", "minWidth": "40px"}} />
											</div>
										</div>
									</div>
								)}
							>
								{
									this.state.chartUtilizationByUserLoaded &&
									<CardChartPie
										title={
												<div className="labelsCardChartPieDashboard">
													Seu desempenho:
												</div>
											}
										subtitle={
												<div className="labelsCardChartPieDashboard italic">
													<span className="strong">{this.state.messageProgressChartPieUtilization[0]}</span>
													{this.state.messageProgressChartPieUtilization[1]}
												</div>
											}
										labelOver={`${this.state.labelPercentUtilizationByUser}%`}
										colorLabelOver={`${this.state.colorProgressChartPieUtilization}`}
									>								
										<PieChart
											width={170} 
											height={170} 
											onMouseEnter={this.onPieEnter}
											style={{transform: "rotate(-90deg)"}}
										>
											<Pie
												data={this.state.percentUtilizationBusinessByUser}
												cx="50%"
												cy="50%"
												innerRadius={70}
												outerRadius={80}
												fill="#8884d8"
												paddingAngle={0}
												dataKey="value"
												label={false}
											>
												{this.state.percentUtilizationBusinessByUser.map((entry, index) => (
													<Cell 
														key={`cell-${index}`} 
														fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilization})` : "transparent"} 
														strokeWidth={0}
													/>
												))}
											</Pie>
										</PieChart>
									</CardChartPie>
								}
							</CardInfoLeftChart>
						</div>
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
							<CardInfoLeftChart
								classaditional="barsChartCardInfoLeftDashboard"
								title="Aproveitamento Global (últimos 2 meses)"
								typeColorTitle="purple"
								amountColumnLeft={4}
								amountColumnRight={8}
								icon="fas fa-globe-americas"
								information={(
									<Fragment>
										<PerformanceIndex name="Atendidos" valueBefore={this.state.totalMonthOneUtilizationPerfomaceIndex} valueAfter={this.state.totalMonthTwoUtilizationPerfomaceIndex} />
										<PerformanceIndex name="Ganhos" valueBefore={this.state.gainMonthOneUtilizationPerfomaceIndex} valueAfter={this.state.gainMonthTwoUtilizationPerfomaceIndex} />
										<PerformanceIndex name="Perdidos" valueBefore={this.state.lossMonthOneUtilizationPerfomaceIndex} valueAfter={this.state.lossMonthTwoUtilizationPerfomaceIndex} />
										<PerformanceIndex name="Recuperados" valueBefore={this.state.recoveredMonthOneUtilizationPerfomaceIndex} valueAfter={this.state.recoveredMonthTwoUtilizationPerfomaceIndex} />
									</Fragment>
								)}
							>
								<ResponsiveContainer width="100%" height={195}>
									<BarChart
										className="bar"
										width={300}
										height={195}
										data={this.state.historicTwoMonthsUtilization}
										margin={{
											top: 0,
											right: 0,
											left: 20,
											bottom: 15
										}}
										layout="vertical"
									>
										
										<CartesianGrid strokeDasharray="3 3" horizontal={false} />
										<XAxis type="number" />
										<YAxis type="category" dataKey="month"  />
										<Tooltip />
										<Bar maxBarSize={15} dataKey="totalUtilization" name="Atendidos" fill="var(--color-focus)" />
										<Bar maxBarSize={15} dataKey="gainUtilization" name="Ganhos" fill="var(--color-success)" />
										<Bar maxBarSize={15} dataKey="lossUtilization" name="Perdidos" fill="var(--color-error)" />
										<Bar maxBarSize={15} dataKey="recoveredUtilization" name="Recuperados" fill="var(--color-warning)" />
									</BarChart>
								</ResponsiveContainer>
							</CardInfoLeftChart>
						</div>
					</div>
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Aproveitamento Assessorias (${this.state.currentMonthName})`}
									typeColorTitle="success"
									amountColumnLeft={6}
									amountColumnRight={6}
									icon="fas fa-money-check-edit-alt"
									information={(
										<div>
											<div className="areaSubtitlesChartPieDashboard">
												<div className="areSubtitleChartPieDashboard">
													Negócios atendidos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationOtherServiceTotal}`} color="information" style={{"marginLeft": "19px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios ganhos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationOtherServiceGain}`} color="success" style={{"marginLeft": "36px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios perdidos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationOtherServiceLoss}`} color="error" style={{"marginLeft": "27px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios recuperados:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationOtherServiceRecovered}`} color="warning" style={{"marginLeft": "0px", "minWidth": "40px"}} />
												</div>
											</div>
										</div>
									)}
								>
									{
										this.state.chartUtilizationOtherServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Seu desempenho:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														<span className="strong">{this.state.messageProgressChartPieUtilizationOtherService[0]}</span>
														{this.state.messageProgressChartPieUtilizationOtherService[1]}
													</div>
												}
											labelOver={`${this.state.labelPercentUtilizationOtherServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieUtilizationOtherService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentUtilizationOtherServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentUtilizationOtherServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationOtherService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Aproveitamento Assessorias (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-money-check-edit-alt"
									information={(
										<Fragment>
											<PerformanceIndex name="Atendidos" valueBefore={this.state.totalMonthOneUtilizationOtherServicePerfomaceIndex} valueAfter={this.state.totalMonthTwoUtilizationOtherServicePerfomaceIndex} />
											<PerformanceIndex name="Ganhos" valueBefore={this.state.gainMonthOneUtilizationOtherServicePerfomaceIndex} valueAfter={this.state.gainMonthTwoUtilizationOtherServicePerfomaceIndex} />
											<PerformanceIndex name="Perdidos" valueBefore={this.state.lossMonthOneUtilizationOtherServicePerfomaceIndex} valueAfter={this.state.lossMonthTwoUtilizationOtherServicePerfomaceIndex} />
											<PerformanceIndex name="Recuperados" valueBefore={this.state.recoveredMonthOneUtilizationOtherServicePerfomaceIndex} valueAfter={this.state.recoveredMonthTwoUtilizationOtherServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									<ResponsiveContainer width="100%" height={195}>
										<BarChart
											className="bar"
											width={300}
											height={195}
											data={this.state.historicTwoMonthsUtilizationOtherService}
											margin={{
												top: 0,
												right: 0,
												left: 20,
												bottom: 15
											}}
											layout="vertical"
										>
											
											<CartesianGrid strokeDasharray="3 3" horizontal={false} />
											<XAxis type="number" />
											<YAxis type="category" dataKey="month"  />
											<Tooltip />
											<Bar maxBarSize={15} dataKey="totalUtilization" name="Atendidos" fill="var(--color-focus)" />
											<Bar maxBarSize={15} dataKey="gainUtilization" name="Ganhos" fill="var(--color-success)" />
											<Bar maxBarSize={15} dataKey="lossUtilization" name="Perdidos" fill="var(--color-error)" />
											<Bar maxBarSize={15} dataKey="recoveredUtilization" name="Recuperados" fill="var(--color-warning)" />
										</BarChart>
									</ResponsiveContainer>
								</CardInfoLeftChart>
							</div>
						</div>
					}					
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Aproveitamento do Limpa Nome (${this.state.currentMonthName})`}
									typeColorTitle="success"
									amountColumnLeft={6}
									amountColumnRight={6}
									icon="fas fa-tachometer-fast"
									information={(
										<div>
											<div className="areaSubtitlesChartPieDashboard">
												<div className="areSubtitleChartPieDashboard">
													Negócios atendidos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationNewServiceTotal}`} color="information" style={{"marginLeft": "19px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios ganhos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationNewServiceGain}`} color="success" style={{"marginLeft": "36px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios perdidos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationNewServiceLoss}`} color="error" style={{"marginLeft": "27px", "minWidth": "40px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Negócios recuperados:&nbsp;&nbsp;
													<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAmountUtilizationNewServiceRecovered}`} color="warning" style={{"marginLeft": "0px", "minWidth": "40px"}} />
												</div>
											</div>
										</div>
									)}
								>
									{
										this.state.chartUtilizationNewServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Seu desempenho:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														<span className="strong">{this.state.messageProgressChartPieUtilizationNewService[0]}</span>
														{this.state.messageProgressChartPieUtilizationNewService[1]}
													</div>
												}
											labelOver={`${this.state.labelPercentUtilizationNewServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieUtilizationNewService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentUtilizationNewServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentUtilizationNewServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieUtilizationNewService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Aproveitamento do Limpa Nome (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-tachometer-fast"
									information={(
										<Fragment>
											<PerformanceIndex name="Atendidos" valueBefore={this.state.totalMonthOneUtilizationNewServicePerfomaceIndex} valueAfter={this.state.totalMonthTwoUtilizationNewServicePerfomaceIndex} />
											<PerformanceIndex name="Ganhos" valueBefore={this.state.gainMonthOneUtilizationNewServicePerfomaceIndex} valueAfter={this.state.gainMonthTwoUtilizationNewServicePerfomaceIndex} />
											<PerformanceIndex name="Perdidos" valueBefore={this.state.lossMonthOneUtilizationNewServicePerfomaceIndex} valueAfter={this.state.lossMonthTwoUtilizationNewServicePerfomaceIndex} />
											<PerformanceIndex name="Recuperados" valueBefore={this.state.recoveredMonthOneUtilizationNewServicePerfomaceIndex} valueAfter={this.state.recoveredMonthTwoUtilizationNewServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									<ResponsiveContainer width="100%" height={195}>
										<BarChart
											className="bar"
											width={300}
											height={195}
											data={this.state.historicTwoMonthsUtilizationNewService}
											margin={{
												top: 0,
												right: 0,
												left: 20,
												bottom: 15
											}}
											layout="vertical"
										>
											
											<CartesianGrid strokeDasharray="3 3" horizontal={false} />
											<XAxis type="number" />
											<YAxis type="category" dataKey="month"  />
											<Tooltip />
											<Bar maxBarSize={15} dataKey="totalUtilization" name="Atendidos" fill="var(--color-focus)" />
											<Bar maxBarSize={15} dataKey="gainUtilization" name="Ganhos" fill="var(--color-success)" />
											<Bar maxBarSize={15} dataKey="lossUtilization" name="Perdidos" fill="var(--color-error)" />
											<Bar maxBarSize={15} dataKey="recoveredUtilization" name="Recuperados" fill="var(--color-warning)" />
										</BarChart>
									</ResponsiveContainer>
								</CardInfoLeftChart>
							</div>
						</div>
					}
					<div className="row no-gutters lineBodyDashboard">
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
							<CardInfoLeftChart
								title={`Recebimento Global (${this.state.currentMonthName})`}
								typeColorTitle="success"
								icon="fas fa-globe-americas"
								information={(
									<div>
										{
											this.state.isSpecialistOrManagerUserLogged &&
											<Fragment>
												<SubtitleProgressLine
													classaditional="subtitleProgressLineDashboard"
													label="Mínimo para qualificação:"
													color="warning"
													progressOne={this.state.subtitleRulePercentMinimumReceiving}
												/>
												<SubtitleProgressLine
													classaditional="subtitleProgressLineDashboard" 
													label="Meta:"
													color="success"
													progressOne={this.state.subtitleRulePercentMaximumReceiving}
												/>
											</Fragment>
										}
										{
											!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
											<div className="areaSubtitlesChartPieDashboard">
												<div className="areSubtitleChartPieDashboard">
													Total de vendas:&nbsp;&nbsp;
													<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingSold}`} color="information" style={{"marginLeft": "18px"}} />
												</div>
												<div className="areSubtitleChartPieDashboard">
													Total de recebidos:&nbsp;&nbsp;
													<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingReceived}`} color="success"/>
												</div>
											</div>
										}
									</div>
								)}
							>
								{
									this.state.chartReceivingByUserLoaded &&
									<CardChartPie
										title={
												<div className="labelsCardChartPieDashboard">
													Seu desempenho:
												</div>
											}
										subtitle={
												<div className="labelsCardChartPieDashboard italic">
													<span className="strong">{this.state.messageProgressChartPieReceiving[0]}</span>
													{this.state.messageProgressChartPieReceiving[1]}
												</div>
											}
										labelOver={`${this.state.labelPercentReceivingByUser}%`}
										colorLabelOver={`${this.state.colorProgressChartPieReceiving}`}
									>								
										<PieChart 
											width={170} 
											height={170} 
											onMouseEnter={this.onPieEnter}
											style={{transform: "rotate(-90deg)"}}
										>
											<Pie
												data={this.state.percentReceivingBusinessByUser}
												cx="50%"
												cy="50%"
												innerRadius={70}
												outerRadius={80}
												fill="#8884d8"
												paddingAngle={0}
												dataKey="value"
												label={false}
											>
												{this.state.percentReceivingBusinessByUser.map((entry, index) => (
													<Cell 
														key={`cell-${index}`} 
														fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieReceiving})` : "transparent"} 
														strokeWidth={0}
													/>
												))}
											</Pie>
										</PieChart>
									</CardChartPie>
								}
							</CardInfoLeftChart>
						</div>
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
							<CardInfoLeftChart
								classaditional="barsChartCardInfoLeftDashboard"
								title="Recebimento Global (últimos 2 meses)"
								typeColorTitle="purple"
								amountColumnLeft={4}
								amountColumnRight={8}
								icon="fas fa-globe-americas"
								information={(
									<Fragment>
										<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneReceivingPerformaceIndex} valueAfter={this.state.soldMonthTwoReceivingPerformaceIndex} />
										<PerformanceIndex name="Recebidos" valueBefore={this.state.receivedMonthOneReceivingPerfomaceIndex} valueAfter={this.state.receivedMonthTwoReceivingPerfomaceIndex} />
									</Fragment>
								)}
							>
								{
									!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<ResponsiveContainer width="100%" height={195}>
										<BarChart
											className="bar"
											width={300}
											height={195}
											data={this.state.historicTwoMonthsReceiving}
											margin={{
												top: 0,
												right: 0,
												left: 20,
												bottom: 20
											}}
											layout="vertical"
										>
											
											<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
											<XAxis type="number" />
											<YAxis type="category" dataKey="month" />
											<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
											<Bar maxBarSize={15} dataKey="soldReceiving" name="Total de vendas" fill="var(--color-focus)" />
											<Bar maxBarSize={15} dataKey="receivedReceiving" name="Total de recebidos" fill="var(--color-success)" />
										</BarChart>
									</ResponsiveContainer>
								}
							</CardInfoLeftChart>
						</div>
					</div>
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Recebimento Assessorias (${this.state.currentMonthName})`}
									typeColorTitle="success"
									icon="fas fa-money-check-edit-alt"
									information={(
										<div>
											{
												!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
												<div className="areaSubtitlesChartPieDashboard">
													<div className="areSubtitleChartPieDashboard">
														Total de vendas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingOtherServiceSold}`} color="information" style={{"marginLeft": "18px"}} />
													</div>
													<div className="areSubtitleChartPieDashboard">
														Total de recebidos:&nbsp;&nbsp;
														<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingOtherServiceReceived}`} color="success"/>
													</div>
												</div>
											}
										</div>
									)}
								>
									{
										this.state.chartReceivingOtherServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Seu desempenho:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														<span className="strong">{this.state.messageProgressChartPieReceivingOtherService[0]}</span>
														{this.state.messageProgressChartPieReceivingOtherService[1]}
													</div>
												}
											labelOver={`${this.state.labelPercentReceivingOtherServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieReceivingOtherService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentReceivingOtherServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentReceivingOtherServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieReceivingOtherService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Recebimento Assessorias (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-money-check-edit-alt"
									information={(
										<Fragment>
											<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneReceivingOtherServicePerformaceIndex} valueAfter={this.state.soldMonthTwoReceivingOtherServicePerformaceIndex} />
											<PerformanceIndex name="Recebidos" valueBefore={this.state.receivedMonthOneReceivingOtherServicePerfomaceIndex} valueAfter={this.state.receivedMonthTwoReceivingOtherServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									{
										!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
										<ResponsiveContainer width="100%" height={195}>
											<BarChart
												className="bar"
												width={300}
												height={195}
												data={this.state.historicTwoMonthsReceivingOtherService}
												margin={{
													top: 0,
													right: 0,
													left: 20,
													bottom: 20
												}}
												layout="vertical"
											>
												
												<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
												<XAxis type="number" />
												<YAxis type="category" dataKey="month" />
												<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
												<Bar maxBarSize={15} dataKey="soldReceiving" name="Total de vendas" fill="var(--color-focus)" />
												<Bar maxBarSize={15} dataKey="receivedReceiving" name="Total de recebidos" fill="var(--color-success)" />
											</BarChart>
										</ResponsiveContainer>
									}
								</CardInfoLeftChart>
							</div>
						</div>
					}					
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Recebimento do Limpa Nome (${this.state.currentMonthName})`}
									typeColorTitle="success"
									icon="fas fa-tachometer-fast"
									information={(
										<div>
											{
												!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
												<div className="areaSubtitlesChartPieDashboard">
													<div className="areSubtitleChartPieDashboard">
														Total de vendas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingNewServiceSold}`} color="information" style={{"marginLeft": "18px"}} />
													</div>
													<div className="areSubtitleChartPieDashboard">
														Total de recebidos:&nbsp;&nbsp;
														<StylizedMoneyOrNumber value={`${this.state.labelAmountReceivingNewServiceReceived}`} color="success"/>
													</div>
												</div>
											}
										</div>
									)}
								>
									{
										this.state.chartReceivingNewServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Seu desempenho:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														<span className="strong">{this.state.messageProgressChartPieReceivingNewService[0]}</span>
														{this.state.messageProgressChartPieReceivingNewService[1]}
													</div>
												}
											labelOver={`${this.state.labelPercentReceivingNewServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieReceivingNewService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentReceivingNewServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentReceivingNewServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieReceivingNewService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Recebimento do Limpa Nome (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-tachometer-fast"
									information={(
										<Fragment>
											<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneReceivingNewServicePerformaceIndex} valueAfter={this.state.soldMonthTwoReceivingNewServicePerformaceIndex} />
											<PerformanceIndex name="Recebidos" valueBefore={this.state.receivedMonthOneReceivingNewServicePerfomaceIndex} valueAfter={this.state.receivedMonthTwoReceivingNewServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									{
										!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
										<ResponsiveContainer width="100%" height={195}>
											<BarChart
												className="bar"
												width={300}
												height={195}
												data={this.state.historicTwoMonthsReceivingNewService}
												margin={{
													top: 0,
													right: 0,
													left: 20,
													bottom: 20
												}}
												layout="vertical"
											>
												
												<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
												<XAxis type="number" />
												<YAxis type="category" dataKey="month" />
												<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
												<Bar maxBarSize={15} dataKey="soldReceiving" name="Total de vendas" fill="var(--color-focus)" />
												<Bar maxBarSize={15} dataKey="receivedReceiving" name="Total de recebidos" fill="var(--color-success)" />
											</BarChart>
										</ResponsiveContainer>
									}
								</CardInfoLeftChart>
							</div>
						</div>
					}
					<div className="row no-gutters lineBodyDashboard">
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
							<CardInfoLeftChart
								title={`Perdas Global (${this.state.currentMonthName})`}
								typeColorTitle="success"
								amountColumnLeft={6}
								amountColumnRight={6}
								icon="fas fa-globe-americas"
								information={(
									<div>
										{
											this.state.isSpecialistOrManagerUserLogged &&
											<Fragment>
												{/* <SubtitleProgressLine 
													label="Mínimo para qualificação:"
													color="warning"
													progress={this.state.subtitleRulePercentMinimumLoss}
												/>
												<SubtitleProgressLine 
													label="Meta:"
													color="success"
													progress={this.state.subtitleRulePercentMaximumLoss}
												/> */}
											</Fragment>
										}
										<div className="areaSubtitlesChartPieDashboard">
											{
												!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
												<Fragment>
													<div className="areSubtitleChartPieDashboard">
														Total de vendas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossSold}`} color="success" style={{"marginLeft": "11px"}} />
													</div>
													<div className="areSubtitleChartPieDashboard">
														Total de perdas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossLoss}`} color="error" style={{"marginLeft": "13px"}} />
													</div>
												</Fragment>
											}
											<div className="areSubtitleChartPieDashboard">
												Média de ganhos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossGain}%`} color="success" style={{"marginLeft": "0"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Média de perdas:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossLoss}%`} color="error" style={{"marginLeft": "3px"}} />
											</div>
										</div>
									</div>
								)}
							>
								{
									this.state.chartLossByUserLoaded &&
									<CardChartPie
										title={
												<div className="labelsCardChartPieDashboard">
													Suas perdas:
												</div>
											}
										subtitle={
												<div className="labelsCardChartPieDashboard italic">
													{/* <span className="strong">{this.state.messageProgressChartPieLoss[0]}</span>
													{this.state.messageProgressChartPieLoss[1]} */}
												</div>
											}
										labelOver={`${this.state.labelPercentLossByUser}%`}
										colorLabelOver={`${this.state.colorProgressChartPieLoss}`}
									>								
										<PieChart 
											width={170} 
											height={170} 
											onMouseEnter={this.onPieEnter}
											style={{transform: "rotate(-90deg)"}}
										>
											<Pie
												data={this.state.percentLossBusinessByUser}
												cx="50%"
												cy="50%"
												innerRadius={70}
												outerRadius={80}
												fill="#8884d8"
												paddingAngle={0}
												dataKey="value"
												label={false}
											>
												{this.state.percentLossBusinessByUser.map((entry, index) => (
													<Cell 
														key={`cell-${index}`} 
														fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieLoss})` : "transparent"} 
														strokeWidth={0}
													/>
												))}
											</Pie>
										</PieChart>
									</CardChartPie>
								}
							</CardInfoLeftChart>
						</div>
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
							<CardInfoLeftChart
								classaditional="barsChartCardInfoLeftDashboard"
								title="Perdas Global (últimos 2 meses)"
								typeColorTitle="purple"
								amountColumnLeft={4}
								amountColumnRight={8}
								icon="fas fa-globe-americas"
								information={(
									<Fragment>
										<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneLossPerformaceIndex} valueAfter={this.state.soldMonthTwoLossPerformaceIndex} />
										<PerformanceIndex name="Perdas" valueBefore={this.state.lossMonthOneLossPerfomaceIndex} valueAfter={this.state.lossMonthTwoLossPerfomaceIndex} />
									</Fragment>
								)}
							>
								{
									!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
									<ResponsiveContainer width="100%" height={195}>
										<BarChart
											className="bar"
											width={300}
											height={195}
											data={this.state.historicTwoMonthsLoss}
											margin={{
												top: 0,
												right: 0,
												left: 20,
												bottom: 20
											}}
											layout="vertical"
										>
											
											<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
											<XAxis type="number" />
											<YAxis type="category" dataKey="month" />
											<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
											<Bar maxBarSize={15} dataKey="soldLoss" name="Total de vendas" fill="var(--color-success)" />
											<Bar maxBarSize={15} dataKey="lostLoss" name="Total de perdas" fill="var(--color-error)" />
										</BarChart>
									</ResponsiveContainer>
								}
							</CardInfoLeftChart>
						</div>
					</div>
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Perdas Assessorias (${this.state.currentMonthName})`}
									typeColorTitle="success"
									amountColumnLeft={6}
									amountColumnRight={6}
									icon="fas fa-money-check-edit-alt"
									information={(
										<div className="areaSubtitlesChartPieDashboard">
											{
												!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
												<Fragment>
													<div className="areSubtitleChartPieDashboard">
														Total de vendas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossOtherServiceSold}`} color="success" style={{"marginLeft": "11px"}} />
													</div>
													<div className="areSubtitleChartPieDashboard">
														Total de perdas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossOtherServiceLoss}`} color="error" style={{"marginLeft": "13px"}} />
													</div>
												</Fragment>
											}
											<div className="areSubtitleChartPieDashboard">
												Média de ganhos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossOtherServiceGain}%`} color="success" style={{"marginLeft": "0"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Média de perdas:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossOtherServiceLoss}%`} color="error" style={{"marginLeft": "3px"}} />
											</div>
										</div>
									)}
								>
									{
										this.state.chartLossOtherServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Suas perdas:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														{/* <span className="strong">{this.state.messageProgressChartPieLoss[0]}</span>
														{this.state.messageProgressChartPieLoss[1]} */}
													</div>
												}
											labelOver={`${this.state.labelPercentLossOtherServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieLossOtherService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentLossOtherServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentLossOtherServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieLossOtherService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Perdas Assessorias (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-money-check-edit-alt"
									information={(
										<Fragment>
											<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneLossOtherServicePerformaceIndex} valueAfter={this.state.soldMonthTwoLossOtherServicePerformaceIndex} />
											<PerformanceIndex name="Perdas" valueBefore={this.state.lossMonthOneLossOtherServicePerfomaceIndex} valueAfter={this.state.lossMonthTwoLossOtherServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									{
										!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
										<ResponsiveContainer width="100%" height={195}>
											<BarChart
												className="bar"
												width={300}
												height={195}
												data={this.state.historicTwoMonthsLossOtherService}
												margin={{
													top: 0,
													right: 0,
													left: 20,
													bottom: 20
												}}
												layout="vertical"
											>
												
												<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
												<XAxis type="number" />
												<YAxis type="category" dataKey="month" />
												<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
												<Bar maxBarSize={15} dataKey="soldLoss" name="Total de vendas" fill="var(--color-success)" />
												<Bar maxBarSize={15} dataKey="lostLoss" name="Total de perdas" fill="var(--color-error)" />
											</BarChart>
										</ResponsiveContainer>
									}
								</CardInfoLeftChart>
							</div>
						</div>
					}
					{
						this.state.showContentByNewService && this.state.showContentByConsultancy &&
						<div className="row no-gutters lineBodyDashboard">
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pl-md-2">
								<CardInfoLeftChart
									title={`Perdas do Limpa Nome (${this.state.currentMonthName})`}
									typeColorTitle="success"
									amountColumnLeft={6}
									amountColumnRight={6}
									icon="fas fa-tachometer-fast"
									information={(
										<div className="areaSubtitlesChartPieDashboard">
											{
												!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
												<Fragment>
													<div className="areSubtitleChartPieDashboard">
														Total de vendas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossNewServiceSold}`} color="success" style={{"marginLeft": "11px"}} />
													</div>
													<div className="areSubtitleChartPieDashboard">
														Total de perdas:&nbsp;&nbsp;
														<StylizedMoneyOrNumber symbol="R$" value={`${this.state.labelAmountLossNewServiceLoss}`} color="error" style={{"marginLeft": "13px"}} />
													</div>
												</Fragment>
											}
											<div className="areSubtitleChartPieDashboard">
												Média de ganhos:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossNewServiceGain}%`} color="success" style={{"marginLeft": "0"}} />
											</div>
											<div className="areSubtitleChartPieDashboard">
												Média de perdas:&nbsp;&nbsp;
												<StylizedMoneyOrNumber symbol=" " value={`${this.state.labelAverageLossNewServiceLoss}%`} color="error" style={{"marginLeft": "3px"}} />
											</div>
										</div>
									)}
								>
									{
										this.state.chartLossNewServiceByUserLoaded &&
										<CardChartPie
											title={
													<div className="labelsCardChartPieDashboard">
														Suas perdas:
													</div>
												}
											subtitle={
													<div className="labelsCardChartPieDashboard italic">
														{/* <span className="strong">{this.state.messageProgressChartPieLoss[0]}</span>
														{this.state.messageProgressChartPieLoss[1]} */}
													</div>
												}
											labelOver={`${this.state.labelPercentLossNewServiceByUser}%`}
											colorLabelOver={`${this.state.colorProgressChartPieLossNewService}`}
										>								
											<PieChart 
												width={170} 
												height={170} 
												onMouseEnter={this.onPieEnter}
												style={{transform: "rotate(-90deg)"}}
											>
												<Pie
													data={this.state.percentLossNewServiceBusinessByUser}
													cx="50%"
													cy="50%"
													innerRadius={70}
													outerRadius={80}
													fill="#8884d8"
													paddingAngle={0}
													dataKey="value"
													label={false}
												>
													{this.state.percentLossNewServiceBusinessByUser.map((entry, index) => (
														<Cell 
															key={`cell-${index}`} 
															fill={COLORS[index] !== "transparent" ? `var(--color-${this.state.colorProgressChartPieLossNewService})` : "transparent"} 
															strokeWidth={0}
														/>
													))}
												</Pie>
											</PieChart>
										</CardChartPie>
									}
								</CardInfoLeftChart>
							</div>
							<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard pr-md-2">
								<CardInfoLeftChart
									classaditional="barsChartCardInfoLeftDashboard"
									title="Perdas do Limpa Nome (2 meses)"
									typeColorTitle="purple"
									amountColumnLeft={4}
									amountColumnRight={8}
									icon="fas fa-tachometer-fast"
									information={(
										<Fragment>
											<PerformanceIndex name="Vendas" valueBefore={this.state.soldMonthOneLossNewServicePerformaceIndex} valueAfter={this.state.soldMonthTwoLossNewServicePerformaceIndex} />
											<PerformanceIndex name="Perdas" valueBefore={this.state.lossMonthOneLossNewServicePerfomaceIndex} valueAfter={this.state.lossMonthTwoLossNewServicePerfomaceIndex} />
										</Fragment>
									)}
								>
									{
										!ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP) &&
										<ResponsiveContainer width="100%" height={195}>
											<BarChart
												className="bar"
												width={300}
												height={195}
												data={this.state.historicTwoMonthsLossNewService}
												margin={{
													top: 0,
													right: 0,
													left: 20,
													bottom: 20
												}}
												layout="vertical"
											>
												
												<CartesianGrid strokeDasharray="3 3" horizontal={false}/>
												<XAxis type="number" />
												<YAxis type="category" dataKey="month" />
												<Tooltip formatter={(value, name, props) => formatedMoneyBRAFromFloat(value)}/>
												<Bar maxBarSize={15} dataKey="soldLoss" name="Total de vendas" fill="var(--color-success)" />
												<Bar maxBarSize={15} dataKey="lostLoss" name="Total de perdas" fill="var(--color-error)" />
											</BarChart>
										</ResponsiveContainer>
									}
								</CardInfoLeftChart>
							</div>
						</div>
					}
					{/*  ends content in charts */}

					{/* start content for managers */}
					{
					  	this.state.userLoggedManager && this.state.loadingPerformaceCompilation &&
						<Fragment>
							<div className="row no-gutters lineHeaderDashboard d-none d-md-flex">
								<Box classaditional="boxHeaderDashboard">
									<h4>Desempenho da Unidades</h4>
								</Box>
							</div>
							<div className="row no-gutters lineBodyDashboard d-none d-md-flex">
								<Box classaditional="boxBodyDashboard">
									<Loading loading={this.state.loadingPerformaceCompilation} />
								</Box>
							</div>
						</Fragment>		
					}
					{
						!this.state.loadingPerformaceCompilation &&
						this.state.userLoggedManager && 
						this.state.allPerformacesGlobalSalesAndReceivedByUnitCompany.map((p, i) => {
							let isGroupTraining = ID_PERMISSIONS_GROUP_TRAINING.includes(this.props.user.PER_ID_PERMISSIONS_GROUP);

							return (
								<Fragment
									key={`${i}`}
								>
									{
										(p.consultanciesService?.allSpecialists || p.newService?.allSpecialists) &&
										<Fragment>
											<div className="row no-gutters lineHeaderDashboard d-none d-md-flex">
												<Box classaditional="boxHeaderDashboard">
													<h4>Desempenho da Unidade {p.nameUnitCompany}</h4>
												</Box>
											</div>
											<div className="row no-gutters lineBodyDashboard d-none d-md-flex">
												<Box classaditional="boxBodyDashboard">
													<Loading loading={this.state.loadingPerformaceCompilation} />
													{
														p.consultanciesService?.allSpecialists &&
														<div className="tableDashboard">
															<span className="flagTypeServiceDashboard">Assessorias</span>
															<div className="row no-gutters lineTitleHeaderDashboard">
																<div className="col-3 columnTitleHeaderDashboard left">
																	<span>Especialista</span>
																	<span className="help">Especialista</span>
																</div>
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Atendeu</span>
																	<span className="help">Atendeu</span>
																</div>
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Ganhou</span>
																	<span className="help">Ganhou</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Perdeu</span>
																	<span className="help">Perdeu</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Aproveitamento (%)</span>
																	<span className="help">Aproveitamento (%)</span>
																</div>
																{
																	!isGroupTraining &&
																	<Fragment>
																		<div className="col-1 columnTitleHeaderDashboard">
																			<span>Vendeu (R$)</span>
																			<span className="help">Vendeu (R$)</span>
																		</div>
																		<div className="col-1 columnTitleHeaderDashboard">
																			<span>Recebeu (R$)</span>
																			<span className="help">Recebeu (R$)</span>
																		</div>
																	</Fragment>
																}
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Recebimento (%)</span>
																	<span className="help">Recebimento (%)</span>
																</div>
																{
																	!isGroupTraining &&
																	<div className="col-1 columnTitleHeaderDashboard">
																		<span>Perdeu (R$)</span>
																		<span className="help">Perdeu (R$)</span>
																	</div>
																}
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Perdeu (%)</span>
																	<span className="help">Perdeu (%)</span>
																</div>
															</div>
															<div>
																{
																	p.consultanciesService.allSpecialists.map((e, j) => {
																		return (
																			<div key={`${j}`} className={`row no-gutters lineBodyDashboard`}>
																				<div className="col-3 columnBodyDashboard left">
																					<span>{e.name}</span>
																				</div>
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.amountUtilizationTotal}</span>
																				</div>
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.amountUtilizationGain}</span>
																				</div>
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.amountUtilizationLoss}</span>
																				</div>
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.percentUtilization}%</span>
																				</div>
																				{
																					!isGroupTraining &&
																					<Fragment>
																						<div className="col-1 columnBodyDashboard right">
																							<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountReceivingSold))}</span>
																						</div>
																						<div className="col-1 columnBodyDashboard right">
																							<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountRecevingReceived))}</span>
																						</div>
																					</Fragment>
																				}
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.percentReceiving}%</span>
																				</div>
																				{
																					!isGroupTraining &&
																					<div className="col-1 columnBodyDashboard right">
																						<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountReceivingLoss))}</span>
																					</div>
																				}
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.percentLoss}%</span>
																				</div>
																			</div>
																		)
																	})
																}
															</div>
															<div className="row no-gutters lineTitleFooterDashboard">
																<div className="col-3 columnTitleFooterDashboard left">
																	<span>Total</span>
																</div>
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.consultanciesService.final.amountUtilizationTotalFooter}</span>
																</div>
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.consultanciesService.final.amountUtilizationGainFooter}</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.consultanciesService.final.amountUtilizationLossFooter}</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.consultanciesService.final.percentUtilizationFooter}%</span>
																</div>
																{
																	!isGroupTraining &&
																	<Fragment>
																		<div className="col-1 columnTitleFooterDashboard right">
																			<span>{formatedMoneyBRAFromFloat(parseFloat(p.consultanciesService.final.amountReceivingSoldFooter))}</span>
																		</div>
																		<div className="col-1 columnTitleFooterDashboard right">
																			<span>{formatedMoneyBRAFromFloat(parseFloat(p.consultanciesService.final.amountRecevingReceivedFooter))}</span>
																		</div>
																	</Fragment>
																}
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.consultanciesService.final.percentReceivingFooter}%</span>
																</div>
																{
																	!isGroupTraining &&
																	<div className="col-1 columnTitleFooterDashboard right">
																		<span>{formatedMoneyBRAFromFloat(parseFloat(p.consultanciesService.final.amountReceivingLossFooter))}</span>
																	</div>
																}
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.consultanciesService.final.percentLossFooter}%</span>
																</div>
															</div>
														</div>
													}
													{
														p.newService?.allSpecialists &&
														<div className="tableDashboard">
															<span className="flagTypeServiceDashboard newServiceDashboard">Limpa Nome</span>
															<div className="row no-gutters lineTitleHeaderDashboard">
																<div className="col-3 columnTitleHeaderDashboard left">
																	<span>Especialista</span>
																	<span className="help">Especialista</span>
																</div>
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Atendeu</span>
																	<span className="help">Atendeu</span>
																</div>
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Ganhou</span>
																	<span className="help">Ganhou</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Perdeu</span>
																	<span className="help">Perdeu</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Aproveitamento (%)</span>
																	<span className="help">Aproveitamento (%)</span>
																</div>
																{
																	!isGroupTraining &&
																	<Fragment>
																		<div className="col-1 columnTitleHeaderDashboard">
																			<span>Vendeu (R$)</span>
																			<span className="help">Vendeu (R$)</span>
																		</div>
																		<div className="col-1 columnTitleHeaderDashboard">
																			<span>Recebeu (R$)</span>
																			<span className="help">Recebeu (R$)</span>
																		</div>
																	</Fragment>
																}
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleHeaderDashboard`}>
																	<span>Recebimento (%)</span>
																	<span className="help">Recebimento (%)</span>
																</div>
																{
																	!isGroupTraining &&
																	<div className="col-1 columnTitleHeaderDashboard">
																		<span>Perdeu (R$)</span>
																		<span className="help">Perdeu (R$)</span>
																	</div>
																}
																<div className="col-1 columnTitleHeaderDashboard">
																	<span>Perdeu (%)</span>
																	<span className="help">Perdeu (%)</span>
																</div>
															</div>
															<div>
																{
																	p.newService.allSpecialists.map((e, j) => {
																		return (
																			<div key={`${j}`} className={`row no-gutters lineBodyDashboard`}>
																				<div className="col-3 columnBodyDashboard left">
																					<span>{e.name}</span>
																				</div>
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.amountUtilizationTotal}</span>
																				</div>
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.amountUtilizationGain}</span>
																				</div>
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.amountUtilizationLoss}</span>
																				</div>
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.percentUtilization}%</span>
																				</div>
																				{
																					!isGroupTraining &&
																					<Fragment>
																						<div className="col-1 columnBodyDashboard right">
																							<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountReceivingSold))}</span>
																						</div>
																						<div className="col-1 columnBodyDashboard right">
																							<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountRecevingReceived))}</span>
																						</div>
																					</Fragment>
																				}
																				<div className={`${isGroupTraining ? "col-2" : "col-1"} columnBodyDashboard`}>
																					<span>{e.percentReceiving}%</span>
																				</div>
																				{
																					!isGroupTraining &&
																					<div className="col-1 columnBodyDashboard right">
																						<span>{formatedMoneyBRAFromFloat(parseFloat(e.amountReceivingLoss))}</span>
																					</div>
																				}
																				<div className="col-1 columnBodyDashboard">
																					<span>{e.percentLoss}%</span>
																				</div>
																			</div>
																		)
																	})
																}
															</div>
															<div className="row no-gutters lineTitleFooterDashboard">
																<div className="col-3 columnTitleFooterDashboard left">
																	<span>Total</span>
																</div>
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.newService.final.amountUtilizationTotalFooter}</span>
																</div>
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.newService.final.amountUtilizationGainFooter}</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.newService.final.amountUtilizationLossFooter}</span>
																</div>
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.newService.final.percentUtilizationFooter}%</span>
																</div>
																{
																	!isGroupTraining &&
																	<Fragment>
																		<div className="col-1 columnTitleFooterDashboard right">
																			<span>{formatedMoneyBRAFromFloat(parseFloat(p.newService.final.amountReceivingSoldFooter))}</span>
																		</div>
																		<div className="col-1 columnTitleFooterDashboard right">
																			<span>{formatedMoneyBRAFromFloat(parseFloat(p.newService.final.amountRecevingReceivedFooter))}</span>
																		</div>
																	</Fragment>
																}
																<div className={`${isGroupTraining ? "col-2" : "col-1"} columnTitleFooterDashboard`}>
																	<span>{p.newService.final.percentReceivingFooter}%</span>
																</div>
																{
																	!isGroupTraining &&
																	<div className="col-1 columnTitleFooterDashboard right">
																		<span>{formatedMoneyBRAFromFloat(parseFloat(p.newService.final.amountReceivingLossFooter))}</span>
																	</div>
																}
																<div className="col-1 columnTitleFooterDashboard">
																	<span>{p.newService.final.percentLossFooter}%</span>
																</div>
															</div>
														</div>
													}
												</Box>
											</div>
										</Fragment>
									}	
								</Fragment>								
							)
						})
					}
					{/* ends content for managers */}

					<div className="cardChartBodyDashboard">
					</div>
				</div>
			</div>
		)							
	}
}

function mapStateToProps (state)
{
    const permissions = state.permissions.dashboard;
	const permissionMonths = state.permissions.dashboardMonths;
	const {user} = state.auth;
	const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
		hasPermissionsLoaded,
        permissions,
		permissionMonths,
		user
    }
}

export default withRouter(connect(mapStateToProps)(Dashboard));