import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyCi2czDgEmp-ZnSUMCQ4CNmXrbto5EI7jg",
    authDomain: "crm-whatsapp-8ccd5.firebaseapp.com",
    databaseURL: "https://crm-whatsapp-8ccd5-default-rtdb.firebaseio.com",
    projectId: "crm-whatsapp-8ccd5",
    storageBucket: "crm-whatsapp-8ccd5.appspot.com",
    messagingSenderId: "448731763457",
    appId: "1:448731763457:web:8b6be7d625dbe8e992c100"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export {database, ref, onValue, getAuth, signInWithCustomToken};