import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditOwners.css";
import { getAllUsersOwners } from "../../store/actions/owners";
import { dateDiff } from "../../auxiliary/generalFunctions";
import { getWindowDimensions, sortInAlphabeticalOrder } from "../../auxiliary/generalFunctions";
import { getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import { setModalMessage, showModalMoreActions } from "../../store/actions/general";
import { withRouter } from "react-router-dom";
import Check from "./Check";
import Select from "./Select";
import PermissionsGroupCollectionsController from "../../controllers/PermissionsGroupCollectionsController";
import UserController from "../../controllers/UserController";
import SelectMulti from "./SelectMulti";
import Button from "./Button";
import { 
    ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT,
    ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT_AND_SPECIALIST,
    ID_PERMISSIONS_GROUP_SPECIALIST 
} from "../../core/constants";
import ServiceQueuePersonsController from "../../controllers/ServiceQueuePersonsController";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    optionsCompanyUnitOwner: [],
    optionsPermissionGroupOwner: [],
    optionChosenPermissionGroupOwner: {},
    optionsChosenCompanyUnitOwner: [],
    optionsWorkplace: [
        {
            name: "Unidade",
            value: 1,
            checked: false
        },
        {
            name: "Home Office",
            value: 2,
            checked: false
        }
    ],
    optionChosenWorkplace: {},

    optionsUsersERP: [
        {
            name: "",
            value: 0,
            checked: false
        }
    ],

    optionChosenWorkShifts: {},
    choosenUserERP: {
        name: "",
        value: "",
    },

    // start content about supervisors
    optionsSupervisor: [],
    optionChosenSupervisor: null,
    // ends content about supervisors
}

const permissionsGroupCollectionsController = new PermissionsGroupCollectionsController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();
const serviceQueuePersonsController = new ServiceQueuePersonsController();
const userController = new UserController();

class TableEditOwners extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        await this.getAllSupervisors();
        await this.getUsersERP();
        await this.setState({ headerTable });
        this.getCompanyUnit();
        await this.getPermissionsGroup();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT LEADS prevProps ", prevProps);
        // console.log("TABLE EDIT LEADS this.props ", this.props);
    }

    getUsersERP = async () =>
    {
        let response = await userController.getUsersERPTemporaryWebhook();
        if (response.status)
        {
            let users = response.data.data;
            let optionsUsersERP = [];
            users.forEach(u => {
                optionsUsersERP.push({
                    name: `${getFullNameFisrtCharsUppercase(u.nome)} - [${u.grupo}] [${u.idusuario}]`,
                    value: parseInt(u.idusuario),
                    checked: false
                });
            });
            optionsUsersERP = sortInAlphabeticalOrder(optionsUsersERP);
            await this.setState({optionsUsersERP});
        }
        else
        {
            this.message("error", "Erro ao obter os usuários do ERP!");
        }
    }

    getCompanyUnit = async () => 
    {
        const result = this.props.user.unit_companies;
        let units = [];
        for (const unit of result) 
        {
            units.push({
                id: unit.UNT_ID,
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: false
            });
        }
        this.setState({ optionsCompanyUnitOwner: units });
    }

    getPermissionsGroup = async () => {
        let allCollectionsPermissionsGroup = [];

        const result = await permissionsGroupCollectionsController.getByPermissionGroup(this.props.user.PER_ID_PERMISSIONS_GROUP);

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME_CONTROLLED,
                    value: permission.PEB_ID_PERMISSIONS_GROUP_CONTROLLED,
                    checked: false
                });
            }
        }
                
        this.setState({optionsPermissionGroupOwner: allCollectionsPermissionsGroup});
    }

    getAllSupervisors = async () =>
    {
        let optionsSupervisor = [];

        const user = await userController.getById(this.props.user.PER_ID);
        const userUnits = user.data.unit_companies.map(u => u.UNT_ID);
        const resultProspectionOwner = await prospectionTeamHierarchyController.get();

        if (!resultProspectionOwner.status) 
        {
            return;
        }

        for (const coordinator of resultProspectionOwner.data.data.coordinators) 
        {
            for (const supervisor of coordinator.supervisors) 
            {
                let allowedSupervisor = false;
                for (const unit of supervisor.units)
                {
                    if (!allowedSupervisor)
                    {
                        allowedSupervisor = userUnits.some(u => u === unit.UNT_ID);
                    }
                }
                                
                if (allowedSupervisor)
                {
                    optionsSupervisor.push({
                        name: supervisor.name,
                        value: supervisor.id,
                        checked: false
                    });
                }
            }
        }

        await this.setState({optionsSupervisor});
    }

    getCompaniesChecked = (userCompanies) => {

        let all = [];

        for (const unit of this.state.optionsCompanyUnitOwner)
        {
            all.push({
                ...unit,
                checked: userCompanies.some((u) => u.id === unit.id)
            });
        }

        return all;

    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    createAt = (date) =>
    {
        let diff = dateDiff.inDays(date, new Date());
        if (diff <= 0)
        {
            diff = " hoje ";
        }
        else
        {
            diff = (diff === 1) ? diff+" dia " : diff+" dias ";
        }
        return diff;
    }

    getCompaniesCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }

        return display.toString();
    }

    addPersonToQueue = async (idPerson, unitCompanies) =>
    {

        const ownerCompanyUnit = unitCompanies.filter(u => u.checked);
        
        if (ownerCompanyUnit.length !== 1) {
            return this.message("error", "O usuário precisa estar associado a apenas uma unidade!");
        }

        const result = await serviceQueuePersonsController.create({
            SEP_ID_PERSON: idPerson,
            SEP_ID_COMPANY_UNIT: ownerCompanyUnit[0].value,
            SEP_FROZEN: 2
        });

        if (result.status) 
        {
            this.message("success", "Usuário adicionado à fila!")  
        }
        else
        {
            this.message("error", result.message);    
        }

        this.props.onIgnoreSave()

    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="tableEditOwners">
                <label className="helpTableEditOwners" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditOwners" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditOwners">
                                <Check
                                    classaditional={`buttonCheckTableEditOwners ${(this.props.allOwnersChecked) ? "active" : ""}`}
                                    checked={this.props.allOwnersChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditOwners: true});
                                        await this.props.onToggleCheckedAllUsersOwners(!this.props.allOwnersChecked);

                                    }}
                                />
                            </td>
                            <td
                                className="minimus60"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "ID Usuário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                ID
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Usuário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Usuário
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "E-mail")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                E-mail
                            </td>
                            <td
                                className="minimus54"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Senha")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Senha
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade(s)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade(s)
                            </td>
                            { 
                                this.state.optionsPermissionGroupOwner.map(p => p.value).includes(ID_PERMISSIONS_GROUP_SPECIALIST) &&
                                <td
                                    onMouseOver={(e) => this.setPositionsLabelHelp(e, "Equivalência com Usuário no ERP")}
                                    onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                >
                                    Usuário ERP
                                </td>
                            }
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Grupo de Permissão")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Grupo de Permissão
                            </td>
                            <td
                                className="minimus75"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Trabalho")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Trabalho
                            </td>
                            <td
                                className="minimus75"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Supervisor")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Supervisor
                            </td>
                            <td
                                className="minimus200"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Turno")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Turno
                            </td>
                            <td
                                className="minimus60"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Ações")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditOwners">
                                        <td className="checkTableEditOwners">
                                            <Check
                                                classaditional={`buttonCheckTableEditOwners ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={async () => {
                                                    await this.props.onChangeValue(!e.checked, e.id, "checked")
                                                    this.props.hasAllOwnersChecked();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditOwners noEdit"
                                                value={e.id}
                                                placeholder={e.id}
                                                name="ID Usuário"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Usuário"
                                                onChange={value => this.props.onChangeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_NAME", e.name)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.mail}
                                                placeholder={e.mail}
                                                name="E-mail"
                                                onChange={value => this.props.onChangeValue(value, e.id, "mail")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_MAIL", e.mail)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />                              
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.password}
                                                placeholder="********"
                                                name="Senha"
                                                onChange={value => this.props.onChangeValue(value, e.id, "password")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PASSWORD", e.password)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />                              
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Unidade(s)"
                                                onPositiveClick={() => {
                                                    this.props.onSaveValue(e.id, "company_unit", this.state.optionsChosenCompanyUnitOwner)
                                                }}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                placeholder={this.getCompaniesCheckedFormated(e.unitCompanies)}
                                                permissions={this.props.permissions}
                                            >
                                                <SelectMulti
                                                    withFieldSearch
                                                    options={e.unitCompanies}
                                                    onChange={(optionsCompanyUnitOwner) => {
                                                        this.setState({optionsCompanyUnitOwner, optionsChosenCompanyUnitOwner: optionsCompanyUnitOwner});
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td>
                                        { 
                                            this.state.optionsPermissionGroupOwner.map(p => p.value).includes(ID_PERMISSIONS_GROUP_SPECIALIST) &&
                                            <td>
                                                <FieldEdit
                                                    classaditional="editTableEditOwners minimus500"
                                                    name="Usuário ERP"
                                                    onPositiveClick={() => {
                                                        this.props.onSaveValue(e.id, "PER_ID_USER_ERP", this.state.choosenUserERP.value)
                                                    }}
                                                    onNegativeClick={() => this.props.onIgnoreSave()}
                                                    optionsSelectMulti
                                                    placeholder={this.state.optionsUsersERP.filter(t => t.value === e.idUserERP)[0]?.name}
                                                    permissions={this.props.permissions}
                                                >
                                                    <Select
                                                        default={{
                                                            name: this.state.optionsUsersERP.filter(t => t.value === e.idUserERP)[0]?.name,
                                                            value: this.state.optionsUsersERP.filter(t => t.value === e.idUserERP)[0]?.value,
                                                            checked: true
                                                        }}
                                                        options={this.state.optionsUsersERP}
                                                        onChange={async (choosenUserERP) => {
                                                            await this.setState({choosenUserERP});
                                                            await this.props.onChangeValue(choosenUserERP.value, e.id, "idUserERP");
                                                        }}
                                                        withFieldSearch
                                                    />
                                                </FieldEdit>
                                            </td>
                                        }
                                        <td>
                                            <span className="companyEditOwners">
                                                {e.permissionGroup.name}
                                            </span>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Grupo de Permissão"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_ID_PERMISSIONS_GROUP", e.permissionGroup.value)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    classaditional="selectCompanyUnitForm w-100"
                                                    default={e.permissionGroup}
                                                    options={this.state.optionsPermissionGroupOwner} 
                                                    onChange={(optionChosenPermissionGroupOwner) => {
                                                        this.props.onChangeValue(optionChosenPermissionGroupOwner, e.id, "permissionGroup")
                                                    }}
                                                />
                                            </FieldEdit>  
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditOwners"
                                                name="Trabalho"
                                                onPositiveClick={() => {
                                                    this.props.onSaveValue(e.id, "PER_TYPE_WORKPLACE", this.state.optionChosenWorkplace.value)
                                                }}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                placeholder={this.state.optionsWorkplace.filter(t => t.value === e.typeWorkplace)[0]?.name}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: this.state.optionsWorkplace.filter(t => t.value === e.typeWorkplace)[0]?.name,
                                                        value: this.state.optionsWorkplace.filter(t => t.value === e.typeWorkplace)[0]?.value,
                                                        checked: true
                                                    }}
                                                    options={this.state.optionsWorkplace}
                                                    onChange={async (optionChosenWorkplace) => {
                                                        await this.setState({optionChosenWorkplace});
                                                        await this.props.onChangeValue(optionChosenWorkplace.value, e.id, "typeWorkplace");
                                                    }}
                                                    withFieldSearch
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditOwners ${ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(e.permissionGroup.id) ? "" : "noEdit"}`}
                                                name="Supervisor"
                                                onPositiveClick={() => {
                                                    this.props.onSaveValue(e.id, "PER_ID_PERSON_SUPERIOR_TEAM", this.state.optionChosenSupervisor.value)
                                                }}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                placeholder={this.state.optionsSupervisor.filter(t => t.value === e.supervisor)[0]?.name}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: this.state.optionsSupervisor.filter(t => t.value === e.supervisor)[0]?.name,
                                                        value: this.state.optionsSupervisor.filter(t => t.value === e.supervisor)[0]?.value,
                                                        checked: true
                                                    }}
                                                    options={this.state.optionsSupervisor}
                                                    onChange={async (optionChosenSupervisor) => {
                                                        await this.setState({optionChosenSupervisor});
                                                        await this.props.onChangeValue(optionChosenSupervisor.value, e.id, "supervisor");
                                                    }}
                                                    withFieldSearch
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditOwners ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                name="Turno"
                                                optionsSelectMulti
                                                placeholder={this.props.optionsWorkShifts.filter(o => o.value === e.shift)[0]?.name}
                                                onChange={() => {}}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "WKP_ID_PERSON", e.shift)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={this.props.optionsWorkShifts.filter(o => o.value === e.shift)[0]}
                                                    options={this.props.optionsWorkShifts}
                                                    onChange={async (optionChosenWorkShifts) => await this.props.onChangeValue(optionChosenWorkShifts.value, e.id, "shift")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            { 
                                                (
                                                    (e.permissionGroup.value === ID_PERMISSIONS_GROUP_SPECIALIST || ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT_AND_SPECIALIST === e.permissionGroup.value) &&
                                                    e.inQueue === false
                                                )
                                                &&
                                                (
                                                    <Button
                                                        title="Adicionar à fila"
                                                        classaditional="buttonShowConfigTableEditOwners"
                                                        icon="far fa-users-medical"
                                                        onClick={() => this.addPersonToQueue(e.id, e.unitCompanies)}
                                                    />
                                                )   
                                            }
                                            { 
                                                (
                                                    ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(e.permissionGroup.value) &&
                                                    !e.inQueuePreSale
                                                ) 
                                                ?
                                                (
                                                    <Button
                                                        title="Adicionar à fila de Pré-Atendimento"
                                                        classaditional="buttonShowConfigTableEditOwners"
                                                        icon="far fa-users-medical"
                                                        onClick={() => this.props.onInsertPersonToPreSaleQueue(e.id)}
                                                    />
                                                )
                                                :
                                                null
                                            }
                                            { 
                                                (
                                                    ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(e.permissionGroup.value) &&
                                                    e.inQueuePreSale
                                                ) 
                                                ?
                                                (
                                                    <Button
                                                        title="Remover da fila de Pré-Atendimento"
                                                        classaditional="buttonShowConfigTableEditOwners error"
                                                        icon="far fa-user-times"
                                                        onClick={() => this.props.onRemovePersonToPreSaleQueue(e.id)}
                                                    />
                                                )
                                                :
                                                null
                                            }
                                            {
                                                (
                                                    (e.permissionGroup.value !== ID_PERMISSIONS_GROUP_SPECIALIST && e.permissionGroup.value !== ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT_AND_SPECIALIST) &&
                                                    !ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(e.permissionGroup.value)
                                                )
                                                &&
                                                (
                                                    <div className={`fieldEdit editTableEditOwners`}></div>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;
    const { user } = state.auth;

    return {
        changeDeal,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersOwners (data)
        {
            const action = getAllUsersOwners(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableEditOwners));