import {
    lead, 
    note, 
    owner, 
    months,
    person, 
    contact, 
    activity, 
    business, 
    campaign,
    unitCompany,
    commissionRule,
    permissionGroup,
    businessByClient,
    commissionReport,
    absenceAttendants,
    commissionRuleProspection,
    serverErrorDefaultMessage,
    unauthorizedDefaultMessage,
    unknowApiErrorDefaultMessage,
    commissionRuleIntervals,
    commissionReportIntervals,
} from "../core/constants";

//param name String example: ewerton Fernandes Dias
//return name String example: EF
export const getInitialsName = (name) => 
{

    if (name.length > 0)
    {
        if (name.indexOf(" ") > 0)
        {
            let parts = name.split(" ");
            parts = parts.map((word, i) => {
                return (i <= 1) ? word.charAt(0).toString().toUpperCase() : null;
            });

            name = parts.join("");
        }
    }

    return name;
}

//param name String exemple: joÃo GUSTAVO Silverino
//return name String example: João Gustavo Silverino
export const getFullNameFisrtCharsUppercase = (name) => 
{

    if (name.length > 0)
    {
        if (name.indexOf(" ") > 0)
        {
            let parts = name.split(" ");
            parts = parts.map((word, i) => {
                word = word.toLowerCase();
                return word = word.charAt(0).toUpperCase()+word.substr(1, word.length);
            });

            name = parts.join(" ");
        }
    }

    return name;
}

//@param [nothing]
//return {window.width: 1024, window.height: 720}
export const getWindowDimensions = () =>
{
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const getPortugueseTypeComponent = (name) =>
{
    switch (name) {
        case lead:
            return "Lead";

        case note:
            return "Nota";

        case owner:
            return "Usuário";

        case person:
            return "Pessoa";

        case contact:
            return "Contato";

        case business:
            return "novo Cliente e Negócio";

        case campaign:
            return "Campanha";

        case activity:
            return "Atividade";

        case unitCompany:
            return "Unidade";

        case commissionRule:
            return "Regra de Comissionamento";

        case permissionGroup:
            return "Grupo de Permissão";

        case commissionReport:
        case commissionReportIntervals:
            return "Comissão";
            
        case businessByClient:
            return "Negócio para o Cliente";

        case absenceAttendants:
            return "Falta (atendentes)";

        case commissionRuleProspection:
            return "Regra de Comissão (Operação)";

        case commissionRuleIntervals:
            return "Regra de Comissão (Limpa Nome)";

        default:
            return "";
    }
}

//return status Boolean true/false
export const isEmail = (mail) => 
{
    const re = /\S+@\S+\.\S+/;
    return re.test(mail);
}

// param phone String example: 1122224444/11922223333
//return phone (formated) String example: (11)2222-4444/(11)92222-3333
export const formatedPhoneAndCellphone = (number) => 
{
    number = formatedOnlyNumber(number.toString());
    if (number.length > 11) {
        number = number.substr(-11);
        if (parseInt(number[2]) !== 9) {
            number = number.substr(-10);
        }
    }

    number = number.replace(/\D/g,"")
    number = number.replace(/^(\d)/,"($1")
    number = number.replace(/(.{3})(\d)/,"$1)$2")
    if(number.length === 9) {
        number = number.replace(/(.{1})$/,"-$1")
    } else if (number.length === 10) {
        number = number.replace(/(.{2})$/,"-$1")
    } else if (number.length === 11) {
        number = number.replace(/(.{3})$/,"-$1")
    } else if (number.length === 12) {
        number = number.replace(/(.{4})$/,"-$1")
    } else if (number.length > 12) {
        number = number.replace(/(.{4})$/,"-$1")
    }
    return number;
}

//param money String example: 123099
//return money (formated) String example: 1.230,99
export const formatedMoneyBRA = (money) => 
{
    money = money.replace(/\D/g,"");
    money = money.replace(/(\d{1})(\d{14})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{11})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{8})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{5})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{1,2})$/,"$1,$2");
    return money;
}

//param money Float example: 9636.869999999999
//return money (formated) String example: 9.636,87
export const formatedMoneyBRAFromFloat = (number) =>
{
    return number.toLocaleString('pt-br', {minimumFractionDigits: 2});
}

//param money String (formatedMoneyBRA) example 1.230,99
//return number Float example 1230.99
export const formatedMoneyBRAToFloat = (money) =>
{
    money = money.replace(".", "");
    money = money.replace(",", ".");
    return parseFloat(money);
}

// param time String example: 1735
// return time (formated) String example: 17:35
export const formatedTime = (string) =>
{
    if (string.length > 2 && string.indexOf(":") === -1)
    {
        let h = string.substr(0, 2);
        let m = string.substr(2, string.length);
        return h+":"+m;
    }

    return string;
}

//param time String example: 25:35 OR 12:35 DANGER: length of string must be have 5 chars
//return time (valided) String example: 00:00 OR 12:35
export const validTime = (time) =>
{
    if (time.length > 4 && time.indexOf(":") > 0)
    {
        let parts = time.split(":");
        let h = parseInt(parts[0]);
        let m = parseInt(parts[1]);
        let validH = false;
        let validM = false;

        for (let i = 0; i <= 23; i++) 
        {
            if (i === h) validH = true;

            for (let j = 0; j < 60; j++) {
                if (j === m) validM = true;        
            }            
        }

        if (validH && validM) return time;

        return "00:00";
    }

}

// param date String example: 2022-01-32
// return Boolean example: false
export const validDate = (date) =>
{   
    //whether the conversion to browser date not occurred correctly formatedDateTimeToBrowserAtMidnight()
    let browser = identifiesBrowser();
    let conversion = formatedDateTimeToBrowserAtMidnight(date);
    conversion = (browser.isSafari) ? conversion.toDateString() : conversion;
    let dateConverted = formatedDateTimeToUSA(conversion);
    let hasInstance = null;
    if (!browser.isSafari)
    { hasInstance = (conversion.toString().includes("00:00")) ? true : false; }
    else
    { hasInstance = dateConverted.toString().includes("aN") ? false : true; }

    return hasInstance && (dateConverted === date);
}

//param value String example: 1s33ff566gw
//return value (formated) number example: 133566
export const formatedOnlyNumber = (string) => 
{
    string = string.replace(/\D/g,"");
    return string;
}

//param value String example: 3eeert25 OR 3eeeert25
//return value String (formated) number example: 3,25
export const formatedPercentageNumber = (string) => 
{
    string = string.toString().replace(/\D/g,"");

    if (string.length >= 3)
    {
        let partOne, partTwo = "";
        partOne = string.substr(0, string.length - 2);
        partTwo = string.substr(-2);
        string = partOne+","+partTwo;
    }

    if (string.length === 2)
    {
        let partOne, partTwo = "";
        partOne = string.substr(0, string.length - 1);
        partTwo = string.substr(-1);
        string = partOne+","+partTwo;
    }
    
    return string;
}

//param value Float example: 1.8891111
//return value String (formated) number example: 1,89
export const formatedPercentageNumberFromFloat = (number) =>
{
    return parseFloat(number).toFixed(2).toString().replace(".", ",");
}

//param DateTime String example: Mon Nov 15 2021 12:21:19 GMT-0300 (Horário Padrão de Brasília) OR 2021-11-15
//return Date (BRA) String example: 15/11/2021
export const formatedDateTimeToBRA = (date) =>
{
    let hasFormatedUSA = (date.toString().split("-").length === 3) ? true : false;
    
    if (!hasFormatedUSA)
    {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }
    else
    {
        let parts = date.toString().split("-");
        let year = parts[0],
            month = parts[1],
            day = parts[2];
        return [day, month, year].join("/");
    }

}

//param DateTime String example: Thu Feb 03 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
//return Date (USA) String example: 2022-02-03
export const formatedDateTimeToUSA = (date) =>
{
    let hasFormatedUSA = (date.toString().split("-").length === 3) ? true : false;
    if (!hasFormatedUSA)
    {  
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();    
        day = ("0" + day).slice(-2);
        month = ("0" + month).slice(-2);
    
        return `${year}-${month}-${day}`;
    }
    else
    {
        return date;
    }
}

//param Date example: 2022-12
//return String example Dez/2022 
export const formatedMonthAndYear = (date) =>
{
    let ms = months;
    let parts = date.toString().split("-");
    let year = parts[0];
    let month = ms.filter(m => m.order === (parts[1] - 1))[0].initials;

    return  `${month}/${year}`;
}

// Functions Object (differences between dates in different formats)
export const dateDiff = {

    inDays: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000));
    },

    inWeeks: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000*7));
    },

    inMonths: function(d1, d2) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return (d2M+12*d2Y)-(d1M+12*d1Y);
    },

    inYears: function(d1, d2) {
        return d2.getFullYear()-d1.getFullYear();
    }
}

export const removeAccents = (string) =>
{
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const apiCallbackTreatment = (response) => 
{
    switch (response.code) {
        case 200:
            return {
                status: true,
                data: response.data
            };
        case 201:
            return {
                status: true,
                data: response.data
            };
        case 401:
        case 403:
            return {
                status: false,
                message: unauthorizedDefaultMessage
            };
        case 412:
            return {
                status: false,
                message: Object.values(response.data.errors)[0].toString()
            };
        case 500:
            return {
                status: false,
                message: serverErrorDefaultMessage
            };
        default:
            return {
                status: false,
                message: unknowApiErrorDefaultMessage
            };
    }
}

//param Array example: [{name: Baaa, ...}, {name: Aaaa, ...}, ...]
//return Array example: [{name: Aaaa, ...}, {name: Baaa, ...}, ...]
export const sortInAlphabeticalOrder = (arrayObjects) =>
{
    return arrayObjects.sort(function (a, b) 
    {
        if (a.name > b.name) 
        {
          return 1;
        }
        if (a.name < b.name) 
        {
          return -1;
        }
        return 0;
    });

}

// return Object Thu Feb 03 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
export const getDateTimeCurrentBrowserAtMidnight = () =>
{
    //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let dateCurrent = new Date();
    let y, m, d = 0;
    let timezone = (browser.isSafari) ? "T03:00" : "T00:00";
    y = dateCurrent.getFullYear();
    d = dateCurrent.getDate();
    d = ("0" + d).slice(-2);
    m = dateCurrent.getMonth();
    m = ("0" + (m + 1)).slice(-2);

    return new Date(`${y}-${m}-${d}${timezone}`); //browser's current date at midnight.
}

// return Object Thu Feb 03 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
export const getDateTimeCurrentBrowserAtAnyTime = () =>
{
    //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let dateCurrent = new Date();
    let y, m, d = 0;
    y = dateCurrent.getFullYear();
    d = dateCurrent.getDate();
    d = ("0" + d).slice(-2);
    m = dateCurrent.getMonth();
    m = ("0" + (m + 1)).slice(-2);

    let hours, minutes, seconds = 0;
    hours = dateCurrent.getHours();
    minutes = dateCurrent.getMinutes();
    seconds = dateCurrent.getSeconds();

    if (browser.isSafari) { hours = (hours + 3); }

    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().substring(0, 2);

    let timezone = `T${hours}:${minutes}:${seconds}`;
    
    return new Date(`${y}-${m}-${d}${timezone}`); //browser's current date at midnight.
}

// return String "2023-08-21 14:10:59"
export const getDateTimeCurrentBrowserUSA = () =>
{
    let dateCurrent = new Date();
    let y, m, d = 0;
    y = dateCurrent.getFullYear();
    d = dateCurrent.getDate();
    d = ("0" + d).slice(-2);
    m = dateCurrent.getMonth();
    m = ("0" + (m + 1)).slice(-2);

    let hours, minutes, seconds = 0;

    hours = dateCurrent.getHours().toString().padStart(2, "0");
    minutes = dateCurrent.getMinutes().toString().padStart(2, "0");
    seconds = dateCurrent.getSeconds().toString().substring(0, 2).padStart(2, "0");
    
    return (`${y}-${m}-${d} ${hours}:${minutes}:${seconds}`); //browser's current date at midnight.
}

// NOTE: assuming today is: 2022-08-02
// param Integer amountBefore example: 2
// return Object Sun Jul 31 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
export const getPreviousDateTimeCurrentBrowserAtMidnight = (amountBefore) =>
{
    //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let dateCurrent = new Date(Date.now() - amountBefore * 24 * 60 * 60 * 1000);
    let y, m, d = 0;
    let timezone = (browser.isSafari) ? "T03:00" : "T00:00";
    y = dateCurrent.getFullYear();
    d = dateCurrent.getDate();
    d = ("0" + d).slice(-2);
    m = dateCurrent.getMonth();
    m = ("0" + (m + 1)).slice(-2);

    return new Date(`${y}-${m}-${d}${timezone}`); //browser's current date at midnight.

}

// NOTE: assuming today is: 2024-03-01
// param Integer amountAfter example: 1
// return Object Sat Mar 02 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)
export const getNextDateTimeCurrentBrowserAtMidnight = (amountAfter) =>
{
    //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let dateCurrent = new Date(Date.now() + amountAfter * 24 * 60 * 60 * 1000);
    let y, m, d = 0;
    let timezone = (browser.isSafari) ? "T03:00" : "T00:00";
    y = dateCurrent.getFullYear();
    d = dateCurrent.getDate();
    d = ("0" + d).slice(-2);
    m = dateCurrent.getMonth();
    m = ("0" + (m + 1)).slice(-2);

    return new Date(`${y}-${m}-${d}${timezone}`); //browser's current date at midnight.

}

// param Integer indexDayOnWeek example: 1 (0 for Sunday, 1 for Monday...)
// param String chosenMonthYear example: "2022-06"
// return Array of Objects example: [
// 0: {dateUTC: Mon Jun 06 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília), dateUSA: '2022-06-06', stamp: 1654484400000}
// 1: {dateUTC: Mon Jun 13 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília), dateUSA: '2022-06-13', stamp: 1655089200000}
// 2: {dateUTC: Mon Jun 20 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília), dateUSA: '2022-06-20', stamp: 1655694000000}
// 3: {dateUTC: Mon Jun 27 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília), dateUSA: '2022-06-27', stamp: 1656298800000}
// ]
export const getAllSpecificDaysOnMonth = (indexDayOnWeek, chosenMonthYear) => 
{
    let specific = [];

    for (let i = 1; i <= 31; i++)
    {
        let isDay = (chosenMonthYear+"-"+i.toString().padStart(2, "0"));
        let valid = validDate(isDay);
        
        if (valid)
        {
            let day = formatedDateTimeToBrowserAtMidnight(isDay);
            
            if (day.getDay() === indexDayOnWeek) 
            { 
                specific.push({
                    dateUTC: day,
                    dateUSA: isDay,
                    stamp: day.getTime()
                }); 
            }
        }
    }

    return specific;
}

// param Date string example: 2022-02-02
//return DateTime string example: Wed Feb 02 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
export const formatedDateTimeToBrowserAtMidnight = (date) =>
{
    // more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let timezone = (browser.isSafari) ? "T03:00" : "T00:00";
    
    return new Date(`${date}${timezone}`);
}

// param Date string example: 2022-02-02 09:45:59
//return DateTime string example: Wed Feb 02 2022 09:45:59 GMT-0300 (Horário Padrão de Brasília)
export const formatedDateTimeToBrowserAtAnyTime = (datetime) =>
{
    // more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
    let browser = identifiesBrowser();
    let partsDate = datetime.split(" ");
    let date = partsDate[0];
    let time = partsDate[1];
    let timezone = `T${time}`;

    if (browser.isSafari)
    {
        // plus 3 hours, for safari browser
        let partsTime = time.split(":");
        let hours = partsTime[0];
        let minutes = partsTime[1];
        let seconds = partsTime[2];
        // hours = (parseInt(hours) + 3);
        hours = hours.toString().padStart(2, "0");
        timezone = `T${hours}:${minutes}:${seconds}`;
    }
    
    return new Date(`${date}${timezone}`);
}

// WARNING: starting monday, not sunday!
// param Date example [Date(2022, 1, 9)] OR [Wed Feb 09 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)]
// return Array [
//               0: Mon Feb 07 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               1: Tue Feb 08 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               2: Wed Feb 09 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               3: Thu Feb 10 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               4: Fri Feb 11 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               5: Sat Feb 12 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
//               6: Sun Feb 13 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília) {}
// ]
// NOTE: https://stackoverflow.com/questions/43008354/js-get-days-of-the-week
export const getDatesOfTheWeek = (date) =>
{
    var week = []; 

    date.setDate((date.getDate() - date.getDay() +1));

    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(date)
        ); 
        date.setDate(date.getDate() +1);
    }
    return week; 
}

// param String example: 99,98
// return Float example: 99.98
export const formatedPercentToFloat = (string) =>
{
    string = string.replace(",", ".");
    return parseFloat(string);
}

// param Array options example: [{name: "Mary", value: 1, checked: true}, {name: "John", value: 2, checked: true}, {name: "Paul", value: 3, checked: false}]
// param String expressionAllPluralChecked example: "[Todos Clientes]" (when options have no a checked)
// param String expressionCountPluralChecked example: "clientes" (when options have more than one checked)
// return String example: "2 clientes" (in this case)
//OR
// return String example: "Todos Clientes" (if have not one checked)
// OR
// return String example: "Mary" (if only Mary is checked)
export const getOptionsSelectCheckedFormated = (options, expressionAllPluralChecked, expressionCountPluralChecked) =>
{
    let checkeds = expressionAllPluralChecked;
    let plus = 0;
    let display = "";

    options.forEach((obj, i) => {
        if (obj.checked)
        {
            plus++;
            if (plus === 1)
            {
                checkeds = obj.name;
            }                
        }
    });

    if (plus <= 0)
    {
        display = checkeds;
    }
    else
    {
        if (plus === 0)
        {
            display = checkeds;
        }
        else
        {
            if (plus === 1)
            {
                display = checkeds;
            }
            else
            {
                checkeds = expressionCountPluralChecked;
                display = " "+plus+" "+checkeds;
            }
        }

    }
    return display.toString();
}

// return Array dates (in midnight) examples: 
// [
//   0: (first day on month) Tue Feb 01 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
//   1: (last day on month) Mon Feb 28 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)
// ]
export const getFirstAndLastDayOnMonth = () =>
{
    let date = getDateTimeCurrentBrowserAtMidnight();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return [firstDay, lastDay]
}

// param String date example: 2022-03-31 12:54:53 (pattern USA)
// return String date example: 31/03/2022 ÀS 12:54:53 (pattern BRA)
export const formatedDateTime = (string) => 
{
    let parts = string.split(" ");
    let date = formatedDateTimeToBRA(parts[0]);
    let time = parts[1];
    return `${date} às ${time}`;
}

// param String example: "Abc@123"
// return Boolean example: true
export const hasSpecialChar = (string) =>
{
    let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    return format.test(string);
}

// param String example: "abCsde@1234"
// return String example: "abCsde"
export const onlyLetters = (string) =>
{
    return string.replace(/[^a-zA-Z]+/g, '');
}

// return Object example:
// {
//     name: "Google Chrome"
//     code: 5
//     isChrome: true
//     isEpiphany: false
//     isFirefox: false
//     isIEorEdge: false
//     isOpera: false
//     isSafari: false
// }
export const identifiesBrowser = () =>
{
    let browser = {
        name: "[Não-Identificado]",
        code: 0,
        isOpera: false,
        isIEorEdge: false,
        isEpiphany: false,
        isChrome: false,
        isFirefox: false,
        isSafari: false,
    };

    if (navigator.userAgent.toLowerCase().indexOf('op') > -1) 
    {
        browser.name = "Opera";
        browser.code = 1;
        browser.isOpera = true;
    }
    else if (navigator.userAgent.indexOf('MSIE') > -1)
    {
        browser.name = "IE ou Edge";
        browser.code = 2;
        browser.isIEorEdge = true;
    }
    else if (navigator.userAgent.indexOf('Firefox') > -1)
    {
        browser.name = "Firefox";
        browser.code = 3;
        browser.isFirefox = true;
    }
    else if (navigator.userAgent.indexOf('Epiphany') > -1)
    {
        browser.name = "Epiphany";
        browser.code = 4;
        browser.isEpiphany = true;
    }
    else if (navigator.userAgent.indexOf('Chrome') > -1) 
    {
        browser.name = "Chrome";
        browser.code = 5;
        browser.isChrome = true;
    }
    else if (navigator.userAgent.indexOf('Safari') > -1) {
        browser.name = "Safari";
        browser.code = 6;
        browser.isSafari = true;
    }

    return browser;
}

// param String example: "2022-07"
// param Integer example: 2
// return String example: "2022-05"
export const getPreviousMonth = (chosenMonthYear, amountBefore) =>
{
    let instanceDate = formatedDateTimeToBrowserAtMidnight(`${chosenMonthYear}-01`);
    var date = new Date(instanceDate);
    let datePrevious = formatedDateTimeToUSA(date.setMonth(date.getMonth() - amountBefore));
    let parts = datePrevious.split("-");
    return [parts[0], parts[1]].join("-");
}

// return String example: "Boa tarde"
export const getGreetingMessage = () => {
    let date = new Date();
    date.toLocaleTimeString('pt-BR', { hour: 'numeric', hour12: false });
    let h = date.getHours();
    return (h <= 5) ? 'Boa madrugada' :
            (h < 12) ? 'Bom dia' :
            (h < 18) ? 'Boa tarde' :
            'Boa noite';
}

// return String example: "04:05"
export const getCurrentTimeBrowser = () =>
{
    let currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

// return Float example: 12345.98889998
export const getRandomNumber = () =>
{
    let d = new Date();
    let rdnOne = parseInt(Math.random() * 1000);
    let rdnTwo = (Math.random() * 12345);
    return (d.getMilliseconds() + rdnOne) * rdnTwo;
}

// param string example: "5511959988098"
// rturn String overshawdoed: "5511959****98"
export const overshadowsPhone = (string) =>
{
    if (string?.length > 0)
    {
        let replace = string.slice((string.length -6), (string.length -2));
        return string.replace(replace, "****");
    }
}