import React, { Component } from "react";  
import "./BusinessForm.css";

import { withRouter } from "react-router-dom";
import { optionsFrequencyTypePhone, optionsFrequencyTypeMail } from "../../core/constants";
import { isEmail, formatedMoneyBRA, getFullNameFisrtCharsUppercase, formatedOnlyNumber } from "../../auxiliary/generalFunctions";
import { connect } from "react-redux";
import { newDealBusiness } from "../../store/actions/business";
import { showModalMoreActions }  from "../../store/actions/general";

import Select from "../general/Select";
import Button from "../general/Button";
import ProgressLine from "./ProgressLine";
import InputGeneral from "../general/InputGeneral";

import BusinessController from "../../controllers/BusinessController";
import ClientController from "../../controllers/ClientController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import UserController from "../../controllers/UserController";
import BusinessServices from "../../controllers/BusinessServicesController";

const initialState = { 
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name 
    
    // start content midiaOrigin
    optionsMidiaOrigin: [],
    optionChosenMidiaOrigin: null,
    validMidiaOrigin: true,
    // ends content stampsLead 

    // start content phoneOne
    phoneOne: "",
    searchingPhoneOne: false,
    warningPhoneOne: false,
    noticesPhoneOne: [
        <span>aviso 1</span>,
        <span>aviso 2</span>,
    ],
    validPhoneOne: true,
    optionsPhoneOne: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneOne: null,
    // ends content phoneOne 

    // start content phoneTwo
    phoneTwo: "",
    searchingPhoneTwo: false,
    warningPhoneTwo: false,
    noticesPhoneTwo: [],
    validPhoneTwo: true,
    optionsPhoneTwo: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneTwo: null,
    // ends content phoneTwo 

    // start content mail
    mail: "",
    searchingMail: false,
    warningMail: false,
    noticesMail: [],
    validMail: true,
    optionsMail: optionsFrequencyTypeMail,
    optionChosenFrequencyTypeMail: {},
    // ends content mail

    // start content title
    title: "",
    validTitle: true,
    // ends content title

    // start content money
    money: "",
    validMoney: true,
    // ends content money

    // start content step
    step: 0,
    // ends content step

    // start content companyUnit
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: null,
    validCompanyUnit: true,
    // ends content companyUnit

    // start content businessServices
    optionsBusinessServices: [],
    optionChosenBusinessServices: null,
    validBusinessServices: true,
    // ends content businessServices

    // start content button
    disabledButton: false
    // ends content button
}

const businessController = new BusinessController();
const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();
const userController = new UserController();
const businessServices = new BusinessServices();

class BusinessForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        await this.getCompanyUnit();
        await this.getMidiaOrigin();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        };

        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({searchingName: true, warningName: false, validName: true});
        this.setState({name});

        setTimeout(() => { this.setState({searchingName: false}); }, 1000);
    }

    phoneOneChangeAndChecking = (phoneOne) =>
    {
        this.setState(
            {
                searchingPhoneOne: true, 
                warningPhoneOne: false, 
                validPhoneOne: true,
                phoneOne
            }
        );

        setTimeout(() => { this.setState({searchingPhoneOne: false, warningPhoneOne: false}); }, 1000);
    }

    phoneTwoChangeAndChecking = (phoneTwo) =>
    {
        this.setState(
            {
                searchingPhoneTwo: true, 
                warningPhoneTwo: false,
                phoneTwo
            }
        );

        setTimeout(() => { this.setState({searchingPhoneTwo: false, warningPhoneTwo: false}); }, 1000);
    }

    mailChangeAndChecking = (mail) =>
    {
        this.setState({searchingMail: true, warningMail: false});
        this.setState({mail, validMail: isEmail(mail)});

        setTimeout(() => { this.setState({searchingMail: false, warningMail: false}); }, 1000);
    }

    titleChangeAndChecking = (title) =>
    {
        this.setState({title});
    }

    moneyChangeAndChecking = (money) =>
    {
        this.setState({money: formatedMoneyBRA(money), validMoney: true});
    }
    
    getCompanyUnit = async () => 
    {
        const unitCompanies = await userController.getById(this.props.user.PER_ID);
        let optionsCompanyUnit = [];
        let optionChosenCompanyUnit = null;
        
        for (const company of unitCompanies.data.unit_companies) 
        {
            optionsCompanyUnit.push(
                {
                    id: company.UNT_ID,
                    name: company.UNT_NAME,
                    value: company.UNT_ID,
                }
            );
        }

        // ...setting a companyUnit as default
        if (optionsCompanyUnit.length > 0) 
        { 
            let companyUnit = optionsCompanyUnit[0]
            optionChosenCompanyUnit = {...companyUnit};
            await this.getBusinessServices(companyUnit.id);
        }

        await this.setState({ optionsCompanyUnit, optionChosenCompanyUnit });
    }

    getMidiaOrigin = async () => 
    {
        const result = await midiaOriginController.get();
        let options = [];

        if (result.status) 
        {
            for (const midiaOrigin of result.data.data) 
            {
                if (midiaOrigin.MID_STATUS === 1)
                {
                    options.push(
                        {
                            id: midiaOrigin.MID_ID,
                            name: midiaOrigin.MID_NAME,
                            value: midiaOrigin.MID_ID,
                        }
                    );
                }
            }
        }

        this.setState({ optionsMidiaOrigin: options });
    }

    getBusinessServices = async (id) =>
    {
        const result = await businessServices.getByIdCompanyUnit(id);
        let optionsBusinessServices = [];

        if (result.status)
        {
            for (let service of result.data)
            {
                optionsBusinessServices.push(
                    {
                        id: service.BUN_ID_BUSINESS_SERVICE,
                        name: service.BUP_NAME,
                        value: service.BUN_ID_BUSINESS_SERVICE,
                    }
                );
            }

            await this.setState({optionsBusinessServices});
        }
    }

    zeroPad = (num, places) => String(num).padStart(places, '0')

    onActionForm = async () =>
    {
        if (this.state.name.trim() === "") 
        {
            return this.setState({ validName: false });
        }

        if (this.state.optionChosenMidiaOrigin === null) 
        {
            return this.setState({ validMidiaOrigin: false });
        }

        if (this.state.optionChosenBusinessServices === null)
        {
            return this.setState({validBusinessServices: false});
        }

        if (formatedOnlyNumber(this.state.phoneOne.trim()) === "") 
        {
            return this.setState({ validPhoneOne: false });
        }

        if (this.state.mail !== "" && !isEmail(this.state.mail))
        {
            return this.setState({ validMail: false });
        }

        if (this.props.user.unit_companies.length > 1 && this.state.optionChosenCompanyUnit === null) 
        {
            return this.setState({ validCompanyUnit: false });
        }

        if (this.state.title.trim() === "")
        {
            return this.setState({ validTitle: false });
        }

        if (this.state.money.trim() === "")
        {
            return this.setState({ validMoney: false });
        }

        await this.setState({ disabledButton: true });

        const stamp = 1;
        const date = new Date();
        const day = `${date.getFullYear()}-${this.zeroPad((date.getMonth() + 1), 2)}-${this.zeroPad(date.getDate(), 2)}`;
        const typePhone = "CELULAR";
        const companyUnit = this.props.user.unit_companies.length > 1 ? this.state.optionChosenCompanyUnit.value : this.state.optionsCompanyUnit[0].value;

        const data = {
            PER_NAME: getFullNameFisrtCharsUppercase(this.state.name),
            PER_MAIL: this.state.mail,
            PER_PHONE_ONE: formatedOnlyNumber(this.state.phoneOne),
            PER_TYPE_PHONE_ONE: typePhone,
            PER_PHONE_TWO: formatedOnlyNumber(this.state?.phoneTwo),
            PER_TYPE_PHONE_TWO: typePhone,
            PER_ID_MIDIA_ORIGIN: this.state.optionChosenMidiaOrigin.value,
            PER_STAMP: stamp,
            PER_ID_PERSON_TYPE: 2,
            PER_ID_COMPANY_UNIT: companyUnit,
            PER_ID_CREATE_PERSON: this.props.user.PER_ID
        }

        const result = await clientController.create(data);

        if (!result.status) 
        {
            await this.setState({ disabledButton: false });
            return alert(`ERRO: ${result.message}`);
        }

        const client = result.data;

        const business = await businessController.create(
            {
                BUS_TITLE: getFullNameFisrtCharsUppercase(this.state.title),
                BUS_STEP: this.state.step + 1,
                BUS_ID_PERSON_CLIENT: client.PER_ID,
                BUS_ID_STAMP: stamp,
                BUS_MONEY_VALUE: this.state.money.replaceAll(".", "").replace(",", "."),
                BUS_DATE_CREATED: day,
                BUS_ID_CREATE_PERSON: this.props.user.PER_ID,
                BUS_ID_BUSINESS_SERVICE: this.state.optionChosenBusinessServices.value
            }
        );

        if (!business.status)
        {
            await this.setState({ disabledButton: false });
            return alert(`ERRO: ${business.message}`);
        }

        await this.setState({ disabledButton: false });
        this.closeModalMoreActions();

        setTimeout(() => { this.props.history.push(`/business/details/${business.data.BUS_ID}`); }, 1000);

    }

    render () 
    {
        return (
            <div className="businessForm"> 
                <div className="row no-gutters">
                    <div className="col-6 pr-2">
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="Nome"
                                    value={this.state.name}
                                    onChange={e => this.nameChangeAndChecking(e.target.value)}
                                    searching={this.state.searchingName.toString()}
                                    warning={this.state.warningName.toString()}
                                    warningcontent={this.state.noticesName}
                                    mandatory="true"
                                    valid={this.state.validName.toString()}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <Select 
                                    label="Fonte"
                                    default={this.state.optionChosenMidiaOrigin}
                                    options={this.state.optionsMidiaOrigin} 
                                    mandatory="true"
                                    onChange={(optionChosenMidiaOrigin) => {
                                        this.setState({optionChosenMidiaOrigin});
                                        if (this.state.validMidiaOrigin === false)
                                        {
                                            this.setState({ validMidiaOrigin: true });
                                        }
                                    }}
                                    valid={this.state.validMidiaOrigin.toString()}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="Telefone Principal"
                                    value={this.state.phoneOne}
                                    onChange={e => this.phoneOneChangeAndChecking(e.target.value)}
                                    searching={this.state.searchingPhoneOne.toString()}
                                    warning={this.state.warningPhoneOne.toString()}
                                    warningcontent={this.state.noticesPhoneOne}
                                    mandatory="true"
                                    
                                    valid={this.state.validPhoneOne.toString()}
                                    maxLength="14"
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="Telefone"
                                    value={this.state.phoneTwo}
                                    onChange={e => this.phoneTwoChangeAndChecking(e.target.value)}
                                    searching={this.state.searchingPhoneTwo.toString()}
                                    warning={this.state.warningPhoneTwo.toString()}
                                    warningcontent={this.state.noticesPhoneTwo}
                                    valid={this.state.validPhoneTwo.toString()}
                                    maxLength="14"
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="E-mail"
                                    value={this.state.mail}
                                    onChange={e => this.mailChangeAndChecking(e.target.value)}
                                    searching={this.state.searchingMail.toString()}
                                    warning={this.state.warningMail.toString()}
                                    warningcontent={this.state.noticesMail} 
                                    valid={this.state.validMail.toString()}
                                />
                            </div>
                        </div>
                        {
                            this.props.user.unit_companies.length > 1 &&
                                <div className="row no-gutters linePersonForm">
                                    <div className="col-12 columnPersonForm">
                                        <Select 
                                            label="Unidade"
                                            default={this.state.optionChosenCompanyUnit}
                                            options={this.state.optionsCompanyUnit} 
                                            onChange={async (optionChosenCompanyUnit) => {
                                                await this.setState({optionChosenCompanyUnit});
                                                await this.getBusinessServices(optionChosenCompanyUnit.value);

                                                if (this.state.validCompanyUnit === false)
                                                {
                                                    this.setState({ validCompanyUnit: true });
                                                }
                                            }}
                                            valid={this.state.validCompanyUnit.toString()}
                                            mandatory
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-6 pl-2">
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="Título"
                                    value={this.state.title}
                                    onChange={e => this.titleChangeAndChecking(e.target.value)}
                                    mandatory="true"
                                    valid={this.state.validTitle.toString()}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <InputGeneral
                                    label="Valor"
                                    value={this.state.money}
                                    onChange={e => this.moneyChangeAndChecking(e.target.value)}
                                    valid={this.state.validMoney.toString()}
                                    mandatory="true"
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <Select 
                                    label="Produto"
                                    default={this.state.optionChosenBusinessServices}
                                    options={this.state.optionsBusinessServices} 
                                    onChange={(optionChosenBusinessServices) => {
                                        this.setState({optionChosenBusinessServices});
                                        if (this.state.validBusinessServices === false)
                                        {
                                            this.setState({ validBusinessServices: true });
                                        }
                                    }}
                                    valid={this.state.validBusinessServices.toString()}
                                    mandatory
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessForm">
                            <div className="col-12 columnBusinessForm">
                                <label className="stepBusinessForm">Etapa do Funil</label>
                                <ProgressLine step={this.state.step} classaditional="progressLineBusinessForm" onChangeStep={(value) => this.setState({ step: value })} />
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="row no-gutters lineBusinessForm">
                    <div className="col-12 columnBusinessForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterBusinessForm">
                    <Button
                        classaditional="buttonBusinessForm"
                        name="Cancelar"
                        onClick={() => this.props.showModalMoreActions({show: false, type: null})}
                        />
                    <Button
                        classaditional="buttonBusinessForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const {allDraggables} = state.business;

    return {
        allDraggables,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        newDealBusiness (data)
        {
            //action creator -> action
            const action = newDealBusiness(data);
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessForm));